import icsLogoEn from '../../resources/images/ics-logo_en.svg';
import icsLogoRu from '../../resources/images/ics-logo_ru.svg';
import { Features } from '../../types';

const features: Features = {
    loginWithoutRegister: true,
    headerLogo: {
        isEnabled: true,
        locales: {
            ru: {
                image: icsLogoRu,
                alt: 'TOT Select логотип',
                style: { width: '130px', height: '28px' },
            },
            en: {
                image: icsLogoEn,
                alt: 'TOT Select logo',
                style: { width: '113px', height: '28px' },
            },
        },
    },
    helpIcon: true,
    notificationIcon: true,
    phoneNumber: true,
    esiaLogin: true,
    globalSearch: true,
    showCreateRequestButton: true,
    showRequestsCount: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: {
        isEnabled: true,
        providers: [
            {
                code: 'onelogin',
                title: 'OneLogin',
            },
        ],
    },
    showCampaignPeriod: true,
    allUsersRedirectPath: false,
    isWithRegistrationPage: true,
    isWithoutChunkGrouping: true,
};

export default features;

import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Redirect, Route, useParams } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { CampaignPageContext } from '../../contexts';
import { useStore } from '../../hooks';
import { CampaignInfoModel, CampaignSettingsRequestFormModel } from '../../models';
import { CampaignCardPage as CampaignCardPageInj } from './campaign-card';
import { CampaignSettingsPageRoutes as CampaignSettingsPageRoutesInj } from './campaign-settings';

/** Страница категории */
export const CampaignPage = observer((): JSX.Element => {
    const [CampaignCardPage] = di([CampaignCardPageInj], CampaignPage);
    const [CampaignSettingsPageRoutes] = di([CampaignSettingsPageRoutesInj], CampaignPage);

    const { campaignsStore, coreRootStore } = useStore();
    const { loadCampaign } = campaignsStore;
    const { env } = coreRootStore;

    const model = useMemo<CampaignInfoModel>(() => new CampaignInfoModel(env.apiUrl), []);
    const requestFormModel = useMemo<CampaignSettingsRequestFormModel>(
        () => new CampaignSettingsRequestFormModel(),
        [],
    );

    const { id } = useParams<RouteParamsDefault>();

    const reloadModel = (): Promise<void> => {
        return loadCampaign(id).then(model.load);
    };

    useEffect(() => {
        reloadModel();
    }, [loadCampaign, id, model]);

    return (
        <CampaignPageContext.Provider value={{ model, requestFormModel, reloadModel }}>
            <Route
                exact
                path={[
                    clientRoute.campaign,
                    clientRoute.campaignDescription,
                    clientRoute.campaignDescriptionCommonInfo,
                    clientRoute.campaignDescriptionRequestForms,
                    clientRoute.campaignRequests,
                ]}
            >
                <Route exact path={[clientRoute.campaign, clientRoute.campaignDescription]}>
                    <Redirect to={generatePath(clientRoute.campaignDescriptionCommonInfo, { id })} />
                </Route>
                <CampaignCardPage />
            </Route>
            <Route path={clientRoute.campaignSettings}>
                <Route exact path={clientRoute.campaignSettings}>
                    <Redirect to={generatePath(clientRoute.campaignSettingsDescription, { id })} />
                </Route>
                <Route exact path={clientRoute.campaignSettingsEditOld}>
                    <Redirect to={generatePath(clientRoute.campaignSettingsEdit, { id })} />
                </Route>
                <CampaignSettingsPageRoutes />
            </Route>
            <Route exact path={clientRoute.createCampaignOld}>
                <Redirect to={generatePath(clientRoute.createCampaign, { id })} />
            </Route>
        </CampaignPageContext.Provider>
    );
});

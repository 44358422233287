import { SidebarItem } from '@platform/front-types';
import { ElementPositionUtils, scrollToElementById } from '@platform/front-utils';
import { action, makeObservable, observable, toJS } from 'mobx';
import React, { RefObject } from 'react';
import { BootstrapPanelUtils, dropFocus, getSelectedSidebarItemsIds } from '../utils';

export const FormSidebarModelProps = {
    formWrapperRef: observable,
    sidebarWrapperRef: observable,
    sidebarItems: observable,
    expandedItems: observable,
    selectedItems: observable,
    itemAnchorPrefix: observable,
    isFirstSelectedItemActive: observable,

    handleScroll: action.bound,
    resetSidebarScroll: action.bound,
    onNodeSelect: action.bound,
    findIntoViewElement: action.bound,
    handleSelectItems: action.bound,
    highlightFirstItemOnPageLoaded: action.bound,
    expandItem: action.bound,
    decreaseItem: action.bound,
    setSidebarItems: action.bound,
    setExpandedItems: action.bound,
    setSelectedItems: action.bound,
};

export class FormSidebarModel {
    isFirstSelectedItemActive = false;
    formWrapperRef: RefObject<HTMLDivElement>;
    sidebarWrapperRef: RefObject<HTMLDivElement>;
    sidebarItems: SidebarItem[] = [];
    expandedItems: string[] = [];
    selectedItems: string[] = [];
    itemAnchorPrefix = 'sidebarItem-';

    constructor(formWrapperRef: RefObject<HTMLDivElement>, sidebarWrapperRef: RefObject<HTMLDivElement>) {
        makeObservable(this, FormSidebarModelProps);
        this.formWrapperRef = formWrapperRef;
        this.sidebarWrapperRef = sidebarWrapperRef;
    }

    handleScroll(): void {
        const isChildInView = this.findIntoViewElement(this.sidebarItems);
        !isChildInView && this.findIntoViewElement(this.sidebarItems, false);
        const lastSelectedItemId = this.selectedItems.length - 1;
        const sidebarWrapperElement = this.sidebarWrapperRef.current;

        if (sidebarWrapperElement) {
            const selectedItemId = this.selectedItems[lastSelectedItemId];
            const firstSidebarItem = this.sidebarItems[0];

            // Используем resetSidebarScroll для первого элемента т.к
            // При использовании scrollToElementById не захватывается отступ самого сайдбара
            firstSidebarItem?.id == selectedItemId
                ? this.resetSidebarScroll()
                : scrollToElementById(this.itemAnchorPrefix + selectedItemId, sidebarWrapperElement);
        }
    }

    /** Сброс скрола у сайдбара */
    resetSidebarScroll(): void {
        const sidebarWrapperElement = this.sidebarWrapperRef.current;
        sidebarWrapperElement && sidebarWrapperElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    onNodeSelect(event: React.SyntheticEvent, nodeIds: string[]): void {
        const isClickEvent = event.type === 'click';
        const tagName = (event.target as HTMLElement).tagName;
        const isClickOnButton = tagName === 'BUTTON' || tagName === 'svg' || tagName === 'path';

        if (!isClickOnButton) {
            const firstNodeId = nodeIds[0];
            const currentSelectedItemId =
                isClickEvent || nodeIds.length !== 1
                    ? firstNodeId
                    : toJS(this.selectedItems).find((itemId) => itemId !== firstNodeId);

            if (currentSelectedItemId) {
                const wrapperElement = this.formWrapperRef.current;

                BootstrapPanelUtils.openPanelById(currentSelectedItemId);
                wrapperElement && scrollToElementById(currentSelectedItemId, wrapperElement);
                isClickEvent && dropFocus();
            }
        }
    }

    findIntoViewElement(sidebarItems: SidebarItem[], isFindByChild = true): boolean {
        const wrapperElement = this.formWrapperRef.current;

        const wrapperElementRect = wrapperElement?.getBoundingClientRect();

        if (wrapperElementRect) {
            for (let indexItem = 0; indexItem < sidebarItems.length; indexItem++) {
                const item = sidebarItems[indexItem];
                const { id, subItems } = item;

                if (subItems?.length && isFindByChild) {
                    return this.findIntoViewElement(subItems);
                }

                if (document.activeElement?.closest(`#${this.itemAnchorPrefix}${id}`)) {
                    (document.activeElement as HTMLElement).blur();
                }

                const element: HTMLElement | undefined | null = wrapperElement?.querySelector('#' + id);
                const elementRect = element?.children[0].getBoundingClientRect();

                if (element && elementRect) {
                    // проверяем, попала ли часть элемента в верхнюю половину родительского контейнера + проверяем, является ли элемент крайним
                    // потому что без этой проверки крайние элементы не выделяются совсем
                    const isElementInView =
                        (elementRect.bottom < wrapperElementRect.bottom / 2 &&
                            elementRect.top > wrapperElementRect.top) ||
                        indexItem === 0 ||
                        indexItem === sidebarItems.length - 1;

                    const isElementInWrapper =
                        isElementInView &&
                        ElementPositionUtils.isPartOfElementInViewInParentElement(element, wrapperElement);

                    if (isElementInWrapper) {
                        this.handleSelectItems(id);
                        return true;
                    }
                }
            }
        }

        return false;
    }

    handleSelectItems(nodeId: string): void {
        const newSelectedItemsIds = getSelectedSidebarItemsIds(toJS(this.sidebarItems), nodeId);
        this.setSelectedItems(newSelectedItemsIds);
    }

    highlightFirstItemOnPageLoaded(nodeId: string): void {
        this.handleSelectItems(nodeId);
        this.isFirstSelectedItemActive = true;
    }

    expandItem(nodeId: string): void {
        const newtExpandedItemsIds: string[] = [...this.expandedItems, nodeId];
        this.setExpandedItems(newtExpandedItemsIds);
    }

    decreaseItem(nodeId: string): void {
        const newExpandedItemsIds = [...this.expandedItems];
        const itemIndex = newExpandedItemsIds.indexOf(nodeId);
        newExpandedItemsIds.splice(itemIndex, 1);
        this.setExpandedItems(newExpandedItemsIds);
    }

    setSidebarItems(sidebarItems: SidebarItem[]): void {
        this.sidebarItems = sidebarItems;
    }

    setExpandedItems(expandedItems: string[]): void {
        this.expandedItems = expandedItems;
    }

    setSelectedItems(selectedItems: string[]): void {
        this.selectedItems = selectedItems;
        this.setExpandedItems(selectedItems);
    }
}

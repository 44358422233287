import { Button, Grid, SxProps, Theme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { CampaignRequestRouteParams } from '../../../../../types/campaignRequest';
import { CampaignSettingsBodyHeader } from '../CampaignSettingsBodyHeader';
import { CampaignSettingsInfoCommonObjectRelations } from './campaign-settings-info-common/CampaignSettingsInfoCommonObjectRelations';

const CampaignSettingsRelatedObjectsSx: SxProps<Theme> = {
    paddingX: 2,
    paddingBottom: 1,
    flex: 1,
    flexWrap: 'nowrap',
    flexDirection: 'column',
};

const headerTitleSx: SxProps<Theme> = {
    fontSize: '0.875rem',
};

const headerSx: SxProps<Theme> = {
    marginBottom: 1,
};

export const CampaignSettingsRelatedObjects = observer((): JSX.Element => {
    const params = useParams<CampaignRequestRouteParams>();
    const history = useHistory();

    const createItem = (): void => {
        history.push(
            generatePath(generatePath(clientRoute.campaignSettingsRequestFormAddRelatedObjects, params), params),
        );
    };

    const renderButtons = (): JSX.Element => {
        const actionButtons = [
            <Grid key="create-relatedObject" item display="flex" justifyContent="flex-end">
                <Button color="secondary" onClick={createItem}>
                    <FormattedMessage id="campaignSettings.relatedObjects.addRelation" />
                </Button>
            </Grid>,
        ];
        return <React.Fragment>{actionButtons}</React.Fragment>;
    };

    return (
        <Grid container sx={CampaignSettingsRelatedObjectsSx}>
            <CampaignSettingsBodyHeader
                renderButtons={renderButtons}
                titleId="campaignSettings.relatedObjects.title"
                titleSx={headerTitleSx}
                sx={headerSx}
            />

            <Grid item display="flex" flex={1} paddingLeft={1}>
                <CampaignSettingsInfoCommonObjectRelations />
            </Grid>
        </Grid>
    );
});

import icsLogo from '../../resources/images/ics-logo.svg';
import { Features } from '../../types';

const features: Features = {
    loginWithoutRegister: true,
    customLogo: false,
    headerLogo: {
        isEnabled: true,
        locales: {
            ru: {
                image: icsLogo,
                alt: 'TOT Select логотип',
                style: { width: '115px', height: '30px' },
            },
            en: {
                image: icsLogo,
                alt: 'TOT Select logo',
                style: { width: '115px', height: '30px' },
            },
        },
    },
    helpIcon: true,
    notificationIcon: true,
    phoneNumber: true,
    esiaLogin: true,
    globalSearch: true,
    showCreateRequestButton: true,
    showRequestsCount: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: {
        isEnabled: true,
        providers: [
            {
                code: 'onelogin',
                title: 'OneLogin',
            },
        ],
    },
    showCampaignPeriod: true,
    allUsersRedirectPath: false,
    isWithRegistrationPage: true,
};

export default features;

import { CustomLogoConfig } from '@platform/front-core';
import { clientRoute } from '../../clientRoute';
import { Features } from '../../types';

const customLogo: CustomLogoConfig = {
    src: require('./vtb-logo.svg'),
    alt: 'ВТБ',
    style: { width: '100%', maxWidth: '125px', height: '70px' },
    withText: false,
    mdLogo: false,
};

const features: Features = {
    loginWithoutRegister: false,
    headerLogo: false,
    customLogo: {
        isEnabled: true,
        locales: {
            ru: customLogo,
            en: customLogo,
        },
    },
    helpIcon: false,
    notificationIcon: true,
    phoneNumber: false,
    esiaLogin: false,
    showCreateRequestButton: true,
    showRequestsCount: false,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: false,
    showCampaignPeriod: true,
    allUsersRedirectPath: {
        isEnabled: true,
        path: clientRoute.campaigns,
    },
    isWithRegistrationPage: true,
};

export default features;

import { createContext } from 'react';
import { PrintFormModel } from '../models';

export type CampaignRequestPrintFormContextType = {
    model: PrintFormModel;
    isWithEdit: boolean;
};

export const CampaignRequestPrintFormContext = createContext<CampaignRequestPrintFormContextType>({
    model: {} as PrintFormModel,
    isWithEdit: false,
});

import { CustomLogoConfig } from '@platform/front-core';
import { TotLocale } from '@platform/front-types';
import { clientRoute } from '../../clientRoute';
import { Features } from '../../types';
import headerLogoEn from './misis-psal-logo-black_en.svg';
import headerLogoRu from './misis-psal-logo-black_ru.svg';
import startPageLogoEn from './misis-psal-logo-white_en.svg';
import startPageLogoRu from './misis-psal-logo-white_ru.svg';

const headerLogoStyles = {
    height: '50px',
};

const getStartPageLogo = (locale?: TotLocale): CustomLogoConfig => {
    return {
        src: locale === TotLocale.ru ? startPageLogoRu : startPageLogoEn,
        alt: 'НИТУ «МИСиС»',
        style: { width: '100%', maxWidth: '225px', height: '100px' },
        withText: false,
        mdLogo: false,
    };
};

const features: Features = {
    customLogo: {
        isEnabled: true,
        locales: {
            ru: getStartPageLogo(TotLocale.ru),
            en: getStartPageLogo(TotLocale.en),
        },
    },
    loginWithoutRegister: true,
    headerLogo: {
        isEnabled: true,
        locales: {
            ru: {
                image: headerLogoRu,
                alt: 'НИТУ «МИСиС»',
                style: headerLogoStyles,
            },
            en: {
                image: headerLogoEn,
                alt: 'НИТУ «МИСиС»',
                style: headerLogoStyles,
            },
        },
    },
    allUsersRedirectPath: {
        isEnabled: true,
        path: clientRoute.campaigns,
    },
    helpIcon: false,
    notificationIcon: true,
    phoneNumber: true,
    esiaLogin: false,
    showCreateRequestButton: true,
    showRequestsCount: true,
    loginDialogAlert: false,
    yaMetrika: true,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: false,
    showCampaignPeriod: true,
    isWithRegistrationPage: true,
    globalSearch: true,
};

export default features;

import {
    IdTitle,
    NotificationCommonParams,
    NotificationDTO,
    NotificationType,
    WebsocketRequestData,
    WebsocketShortRequestData,
} from '@platform/front-types';

export enum NotificationRequestCode {
    connect = 'connect',
    disconnect = 'disconnect',
    notification = 'notification',
}

export enum AdditionalNotificationType {
    sectionsHasChanges = 'sectionsHasChanges',
}

export const ICSNotificationType = { ...NotificationType, ...AdditionalNotificationType };
export type ICSNotificationType = keyof typeof ICSNotificationType;

export enum NotificationLocation {
    request = 'request',
    sectionEdit = 'sectionEdit',
}

export type NotificationShortRequestData = WebsocketShortRequestData<NotificationRequestCode>;
export type NotificationRequestData<Body extends {}> = WebsocketRequestData<Body, NotificationShortRequestData>;

export type NotificationLocationDTO<Params extends Record<string, string> = {}> = {
    type: NotificationLocation;
    params?: Params;
};

export type NotificationRequestLocationParams = {
    requestId: string;
};

export type NotificationSectionLocationParams = NotificationRequestLocationParams & {
    sectionId: string;
};

export type NotificationRequestLocationDTO = NotificationLocationDTO<NotificationRequestLocationParams>;
export type NotificationSectionLocationDTO = NotificationLocationDTO<NotificationSectionLocationParams>;

export type NotificationSectionsHasChangesParams = {
    sections: IdTitle[];
};

export type NotificationParams = NotificationCommonParams | NotificationSectionsHasChangesParams;
export type ICSNotificationDTO<Params extends NotificationParams = NotificationParams> = NotificationDTO<
    ICSNotificationType,
    Params
>;

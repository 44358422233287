import { CommentsApisArgs } from '@platform/comments';
import { ApiConfigs as CoreApiConfigs, DefaultNewPermissionDTO } from '@platform/front-core';
import { FullSubmission, TotLocale } from '@platform/front-types';
import { AxiosRequestConfig } from 'axios';
import {
    CampaignRequestListFilterData,
    EditStatusDTO,
    FullNameSettingsDTO,
    LoginSettingsDTO,
    NewPermissionDTO,
    PermissionsType,
    RolesSettingsDTO,
    SectionSettingDTO,
} from './types';

export class ApiConfigs extends CoreApiConfigs {
    createCampaign: (lifecycleCode: string) => AxiosRequestConfig = (lifecycleCode) => {
        return {
            url: '/campaign',
            method: 'POST',
            data: {
                lifecycleCode,
            },
        };
    };

    campaigns: AxiosRequestConfig = {
        url: '/campaigns',
        method: 'GET',
    };

    campaign: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}`,
            method: 'GET',
        };
    };

    editCampaign: (id: string, data: FullSubmission) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/campaign/${id}`,
            method: 'PUT',
            data,
        };
    };

    campaignForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}/form`,
            method: 'GET',
        };
    };

    campaignUserRequests: (id: string, page: number, pageSize?: number) => AxiosRequestConfig = (
        id,
        page,
        pageSize = 10,
    ) => {
        return {
            url: `/campaign/${id}/userRequests`,
            method: 'PUT',
            data: {
                filter: {},
                sort: [{ created: 'desc' }],
                page,
                pageSize,
            },
        };
    };

    campaignFileUrl = (id: string): string => `/campaign/file/${id}`;

    campaignFile: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: this.campaignFileUrl(id),
            method: 'GET',
            responseType: 'blob',
        };
    };

    createRequestForm: (campaignId: string) => AxiosRequestConfig & { params?: { campaign: string } } = (
        campaignId,
    ) => {
        return {
            url: '/regForm',
            method: 'POST',
            params: {
                campaign: campaignId,
            },
        };
    };

    editRequestForm: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/regForm/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    requestFormCard: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}`,
            method: 'GET',
        };
    };

    requestForms: (campaignId: string, minimal: boolean, edit?: boolean) => AxiosRequestConfig = (
        campaignId,
        minimal = false,
        edit,
    ) => {
        return {
            url: '/regForms',
            method: 'GET',
            params: {
                campaign: campaignId,
                minimal: minimal,
                edit: edit,
            },
        };
    };

    requestFormsByCampaignsForSelect: (campaigns: string[]) => AxiosRequestConfig = (campaigns) => {
        const campaignsQuery = campaigns.map((c) => `campaign=${c}`).join('&');
        return {
            url: `/select/regForms/grouped?${campaignsQuery}`,
            method: 'GET',
        };
    };

    changeRequestFormsPosition: (requestFormId: string, newPosition: number) => AxiosRequestConfig = (
        requestFormId,
        newPosition,
    ) => {
        return {
            url: `/regForm/${requestFormId}/position/${newPosition}`,
            method: 'PUT',
        };
    };

    requestForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}/form`,
            method: 'GET',
        };
    };

    deleteRequestForm: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}`,
            method: 'DELETE',
        };
    };

    requestFormFile: (fileId: string) => AxiosRequestConfig = (fileId) => {
        return {
            url: `/regForm/file/${fileId}`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    sectionTemplates: (sectionSettingsId: string) => AxiosRequestConfig = (sectionSettingsId) => {
        return {
            url: `/section-settings/${sectionSettingsId}/templates`,
            method: 'GET',
        };
    };

    commentPermissions: (commentPermissionId: string) => AxiosRequestConfig = (commentPermissionId) => {
        return {
            url: `section-settings/${commentPermissionId}/comment-permissions`,
            method: 'GET',
        };
    };

    updateCommentsPermissions = (
        commentPermissionId: string,
        permissions: Partial<NewPermissionDTO>[],
    ): AxiosRequestConfig => {
        return {
            url: `/section-settings/${commentPermissionId}/comment-permissions`,
            method: 'PUT',
            data: permissions,
        };
    };

    template: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/template/${id}`,
            method: 'GET',
        };
    };

    createTemplate: (sectionSettingId: string, formData: FormData) => AxiosRequestConfig = (
        sectionSettingId,
        formData,
    ) => {
        return {
            url: `/section-settings/${sectionSettingId}/template`,
            method: 'POST',
            data: formData,
        };
    };

    saveTemplate: (sectionSettingId: string, templateId: string, formData: FormData) => AxiosRequestConfig = (
        sectionSettingId,
        templateId,
        formData,
    ) => {
        return {
            url: `/section-settings/${sectionSettingId}/template/${templateId}`,
            method: 'PUT',
            data: formData,
        };
    };

    deleteTemplate: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}`,
            method: 'DELETE',
        };
    };

    changeTemplatePosition: (templateId: string, newPosition: number) => AxiosRequestConfig = (
        templateId,
        newPosition,
    ) => {
        return {
            url: `/template/${templateId}/position/${newPosition}`,
            method: 'PUT',
        };
    };

    downloadTemplateFile: (templateId: string) => AxiosRequestConfig = (templateId) => {
        return {
            url: `/template/${templateId}/file`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    createRequest: (requestFormId: string) => AxiosRequestConfig = (requestFormId) => {
        return {
            url: `/request?regForm=${requestFormId}`,
            method: 'POST',
        };
    };

    getRequest: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}`,
            method: 'GET',
        };
    };

    getSimilarObjects: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/similarity-info`,
            method: 'GET',
        };
    };

    getDeadlines: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/deadline`,
            method: 'GET',
        };
    };

    updatePreScores: (requestId: string, submission: FullSubmission) => AxiosRequestConfig = (
        requestId,
        submission,
    ) => {
        return {
            url: `/request/${requestId}/preScores`,
            method: 'PUT',
            data: submission,
        };
    };

    updatePreExpertise: (requestId: string, submission: FullSubmission) => AxiosRequestConfig = (
        requestId,
        submission,
    ) => {
        return {
            url: `/request/${requestId}/preExpertise`,
            method: 'PUT',
            data: submission,
        };
    };

    updateExpertise: (requestId: string, submission: FullSubmission) => AxiosRequestConfig = (
        requestId,
        submission,
    ) => {
        return {
            url: `/request/${requestId}/expertise`,
            method: 'PUT',
            data: submission,
        };
    };

    deleteRequest: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}`,
            method: 'DELETE',
        };
    };

    getAllActiveNonBlockedUsers = (): AxiosRequestConfig => ({
        url: `/select/users/v2`,
        method: 'GET',
        params: {
            blocked: false,
            emailConfirmed: true,
        },
    });

    requestListFilterData: (data: CampaignRequestListFilterData) => AxiosRequestConfig = (data) => {
        return {
            url: '/requests/filters',
            method: 'PUT',
            data,
        };
    };

    // requestListXls: (queryData: TableQueryData) => AxiosRequestConfig = (queryData) => {
    //     return {
    //         url: '/requestsXls',
    //         method: 'PUT',
    //         responseType: 'blob',
    //         data: queryData,
    //     };
    // };

    transitionRequest: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/transitions`,
            method: 'GET',
        };
    };

    transitionToNextLifeCycleStep: (transitionId: string, requestId: string) => AxiosRequestConfig = (
        transitionId,
        requestId,
    ) => {
        return {
            url: `/request/${requestId}/changeState?transition=${transitionId}`,
            method: 'PUT',
        };
    };

    personsListFilterData: AxiosRequestConfig = {
        url: '/persons?filter',
        method: 'GET',
    };

    loadPrintForms: (sectionId: string) => AxiosRequestConfig = (sectionId) => {
        return {
            url: `/section/${sectionId}/templates`,
            method: 'GET',
        };
    };

    getPrintForm: (templateId: string, sectionId: string) => AxiosRequestConfig = (templateId, sectionId) => {
        return {
            url: `/template/${templateId}/section/${sectionId}`,
            method: 'GET',
        };
    };

    generatePrintForm: (sectionId: string, templateId: string) => AxiosRequestConfig = (sectionId, templateId) => {
        return {
            url: `/section/${sectionId}/template/${templateId}`,
            method: 'PUT',
        };
    };

    downloadPrintForm: (sectionId: string, templateId: string) => AxiosRequestConfig = (sectionId, templateId) => {
        return {
            url: `/section/${sectionId}/template/${templateId}/download`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    downloadAllPrintFormFiles: (sectionId: string) => AxiosRequestConfig = (sectionId) => {
        return {
            url: `/pf/section/${sectionId}/all`,
            method: 'GET',
            responseType: 'blob',
        };
    };

    getActualCampaignList: AxiosRequestConfig = {
        url: `/campaigns/active`,
        method: 'GET',
    };

    getLifecycleList: AxiosRequestConfig = {
        url: `/select/lifecycles?processCode=CampaignProcess`,
        method: 'GET',
    };

    getLifecycleStatesListByProcessCode: (processCode: string) => AxiosRequestConfig = (processCode) => {
        return {
            url: `/select/states/byProcessCode`,
            method: 'GET',
            params: {
                processCode,
            },
        };
    };

    roleConditionsList: AxiosRequestConfig = {
        url: '/select/section-settings/permissions',
        method: 'GET',
    };

    catalogItems: (catalogCode: string, lang?: string) => AxiosRequestConfig = (catalogCode, lang) => {
        return {
            url: `/catalogItems`,
            method: 'GET',
            params: {
                catalogCode,
                lang,
            },
        };
    };

    deleteCampaign: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/campaign/${id}`,
            method: 'DELETE',
        };
    };

    transitionCampaign: (campaignId: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: `/campaign/${campaignId}/transitions`,
            method: 'GET',
        };
    };

    getFormForGeneralSettings: (campaignId: string) => AxiosRequestConfig = (campaignId) => {
        return {
            url: `/campaign/${campaignId}/general-settings-form`,
            method: 'GET',
        };
    };

    transitionToNextLifeCycleStepCampaign: (transitionId: string, campaignId: string) => AxiosRequestConfig = (
        transitionId,
        campaignId,
    ) => {
        return {
            url: `/campaign/${campaignId}/changeState?transition=${transitionId}`,
            method: 'PUT',
        };
    };

    saveFormForGeneralSettings: (id: string, submission: FullSubmission) => AxiosRequestConfig = (
        campaignId,
        submission,
    ) => {
        return {
            url: `/campaign/${campaignId}/general-settings-form`,
            method: 'PUT',
            data: submission,
        };
    };

    //<editor-fold desc="Конфиги для настроек форм заявки">

    sectionSettings: (sectionSettingId: string) => AxiosRequestConfig = (sectionSettingId) => {
        return {
            url: '/section-settings',
            method: 'GET',
            params: {
                regForm: sectionSettingId,
            },
        };
    };

    createSectionSetting: (
        sectionSettingId: string,
        dto: SectionSettingDTO,
    ) => AxiosRequestConfig & { params?: { regForm: string } } = (sectionSettingId, dto) => {
        return {
            url: '/section-settings',
            method: 'POST',
            params: {
                regForm: sectionSettingId,
            },
            data: dto,
        };
    };

    loadSectionSetting: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/section-settings/${id}`,
            method: 'GET',
        };
    };

    saveSectionSetting: (id: string, dto: SectionSettingDTO) => AxiosRequestConfig = (id, dto) => {
        return {
            url: `/section-settings/${id}`,
            method: 'PUT',
            data: dto,
        };
    };

    deleteSectionSetting: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/section-settings/${id}`,
            method: 'DELETE',
        };
    };

    changePositionSectionSetting: (id: string, position: number) => AxiosRequestConfig = (id, position) => {
        return {
            url: `/section-settings/${id}/position/${position}`,
            method: 'PUT',
        };
    };

    loadExecutors: () => AxiosRequestConfig = () => {
        return {
            url: `/select/users/v2?role=Employee&role=SeniorEmployee`,
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    loadStates: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}/states`,
            method: 'GET',
        };
    };

    loadRequestPermissions: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}/request-permissions`,
            method: 'GET',
        };
    };

    loadRequestPermissionsSelectData: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/regForm/${id}/request-permissions/select-data`,
            method: 'GET',
        };
    };

    updateRequestPermissions: (id: string, permissions: DefaultNewPermissionDTO[]) => AxiosRequestConfig = (
        id,
        permissions,
    ) => {
        return {
            url: `/regForm/${id}/request-permissions`,
            method: 'PUT',
            data: permissions,
        };
    };

    loadParentSectionSetting: (requestFormId: string, sectionId?: string) => AxiosRequestConfig = (
        requestFormId,
        sectionId,
    ) => {
        return {
            url: `/regForm/${requestFormId}/section-settings`,
            method: 'GET',
            params: sectionId && {
                curSectionSettingsId: sectionId,
            },
        };
    };

    loadPermissions: () => AxiosRequestConfig = () => {
        return {
            url: `/select/permissions?entityCode=RequestCustomForm`,
            method: 'GET',
        };
    };

    loadFormCode: () => AxiosRequestConfig = () => {
        return {
            url: `/select/forms/search?code=RequestCustomForm_`,
            method: 'GET',
        };
    };
    //<editor-fold/>

    //<editor-fold desc="Кастомные формы заявки">
    loadSection: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/section/${id}`,
            method: 'GET',
        };
    };

    saveFullSection: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/section/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    sectionCheckEditable: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/section/${id}/checkEditable`,
            method: 'GET',
        };
    };
    //<editor-fold/>

    getAllActiveEmployees: () => AxiosRequestConfig = () => {
        return {
            url: `/select/users?role=Employee&role=SeniorEmployee`,
            method: 'GET',
            params: {
                blocked: false,
            },
        };
    };

    getAllEmployees: () => AxiosRequestConfig = () => {
        return {
            url: `/select/users?role=Employee&role=SeniorEmployee`,
            method: 'GET',
        };
    };

    setCurrentUserAsExecutor: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/executor`,
            method: 'GET',
        };
    };

    getExecutorSettings: (requestId: string) => AxiosRequestConfig = (requestId) => {
        return {
            url: `/request/${requestId}/executorSetting`,
            method: 'GET',
        };
    };

    editExecutorSettings: (requestId: string, userId: string) => AxiosRequestConfig = (requestId, userId) => {
        return {
            url: `/request/${requestId}/executorSetting`,
            method: 'PUT',
            data: {
                userId,
            },
        };
    };

    loadAccessPermissions = (settingsId: string, permissionsType: PermissionsType): AxiosRequestConfig => {
        return {
            url: `/section-settings/${settingsId}/${permissionsType}`,
            method: 'GET',
        };
    };

    saveAccessPermissions = (
        sectionSettingsId: string,
        permissions: Partial<NewPermissionDTO>[],
        permissionsType: PermissionsType,
    ): AxiosRequestConfig => {
        return {
            url: `/section-settings/${sectionSettingsId}/${permissionsType}`,
            method: 'PUT',
            data: permissions,
        };
    };

    checkEditablePf = (id: string): AxiosRequestConfig => {
        return {
            url: `/section/${id}/checkEditablePf`,
            method: 'GET',
        };
    };

    checkIsPasswordRecoveryTokenValid = (token: string): AxiosRequestConfig => {
        return {
            url: `/checkPasswordResetToken?token=${token}`,
            method: 'GET',
        };
    };

    getFileByte = (printFormId: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/toByte`,
            method: 'GET',
            responseType: 'arraybuffer',
        };
    };

    uploadSignature = (printFormId: string, signature: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign`,
            method: 'POST',
            data: { sign: signature },
        };
    };

    uploadSignatureFile = (printFormId: string, signatureFile: FormData): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/upload-sign`,
            method: 'POST',
            data: signatureFile,
        };
    };

    getSignatureInfo = (printFormId: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign-info`,
            method: 'GET',
        };
    };

    downloadSignature = (printFormId: string, isWithFile: boolean): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign`,
            method: 'GET',
            params: {
                attached: isWithFile,
            },
        };
    };

    deleteSignature = (printFormId: string): AxiosRequestConfig => {
        return {
            url: `/pf/${printFormId}/sign`,
            method: 'DELETE',
        };
    };

    getFormioFileByte = (url: string): AxiosRequestConfig => {
        return {
            url,
            method: 'GET',
            responseType: 'arraybuffer',
        };
    };

    uploadFormioSignature = (url: string, signature: string): AxiosRequestConfig => {
        return {
            url,
            method: 'POST',
            data: { sign: signature },
        };
    };

    uploadFormioSignatureFile = (url: string, signatureFile: FormData): AxiosRequestConfig => {
        return {
            url,
            method: 'POST',
            data: signatureFile,
        };
    };

    getFormioSignatureInfo = (url: string): AxiosRequestConfig => {
        return {
            url,
            method: 'GET',
        };
    };

    downloadFormioSignature = (url: string, isWithFile: boolean): AxiosRequestConfig => {
        return {
            url,
            method: 'GET',
            params: {
                attached: isWithFile,
            },
        };
    };

    deleteFormioSignature = (url: string): AxiosRequestConfig => {
        return {
            url,
            method: 'DELETE',
        };
    };

    getCommentContextInfo = (params: CommentsApisArgs['context']): AxiosRequestConfig => {
        return {
            url: `/comment-context`,
            method: 'GET',
            params,
        };
    };

    getComments = ({ contextId, config, entityId, entityType }: CommentsApisArgs['get']): AxiosRequestConfig => {
        return {
            url: `/comment-context/${contextId}/comments`,
            method: 'PUT',
            data: config,
            params: {
                entityId,
                entityType,
            },
        };
    };

    createComment = ({ comment, entityId, entityType }: CommentsApisArgs['create']): AxiosRequestConfig => {
        return {
            url: '/comment-context/comment',
            method: 'POST',
            data: comment,
            params: {
                entityId,
                entityType,
            },
        };
    };

    editComment = ({ commentId, text: content }: CommentsApisArgs['edit']): AxiosRequestConfig => {
        return {
            url: `/comment-context/comment/${commentId}`,
            method: 'PUT',
            data: { content },
        };
    };

    deleteComment = ({ commentId }: CommentsApisArgs['delete']): AxiosRequestConfig => {
        return {
            url: `/comment-context/comment/${commentId}`,
            method: 'DELETE',
        };
    };

    markRead = ({ commentIds }: CommentsApisArgs['markRead']): AxiosRequestConfig => {
        return {
            url: `/comments/mark-read`,
            method: 'POST',
            data: commentIds,
        };
    };

    markUnread = ({ commentIds }: CommentsApisArgs['markUnread']): AxiosRequestConfig => {
        return {
            url: `/comments/un-mark-read`,
            method: 'DELETE',
            data: commentIds,
        };
    };

    getCommentsBranch({
        commentId,
        entityId,
        entityType,
        contextId,
    }: CommentsApisArgs['getBranch']): AxiosRequestConfig {
        return {
            url: `/comment-context/${contextId}/comment/${commentId}`,
            method: 'GET',
            params: {
                entityId,
                entityType,
            },
        };
    }

    getCommentsTransitions(commentId: string): AxiosRequestConfig {
        return {
            url: `/comments/transitions/${commentId}`,
            method: 'GET',
        };
    }

    commentsTransitionToNextLifeCycleStep(transitionId: string, objectId: string): AxiosRequestConfig {
        return {
            url: `/comments/${objectId}/change-state`,
            method: 'PUT',
            params: {
                transitionId,
            },
        };
    }

    campaignRequestServiceInfo = (requestId: string): AxiosRequestConfig => {
        return {
            url: `/request/${requestId}/utilInfo`,
            method: 'GET',
        };
    };

    userRoleList: AxiosRequestConfig = {
        url: '/roles',
        method: 'GET',
    };

    loadUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'GET',
        };
    };

    saveUser: (id: string, submission: FullSubmission) => AxiosRequestConfig = (id, submission) => {
        return {
            url: `/administration/user/${id}`,
            method: 'PUT',
            data: submission,
        };
    };

    loginAsUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/login-as/${id}`,
            method: 'PUT',
        };
    };

    editUserStatus: (id: string, data: EditStatusDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/status`,
            method: 'PUT',
            data,
        };
    };

    setLogin: (id: string, data: LoginSettingsDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/login`,
            method: 'PUT',
            data,
        };
    };

    setRoles: (id: string, data: RolesSettingsDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/roles`,
            method: 'PUT',
            data,
        };
    };

    setUserLang: (id: string, lang: TotLocale) => AxiosRequestConfig = (id, lang) => {
        return {
            url: `/administration/user/${id}/lang`,
            method: 'PUT',
            params: {
                lang,
            },
        };
    };

    userFullName: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}/fullName`,
            method: 'GET',
        };
    };

    setFullName: (id: string, data: FullNameSettingsDTO) => AxiosRequestConfig = (id, data) => {
        return {
            url: `/administration/user/${id}/fullName`,
            method: 'PUT',
            data,
        };
    };

    deleteUser: (id: string) => AxiosRequestConfig = (id) => {
        return {
            url: `/administration/user/${id}`,
            method: 'DELETE',
        };
    };
}

export const apiConfigs = new ApiConfigs();

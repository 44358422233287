import {
    CommentContextInfoDTO,
    CommentDTO,
    CommentsApisArgs,
    ICommentsApiConfigs,
    ICommentsStore,
    OneCommentThreadDTO,
} from '@platform/comments';
import { ApiStore } from '@platform/front-core';
import { TransitionDTO } from '@platform/front-types';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';

export const commentsStoreObservables = {
    apiConfigs: observable,
    api: observable,

    getComments: action.bound,
    createComment: action.bound,
    editComment: action.bound,
    deleteComment: action.bound,
    getCommentContextInfo: action.bound,
    markRead: action.bound,
    markUnread: action.bound,
    getCommentsBranch: action.bound,
};

export class CommentsStore implements ICommentsStore {
    apiConfigs: ICommentsApiConfigs;
    api: ApiStore;

    /** Хранилище с инструментами взаимодействия фронтовой части
     * приложения комментариев с апи бекенда.
     * @param client - Клиент, выполняющий запросы. Может быть
     * использован любой клиент из ApiStore.
     * @param api - Набор инструментов для взаимодействия с апи бекенда.
     */
    constructor(api: ApiStore, apiConfigs: ICommentsApiConfigs) {
        makeObservable(this, commentsStoreObservables);

        this.api = api;
        this.apiConfigs = apiConfigs;
    }

    /**
     * Получение комментариев.
     * @param args - Объект с необходимыми данными для запроса.
     * @example
     * // Объект:
     *  {
            config: CommentsBatchInformation; // Данные для пагинации. (см. тип)
            contextId: string; // Id контекста.
            entityId: string; // Id для распознавания какой-либо конкретной сущности модуля.
            entityType: string; // Код сущности из схемы авторизации на беке.
        };
     * @returns Promise<CommentDTO[]>
     */
    getComments(args: CommentsApisArgs['get']): Promise<CommentDTO[]> {
        return this.api.client(this.apiConfigs.getComments(args)).then((r) => r.data);
    }

    /**
     * Создание комментария
     * @param args - Объект с необходимыми данными для запроса.
     * @example
     * // Объект:
     * {
            comment: NewCommentData; // Тело комментария с идентификаторами
            // комментария-родителя и др. (см. тип).
            entityId: string; // Id для распознавания какой-либо конкретной сущности модуля.
            entityType: string; // Код сущности из схемы авторизации на беке.
        }
     * @returns Promise<CommentDTO>
     */
    createComment(args: CommentsApisArgs['create']): Promise<CommentDTO> {
        return this.api.userActionClient(this.apiConfigs.createComment(args)).then((r) => r.data);
    }

    /**
     * Редактирование комментария
     * @param args - Объект с необходимыми данными для запроса.
     * @example
     * // Объект:
     * {
            commentId: string; // Id комментария
            text: string; // Новый текст комментария
        }
     * @returns Promise<CommentDTO>
     */
    editComment(args: CommentsApisArgs['edit']): Promise<CommentDTO> {
        return this.api.userActionClient(this.apiConfigs.editComment(args)).then((r) => r.data);
    }

    /**
     * Удаление комментария
     * @param args - Объект с необходимыми данными для запроса.
     * @example
     * // Объект:
     * {
            commentId: string; // Id комментария
        }
     * @returns Promise<CommentDTO>
     */
    deleteComment(args: CommentsApisArgs['delete']): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.deleteComment(args)).then((r) => r.data);
    }

    /**
     * Получение информации о контексте приложения.
     * Контекст - это данные БО, в котором находится приложения.
     * @param args - Объект с необходимыми данными для запроса.
     *  @example
     * // Объект:
     * {
            entityId: string; // Id для распознавания какой-либо конкретной сущности модуля.
            entityType: string; // Код сущности из схемы авторизации на беке.
        }
     * @returns Promise<CommentContextInfoDTO>
     */
    getCommentContextInfo(args: CommentsApisArgs['context']): Promise<CommentContextInfoDTO> {
        return this.api.client(this.apiConfigs.getCommentContextInfo(args)).then((r) => r.data);
    }

    /**
     * Отправляет массив с айди комментариев, помеченных в приложении, как прочитанные.
     * @param args - Объект с необходимыми данными для запроса.
     * @example
     * // Объект:
     * {
            commentIds: string[]; // Массив с айди комментариев.
        }
     */
    markRead(args: CommentsApisArgs['markRead']): Promise<void> {
        return this.api.client(this.apiConfigs.markRead(args)).then((r) => r.data);
    }

    /**
     * Отправляет массив с айди комментариев, помеченных в приложении, как непрочитанные.
     * @param args - Объект с необходимыми данными для запроса.
     * @example
     * // Объект:
     * {
            commentIds: string[]; // Массив с айди комментариев.
        }
     */
    markUnread(args: CommentsApisArgs['markUnread']): Promise<void> {
        return this.api.client(this.apiConfigs.markUnread(args)).then((r) => r.data);
    }

    /**
     * Отправляет entityType, entityId, commentId, contextId и получает объект для
     * отрисовки отдельной ветви комментариев с целевым комментарием.
     * @param args - Объект с необходимыми данными для запроса.
     * @example
     * // Объект:
     * {
            entityId: string; // Id для распознавания какой-либо конкретной сущности модуля.
            entityType: string; // Код сущности из схемы авторизации на беке.
            commentId: string;// Id комментария
            contextId: string;// Id контекста
        }
     */
    getCommentsBranch(args: CommentsApisArgs['getBranch']): Promise<OneCommentThreadDTO> {
        return this.api.client(this.apiConfigs.getCommentsBranch(args)).then((r) => r.data);
    }

    /**
     * Получение объектов с переходами ЖЦ для комментария.
     * @param id - id комментария
     */
    getTransitions(id: string): Promise<TransitionDTO[]> {
        return this.api.client(this.apiConfigs.getCommentsTransitions(id)).then((r) => r.data);
    }

    /**
     * Осуществить переход по ЖЦ для комментария.
     * @param transitionId - id перехода.
     * @param objectId - id комментария
     */
    transitionToNextLifeCycleStep(transitionId: string, objectId: string) {
        return this.api
            .client(this.apiConfigs.commentsTransitionToNextLifeCycleStep(transitionId, objectId))
            .then((r) => r.data);
    }
}

export const getCommentsStore = (): any => {
    const [_CommentsStore] = di([CommentsStore], getCommentsStore);
    return _CommentsStore;
};

import { MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { NavLink, useParams } from 'react-router-dom';
import { useCampaignRequestPageContext } from '../../../../../hooks';
import { CampaignRequestSectionRouteParams, RequestSubSectionListDTO } from '../../../../../types';
import { getCampaignRequestSectionPath as getCampaignRequestSectionPathInj } from '../getCampaignRequestSectionPath';
import { CampaignRequestSubSectionContent as CampaignRequestSubSectionContentInj } from './CampaignRequestSubSectionContent';

export type CampaignRequestSubSectionProps = {
    hideMenu: VoidFunction;
    subSection: RequestSubSectionListDTO;
};

export const CampaignRequestSubSection = observer((props: CampaignRequestSubSectionProps): JSX.Element => {
    const [CampaignRequestSubSectionContent] = di([CampaignRequestSubSectionContentInj], CampaignRequestSubSection);
    const [getCampaignRequestSectionPath] = di([getCampaignRequestSectionPathInj], CampaignRequestSubSection);

    const { hideMenu, subSection } = props;
    const { id: subSectionId } = subSection;

    const { model } = useCampaignRequestPageContext();
    const { errorsByTabs } = model.errorsModel;
    const { id } = useParams<CampaignRequestSectionRouteParams>();

    const pathParams = { id, sectionId: subSectionId };
    const errors = errorsByTabs[subSectionId];
    const subSectionPath = getCampaignRequestSectionPath(pathParams, errors);

    const content = <CampaignRequestSubSectionContent subSection={subSection} />;

    return (
        <MenuItem key={subSectionId} component={NavLink} to={subSectionPath} onClick={hideMenu}>
            {content}
        </MenuItem>
    );
});

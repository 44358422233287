import { CodeTitle, VoidFunction } from '@platform/front-utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { SelectStore } from '../stores';
import {
    AccessPermissionDTO,
    AccessPermissionMapToNewPermissionDTOOverloads,
    CodeTitleGroupRC,
    CommentsPermissionDTO,
    CustomAndDefaultPermissionSettingsDTO,
    EitherCommentOrDefaultAccessPermissions,
    LoadPermissions,
    NewPermissionDTO,
    PermissionSettingsDTO,
    PermissionTrigger,
    SelectedIndex,
    SubmitPermissionsOverloads,
    UpdatePermissions,
} from '../types';

export const PermissionsModelProps = {
    selectStore: observable,
    loadPermissions: observable,
    updatePermissions: observable,
    isLoading: observable,
    processCode: observable,
    permissionList: observable,
    roleConditionList: observable,
    lifecycleStatesList: observable,
    customFormPermissionsList: observable,

    newPermissionsDTO: computed,

    load: action.bound,
    getPermissions: action.bound,
    submitPermissions: action.bound,
    deletePermission: action.bound,
    accessPermissionMapToNewPermissionDTO: action.bound,
    dropPermissions: action.bound,
    setMainFields: action.bound,
    setProcessCode: action.bound,
    setRoleConditionList: action.bound,
    setLifecycleStatesList: action.bound,
    setIsLoading: action.bound,
};

export class AccessPermissionModel {
    private selectStore: SelectStore;

    private loadPermissions: LoadPermissions;
    private updatePermissions: UpdatePermissions;

    isLoading = true;
    processCode = '';
    permissionList: AccessPermissionDTO[] = [];
    customFormPermissionsList: CommentsPermissionDTO[] = [];

    roleConditionList: CodeTitleGroupRC[] = [];
    lifecycleStatesList: CodeTitle[] = [];

    constructor(selectStore: SelectStore, loadPermissions: LoadPermissions, updatePermissions: UpdatePermissions) {
        makeObservable(this, PermissionsModelProps);
        this.selectStore = selectStore;

        this.updatePermissions = updatePermissions;
        this.loadPermissions = loadPermissions;
    }

    get newPermissionsDTO(): Partial<NewPermissionDTO>[] {
        return this.customFormPermissionsList.length
            ? this.customFormPermissionsList.map(this.accessPermissionMapToNewPermissionDTO)
            : this.permissionList.map(this.accessPermissionMapToNewPermissionDTO);
    }

    load(): Promise<void> {
        this.setIsLoading(true);

        return this.getPermissions()
            .then(() => {
                this.selectStore.getRoleConditionsList().then(this.setRoleConditionList);
                this.selectStore
                    .getLifecyclesStatesListByProcessCode(this.processCode)
                    .then(this.setLifecycleStatesList);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    }

    getPermissions(): Promise<void> {
        return this.loadPermissions().then(
            (permissionSettingsDTO: CustomAndDefaultPermissionSettingsDTO<CommentsPermissionDTO>) => {
                const { processCode } = permissionSettingsDTO;
                this.setProcessCode(processCode);
                this.setMainFields(permissionSettingsDTO);
            },
        );
    }

    submitPermissions: SubmitPermissionsOverloads = (
        formValues: EitherCommentOrDefaultAccessPermissions,
        selectedPermissionIndex: SelectedIndex,
        onSuccess?: VoidFunction,
    ): Promise<void> => {
        const isNewItem = selectedPermissionIndex === null;

        const dto = this.accessPermissionMapToNewPermissionDTO(formValues as any);
        const oldPermissions: Partial<NewPermissionDTO>[] = this.newPermissionsDTO;
        const newPermissions = [...oldPermissions];

        if (isNewItem) {
            newPermissions.push(dto);
        } else {
            newPermissions.splice(selectedPermissionIndex, 1, dto);
        }
        return this.updatePermissions(newPermissions).then(() => {
            onSuccess && onSuccess();
            return this.getPermissions();
        });
    };

    deletePermission(permissionIndex: number): Promise<void> {
        const oldPermissions: Partial<NewPermissionDTO>[] = this.newPermissionsDTO;
        const newPermissions = [...oldPermissions];
        newPermissions.splice(permissionIndex, 1);

        return this.updatePermissions(newPermissions).then(this.getPermissions);
    }

    accessPermissionMapToNewPermissionDTO: AccessPermissionMapToNewPermissionDTOOverloads = (
        permission: EitherCommentOrDefaultAccessPermissions,
    ): Partial<NewPermissionDTO> => {
        const {
            roleCondition,
            whenObjectStateIsOneOf,
            allowEdit,
            allowDeleting,
            allowEditing,
            allowReplying,
            allowTopLevelCommentsCreating,
            allowManagingCommentsStatuses,
        } = permission;

        const dto: Partial<NewPermissionDTO> = {
            whenObjectStateIsOneOf: whenObjectStateIsOneOf?.map((state: CodeTitle) => state.code),
            allowEdit,
            allowDeleting,
            allowEditing,
            allowReplying,
            allowTopLevelCommentsCreating,
            allowManagingCommentsStatuses,
        };

        if (roleCondition) {
            const isWithRole = roleCondition.group === PermissionTrigger.role;
            const roleConditionCode = roleCondition.code;

            if (isWithRole) {
                dto.role = roleConditionCode;
            } else {
                dto.when = roleConditionCode;
            }
        }

        return dto;
    };

    dropPermissions(): void {
        this.permissionList = [];
        this.customFormPermissionsList = [];
    }

    setMainFields(dto: PermissionSettingsDTO<CommentsPermissionDTO>): void;
    setMainFields(dto: PermissionSettingsDTO<AccessPermissionDTO>): void;
    setMainFields(dto: CustomAndDefaultPermissionSettingsDTO<CommentsPermissionDTO>): void {
        const { permissions } = dto;
        if (!Array.isArray(permissions) || !permissions.length) {
            this.dropPermissions();
            return;
        }
        if (permissions[0].allowEdit !== undefined && permissions[0].allowEditing === undefined) {
            this.permissionList = permissions;
        } else {
            this.customFormPermissionsList = permissions;
        }
    }

    setProcessCode(processCode: string): void {
        this.processCode = processCode;
    }

    setRoleConditionList(roleConditionList: CodeTitleGroupRC[]): void {
        this.roleConditionList = roleConditionList;
    }

    setLifecycleStatesList(lifecycleStatesList: CodeTitle[]): void {
        this.lifecycleStatesList = lifecycleStatesList;
    }

    setIsLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }
}

import { Grid } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { ViewFieldLabel as ViewFieldLabelInj } from './ViewFieldLabel';
import { ViewFieldValue as ViewFieldValueInj, ViewFieldValueProps } from './ViewFieldValue';

export type ViewFieldProps = ViewFieldValueProps & {
    labelId: string;
};

export const ViewField = (props: ViewFieldProps): JSX.Element => {
    const [ViewFieldLabel] = di([ViewFieldLabelInj], ViewField);
    const [ViewFieldValue] = di([ViewFieldValueInj], ViewField);

    const { value, labelId } = props;
    const { formatMessage } = useIntl();
    const label = formatMessage({ id: labelId });
    return (
        <Grid container direction="column" spacing="1">
            <Grid item>
                <ViewFieldLabel label={label} />
            </Grid>
            <Grid item>
                <ViewFieldValue value={value} />
            </Grid>
        </Grid>
    );
};

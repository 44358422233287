import { Grid, Typography } from '@mui/material';
import { ClientBreadcrumbsWithCustomLinks, ObjectHeaderLayout, ObjectHeaderLayoutProps } from '@platform/front-core';
import { AuthorizationCheckQuery } from '@platform/front-types';
import { CommonInfoLine, Link, SxStyle, Transitions } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, useHistory, useParams } from 'react-router-dom';
import { permissionsConfig } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { ICSDataTestIds } from '../../../../constants/data-test-ids';
import { useCampaignRequestPageContext, useFetchAllPermissions } from '../../../../hooks';
import { getCampaignRequestBreadcrumbsLinks as getCampaignRequestBreadcrumbsLinksInj } from '../../../../stores';
import { CampaignRequestSectionRouteParams } from '../../../../types';
import { formatDateAndTime } from '../../../../utils';
import { renderCampaignRequestLink as renderCampaignRequestLinkInj } from '../../components';
import { CampaignRequestAddActualAppointmentButton as CampaignRequestAddActualAppointmentButtonInj } from './CampaignRequestAddActualAppointmentButton';
import { CampaignRequestMenuButton as CampaignRequestMenuButtonInj } from './CampaignRequestMenuButton';
import { CampaignRequestUpdateAppointmentButton as CampaignRequestUpdateAppointmentButtonInj } from './CampaignRequestUpdateAppointmentButton';

const titleBlockAndMenuContainerSx: SxStyle = {
    pb: 1,
};

const mainHeaderContentWrapperSx: SxStyle = {
    '&.MuiGrid-item': {
        pt: 0.5,
    },
};

const renderLinkSx: SxStyle = {
    display: 'inline-block',
};

export const CampaignRequestHeader = observer((): JSX.Element => {
    const [CampaignRequestMenuButton] = di([CampaignRequestMenuButtonInj], CampaignRequestHeader);
    const [getCampaignRequestBreadcrumbsLinks] = di([getCampaignRequestBreadcrumbsLinksInj], CampaignRequestHeader);
    const [renderCampaignRequestLink] = di([renderCampaignRequestLinkInj], CampaignRequestHeader);
    const [CampaignRequestUpdateAppointmentButton] = di(
        [CampaignRequestUpdateAppointmentButtonInj],
        CampaignRequestHeader,
    );
    const [CampaignRequestAddActualAppointmentButton] = di(
        [CampaignRequestAddActualAppointmentButtonInj],
        CampaignRequestHeader,
    );

    const { model, campaignRequestTransitionsModel } = useCampaignRequestPageContext();
    const {
        number,
        regEnd,
        state,
        sent,
        campaign,
        setCurrentUserAsExecutor,
        loadExecutor,
        loadRequest,
        currentSection,
        validateReadonlyPage,
    } = model;
    const sectionExecutor = currentSection?.executor;
    const sectionPrintForms = currentSection?.printForms;

    const { formatMessage } = useIntl();

    const params = useParams<CampaignRequestSectionRouteParams>();
    const { id } = params;

    const allPermissionsQueries = useMemo<AuthorizationCheckQuery[]>(() => {
        return [permissionsConfig.addActualAppointment(id), permissionsConfig.updateActualAppointment(id)];
    }, [id]);

    const [permissions, reloadPermission] = useFetchAllPermissions({ allPermissionsQueries });
    const [addActualAppointment, updateActualAppointment] = permissions;

    const requestTitleValues = { number };

    const breadcrumbsLinksData = getCampaignRequestBreadcrumbsLinks(id, number, campaign);

    const handleSuccessSubmitExecutor = (): void => {
        reloadPermission();
        loadExecutor();
    };

    const handleConfirmAddActualAppointment = (): Promise<void> => {
        return setCurrentUserAsExecutor().then(handleSuccessSubmitExecutor);
    };

    const handleSuccessTransition = (): void => {
        reloadPermission();
        loadRequest();
        sectionPrintForms?.load();
    };

    const executorLink = sectionExecutor ? (
        <Grid container alignItems="center">
            <Grid item pr={0.5}>
                {renderCampaignRequestLink(
                    generatePath(clientRoute.user, { id: sectionExecutor.userId }),
                    sectionExecutor.lastNameWithInitials,
                )}
            </Grid>
            {updateActualAppointment && (
                <Grid item>
                    <CampaignRequestUpdateAppointmentButton
                        handleSuccessSubmit={handleSuccessSubmitExecutor}
                        isIconButton
                    />
                </Grid>
            )}
        </Grid>
    ) : null;

    const formEditPath = params.sectionId ? generatePath(clientRoute.requestSectionFormEdit, params) : '';

    const validationErrorMessage = useMemo((): JSX.Element => {
        const renderLink = (...chunks: string[]): JSX.Element => (
            <Link href={formEditPath} sx={renderLinkSx} isWithoutAutoCapitalize>
                {chunks}
            </Link>
        );

        return (
            <FormattedMessage
                id="campaignRequest.readonlyRequestValidationMsg"
                values={{
                    a: renderLink,
                }}
            />
        );
    }, [params]);

    const history = useHistory();
    const onValidationErrorDialogButtonClick = (): void => {
        history.push(formEditPath);
    };

    const objectHeaderLayoutProps: ObjectHeaderLayoutProps = {
        breadcrumbs: <ClientBreadcrumbsWithCustomLinks customLinks={breadcrumbsLinksData} />,
        mainContentContainerProps: {
            sx: mainHeaderContentWrapperSx,
        },
        titleBlockAndMenu: {
            titleBlock: (
                <Typography variant="subtitle2" pt={0.5}>
                    <FormattedMessage id="campaignRequest.requestN" values={requestTitleValues} />
                </Typography>
            ),
            menu: {
                element: <CampaignRequestMenuButton />,
            },
            containerProps: {
                sx: titleBlockAndMenuContainerSx,
            },
        },
        rightBlocks: {
            topBlock: (
                <React.Fragment>
                    <Grid
                        container
                        flexDirection="column"
                        spacing={1}
                        pb={1.25}
                        data-testid={ICSDataTestIds.campaignRequestCardHeaderRequestInfo}
                    >
                        {!sent && (
                            <CommonInfoLine
                                title={formatMessage({ id: 'campaignRequest.fields.submissionDeadline' })}
                                value={formatDateAndTime(regEnd)}
                            />
                        )}
                        <CommonInfoLine
                            title={formatMessage({ id: 'campaignRequest.fields.state' })}
                            value={state.title}
                        />
                        {executorLink && (
                            <CommonInfoLine
                                title={formatMessage({ id: 'campaignRequest.fields.executor' })}
                                value={executorLink}
                                isColumnDirection
                            />
                        )}
                    </Grid>
                    {addActualAppointment && (
                        <Grid container flexDirection="column">
                            <Grid item pb={0.5}>
                                <CampaignRequestAddActualAppointmentButton
                                    handleConfirm={handleConfirmAddActualAppointment}
                                    requestNumber={number}
                                />
                            </Grid>

                            {updateActualAppointment && (
                                <Grid item>
                                    <CampaignRequestUpdateAppointmentButton
                                        handleSuccessSubmit={handleSuccessSubmitExecutor}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                </React.Fragment>
            ),
        },
        leftBottomBlock: {
            element: (
                <Route
                    path={[
                        clientRoute.requestSection,
                        clientRoute.requestSectionRelatedObjects,
                        clientRoute.requestSectionAddRelatedObjects,
                    ]}
                    exact
                >
                    <Transitions
                        onSuccessTransition={handleSuccessTransition}
                        validateFunction={validateReadonlyPage}
                        validationErrorMessage={validationErrorMessage}
                        validationButtonText={formatMessage({
                            id: 'campaignRequest.readonlyRequestValidationBtnMsg',
                        })}
                        onValidationErrorDialogButtonClick={onValidationErrorDialogButtonClick}
                        model={campaignRequestTransitionsModel}
                    />
                </Route>
            ),
        },
    };

    return <ObjectHeaderLayout {...objectHeaderLayoutProps} />;
});

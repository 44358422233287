import { Theme, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import { AuthorizationCheckQuery, IdTitle } from '@platform/front-types';
import { ActionMenuItem, ConfirmationDialog, MenuButton } from '@platform/front-ui';
import { identity, useFlag } from '@platform/front-utils';
import { TTableKebabButton } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { permissionsConfig } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { useFetchAllPermissions, useStore } from '../../../../hooks';
import { CampaignRequestListConfirmationButtons, CampaignRequestRow } from '../../../../types';
import {
    CampaignRequestEditExecutorDialog as CampaignRequestEditExecutorDialogInj,
    CampaignRequestEditExecutorDialogInfo as CampaignRequestEditExecutorDialogInfoInj,
    CampaignRequestEditExecutorFormType,
} from '../../components';
import { CampaignRequestListRelatedObjectsItem as CampaignRequestListRelatedObjectsItemInj } from './CampaignRequestListRelatedObjectsItem';
import { useSettingsConfirmDialog } from './useSettingsConfirmDialog';

export type CampaignRequestListActionsBtnsProps = {
    campaignRequestRow: CampaignRequestRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

export const CampaignRequestListActionsBtns = observer((props: CampaignRequestListActionsBtnsProps): JSX.Element => {
    const [CampaignRequestEditExecutorDialogInfo] = di(
        [CampaignRequestEditExecutorDialogInfoInj],
        CampaignRequestListActionsBtns,
    );
    const [CampaignRequestEditExecutorDialog] = di(
        [CampaignRequestEditExecutorDialogInj],
        CampaignRequestListActionsBtns,
    );
    const [CampaignRequestListRelatedObjectsItem] = di(
        [CampaignRequestListRelatedObjectsItemInj],
        CampaignRequestListActionsBtns,
    );

    const { campaignRequestRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;
    const { id, customData } = campaignRequestRow;
    const { number } = customData;
    const { requestStore, theme } = useStore();
    const history = useHistory();
    const { formatMessage } = useIntl();

    const [isOpenEditExecutorDialog, openEditExecutorDialog, closeEditExecutorDialog] = useFlag();
    const [isModalOpen, openModal, closeModal] = useFlag();

    const allPermissionsQueries = useMemo<AuthorizationCheckQuery[]>(() => {
        return [
            permissionsConfig.addActualAppointment(id),
            permissionsConfig.updateActualAppointment(id),
            permissionsConfig.editCampaignRequest(id),
            permissionsConfig.deleteCampaignRequest(id),
            permissionsConfig.deleteRelations(id),
            permissionsConfig.saveRelation(id),
        ];
    }, [id]);
    const [permissionsData, reloadPermission] = useFetchAllPermissions({ allPermissionsQueries });
    const [
        addActualAppointment,
        updateActualAppointment,
        editCampaignRequest,
        deleteCampaignRequest,
        deleteRelations,
        saveRelation,
    ] = permissionsData;

    const reloadDataAndPermission = (): void => {
        reloadData();
        reloadPermission();
    };

    const onDeleteConfirm = async (): Promise<void> => {
        try {
            toggleIsRowBlocked();
            await requestStore.deleteRequest(id, true);
            reloadData();
        } catch {
            rowErrorHandler();
        } finally {
            closeModal();
        }
    };

    const handleConfirmAddExecutor = useCallback(async (): Promise<void> => {
        try {
            toggleIsRowBlocked();
            await requestStore.setCurrentUserAsExecutor(id, true);
            reloadDataAndPermission();
        } catch {
            rowErrorHandler();
        } finally {
            closeModal();
        }
    }, [reloadData, id, reloadPermission]);

    const handleEditExecutorDialogOpen = useCallback((): void => {
        openEditExecutorDialog();
    }, []);

    const handleSubmitExecutor = async (values: CampaignRequestEditExecutorFormType): Promise<void> => {
        try {
            toggleIsRowBlocked();
            await requestStore.editExecutorSettings(campaignRequestRow.id, (values.executor as IdTitle).id, true);
            reloadDataAndPermission();
        } catch {
            rowErrorHandler();
        } finally {
            closeEditExecutorDialog();
        }
    };

    const [settings, setSettings] = useSettingsConfirmDialog({
        delete: onDeleteConfirm,
        addExecutor: handleConfirmAddExecutor,
    });

    const confirmationMessage =
        CampaignRequestListConfirmationButtons.addExecutor === settings.id ? (
            <React.Fragment>
                <CampaignRequestEditExecutorDialogInfo campaignRequestRow={campaignRequestRow} />
                <Box pt={1}>
                    {formatMessage(
                        {
                            id: settings.messageId,
                        },
                        { number: number.title },
                    )}
                </Box>
            </React.Fragment>
        ) : (
            formatMessage(
                {
                    id: settings.messageId,
                },
                { number: number.title },
            )
        );

    const renderActionItems = useCallback(
        (hideMenu): ReactNode[] => {
            const onDeleteClick = (): void => {
                setSettings(CampaignRequestListConfirmationButtons.delete);
                hideMenu && hideMenu();
                openModal();
            };

            const onEditClick = (): void => {
                history.push(generatePath(clientRoute.requestEdit, { id }));
            };

            const onAddExecutorClick = (): void => {
                setSettings(CampaignRequestListConfirmationButtons.addExecutor);
                hideMenu && hideMenu();
                openModal();
            };

            const onOpenEditExecutorDialog = (): void => {
                hideMenu && hideMenu();
                handleEditExecutorDialogOpen();
            };

            return [
                <React.Fragment key="actionBtns">
                    {addActualAppointment && updateActualAppointment && (
                        <ActionMenuItem
                            messageId="campaignRequest.actions.appointExecutor"
                            onClick={onOpenEditExecutorDialog}
                        />
                    )}
                    {!addActualAppointment && updateActualAppointment && (
                        <ActionMenuItem
                            messageId="campaignRequest.actions.changeExecutor"
                            onClick={onOpenEditExecutorDialog}
                        />
                    )}
                    {addActualAppointment && (
                        <ActionMenuItem
                            messageId="campaignRequest.actions.requestToWork"
                            onClick={onAddExecutorClick}
                        />
                    )}
                    {(deleteRelations || saveRelation) && (
                        <CampaignRequestListRelatedObjectsItem
                            isAllowSaveRelation={saveRelation}
                            isAllowDeleteRelations={deleteRelations}
                            campaignRequestRow={campaignRequestRow}
                        />
                    )}
                    {editCampaignRequest && <ActionMenuItem messageId="common.edit" onClick={onEditClick} />}
                    {deleteCampaignRequest && <ActionMenuItem messageId="common.delete" onClick={onDeleteClick} />}
                </React.Fragment>,
            ];
        },
        [permissionsData, campaignRequestRow, handleEditExecutorDialogOpen],
    );

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        const atLeastOneAllowed = permissionsData.some(identity);
        return atLeastOneAllowed ? <TTableKebabButton onClick={onClick} /> : <React.Fragment />;
    };

    return (
        <React.Fragment>
            <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems} />
            <ConfirmationDialog
                id={settings.id}
                open={isModalOpen}
                title={formatMessage({
                    id: settings.titleId,
                })}
                message={confirmationMessage}
                onConfirm={settings.onConfirm}
                onCancel={closeModal}
                keepMounted
                maxWidth="xs"
            />
            {updateActualAppointment && (
                <ThemeProvider theme={theme as Theme}>
                    <CampaignRequestEditExecutorDialog
                        onCancel={closeEditExecutorDialog}
                        onSubmit={handleSubmitExecutor}
                        open={isOpenEditExecutorDialog}
                        campaignRequestRow={campaignRequestRow}
                        requestId={campaignRequestRow.id}
                    />
                </ThemeProvider>
            )}
        </React.Fragment>
    );
});

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { action, makeObservable, toJS } from 'mobx';
import { collaborationFormUserColors, collaborationFormUserColorsCount } from '../../constants';
import { getFormComponentByKey } from '../../utils';
export var withCollaborationFormReceiveObservables = {
    handleYourConnection: action.bound,
    handleAllUsers: action.bound,
    handleConnect: action.bound,
    handleDisconnect: action.bound,
    handleFormState: action.bound,
    handleBlocked: action.bound,
    handleUnblock: action.bound,
    handleUpdate: action.bound,
    handleDeleted: action.bound,
    handleNotification: action.bound,
};
var findUserById = function (id) {
    return function (user) {
        return user.id === id;
    };
};
export var withCollaborationFormReceive = function (MainModel) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var _this = _super.apply(this, __spreadArray([], __read(args), false)) || this;
            makeObservable(_this, withCollaborationFormReceiveObservables);
            return _this;
        }
        Object.defineProperty(class_1.prototype, "handleYourConnection", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (dto) {
                var connectionId = dto.connectionId;
                this.setConnectionId(connectionId);
            }
        });
        Object.defineProperty(class_1.prototype, "handleAllUsers", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (dto) {
                var activeUsers = dto.users.map(function (user, index) { return (__assign(__assign({}, user), { color: collaborationFormUserColors[index % collaborationFormUserColorsCount] })); });
                this.setActiveUsers(activeUsers);
            }
        });
        Object.defineProperty(class_1.prototype, "handleConnect", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (dto) {
                var user = dto.user;
                var id = user.id;
                var userConnectionIndex = this.activeUsers.findIndex(findUserById(id));
                if (userConnectionIndex === -1) {
                    var activeUsers = __assign(__assign({}, user), { color: collaborationFormUserColors[this.activeUsers.length % collaborationFormUserColorsCount] });
                    this.setActiveUsers(__spreadArray(__spreadArray([], __read(this.activeUsers), false), [activeUsers], false));
                }
            }
        });
        Object.defineProperty(class_1.prototype, "handleDisconnect", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (dto) {
                var _a = dto.user, connectionIds = _a.connectionIds, id = _a.id;
                if (!connectionIds.length) {
                    var disconnectedUserIndex = this.activeUsers.findIndex(findUserById(id));
                    if (disconnectedUserIndex !== -1) {
                        var newActiveUsers = toJS(this.activeUsers);
                        newActiveUsers.splice(disconnectedUserIndex, 1);
                        this.setActiveUsers(newActiveUsers);
                    }
                }
            }
        });
        Object.defineProperty(class_1.prototype, "handleFormState", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (dto) {
                var _this = this;
                var formInfo = dto.formInfo, blocked = dto.blocked;
                this.formModel && this.formModel.setFormDTO(formInfo);
                setTimeout(function () {
                    blocked.map(function (blockedDTO) {
                        _this.handleBlocked(blockedDTO);
                    });
                    // Форма не успевает отрисоваться
                }, 100);
            }
        });
        Object.defineProperty(class_1.prototype, "handleBlocked", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (dto) {
                if (this.formApi) {
                    var key = dto.key, connectionId = dto.connectionId, userId_1 = dto.userId;
                    if (this.connectionId !== connectionId) {
                        var blockedComponent = getFormComponentByKey(this.formApi, key);
                        var activeUser = this.activeUsers.find(function (user) { return user.id === userId_1; });
                        blockedComponent && activeUser && blockedComponent.blockByUser(activeUser);
                    }
                }
            }
        });
        Object.defineProperty(class_1.prototype, "handleUnblock", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (dto) {
                if (this.formApi) {
                    var key = dto.key;
                    var unblockComponent = getFormComponentByKey(this.formApi, key);
                    unblockComponent && unblockComponent.unblockByUser();
                }
            }
        });
        Object.defineProperty(class_1.prototype, "handleUpdate", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (dto) {
                if (this.formApi) {
                    var key = dto.key, value = dto.value;
                    var updateComponent = getFormComponentByKey(this.formApi, key);
                    updateComponent && updateComponent.setValueByUser(value);
                }
            }
        });
        Object.defineProperty(class_1.prototype, "handleDeleted", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function () {
                this.history.push(this.routes.deletedRoute);
            }
        });
        Object.defineProperty(class_1.prototype, "handleNotification", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (params) {
                var text = params.text, title = params.title, withAutoClose = params.withAutoClose, variant = params.variant, severityType = params.severityType;
                this.notificationStore.model.pushNotification(text, withAutoClose, severityType, title, variant);
            }
        });
        return class_1;
    }(MainModel));
};

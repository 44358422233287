import { Typography } from '@platform/front-ui';
import React from 'react';

export type ViewFieldValueProps = {
    value?: string | number;
};

export const ViewFieldValue = (props: ViewFieldValueProps): JSX.Element => {
    const { value = '-' } = props;
    return (
        <Typography isWithoutAutoCapitalize={true} variant="body1">
            {value}
        </Typography>
    );
};

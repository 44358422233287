import { ClientBreadcrumbsWithCustomLinks } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { SyslogPage as SyslogPageInj } from '../../../components';
import { useCampaignRequestPageContext } from '../../../hooks';
import { getCampaignRequestBreadcrumbsLinks as getCampaignRequestBreadcrumbsLinksInj } from '../../../stores';
import { SyslogEntity } from '../../../types';

export const CampaignRequestSyslogPage = observer((): JSX.Element => {
    const [getCampaignRequestBreadcrumbsLinks] = di([getCampaignRequestBreadcrumbsLinksInj], CampaignRequestSyslogPage);
    const [SyslogPage] = di([SyslogPageInj], CampaignRequestSyslogPage);

    const { model } = useCampaignRequestPageContext();
    const { id, number, campaign } = model;
    const breadcrumbsLinksData = getCampaignRequestBreadcrumbsLinks(id, number, campaign, true);
    const breadcrumbs = <ClientBreadcrumbsWithCustomLinks customLinks={breadcrumbsLinksData} />;
    const defaultPath = generatePath(clientRoute.requestSyslog, { id });

    return <SyslogPage breadcrumbs={breadcrumbs} entityType={SyslogEntity.request} defaultPath={defaultPath} />;
});

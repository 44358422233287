import { CommentSettingsDTOWithoutId } from '@platform/comments';
import { SignatureSettings } from '@platform/crypto-ui';
import {
    CodeTitleNull,
    FieldLocales,
    FileDTO,
    IdTitle,
    IdTitleNull,
    LocalesTree,
    PersonDTO,
} from '@platform/front-types';
import { ValueOf } from '@platform/front-utils';
import { TitlesType } from '@platform/multi-lang-field';

export type CommentSettingsDTOWithoutIdForFormik = Omit<CommentSettingsDTOWithoutId, 'maxCommentLength' | 'titles'> & {
    maxCommentLength: string | number;
    titles: TitlesType;
};

export type RequestSectionSettingsListDTO = IdTitle & {
    formInfo?: IdTitle;
    position: number;
};

export type SectionSettingDTO = {
    title: string;
    identifier: string;
    formCode: string;
    formTitle: string;
    fileSize?: number;
    executorId?: string;
    executorFullname?: string;
    parentSectionSettingId?: string;
    openOnCreation?: boolean;
    showComments: boolean;
    commentAttributes?: Partial<CommentSettingsDTOWithoutId>;
};

export type RequestFormSectionSettingFormValues = {
    title: string;
    identifier: string;
    form: CodeTitleNull;
    fileSize?: number;
    executor?: IdTitleNull;
    parentSectionSetting?: IdTitleNull;
    openOnCreation: boolean;
    showComments: boolean;
    enableCommentStatuses: boolean;
} & Partial<CommentSettingsDTOWithoutIdForFormik>;

export enum RequestFormSectionSettingFormField {
    title = 'title',
    identifier = 'identifier',
    form = 'form',
    fileSize = 'fileSize',
    executor = 'executor',
    parentSectionSetting = 'parentSectionSetting',
    openOnCreation = 'openOnCreation',
}

export enum CommentsSectionSettingsFormField {
    titles = 'titles',
    showComments = 'showComments',
    maxCommentLength = 'maxCommentLength',
    commentingAllowed = 'commentingAllowed',
    textEditorSettings = 'textEditorSettings',
    enableCommentStatuses = 'enableCommentStatuses',
}

export enum CommentsAccessRightsSettingsFormField {
    allowTopLevelCommentsCreating = 'allowTopLevelCommentsCreating',
    allowReplying = 'allowReplying',
    allowEditing = 'allowEditing',
    allowDeleting = 'allowDeleting',
    allowManagingCommentsStatuses = 'allowManagingCommentsStatuses',
}

export type RequestFormSectionSettingFormFields = keyof Required<
    RequestFormSectionSettingFormValues & CommentSettingsDTOWithoutId
>;

export enum CampaignSettingsEntities {
    CampaignRequestForm = 'CampaignRequestForm',
    SectionSetting = 'SectionSetting',
    PF = 'PF',
}

export type DeletionType = {
    delete: Record<CampaignSettingsEntities, string>;
    confirmDelete: Record<CampaignSettingsEntities, string>;
};

export type SectionFormLocales = {
    sectionForm: Record<RequestFormSectionSettingFormFields & CommentsSectionSettingsFormField, FieldLocales>;
    deletion: DeletionType;
};

export type CampaignSettingsLocales = LocalesTree & SectionFormLocales & TemplateLocales;

export type TemplateListDTO = IdTitle & {
    description: string;
    fileDTO?: FileDTO;
    positionRank: number;
    required: boolean;
};

export type TemplateDTO = IdTitle & {
    docCategory?: string;
    description: string;
    author?: PersonDTO;
    fileDTO?: FileDTO;
    resultFileFormat: string;
    signatureSettings: SignatureSettings;
    required: boolean;
};

export type UploadFile = File & {
    isUploaded?: boolean;
};

export type TemplateFormValues = {
    title: string;
    description: string;
    docCategory: IdTitleNull;
    fileFormat: string;
    required: boolean;
    file: UploadFile[];
    signatureSettings: SignatureSettings;
};

export enum TemplateFormSignatureFields {
    signatureEnabled = 'signatureSettings.signatureEnabled',
    signatureRequired = 'signatureSettings.signatureRequired',
    signatureBrowserPlugin = 'signatureSettings.signatureBrowserPlugin',
    signatureUpload = 'signatureSettings.signatureUpload',
}

export enum TemplateFormCommonFields {
    title = 'title',
    description = 'description',
    docCategory = 'docCategory',
    fileFormat = 'fileFormat',
    required = 'required',
    file = 'file',
}

export const TemplateFormFields = { ...TemplateFormCommonFields, ...TemplateFormSignatureFields };
export type TemplateFormFields = ValueOf<typeof TemplateFormFields>;

export type TemplateLocales = {
    templateForm: Record<TemplateFormFields, FieldLocales>;
};

import { CKEditorToolbarConfigDefault } from '@platform/comments';
import { IntlStore } from '@platform/front-core';
import { CodeTitle, CodeTitleNull, IdTitle, IdTitleNull, TitlesDTO } from '@platform/front-types';
import { LoadingModel, useYup } from '@platform/front-utils';
import { titlesDTOtoTitles, titlesYupSchema } from '@platform/multi-lang-field';
import { action, computed, makeObservable, observable } from 'mobx';
import { CampaignsStore, RootStore } from '../stores';
import {
    CommentsSectionSettingsFormField,
    RequestFormSectionSettingFormFields,
    RequestFormSectionSettingFormValues,
    SectionSettingDTO,
} from '../types';

const bannedSymbols = /^[^\/\\:*?<>|]+$/g;
const commentMaxLength = 1000;
const commentMinLength = 1;

const { titles, commentingAllowed, textEditorSettings, maxCommentLength, enableCommentStatuses } =
    CommentsSectionSettingsFormField;

const titlesDefault: TitlesDTO = {
    en: 'Comments',
    ru: 'Комментарии',
};

export const getSectionSettingFormInitialValues = (): RequestFormSectionSettingFormValues => ({
    title: '',
    identifier: '',
    form: null,
    executor: null,
    parentSectionSetting: null,
    openOnCreation: false,
    showComments: false,
    [enableCommentStatuses]: false,
    [titles]: titlesDTOtoTitles(titlesDefault),
    [commentingAllowed]: true,
    [textEditorSettings]: CKEditorToolbarConfigDefault,
    [maxCommentLength]: '',
});

export const requestFormSectionSettingModelObservables = {
    campaignsStore: observable,
    executorsList: observable,
    formCodeList: observable,
    id: observable,
    intlStore: observable,
    isSectionSettingDataLoaded: observable,
    parentSectionSettingList: observable,
    rfId: observable,
    sectionSettingDTO: observable,
    sectionSettingFormInitialValues: observable,

    identifierMessage: computed,
    isNanMessage: computed,
    maxCommentLengthMessage: computed,
    mustBeIntegerMessage: computed,
    positiveNumberMessage: computed,

    getFieldLabel: action.bound,
    getFieldPlaceholder: action.bound,
    getSectionSettingFormValidationSchema: action.bound,
    loadSectionSettingDTO: action.bound,
    loadSectionSettingData: action.bound,
    setDTO: action.bound,
    setSectionSettingFormValues: action.bound,
    syncModelDataWithBackend: action.bound,
};

/**
 * Модель формы настройки создания раздела
 * @param rfId - Id формы заявки
 * @param id - Id категории
 * @param rootStore - Корневой стор, где собраны все сторы.
 * @param isCreatePage - Флаг, который определяет собирается ли пользователь создать новый раздел.
 * В противном случае пользователь собирается редактировать существующий.
 * */
export class RequestFormSectionSettingModel extends LoadingModel {
    /** Стор категорий */
    campaignsStore: CampaignsStore;
    /** Стор апи для работы с локалями (intl) */
    intlStore: IntlStore;

    /** Id формы заявки */
    rfId: string;
    /** Id категории */
    id: string;
    /** Начальные значения формы */
    sectionSettingFormInitialValues: RequestFormSectionSettingFormValues = observable.object(
        getSectionSettingFormInitialValues(),
    );
    /** ДТО с настройками раздела */
    sectionSettingDTO: SectionSettingDTO = {} as SectionSettingDTO;

    /**
     * Флаг загрузки необходимых данных для формы (e.g.
     * список кодов формы, список экзекьюторов, список настроек
     * родительской формы etc.)
     */
    isSectionSettingDataLoaded = false;
    /** Список кодов формы */
    formCodeList: CodeTitle[] = [];
    /** Список экзекьюторов */
    executorsList: IdTitle[] = [];
    /** Список настроек родительской формы */
    parentSectionSettingList: IdTitle[] = [];

    constructor(rfId: string, id: string, rootStore: RootStore, isCreatePage: boolean) {
        super();
        makeObservable(this, requestFormSectionSettingModelObservables);

        this.rfId = rfId;
        this.id = id;
        this.campaignsStore = rootStore.campaignsStore;
        this.intlStore = rootStore.coreRootStore.intlStore;
        isCreatePage ? this.setSectionSettingFormValues() : this.syncModelDataWithBackend();
    }

    /** Загружает данные с бекенда и синхронизирует их с полями по умолчанию */
    async syncModelDataWithBackend(): Promise<void> {
        await this.loadSectionSettingDTO();
        this.setSectionSettingFormValues();
    }

    /** Загружает ДТО настроек раздела */
    async loadSectionSettingDTO(): Promise<void> {
        await this.campaignsStore.loadSectionSetting(this.id).then(this.setDTO);
    }

    /** Загружает настройки необходимых данных для формы. См isSectionSettingDataLoaded */
    async loadSectionSettingData(): Promise<void> {
        this.formCodeList = await this.campaignsStore.loadFormCode();
        this.executorsList = await this.campaignsStore.loadExecutors();
        this.parentSectionSettingList = await this.campaignsStore.loadParentSectionSetting(this.rfId, this.id);
        this.isSectionSettingDataLoaded = true;
    }

    /** Назначает значения формы */
    async setSectionSettingFormValues(): Promise<void> {
        if (!this.isSectionSettingDataLoaded) {
            await this.loadSectionSettingData();
        }

        const { formCode, executorId, parentSectionSettingId, commentAttributes, ...restDTO } = this
            .sectionSettingDTO as SectionSettingDTO;
        /** Получаем начальные значения для формы, которые заданы выше.  */
        const initialValues = getSectionSettingFormInitialValues();

        /** Подменяем начальные значения для формы на пришедшие с ДТО. */
        const form: CodeTitleNull =
            (formCode &&
                this.formCodeList.find((formItem) => {
                    return formItem.code === formCode;
                })) ||
            null;
        const executor: IdTitleNull =
            (executorId &&
                this.executorsList.find((executorItem) => {
                    return executorItem.id === executorId;
                })) ||
            null;
        const parentSectionSetting: IdTitleNull =
            (parentSectionSettingId &&
                this.parentSectionSettingList.find((parentSectionSettingItem) => {
                    return parentSectionSettingItem.id === parentSectionSettingId;
                })) ||
            null;

        /** Устанавливаем измененные начальные значения для формы */
        Object.assign(initialValues, {
            ...restDTO,
            form,
            executor,
            parentSectionSetting,
        });

        /** Если нам пришли commentAttributes в ДТО, то меняем начальные commentAttributes на пришедшие с ДТО. */
        if (commentAttributes) {
            const titles = titlesDTOtoTitles(commentAttributes.titles);
            Object.assign(initialValues, {
                ...initialValues,
                ...commentAttributes,
                titles,
            });
        }

        /** Устаналиваем поля для формы. */
        this.sectionSettingFormInitialValues = observable.object(initialValues);

        this.setIsLoaded(true);
    }

    /** Валидационная схема для формика. */
    getSectionSettingFormValidationSchema() {
        const { Yup } = useYup();
        return Yup.object({
            title: Yup.string().trim().required(),
            identifier: Yup.string().trim().required().matches(bannedSymbols, { message: this.identifierMessage }),
            form: Yup.object().nullable().required(),
            fileSize: Yup.number()
                .min(0, this.positiveNumberMessage)
                .integer(this.mustBeIntegerMessage)
                .typeError(this.mustBeIntegerMessage),
            executor: Yup.object().nullable(),
            titles: titlesYupSchema(Yup, true).min(1),
            maxCommentLength: Yup.number()
                .min(commentMinLength, this.positiveNumberMessage)
                .max(commentMaxLength, this.maxCommentLengthMessage)
                .integer(this.mustBeIntegerMessage)
                .typeError(this.isNanMessage),
        });
    }

    /** Возвращает локализированное сообщение для лейбла поля формы */
    getFieldLabel(fieldName: RequestFormSectionSettingFormFields): string {
        return this.intlStore.intl.formatMessage({ id: `campaignSettings.sectionForm.${fieldName}.label` });
    }

    /** Возвращает локализированное сообщение для плейсхолдера поля формы */
    getFieldPlaceholder(fieldName: RequestFormSectionSettingFormFields): string {
        return this.intlStore.intl.formatMessage({ id: `campaignSettings.sectionForm.${fieldName}.placeholder` });
    }

    /** Возвращает сообщение с ошибкой валидации для запрещенных символов */
    get identifierMessage(): string {
        return this.intlStore.intl.formatMessage({ id: 'validation.bannedSymbols' }, { symbols: '/ \\ : * ? < > |' });
    }

    /** Возвращает сообщение с ошибкой валидации для проверки на число */
    get isNanMessage(): string {
        return this.intlStore.intl.formatMessage({ id: 'validation.number' });
    }

    /** Возвращает сообщение с ошибкой валидации для проверки на положительное число */
    get positiveNumberMessage(): string {
        return this.intlStore.intl.formatMessage({ id: 'validation.numberPositive' });
    }

    /** Возвращает сообщение с ошибкой валидации для проверки на целое число */
    get mustBeIntegerMessage(): string {
        return this.intlStore.intl.formatMessage({ id: 'validation.numberInteger' });
    }

    /** Сообщение о максимально допустимой длине комментария */
    get maxCommentLengthMessage(): string {
        return this.intlStore.intl.formatMessage({ id: 'validation.maxLength' }, { length: commentMaxLength });
    }

    /** Устанавливает ДТО настройки создания раздела */
    setDTO(dto: SectionSettingDTO): void {
        this.sectionSettingDTO = dto;
    }
}

import { grey, makeSxStyles } from '@platform/front-ui';

export const campaignSettingActionPanelSxStyles = makeSxStyles({
    actionPanel: {
        borderTop: `1px solid ${grey[300]}`,
        paddingLeft: (theme) => theme.spacing(2),
        paddingRight: (theme) => theme.spacing(2),
        paddingTop: (theme) => theme.spacing(1),
        paddingBottom: (theme) => theme.spacing(1),
    },
    actionPanelCancelButton: {
        marginRight: (theme) => theme.spacing(1),
    },
});

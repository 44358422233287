import { Button } from '@mui/material';
import { ConfirmationDialog } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { useCampaignRequestFormContext } from '../../../../../../../../hooks';

export type CampaignRequestSavePanelDeleteAllProps = {};

export const CampaignRequestSavePanelDeleteAll = (props: CampaignRequestSavePanelDeleteAllProps): JSX.Element => {
    const { formatMessage } = useIntl();
    const { collaborationFormModel, goToView } = useCampaignRequestFormContext();
    const { sendCancelAll, sendSave } = collaborationFormModel;

    const [isModalOpen, openModal, closeModal] = useFlag();

    const onConfirm = (): Promise<void> => {
        return new Promise(() => {
            sendCancelAll();
            goToView();
        });
    };

    const title = formatMessage({ id: 'form.deleteAllChanges' });
    const message = formatMessage({ id: 'form.deleteAllChangesConfirmText' });

    return (
        <React.Fragment>
            <ConfirmationDialog
                id="delete-all-changes"
                keepMounted
                open={isModalOpen}
                onConfirm={onConfirm}
                onCancel={closeModal}
                title={title}
                message={message}
            />

            <Button variant="text" color="secondary" onClick={openModal}>
                {title}
            </Button>
        </React.Fragment>
    );
};

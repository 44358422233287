import { useState } from 'react';
import {
    CampaignRequestListConfirmationButtons,
    CampaignRequestListSettings,
    CampaignRequestListSettingsConfirmDialogType,
    CampaignRequestListSettingsPropsType,
} from '../../../../types';

export type UseSettingsConfirmDialog = [
    CampaignRequestListSettingsConfirmDialogType,
    (actionButton: CampaignRequestListConfirmationButtons) => void,
];

export function settingsConfirmDialog(props: CampaignRequestListSettingsPropsType): CampaignRequestListSettings {
    return {
        delete: {
            id: CampaignRequestListConfirmationButtons.delete,
            titleId: 'campaignRequest.confirmDeletionTitle',
            messageId: 'campaignRequest.confirmDeletionInfoText',
            onConfirm: props[CampaignRequestListConfirmationButtons.delete],
        },
        addExecutor: {
            id: CampaignRequestListConfirmationButtons.addExecutor,
            titleId: 'campaignRequest.dialog.executorsTitle',
            messageId: 'campaignRequest.dialog.executorsMessage',
            onConfirm: props[CampaignRequestListConfirmationButtons.addExecutor],
        },
    };
}

export const useSettingsConfirmDialog = (props: CampaignRequestListSettingsPropsType): UseSettingsConfirmDialog => {
    const settings = settingsConfirmDialog(props);
    const [settingsConfirm, setSettingsConfirm] = useState<CampaignRequestListSettingsConfirmDialogType>(
        settings[CampaignRequestListConfirmationButtons.delete],
    );

    const setSettings = (actionButton: CampaignRequestListConfirmationButtons): void => {
        setSettingsConfirm(settings[actionButton]);
    };

    return [settingsConfirm, setSettings];
};

import { Button, Grid } from '@mui/material';
import { AuthorizationCheck, CollaborationFormActiveUsers } from '@platform/front-core';
import { SxStyle } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../../../clientRoute';
import { useCampaignRequestFormContext } from '../../../../../../../hooks';
import { CampaignRequestSectionRouteParams } from '../../../../../../../types';
import {
    CampaignRequestSavePanelDeleteAll as CampaignRequestSavePanelDeleteAllInj,
    CampaignRequestSavePanelPublishAll as CampaignRequestSavePanelPublishAllInj,
} from './components';

const goBackSx: SxStyle = {
    marginLeft: 'auto',
};

export const CampaignRequestSavePanelLeftElement = observer((): JSX.Element => {
    const [CampaignRequestSavePanelDeleteAll] = di(
        [CampaignRequestSavePanelDeleteAllInj],
        CampaignRequestSavePanelLeftElement,
    );
    const [CampaignRequestSavePanelPublishAll] = di(
        [CampaignRequestSavePanelPublishAllInj],
        CampaignRequestSavePanelLeftElement,
    );
    const history = useHistory();
    const params = useParams<CampaignRequestSectionRouteParams>();

    const { activeUsers, isFailedConnection } = useCampaignRequestFormContext().collaborationFormModel;

    const goBack = (): void => {
        history.push(generatePath(clientRoute.requestSection, params));
    };

    return (
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            {isFailedConnection ? (
                <Button color="secondary" sx={goBackSx} onClick={goBack}>
                    <FormattedMessage id="common.goBack" />
                </Button>
            ) : (
                <React.Fragment>
                    <Grid item flexGrow={2}>
                        <CollaborationFormActiveUsers users={activeUsers} />
                    </Grid>

                    <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.Administration}>
                        <Grid container item width="auto">
                            <Grid item mr={1}>
                                <CampaignRequestSavePanelDeleteAll />
                            </Grid>
                            <Grid item mr={1}>
                                <CampaignRequestSavePanelPublishAll />
                            </Grid>
                        </Grid>
                    </AuthorizationCheck>
                </React.Fragment>
            )}
        </Grid>
    );
});

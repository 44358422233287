import { MenuItem } from '@mui/material';
import { AuthorizationCheckQuery } from '@platform/front-types';
import { MenuButton } from '@platform/front-ui';
import { identity, VoidFunction } from '@platform/front-utils';
import { TTableKebabButton } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { permissionsConfig } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { useStore } from '../../../../hooks';
import { CampaignTableRow } from '../../../../types';

export type CampaignListRegistryActionsBtnsProps = {
    row: CampaignTableRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
    openCreateRequestDialog: (campaignId: string) => void;
};

export const CampaignListRegistryActionsBtns = observer((props: CampaignListRegistryActionsBtnsProps): JSX.Element => {
    const { row, openCreateRequestDialog } = props;
    const { id } = row;

    const [permissionsData, setPermissionsData] = useState<boolean[]>([]);
    const [createRequest, editCampaign] = permissionsData;

    const { coreRootStore } = useStore();
    const { authorizationStore } = coreRootStore;
    const history = useHistory();

    const allPermissionsQueries = useMemo<AuthorizationCheckQuery[]>(() => {
        return [permissionsConfig.createCampaignRequest(id), permissionsConfig.editCampaign(id)];
    }, [id]);

    useEffect(() => {
        authorizationStore.checkAll(allPermissionsQueries).then(setPermissionsData);
    }, [allPermissionsQueries]);

    const renderActionItems = useCallback((): ReactNode[] => {
        const { id } = row;
        const onCreateClick = (): void => {
            openCreateRequestDialog(id);
        };

        const onEditClick = (): void => {
            history.push(generatePath(clientRoute.campaignSettings, { id }));
        };

        return [
            createRequest && (
                <MenuItem key="createRequest" onClick={onCreateClick}>
                    <FormattedMessage id="campaign.createRequest" />
                </MenuItem>
            ),
            editCampaign && (
                <MenuItem key="campaignSettings" onClick={onEditClick}>
                    <FormattedMessage id="campaignSettings.campaignSettings" />
                </MenuItem>
            ),
        ];
    }, [createRequest, editCampaign, row]);

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        const atLeastOneAllowed = permissionsData.some(identity);
        return atLeastOneAllowed ? <TTableKebabButton onClick={onClick} /> : <React.Fragment />;
    };

    return <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems} />;
});

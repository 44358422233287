import { Grid } from '@mui/material';
import { AutocompleteField, FullScreenForm } from '@platform/front-ui';
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { useCampaignSettingsAccessPermissionContext } from '../../../../../../hooks';
import { AccessPermissionDTOFields, CodeTitleGroupRC } from '../../../../../../types';

const { whenObjectStateIsOneOf, roleCondition } = AccessPermissionDTOFields;

export type CampaignSettingsEditPermissionFormProps = {
    dialogTitle: React.ReactNode;
};

/** Попап "добавление доступа к разделу" и "редактирование доступа к разделу" */
export const CampaignSettingsEditPermissionForm = observer(
    (props: CampaignSettingsEditPermissionFormProps): JSX.Element => {
        const { dialogTitle } = props;

        const { formatMessage } = useIntl();
        const { model, checkboxLabel, goBack } = useCampaignSettingsAccessPermissionContext();
        const { roleConditionList, lifecycleStatesList } = model;

        const createCheckboxLabel = {
            label: checkboxLabel,
        };

        const roleConditionGroupBy = (option: CodeTitleGroupRC): string => {
            return formatMessage({
                id: `campaignSettings.editPermissions.group.${option.group}`,
            });
        };

        return (
            <FullScreenForm onCancel={goBack} dialogTitle={dialogTitle}>
                <Grid container flexDirection="column" spacing={2}>
                    <Grid item>
                        <AutocompleteField
                            fieldName={roleCondition}
                            options={roleConditionList}
                            disableClearable={true}
                            required
                            label={formatMessage({ id: 'campaignSettings.editPermissions.list.role' })}
                            placeholder={formatMessage({
                                id: 'campaignSettings.sectionForm.form.placeholder',
                            })}
                            groupBy={roleConditionGroupBy}
                        />
                    </Grid>
                    <Grid item>
                        <AutocompleteField
                            fieldName={whenObjectStateIsOneOf}
                            multiple
                            options={lifecycleStatesList}
                            disableClearable={true}
                            label={formatMessage({ id: 'campaignSettings.editPermissions.lifecycleStage' })}
                            placeholder={formatMessage({
                                id: 'campaignSettings.editPermissions.list.allStatuses',
                            })}
                        />
                    </Grid>
                    <Grid item container flexDirection="column">
                        <Field
                            name="allowEdit"
                            type="checkbox"
                            component={CheckboxWithLabel}
                            Label={createCheckboxLabel}
                        />
                    </Grid>
                </Grid>
            </FullScreenForm>
        );
    },
);

import { LocalesTree } from '@platform/front-utils';

const startPage: LocalesTree = {
    headerLinks: {
        about: 'O продукте',
        news: 'Новости',
        documents: 'Документы',
        resources: 'Другие ресурсы',
        contacts: 'Контакты',
        radar: 'Радар',
        login: 'Войти в систему',
        loginAccount: 'Войти в личный кабинет',
    },
    loginAnonymously: 'Войти без регистрации',
    title: 'Система сбора и верификации идей и предложений',
    productDescription: 'Настраиваемая информационная среда для управления интеллектуальным капиталом организации',
    logoAlt: 'TOT X logo',
    footerLogoAlt: 'TOT X logo',
};

export default startPage;

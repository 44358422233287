import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { sxHeight100 } from '../../../../constants';
import { CampaignCardBodyDescription as CampaignCardBodyDescriptionInj } from './campaign-card-body-description';
import { CampaignCardBodyRequests as CampaignCardBodyRequestsInj } from './campaign-card-body-requests';

export const CampaignCardBody = observer((): JSX.Element => {
    const [CampaignCardBodyDescription] = di([CampaignCardBodyDescriptionInj], CampaignCardBody);
    const [CampaignCardBodyRequests] = di([CampaignCardBodyRequestsInj], CampaignCardBody);

    return (
        <Grid item container direction="column" flexWrap="nowrap" flexGrow={1}>
            <Grid item sx={sxHeight100}>
                <Route path={clientRoute.campaignDescription}>
                    <CampaignCardBodyDescription />
                </Route>
                <Route path={clientRoute.campaignRequests}>
                    <CampaignCardBodyRequests />
                </Route>
            </Grid>
        </Grid>
    );
});

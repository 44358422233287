import { Box, Grid, SxProps, Theme } from '@mui/material';
import { Approval } from '@platform/approval';
import { Comments } from '@platform/comments';
import { AuthorizationCheck } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useCallback, useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { entities, permissions, permissionsConfig } from '../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../clientRoute';
import { approvalAppDomId, commentsAppDomId } from '../../../../../constants';
import { ICSDataTestIds } from '../../../../../constants/data-test-ids';
import {
    CampaignRequestFormContext,
    CampaignRequestFormContextType,
    CampaignRequestPrintFormsContext,
    CampaignRequestPrintFormsContextType,
} from '../../../../../contexts';
import {
    useCampaignRequestCollaborationForm,
    useCampaignRequestPageContext,
    useCommentsAppContext,
    useStore,
} from '../../../../../hooks';
import { PrintFormListModel, PrintFormListModel as PrintFormListModelInj } from '../../../../../models';
import { CampaignRequestSectionRouteParams } from '../../../../../types';
import { CampaignRequestForm as CampaignRequestFormInj } from './campaign-request-form';
import { CampaignRequestPrintForms as CampaignRequestPrintFormsInj } from './campaign-request-print-forms';
import { CampaignRequestSavePanel as CampaignRequestSavePanelInj } from './campaign-request-save-panel';

const mainContentWrapperSx: SxProps<Theme> = {
    overflowY: 'auto',
    overflowX: 'hidden',
    flexWrap: 'nowrap',
    margin: 0,
    width: 'auto',
};

const printFormsWrapperSx: SxProps<Theme> = {
    my: 2,
};

export type CampaignRequestContentProps = {
    formWrapperRef: React.RefObject<HTMLDivElement>;
};

const formPrintFormsWrapperStyles: CSSProperties = { paddingTop: 0 };

export const CampaignRequestContent = observer((props: CampaignRequestContentProps): JSX.Element => {
    const [CampaignRequestForm] = di([CampaignRequestFormInj], CampaignRequestContent);
    const [CampaignRequestPrintForms] = di([CampaignRequestPrintFormsInj], CampaignRequestContent);
    const [PrintFormListModel] = di([PrintFormListModelInj], CampaignRequestContent);
    const [CampaignRequestSavePanel] = di([CampaignRequestSavePanelInj], CampaignRequestContent);

    const { formWrapperRef } = props;
    const params = useParams<CampaignRequestSectionRouteParams>();
    const rootStore = useStore();
    const history = useHistory();
    const { id, sectionId } = params;
    const { model, isFormView, campaignRequestTransitionsModel } = useCampaignRequestPageContext();
    const { config } = useCommentsAppContext();
    const { state, currentSection, formName, formApi, setFormApi, loadRequestServiceInfo, loadRequest } = model;
    const stateId = state.id;

    useEffect(() => {
        if (currentSection) {
            currentSection.setPrintForms(new PrintFormListModel(rootStore, sectionId));
        }
    }, [currentSection, rootStore, sectionId]);

    const returnPath = sectionId ? generatePath(clientRoute.requestSection, params) : '';
    const goToView = useCallback((): void => {
        if (returnPath) {
            history.push(returnPath);
            loadRequestServiceInfo();
        }
    }, [returnPath]);

    const collaborationFormModel = useCampaignRequestCollaborationForm(isFormView, goToView, currentSection, formApi);

    const formContextValue: CampaignRequestFormContextType = {
        collaborationFormModel,
        goToView,
    };

    const printFormsContextValue: CampaignRequestPrintFormsContextType = {
        model: currentSection?.printForms as PrintFormListModel,
    };

    const onSheetFinished = (): void => {
        loadRequest();
        campaignRequestTransitionsModel.getTransitions();
    };

    return (
        <CampaignRequestFormContext.Provider value={formContextValue}>
            <Grid
                item
                container
                direction="column"
                flexGrow={4}
                flexWrap="nowrap"
                overflow="hidden"
                data-testid={ICSDataTestIds.campaignRequestCardObjectContentLayoutContent}
            >
                <Grid container direction="row" flexGrow={1} flexWrap="nowrap" overflow="hidden">
                    <Grid item flexGrow={1} sx={mainContentWrapperSx} ref={formWrapperRef}>
                        <Grid
                            container
                            direction="column"
                            flexWrap="nowrap"
                            p={1}
                            pr={2}
                            {...(isFormView && { style: formPrintFormsWrapperStyles })}
                        >
                            <Grid item>
                                {currentSection && (
                                    <CampaignRequestForm
                                        requestModel={model}
                                        section={currentSection}
                                        setFormApi={setFormApi}
                                        isFormView={isFormView}
                                        formName={formName}
                                    />
                                )}
                            </Grid>

                            {isFormView && !!currentSection?.printForms && (
                                <AuthorizationCheck
                                    key={stateId + 'pf'}
                                    entityCode={entities.section}
                                    permCode={permissions.section.ViewPf}
                                    entityId={sectionId}
                                >
                                    <Grid item sx={printFormsWrapperSx}>
                                        <CampaignRequestPrintFormsContext.Provider value={printFormsContextValue}>
                                            <CampaignRequestPrintForms />
                                        </CampaignRequestPrintFormsContext.Provider>
                                    </Grid>
                                </AuthorizationCheck>
                            )}

                            {isFormView && (
                                <AuthorizationCheck
                                    {...permissionsConfig.viewApprovalSheets(sectionId)}
                                    key={stateId + 'approval'}
                                >
                                    <Grid item id={approvalAppDomId}>
                                        <Approval objectId={sectionId} onSheetFinished={onSheetFinished} />
                                    </Grid>
                                </AuthorizationCheck>
                            )}
                        </Grid>

                        {config && (
                            <Box id={commentsAppDomId}>
                                <AuthorizationCheck
                                    entityCode={entities.section}
                                    permCode={permissions.section.ViewComment}
                                    entityId={sectionId}
                                >
                                    <Comments config={config} />
                                </AuthorizationCheck>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                {!isFormView && (
                    <Grid item data-testid={ICSDataTestIds.campaignRequestSavePanelWrapper}>
                        <CampaignRequestSavePanel />
                    </Grid>
                )}
            </Grid>
        </CampaignRequestFormContext.Provider>
    );
});

import { ActionItemProps } from '@platform/front-types';
import { ActionMenuItem } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { UserTableRow } from '../../../../types';
import { actionCodeByUserStatus } from '../../../../utils';
import { UserStatusModal } from '../../../users';

export type UserStatusButtonProps = ActionItemProps & {
    userTableRow: UserTableRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

export const UserStatusButton = observer((props: UserStatusButtonProps): JSX.Element => {
    const { userTableRow, reloadData, hideMenu, toggleIsRowBlocked, rowErrorHandler } = props;
    const [isConfirmationDialogOpen, setConfirmationDialogOpen, setConfirmationDialogClosed] = useFlag();

    const customData = userTableRow.customData;
    const { name, login } = customData;
    const code = userTableRow.customData.state.code;
    const id = userTableRow.id;

    const closeDialogAndMenu = (): void => {
        setConfirmationDialogClosed();
        hideMenu && hideMenu();
    };

    return (
        <React.Fragment>
            <ActionMenuItem
                messageId={`user.action.${actionCodeByUserStatus[code]}`}
                onClick={setConfirmationDialogOpen}
            />
            <UserStatusModal
                id={id}
                name={name.title}
                login={login.title}
                userStatusCode={code}
                open={isConfirmationDialogOpen}
                reloadData={reloadData}
                onCancel={closeDialogAndMenu}
                toggleIsRowBlocked={toggleIsRowBlocked}
                rowErrorHandler={rowErrorHandler}
            />
        </React.Fragment>
    );
});

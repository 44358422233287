import { SxProps, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { grey } from '@platform/front-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const thSx: SxProps<Theme> = {
    // todo: паддинг из темы
    paddingX: 1,
    paddingY: 0.75,
    color: grey[500],
};

export const renderSectionSettingsHead = (): JSX.Element => {
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={thSx} width="31.5%">
                    <Typography variant="body1">
                        <FormattedMessage id="campaignSettings.requestFormSettings.title" />
                    </Typography>
                </TableCell>
                <TableCell sx={thSx} width="39%">
                    <Typography variant="body1">
                        <FormattedMessage id="campaignSettings.requestFormSettings.formTitle" />
                    </Typography>
                </TableCell>
                <TableCell sx={thSx} width="29.5%" />
            </TableRow>
        </TableHead>
    );
};

import { GlobalStyles } from '@mui/material';
import React from 'react';
import { sassVars } from '../../constants';
export var flatpickrGlobalStyles = function (theme) { return (React.createElement(GlobalStyles
// @ts-ignore
, { 
    // @ts-ignore
    styles: function () {
        return {
            '.flatpickr-calendar': {
                '&.arrowTop, &.arrowBottom': {
                    padding: "".concat(sassVars.datepickerContainerPadding, " !important"),
                    border: "".concat(sassVars.datepickerContainerBorder, " !important"),
                    boxShadow: "".concat(sassVars.datepickerBoxShadow, " !important"),
                    '&:before, &:after': {
                        left: '50% !important', // выравнивание стрелки по центру календаря
                    },
                    '&:before': {
                        borderWidth: "".concat(sassVars.datepickerArrowBeforeSize, " !important"),
                        margin: "0 -".concat(sassVars.datepickerArrowBeforeSize, " !important"),
                    },
                    '&:after': {
                        borderWidth: "".concat(sassVars.datepickerArrowAfterSize, " !important"),
                        margin: "0 -".concat(sassVars.datepickerArrowAfterSize, " !important"),
                    },
                    '.numInputWrapper:hover': {
                        backgroundColor: "transparent !important",
                        '.numInput': {
                            background: "".concat(sassVars.inputBackgroundColor, " !important"),
                            '&:not(:focus)': {
                                borderColor: "".concat(sassVars.inputHoverBorderColor, " !important"),
                            },
                        },
                    },
                    '.flatpickr-monthDropdown-months, .numInput': {
                        fontFamily: "".concat(sassVars.inputFontFamily, " !important"),
                        fontWeight: "".concat(sassVars.inputFontWeight, " !important"),
                        fontSize: "".concat(sassVars.datepickerFontSize, " !important"),
                        lineHeight: "".concat(sassVars.datepickerLineHeight, " !important"),
                        border: "".concat(sassVars.inputBorderStyle, " ").concat(sassVars.backgroundMainColor, " !important"),
                        borderWidth: "".concat(sassVars.datepickerInputBorderWidth, " !important"),
                        '&:hover, &:focus': {
                            background: "".concat(sassVars.inputBackgroundColor, " !important"),
                        },
                        '&:hover': {
                            borderColor: "".concat(sassVars.inputHoverBorderColor, " !important"),
                        },
                        '&:focus, &:focus:hover': {
                            borderColor: "".concat(sassVars.inputFocusBorderColor, " !important"),
                        },
                    },
                    '.flatpickr-months': {
                        '.flatpickr-prev-month, .flatpickr-next-month': {
                            position: "relative !important",
                            padding: "0 !important",
                            width: "".concat(sassVars.datepickerBaseSize, " !important"),
                            height: "".concat(sassVars.datepickerBaseSize, " !important"),
                            '.flatpickr-icon:before': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                fontFamily: 'glyphicons-theme',
                                content: sassVars.datePickerIcon,
                                fontSize: "11px",
                                fontStyle: 'normal',
                                transform: 'rotate(90deg)',
                            },
                        },
                        '.flatpickr-next-month .flatpickr-next-icon:before': {
                            transform: 'rotate(-90deg)',
                        },
                        '.flatpickr-month, .flatpickr-current-month': {
                            height: "".concat(sassVars.datepickerBaseSize),
                        },
                        '.flatpickr-current-month': {
                            width: "100% !important",
                            left: "0 !important",
                            padding: "0 !important",
                            fontSize: "".concat(sassVars.datepickerFontSize, " !important"),
                            lineHeight: "".concat(sassVars.datepickerLineHeight, " !important"),
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '.flatpickr-monthDropdown-months': {
                                margin: "0 ".concat(sassVars.datepickerBaseMargin, " 0 0"),
                                padding: "0 ".concat(sassVars.datepickerInputPaddingHorizMin),
                            },
                            '.numInputWrapper': {
                                width: "".concat(sassVars.datepickerInputWidth),
                            },
                            '.numInput': {
                                padding: "0 ".concat(sassVars.datepickerInputPaddingHorizMin, " 0 ").concat(sassVars.datepickerInputPaddingHoriz),
                            },
                        },
                    },
                    '.flatpickr-innerContainer': {
                        '.flatpickr-rContainer': {
                            position: 'relative',
                            width: '100%',
                        },
                        '.flatpickr-weekdays': {
                            height: "".concat(sassVars.datepickerBaseSize),
                            margin: "".concat(sassVars.datepickerBaseMargin, " 0"),
                        },
                        '.flatpickr-weekday': {
                            fontFamily: "".concat(sassVars.datepickerWeekdayFontFamily),
                            fontSize: "".concat(sassVars.datepickerWeekdayFontSize),
                            fontWeight: "".concat(sassVars.datepickerWeekdayFontWeight),
                            lineHeight: "".concat(sassVars.datepickerWeekdayLineHeight),
                            textTransform: "".concat(sassVars.datepickerWeekdayTextTransform),
                            letterSpacing: "".concat(sassVars.datepickerWeekdayLetterSpacing),
                            color: "".concat(sassVars.datepickerWeekdayColor),
                            width: "".concat(sassVars.datepickerBaseSize),
                            marginRight: "".concat(sassVars.datepickerBaseMargin),
                        },
                        '.flatpickr-days, .dayContainer': {
                            width: '100%',
                            minWidth: '100%',
                            maxWidth: '100%',
                        },
                        '.flatpickr-day': {
                            maxWidth: "".concat(sassVars.datepickerBaseSize),
                            height: "".concat(sassVars.datepickerBaseSize),
                            lineHeight: "".concat(sassVars.datepickerBaseSize),
                            marginRight: "".concat(sassVars.datepickerBaseMargin),
                            marginBottom: "".concat(sassVars.datepickerSmallMargin),
                            border: 'none',
                            borderRadius: "".concat(sassVars.datepickerDayBorderRadius),
                            color: "".concat(theme.palette.secondary.main),
                            '&:hover': {
                                backgroundColor: "".concat(theme.palette.secondary.contrastText),
                            },
                            '&.prevMonthDay, &.nextMonthDay': {
                                color: 'rgba(57, 57, 57, 0.3)',
                            },
                            '&.today': {
                                color: "".concat(sassVars.datepickerDayTodayColor),
                            },
                            '&.selected': {
                                background: "".concat(theme.palette.primary.main),
                                color: "".concat(theme.palette.primary.contrastText),
                                '&:hover': {
                                    color: "".concat(theme.palette.primary.contrastText),
                                },
                            },
                        },
                    },
                },
                '&.arrowTop': {
                    marginTop: "9px !important",
                    '&:before': {
                        borderBottomColor: "".concat(sassVars.datepickerContainerBorderColor, " !important"),
                    },
                },
                '&.arrowBottom': {
                    marginTop: "-44px !important",
                    '&:before': {
                        borderTopColor: "".concat(sassVars.datepickerContainerBorderColor, " !important"),
                    },
                },
                '&.showTimeInput.hasTime .flatpickr-time': {
                    borderTop: "".concat(sassVars.datepickerTimeBorderTop, " !important"),
                    paddingTop: "".concat(sassVars.datepickerTimePaddingTop, " !important"),
                    height: "".concat(sassVars.datepickerTimeHeight, " !important"),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '.numInputWrapper, .flatpickr-time-separator': {
                        height: "".concat(sassVars.datepickerLineHeight),
                        lineHeight: "".concat(sassVars.datepickerLineHeight),
                    },
                    '.numInputWrapper': {
                        flex: 0,
                    },
                    '.flatpickr-hour, .flatpickr-minute': {
                        width: "".concat(sassVars.datepickerInputWidth, " !important"),
                    },
                    '.flatpickr-hour': {
                        textAlign: 'right',
                        paddingRight: "".concat(sassVars.datepickerInputHourPadding),
                    },
                    '.flatpickr-minute': {
                        textAlign: 'left',
                        paddingLeft: "".concat(sassVars.datepickerInputPaddingHoriz),
                        marginLeft: "".concat(sassVars.datepickerInputMinuteMargin),
                    },
                },
            },
            '.tooltip': {
                opacity: 1,
                left: '10px !important',
            },
        };
    } })); };

import { Box, Typography } from '@mui/material';
import { grey, makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';

export type CampaignCardUserRequestCardColumnItemProps = {
    label: string;
    value?: string;
    altValue?: string;
};

export const campaignCardUserRequestCardColumnItemSxStyles = makeSxStyles({
    labelBox: {
        minWidth: '101px',
    },
    label: {
        color: grey[600],
    },
    value: {
        color: grey[900],
    },
    altValue: {
        color: grey[400],
    },
});

export const CampaignCardUserRequestCardColumnItem = observer(
    (props: CampaignCardUserRequestCardColumnItemProps): JSX.Element => {
        const { label, value, altValue } = props;

        const sx = campaignCardUserRequestCardColumnItemSxStyles;

        return (
            <React.Fragment>
                <Box sx={sx.labelBox}>
                    <Typography variant="body1" sx={sx.label}>
                        {label}
                    </Typography>
                </Box>
                {value ? (
                    <Box>
                        <Typography variant="body1" sx={sx.value}>
                            {value}
                        </Typography>
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="body1" sx={sx.altValue}>
                            {altValue}
                        </Typography>
                    </Box>
                )}
            </React.Fragment>
        );
    },
);

import { Grid, Skeleton } from '@mui/material';
import { SkeletonGrid } from '@platform/front-ui';
import React from 'react';
export var textLeftSx = {
    marginLeft: 2,
};
export var dateTimeFieldSx = {
    'div:last-child': {
        marginLeft: 3,
    },
    'div:first-child': {
        marginLeft: 3,
    },
};
export var textFieldGridProps = {
    direction: 'column',
    item: true,
    spacing: 3,
};
export var dateTimeFieldGridPros = {
    item: true,
    sx: dateTimeFieldSx,
};
export var FormSkeleton = function () {
    return (React.createElement(Grid, { container: true, spacing: 3 },
        React.createElement(SkeletonGrid, { itemsCount: 2, wrapperProps: textFieldGridProps, width: "100%", height: 50 }),
        React.createElement(Grid, { container: true, item: true, direction: "column" },
            React.createElement(Grid, { item: true },
                React.createElement(Skeleton, { variant: "rectangular", width: "100%", height: 72 }))),
        React.createElement(Grid, { container: true, item: true, direction: "column" },
            React.createElement(Grid, { item: true },
                React.createElement(Skeleton, { variant: "rectangular", width: "100%", height: 85 }))),
        React.createElement(SkeletonGrid, { itemsCount: 2, wrapperProps: dateTimeFieldGridPros, width: 870, height: 50 }),
        React.createElement(Grid, { container: true, item: true, direction: "column" },
            React.createElement(Grid, { item: true },
                React.createElement(Skeleton, { variant: "rectangular", width: "100%", height: 72 }))),
        React.createElement(Grid, { container: true, item: true, direction: "row" },
            React.createElement(Grid, { item: true },
                React.createElement(Skeleton, { variant: "rectangular", width: 20, height: 20 })),
            React.createElement(Grid, { item: true, sx: textLeftSx },
                React.createElement(Skeleton, { variant: "text", width: 350, height: 20 })))));
};

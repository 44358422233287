import { Button } from '@mui/material';
import { ConfirmationDialog } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useCampaignRequestFormContext, useCampaignRequestPageContext, useStore } from '../../../../../../../../hooks';
import { CampaignRequestSectionRouteParams } from '../../../../../../../../types';

export const CampaignRequestSavePanelPublishAll = (): JSX.Element => {
    const { requestStore } = useStore();
    const { formatMessage } = useIntl();
    const { sectionId } = useParams<CampaignRequestSectionRouteParams>();
    const { formApi } = useCampaignRequestPageContext().model;
    const { goToView } = useCampaignRequestFormContext();

    const [isModalOpen, openModal, closeModal] = useFlag();

    const onConfirm = (): Promise<void> => {
        return formApi
            ? requestStore.saveFullRequestSectionForm(sectionId, formApi.getSubmission()).then(goToView)
            : Promise.reject();
    };

    const title = formatMessage({ id: 'form.publishAll' });
    const message = formatMessage({ id: 'form.publishAllConfirmText' });

    return (
        <React.Fragment>
            <ConfirmationDialog
                id="publish-all"
                keepMounted
                open={isModalOpen}
                onConfirm={onConfirm}
                onCancel={closeModal}
                title={title}
                message={message}
            />

            <Button color="primary" onClick={openModal}>
                {title}
            </Button>
        </React.Fragment>
    );
};

import { Box, Grid, Typography } from '@mui/material';
import { AuthorizationCheck, EmptyListProps, List, Loader } from '@platform/front-core';
import { makeSxStyles } from '@platform/front-ui';
import { RouteParamsDefault, useAntiDoubleClick, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../../authSchemeConfig';
import { sxHeight100 } from '../../../../../../constants';
import { ICSDataTestIds } from '../../../../../../constants/data-test-ids';
import { useCampaignCardPageContext, useStore } from '../../../../../../hooks';
import { UserRequestDTO } from '../../../../../../types';
import { CampaignCardUserRequestInfiniteScrollStyled as InfiniteScroll } from './CampaignCardUserRequest.styled';
import { CampaignCardUserRequestCard as CampaignCardUserRequestCardInj } from './CampaignCardUserRequestCard';
import { CampaignCardUserRequestsEndMessage as CampaignCardUserRequestsEndMessageInj } from './CampaignCardUserRequestsEndMessage';

export const campaignCardUserRequestsSxStyles = makeSxStyles({
    root: {
        height: '100%',
        boxSizing: 'border-box',
        paddingTop: (theme) => theme.spacing(2.125),
    },
    headerItem: {
        paddingLeft: (theme) => theme.spacing(2),
    },
    firstRequest: {
        paddingTop: '0 !important',
    },
    requestCardOuterContainer: {
        overflow: 'auto',
        paddingRight: (theme) => theme.spacing(1),
        paddingLeft: (theme) => theme.spacing(2),
    },
    requestCardInnerContainer: {
        overflow: 'auto',
        marginTop: (theme) => theme.spacing(1),
    },
});

const scrollTargetId = 'scrollable-requests';

export const CampaignCardUserRequests = observer((): JSX.Element => {
    const [CampaignCardUserRequestCard] = di([CampaignCardUserRequestCardInj], CampaignCardUserRequests);
    const [CampaignCardUserRequestsEndMessage] = di([CampaignCardUserRequestsEndMessageInj], CampaignCardUserRequests);

    const [requests, setRequests] = useState<UserRequestDTO[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [isListEmpty, setIsListEmpty] = useState<boolean>(false);
    const [isLoading, startLoading, stopLoading] = useFlag(true);

    const { campaignsStore } = useStore();
    const { id } = useParams<RouteParamsDefault>();
    const sx = campaignCardUserRequestsSxStyles;
    const { formatMessage } = useIntl();
    const { openCreateRequestDialog } = useCampaignCardPageContext();

    const loadedRequestsCount = requests.length;

    const loadData = useCallback((): Promise<void> => {
        startLoading();

        return campaignsStore
            .loadUserRequests(id, page)
            .then((response) => {
                const { count, rows } = response;
                setRequests((currentRequests) => currentRequests.concat(rows));
                setPage((currentPage) => currentPage + 1);
                setHasMore(count > page * 10);
                setIsListEmpty(count === 0);
            })
            .finally(stopLoading);
    }, [id, page]);

    useEffect(() => {
        loadData();
    }, []);

    const infiniteScrollLoader = <Loader />;
    const infiniteScrollEndMessage = <CampaignCardUserRequestsEndMessage />;

    const requestsItems: JSX.Element[] = requests.map((request, index): JSX.Element => {
        const isFirst = index === 0;
        const sxStyles = isFirst ? sx.firstRequest : {};

        return (
            <Grid item key={request.id} sx={sxStyles}>
                <CampaignCardUserRequestCard dto={request} />
            </Grid>
        );
    });

    const openDialog = (): void => {
        openCreateRequestDialog(id);
    };

    const [isButtonDisabled, endIcon, handleSubmit] = useAntiDoubleClick(openDialog);

    const createButtonProps = {
        title: formatMessage({ id: 'campaign.userRequests.createRequest' }),
        createItem: handleSubmit,
        disabled: isButtonDisabled,
        endIcon,
    };

    return (
        <Box sx={sx.root}>
            <Grid
                container
                direction="column"
                flexWrap="nowrap"
                sx={sxHeight100}
                data-testid={ICSDataTestIds.campaignCardUserRequestsWrapper}
            >
                <AuthorizationCheck
                    entityCode={entities.campaign}
                    permCode={permissions.campaign.AddCampRequest}
                    entityId={id}
                >
                    {(allowed) => {
                        const emptyListProps: EmptyListProps = {
                            noItemsTitle: formatMessage({
                                id: 'campaign.userRequests.isEmpty',
                            }),
                            ...(allowed && { createButtonProps }),
                        };

                        return (
                            <List isLoading={isLoading} {...(isListEmpty && { emptyListProps })}>
                                <React.Fragment>
                                    <Grid item sx={sx.headerItem}>
                                        <Typography variant="button">
                                            <FormattedMessage id="campaign.userRequests.title" />
                                        </Typography>
                                    </Grid>
                                    <Grid item id={scrollTargetId} sx={sx.requestCardOuterContainer}>
                                        <InfiniteScroll
                                            dataLength={loadedRequestsCount}
                                            next={loadData}
                                            scrollThreshold={0.99}
                                            hasMore={hasMore}
                                            loader={infiniteScrollLoader}
                                            scrollableTarget={scrollTargetId}
                                            endMessage={infiniteScrollEndMessage}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                spacing={1}
                                                flexWrap="nowrap"
                                                sx={sx.requestCardInnerContainer}
                                                data-testid={ICSDataTestIds.campaignCardUserRequestsList}
                                                role="list"
                                            >
                                                {requestsItems}
                                            </Grid>
                                        </InfiniteScroll>
                                    </Grid>
                                </React.Fragment>
                            </List>
                        );
                    }}
                </AuthorizationCheck>
            </Grid>
        </Box>
    );
});

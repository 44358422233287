import { TotLocale } from '@platform/front-types';
import { toJS } from 'mobx';
export var getFormComponentByKey = function (formApi, key) {
    var form = formApi.formioFormApi.form;
    return form.getComponent(key);
};
/*
 * Возвращает ключ элемента,
 * если он в эдит гриде, ключ самого верхнего едит грида
 * */
export var getFormComponentKeyFromObservable = function (component) {
    var key = toJS(component.component.key);
    if (component.inGrid) {
        return getFormComponentKeyFromObservable(component.parent);
    }
    return key;
};
/*
 * Возвращает значение (value) элемента
 * */
export var getFormComponentValue = function (formApi, key) {
    var _a;
    return (_a = formApi.getSubmissionWithoutRerender()[TotLocale.ru]) === null || _a === void 0 ? void 0 : _a.data[key];
};

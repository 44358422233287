import { Button, Grid, SxProps, Theme, Typography } from '@mui/material';
import { IdTitle, PromiseVoidFunction } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Route } from 'react-router-dom';
import { CampaignSettingsEntities } from '../../../../../../types';
import { CampaignSettingsItemDelete as CampaignSettingsItemDeleteInj } from './CampaignSettingsItemDelete';

export type CampaignSettingsItemHeaderProps = {
    title: string;
    item: IdTitle;
    itemType: CampaignSettingsEntities;
    isFormView: boolean;
    viewPaths: string[];
    deleteItem: PromiseVoidFunction;
    additionalHeaderButtons?: JSX.Element[];
};

const wrapperSx: (isFormView: boolean) => SxProps<Theme> = (isFormView) => {
    return isFormView
        ? {
              paddingX: 2,
              paddingTop: 1.5,
              paddingBottom: 1.5,
          }
        : {
              paddingX: 2,
              paddingTop: 2,
              paddingBottom: 2.5,
          };
};

const titleSx: (isFormView: boolean) => SxProps<Theme> = (isFormView) => {
    return isFormView
        ? {
              fontSize: '18px',
              fontWeight: 600,
          }
        : {
              fontSize: '16px',
              fontWeight: 500,
          };
};

const headerButtonsSx: SxProps<Theme> = {
    whiteSpace: 'nowrap',
};

/**
 * Хедер с именем раздела и кнопками настроек, и управления разделом.
 * @prop title - Имя раздела.
 * @prop item - Данные раздела, подлежащего удалению.
 * @prop itemType - Объект с кодами сущностей раздела, подлежащего удалению. Требуются для
 * подстановки в id локалей в модальном окне подтверждения удаления.
 * @prop isFormView - Флаг, означающий находимся ли мы в режиме просмотра.
 * @prop viewPaths - Урлы, по которым доступен просмотр хедера.
 * @prop deleteItem - Функция, которая срабатывает при нажатии на кнопку удаления раздела.
 * @prop additionalHeaderButtons - Дополнительные кнопки (кнопка удалить здесь зашита по умолчанию),
 * которые отображаются в этом хедере.
 */
export const CampaignSettingsItemHeader = observer((props: CampaignSettingsItemHeaderProps): JSX.Element => {
    const [CampaignSettingsItemDelete] = di([CampaignSettingsItemDeleteInj], CampaignSettingsItemHeader);
    const { item, title, itemType, isFormView, viewPaths, deleteItem, additionalHeaderButtons } = props;

    return (
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" sx={wrapperSx(isFormView)}>
            <Grid item>
                <Typography variant="subtitle2" sx={titleSx(isFormView)}>
                    {title}
                </Typography>
            </Grid>
            <Route path={viewPaths}>
                <Grid item sx={headerButtonsSx}>
                    <Grid container item spacing={1}>
                        {additionalHeaderButtons &&
                            additionalHeaderButtons.map((button, index) => {
                                return (
                                    <Grid item key={index + button.toString()}>
                                        {button}
                                    </Grid>
                                );
                            })}
                        <Grid item>
                            <CampaignSettingsItemDelete itemToDelete={item} itemType={itemType} deleteItem={deleteItem}>
                                <Button color="secondary" variant="outlined">
                                    <FormattedMessage id={`campaignSettings.deletion.delete.${itemType}`} />
                                </Button>
                            </CampaignSettingsItemDelete>
                        </Grid>
                    </Grid>
                </Grid>
            </Route>
        </Grid>
    );
});

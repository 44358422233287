import { voidFunction, VoidFunction } from '@platform/front-utils';
import { createContext } from 'react';

export type CampaignSettingsListContextType = {
    shouldLoadListItems: boolean;
    setShouldLoadListItems: VoidFunction;
    setShouldntLoadListItems: VoidFunction;
};

export const CampaignSettingsListContext = createContext<CampaignSettingsListContextType>({
    shouldLoadListItems: false,
    setShouldLoadListItems: voidFunction,
    setShouldntLoadListItems: voidFunction,
});

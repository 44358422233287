import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { CampaignRequestFormRouteParams, TabData } from '../../../../../../types';
import { CampaignSettingsItemTabs as CampaignSettingsItemTabsInj } from '../../components';

export const getAdditionalCampaignSettingsSectionSettingTabsRoutesInj = (): TabData[] => [];

export const CampaignSettingsSectionSettingTabs = observer((): JSX.Element => {
    const [getAdditionalCampaignSettingsSectionSettingTabsRoutes] = di(
        [getAdditionalCampaignSettingsSectionSettingTabsRoutesInj],
        CampaignSettingsSectionSettingTabs,
    );
    const [CampaignSettingsItemTabs] = di([CampaignSettingsItemTabsInj], CampaignSettingsSectionSettingTabs);

    const params = useParams<CampaignRequestFormRouteParams>();

    const standardTabsData: TabData[] = [
        {
            title: <FormattedMessage id="campaign.settingsRequestFormTabs.information" />,
            path: generatePath(clientRoute.campaignSettingsRequestFormSectionSettingInfo, params),
        },
        {
            title: <FormattedMessage id="campaign.settingsRequestFormTabs.pf" />,
            path: generatePath(clientRoute.campaignSettingsRequestFormSectionSettingPF, params),
        },
    ];

    const tabsData = [...standardTabsData, ...getAdditionalCampaignSettingsSectionSettingTabsRoutes()];

    return <CampaignSettingsItemTabs tabsData={tabsData} />;
});

import { ApiStore, PersonModel } from '@platform/front-core';
import { CodeTitle, IdTitle, UserPersonDTO } from '@platform/front-types';
import { GroupedIdTitle } from '@platform/ttable';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { CodeTitleGroupRC } from '../types';
import { RootStore } from './RootStore';

export const SelectStoreProps = {
    rootStore: observable,
    api: observable,
    getLifecycleList: action.bound,
    requestFormsByCampaignsForSelect: action.bound,
    loadExecutors: action.bound,
    loadPermissions: action.bound,
    loadFormCode: action.bound,
    fetchAllEmployee: action.bound,
};

export class SelectStore {
    private rootStore: RootStore;
    private api: ApiStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, SelectStoreProps);
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
    }

    requestFormsByCampaignsForSelect(campaigns: string[] = []): Promise<GroupedIdTitle> {
        return this.api.client(apiConfigs.requestFormsByCampaignsForSelect(campaigns)).then((r) => r.data);
    }

    getLifecycleList(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.getLifecycleList).then((r) => r.data);
    }

    loadExecutors(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.loadExecutors()).then((r) => r.data);
    }

    loadPermissions(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.loadPermissions()).then((r) => r.data);
    }

    loadFormCode(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.loadFormCode()).then((r) => r.data);
    }

    async fetchAllEmployee(): Promise<IdTitle[]> {
        return this.api
            .client(apiConfigs.getAllActiveEmployees())
            .then((r) => r.data)
            .then((employees) => {
                return employees.map((employee: UserPersonDTO) => {
                    const person: PersonModel = new PersonModel().load(employee.person);
                    return {
                        id: employee.userId,
                        title: person.lastNameWithInitials,
                    };
                });
            });
    }

    getRoleConditionsList(): Promise<CodeTitleGroupRC[]> {
        return this.api.client(apiConfigs.roleConditionsList).then((r) => r.data);
    }

    getLifecyclesStatesListByProcessCode(processCode: string): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.getLifecycleStatesListByProcessCode(processCode)).then((r) => r.data);
    }
}

export const getSelectStore = (): any => {
    const [_SelectStore] = di([SelectStore], getSelectStore);
    return _SelectStore;
};

import { LocalesTree } from '@platform/front-utils';
import instructionSrc from '../../instructions/ru_instr.docx';

const common: LocalesTree = {
    tabExecutor: 'Ответственный исполнитель: {name}',
    responsibleExecutor: 'Ответственный исполнитель:',
    instructionSrc: instructionSrc,
    instructionFileName: 'Инструкция.docx',
    // todo: move to console
    result: 'Результат',
    copy: 'Копировать',
    clear: 'Очистить',
};

export default common;

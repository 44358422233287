import { AccessPermissionRequests, AccessPermissionSelects, DefaultNewPermissionDTO } from '@platform/front-core';
import { action, makeObservable, observable } from 'mobx';
import { CampaignsStore } from '../stores';
import { AccessPermissionDTO, CommentsPermissionDTO, PermissionSettingsDTO } from '../types';

export const campaignSettingsRequestAccessPermissionModelObservables = {
    requestFormId: observable,
    campaignsStore: observable,
    loadPermissions: action.bound,
    loadSelects: action.bound,
    updatePermissions: action.bound,
};

export class CampaignSettingsRequestAccessPermissionModel implements AccessPermissionRequests {
    protected requestFormId: string;
    protected campaignsStore: CampaignsStore;

    constructor(requestFormId: string, campaignsStore: CampaignsStore) {
        this.requestFormId = requestFormId;
        this.campaignsStore = campaignsStore;

        makeObservable(this, campaignSettingsRequestAccessPermissionModelObservables);
    }

    loadPermissions<T = AccessPermissionDTO>(): Promise<
        T extends AccessPermissionDTO
            ? PermissionSettingsDTO<AccessPermissionDTO>
            : PermissionSettingsDTO<CommentsPermissionDTO>
    > {
        return this.campaignsStore.requestPermissions(this.requestFormId);
    }

    loadSelects(): Promise<AccessPermissionSelects> {
        return this.campaignsStore.requestPermissionsSelectData(this.requestFormId);
    }

    updatePermissions(permissions: DefaultNewPermissionDTO[]): Promise<void> {
        return this.campaignsStore.updateRequestPermissions(this.requestFormId, permissions);
    }
}

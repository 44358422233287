import { Box, Button, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Link, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { useCampaignSettingsSectionSettingContext } from '../../../../../../hooks';
import { CampaignRequestFormSectionSettingsRouteParams } from '../../../../../../types';
import { ViewField as ViewFieldInj } from '../../components';

export const CampaignSettingsSectionSettingInfo = observer((): JSX.Element => {
    const [ViewField] = di([ViewFieldInj], CampaignSettingsSectionSettingInfo);
    const params = useParams<CampaignRequestFormSectionSettingsRouteParams>();
    const { sectionSettingModel } = useCampaignSettingsSectionSettingContext();
    const { sectionSettingDTO } = sectionSettingModel;
    const { formatMessage } = useIntl();
    const { title, identifier, fileSize, formCode, formTitle, executorFullname } = sectionSettingDTO;
    const fileSizeValue = fileSize
        ? formatMessage({ id: 'campaignSettings.sectionFileSize' }, { fileSize })
        : undefined;

    const formFieldValue = `${formCode} - ${formTitle}`;

    return (
        <Box px={2} pt={1} pb={2}>
            <Grid container direction="row" wrap="nowrap" alignItems="space-between">
                <Grid item container direction="column" spacing={1}>
                    <Grid item>
                        <ViewField value={title} labelId="campaignSettings.sectionForm.title.label" />
                    </Grid>
                    <Grid item>
                        <ViewField value={identifier} labelId="campaignSettings.sectionForm.identifier.label" />
                    </Grid>
                    <Grid item>
                        <ViewField value={fileSizeValue} labelId="campaignSettings.sectionForm.fileSize.label" />
                    </Grid>
                    <Grid item>
                        <ViewField value={formFieldValue} labelId="campaignSettings.sectionForm.form.label" />
                    </Grid>
                    <Grid item>
                        <ViewField value={executorFullname} labelId="campaignSettings.sectionForm.executor.label" />
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        color="secondary"
                        component={Link}
                        to={generatePath(clientRoute.campaignSettingsRequestFormSectionSettingEdit, params)}
                    >
                        <FormattedMessage id="common.edit" />
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
});

import { LocalesTree } from '@platform/front-utils';

const form: LocalesTree = {
    publishAll: 'Опубликовать все изменения',
    publishAllConfirmText: 'Вы действительно хотите опубликовать все изменения всех пользователей?',
    deleteAllChanges: 'Удалить все изменения',
    deleteAllChangesConfirmText: 'Вы действительно хотите удалить все изменения всех пользователей?',
    unsavedChanges: 'Несохранённые изменения',
    sections: 'Разделы',
};

export default form;

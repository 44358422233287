import { TableCell, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useCampaignRequestPrintFormContext } from '../../../../../../../../hooks';
import { CampaignRequestPrintFormsRowActionsCell as CampaignRequestPrintFormsRowActionsCellInj } from './CampaignRequestPrintFormsRowActionsCell';
import { CampaignRequestPrintFormsRowFileCell as CampaignRequestPrintFormsRowFileCellInj } from './CampaignRequestPrintFormsRowFileCell';

export const CampaignRequestPrintFormsRow = observer((): JSX.Element => {
    const [CampaignRequestPrintFormsRowFileCell] = di(
        [CampaignRequestPrintFormsRowFileCellInj],
        CampaignRequestPrintFormsRow,
    );
    const [CampaignRequestPrintFormsRowActionsCell] = di(
        [CampaignRequestPrintFormsRowActionsCellInj],
        CampaignRequestPrintFormsRow,
    );

    const { model, isWithEdit } = useCampaignRequestPrintFormContext();
    const { docCategory, status, created } = model;
    const generatedDate = created && new Date(created);

    return (
        <TableRow>
            <CampaignRequestPrintFormsRowFileCell />
            <TableCell>{docCategory}</TableCell>
            <TableCell>{status.title}</TableCell>
            <TableCell>{generatedDate && <FormattedDate value={generatedDate} />}</TableCell>
            {isWithEdit && <CampaignRequestPrintFormsRowActionsCell />}
        </TableRow>
    );
});

import { Grid, SxProps, Theme } from '@mui/material';
import { grey } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { sxHeight100 } from '../../../../../constants';
import { CampaignCardUserRequests as CampaignCardUserRequestsInj } from './campaign-card-description-requests/CampaignCardUserRequests';
import { CampaignCardBodyTabs as CampaignCardBodyTabsInj } from './CampaignCardBodyDescriptionTabs';

const sidesSx: SxProps<Theme> = {
    width: '450px', // при сужении экрана < 900 блоки будут на разных строках
    ...sxHeight100,
};

const leftSideSx: SxProps<Theme> = {
    borderRight: `1px solid ${grey[300]}`,
    ...sidesSx,
};

export const CampaignCardBodyDescription = observer((): JSX.Element => {
    const [CampaignCardUserRequests] = di([CampaignCardUserRequestsInj], CampaignCardBodyDescription);
    const [CampaignCardBodyTabs] = di([CampaignCardBodyTabsInj], CampaignCardBodyDescription);

    return (
        <Grid container wrap="wrap-reverse" sx={sxHeight100}>
            <Grid item flexGrow={1} sx={leftSideSx}>
                <CampaignCardUserRequests />
            </Grid>
            <Grid item flexGrow={1} sx={sidesSx}>
                <CampaignCardBodyTabs />
            </Grid>
        </Grid>
    );
});

import { MenuButton } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { UserPageLanguageButton } from './UserPageLanguageButton';

export const UserPageSidebarActions = observer((): JSX.Element => {
    const renderMenuItems = useCallback((hideMenu: VoidFunction): ReactNode[] => {
        return [<UserPageLanguageButton key="editLanguage" hideMenu={hideMenu} />];
    }, []);

    return <MenuButton renderMenuItems={renderMenuItems} />;
});

import { LocalesTree } from '@platform/front-utils';

const breadcrumbs: LocalesTree = {
    mainPage: 'Главная страница',
    campaigns: 'Категории',
    campaign: 'Категория',
    requests: 'Реестр заявок',
    campaignSettings: 'Настройки категории',
    campaignCreate: 'Создание категории',
    campaignEdit: 'Редактирование категории',
    users: 'Пользователи',
    campaignDescription: 'Описание категории',
    campaignRequestForms: 'Формы заявок',
    campaignRequestFormEdit: 'Редактирование формы заявки',
    campaignRequestFormCreate: 'Добавление формы заявки',
    campaignRequestFormInformation: '{requestFormTitle}. Информация',
    campaignRequestFormSections: '{requestFormTitle}. Разделы',
    campaignRequestFormObjectRelations: '{requestFormTitle}. Связанные объекты',
    campaignRequestFormSectionInformation: '{sectionTitle}. Информация',
    campaignRequestFormSectionPF: '{sectionTitle}. Шаблоны',
    syslog: 'Журнал',
};

export default breadcrumbs;

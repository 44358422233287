import { Skeleton } from '@mui/lab';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { grey, makeSxStyles, SkeletonTable } from '@platform/front-ui';
import { useAntiDoubleClick } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useCampaignRequestPageContext, useCampaignRequestPrintFormsContext } from '../../../../../../hooks';
import { CampaignRequestSectionRouteParams } from '../../../../../../types';
import { CampaignRequestPrintFormsTable as CampaignRequestPrintFormsTableInj } from './campaign-request-print-forms-table';

const campaignRequestPrintFormsSxStyles = makeSxStyles({
    headWrapper: {
        padding: (theme) => theme.spacing(2),
    },
    tableWrapper: {
        padding: (theme) => `${theme.spacing(1.4)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
        borderTop: `1px solid ${grey[200]}`,
    },
});

export const CampaignRequestPrintForms = observer((): JSX.Element => {
    const [CampaignRequestPrintFormsTable] = di([CampaignRequestPrintFormsTableInj], CampaignRequestPrintForms);

    const { sectionId } = useParams<CampaignRequestSectionRouteParams>();
    const { locale } = useIntl();

    const { model } = useCampaignRequestPrintFormsContext();
    const { isLoading, printForms, checkIsWithEdit, load, downloadAllPrintFormFiles, setDownloadAllFilesTitle } = model;
    const { model: campaignRequestModel } = useCampaignRequestPageContext();
    const { number, state } = campaignRequestModel;

    useEffect(() => {
        load();
    }, [locale, sectionId]);

    useEffect(() => {
        checkIsWithEdit();
    }, [sectionId, state.id]);

    useEffect(() => {
        setDownloadAllFilesTitle(number);
    }, [number]);

    const [isSending, endIcon, downloadHandler] = useAntiDoubleClick(downloadAllPrintFormFiles);

    return (
        <React.Fragment>
            {isLoading || !!printForms.length ? (
                <Paper elevation={1}>
                    <Grid container flexDirection="column">
                        <Grid
                            container
                            item
                            flexDirection="column"
                            spacing={1}
                            sx={campaignRequestPrintFormsSxStyles.headWrapper}
                        >
                            <Grid item>
                                <Typography variant="subtitle1" component="h3">
                                    {isLoading ? (
                                        <Skeleton width="275px" height="25px" />
                                    ) : (
                                        <FormattedMessage id="printForms.table.title" />
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {isLoading ? (
                                    <Skeleton width="108px" height="32px" />
                                ) : (
                                    <Button disabled={isSending} endIcon={endIcon} onClick={downloadHandler}>
                                        <FormattedMessage id="printForms.table.downloadAllButton" />
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item sx={campaignRequestPrintFormsSxStyles.tableWrapper}>
                            {isLoading ? (
                                <SkeletonTable rowsCount={3} columnsCount={5} />
                            ) : (
                                <CampaignRequestPrintFormsTable />
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            ) : (
                <React.Fragment />
            )}
        </React.Fragment>
    );
});

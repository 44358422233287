import { CampaignRequestFormListModel } from '../../models';
import { CampaignRequestFormListMember } from '../../types';
import { useStore } from '../useStore';

export type UseLoadCampaignRequestFormsType = {
    loadCampaignRequestFormsModel: (campaignId: string) => Promise<CampaignRequestFormListModel>;
    campaignRequestForms: CampaignRequestFormListMember[];
};

/**
 * Хук для загрузки форм заявки для определенной категории.
 * Ориентируясь на уникальность campaignId, может перезагружать
 * список форм. Также возвращает этот список.
 * @returns {UseLoadCampaignRequestFormsType} Функция для подгрузки форм и формы.
 */
export const useLoadCampaignRequestForms = (): UseLoadCampaignRequestFormsType => {
    const { campaignsStore } = useStore();
    const { campaignRequestFormListModel } = campaignsStore;

    const loadCampaignRequestFormsModel = (campaignId: string): Promise<CampaignRequestFormListModel> => {
        campaignsStore.setCampaignRequestFormListModel(campaignId);
        return campaignsStore.getCampaignRequestFormListModel();
    };

    const campaignRequestForms = campaignRequestFormListModel?.campaignRequestForms || [];

    return {
        loadCampaignRequestFormsModel,
        campaignRequestForms,
    };
};

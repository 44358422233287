import { CustomLogoConfig } from '@platform/front-core';
import { clientRoute } from '../../clientRoute';
import { Features } from '../../types';

const customLogo: CustomLogoConfig = {
    src: require('./cniioiz-logo.png'),
    alt: 'ЦНИИОИЗ',
    style: { width: '400px', height: '70px' },
    withText: false,
    mdLogo: false,
};

const features: Features = {
    loginWithoutRegister: true,
    headerLogo: false,
    customLogo: {
        isEnabled: true,
        locales: {
            ru: customLogo,
            en: customLogo,
        },
    },
    helpIcon: true,
    notificationIcon: true,
    changeLanguage: true,
    phoneNumber: false,
    esiaLogin: false,
    showCreateRequestButton: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: false,
    showCampaignPeriod: true,
    allUsersRedirectPath: {
        isEnabled: true,
        path: clientRoute.campaigns,
    },
    showRequestsCount: true,
    isWithRegistrationPage: true,
};

export default features;

import { WebSocketApiConfigs as CoreWebSocketApiConfigs } from '@platform/front-core';

export class WebSocketApiConfigs extends CoreWebSocketApiConfigs {
    notification(): string {
        return this.getRoute(`/alerts`);
    }

    campaignRequestCollaborationForm(sectionId: string): string {
        return this.getRoute(`/section/${sectionId}/collab`);
    }
}

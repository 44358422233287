import { Grid } from '@mui/material';
import { CodeTitleGroupRC } from '@platform/front-core';
import { AutocompleteField, FullScreenForm, makeSxStyles } from '@platform/front-ui';
import { UseFlag } from '@platform/front-utils';
import { Field, FieldAttributes } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { useCampaignSettingsAccessPermissionContext } from '../../../../../../../../../../../hooks';
import {
    AccessPermissionDTOFields,
    CommentsAccessRightsSettingsFormField,
} from '../../../../../../../../../../../types';
import { CustomCommentsSettingEditPermissionFormContentMainCheckbox } from './CustomCommentsSettingEditPermissionFormContentMainCheckbox';

const checkboxOptions: FieldAttributes<any> = {
    type: 'checkbox',
    component: CheckboxWithLabel,
};

const sx = makeSxStyles({
    fieldsContainer: {
        paddingLeft: (theme) => theme.spacing(2),
    },
});

const { allowDeleting, allowEditing, allowReplying, allowTopLevelCommentsCreating, allowManagingCommentsStatuses } =
    CommentsAccessRightsSettingsFormField;

const { whenObjectStateIsOneOf, roleCondition } = AccessPermissionDTOFields;

export type CustomCommentsSettingEditPermissionFormContentProps = {
    optionsHandles: UseFlag;
    dialogTitle: React.ReactNode;
};

/**
 * Контент формы добавления и редактирования доступа к комментариям.
 * @prop optionsHandles - Инструменты для взаимодействия с состоянием формы добавления и редактирования доступа к комментариям.
 */
export const CustomCommentsSettingEditPermissionFormContent = observer(
    (props: CustomCommentsSettingEditPermissionFormContentProps): JSX.Element => {
        const { optionsHandles, dialogTitle } = props;
        const { model, goBack } = useCampaignSettingsAccessPermissionContext();
        const { formatMessage } = useIntl();
        const { roleConditionList, lifecycleStatesList } = model;

        const [isOptionsVisible, , ,] = optionsHandles;

        const getFieldLabel = (fieldName: string): string => {
            return formatMessage({ id: `campaignSettings.commentsSetting.${fieldName}` });
        };

        const roleConditionGroupBy = (option: CodeTitleGroupRC): string => {
            return formatMessage({
                id: `campaignSettings.editPermissions.group.${option.group}`,
            });
        };

        const allowDeletingLabel = { label: getFieldLabel(allowDeleting) };
        const allowEditingLabel = { label: getFieldLabel(allowEditing) };
        const allowReplyingLabel = { label: getFieldLabel(allowReplying) };
        const allowTopLevelCommentsCreatingLabel = { label: getFieldLabel(allowTopLevelCommentsCreating) };
        const allowManagingCommentsStatusesLabel = { label: getFieldLabel(allowManagingCommentsStatuses) };
        const whenObjectStateIsOneOfLabel = formatMessage({ id: 'campaignSettings.editPermissions.lifecycleStage' });
        const roleConditionLabel = formatMessage({ id: 'campaignSettings.editPermissions.list.role' });
        const whenObjectStateIsOneOfPlaceholder = formatMessage({
            id: 'campaignSettings.editPermissions.list.allStatuses',
        });
        const roleConditionPlaceholder = formatMessage({
            id: 'campaignSettings.sectionForm.form.placeholder',
        });

        return (
            <FullScreenForm onCancel={goBack} dialogTitle={dialogTitle}>
                <Grid container flexDirection="column" spacing={2}>
                    <Grid item>
                        <AutocompleteField
                            fieldName={roleCondition}
                            options={roleConditionList}
                            disableClearable
                            required
                            label={roleConditionLabel}
                            placeholder={roleConditionPlaceholder}
                            groupBy={roleConditionGroupBy}
                        />
                    </Grid>
                    <Grid item>
                        <AutocompleteField
                            fieldName={whenObjectStateIsOneOf}
                            options={lifecycleStatesList}
                            multiple
                            disableClearable
                            label={whenObjectStateIsOneOfLabel}
                            placeholder={whenObjectStateIsOneOfPlaceholder}
                        />
                    </Grid>
                    <CustomCommentsSettingEditPermissionFormContentMainCheckbox optionsHandles={optionsHandles} />
                </Grid>
                {isOptionsVisible && (
                    <Grid item container flexDirection="column" sx={sx.fieldsContainer}>
                        <Field
                            name={allowTopLevelCommentsCreating}
                            Label={allowTopLevelCommentsCreatingLabel}
                            {...checkboxOptions}
                        />
                        <Field name={allowReplying} Label={allowReplyingLabel} {...checkboxOptions} />
                        <Field name={allowEditing} Label={allowEditingLabel} {...checkboxOptions} />
                        <Field name={allowDeleting} Label={allowDeletingLabel} {...checkboxOptions} />
                        <Field
                            name={allowManagingCommentsStatuses}
                            Label={allowManagingCommentsStatusesLabel}
                            {...checkboxOptions}
                        />
                    </Grid>
                )}
            </FullScreenForm>
        );
    },
);

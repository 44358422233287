import { SxProps, Theme } from '@mui/material';
import { DigitalSignature } from '@platform/crypto-ui';
import { FileDownload } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../../../../../../../hooks';
import { PrintFormModel } from '../../../../../../../../models';

export type CampaignRequestPrintFormsSignatureProps = {
    printForm: PrintFormModel;
};

const fileLinkSx: SxProps<Theme> = { width: '100%' };

export const CampaignRequestPrintFormsSignature = observer(
    (props: CampaignRequestPrintFormsSignatureProps): JSX.Element => {
        const { printForm } = props;
        const { filename, signatureSettings, signatureStatus, downloadPrintForm, updatePrintForm } = printForm;
        const { signatureStore } = useStore();
        const {
            getFileByteCreator,
            uploadSignatureCreator,
            uploadSignatureFileCreator,
            getSignatureInfoCreator,
            downloadSignatureCreator,
            deleteSignatureCreator,
        } = signatureStore;
        const printFormId = printForm.pf?.id || '';
        const pfFilename = printForm.pf?.filename || '';

        const getFileByte = getFileByteCreator(printFormId);
        const uploadSignature = uploadSignatureCreator(printFormId);
        const uploadSignatureFile = uploadSignatureFileCreator(printFormId);
        const getSignatureInfo = getSignatureInfoCreator(printFormId);
        const downloadSignature = downloadSignatureCreator(printFormId);
        const deleteSignature = deleteSignatureCreator(printFormId);

        const fileDownloadComponent = (
            <FileDownload fileName={pfFilename} downloadFile={downloadPrintForm} linkSx={fileLinkSx} />
        );

        return (
            <DigitalSignature
                fileTitle={filename}
                fileTitleWithExtension={filename}
                signatureSetting={signatureSettings}
                signatureStatus={signatureStatus}
                fileDownloadComponent={fileDownloadComponent}
                getFileByte={getFileByte}
                uploadSignature={uploadSignature}
                uploadSignatureFile={uploadSignatureFile}
                getSignatureInfo={getSignatureInfo}
                downloadSignature={downloadSignature}
                deleteSignature={deleteSignature}
                onSuccess={updatePrintForm}
            />
        );
    },
);

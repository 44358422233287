import { CampaignSettingsLocales, CommentsAccessRightsSettingsFormField } from '../../../../types';

const { allowDeleting, allowEditing, allowReplying, allowTopLevelCommentsCreating, allowManagingCommentsStatuses } =
    CommentsAccessRightsSettingsFormField;

const commentsSettingFields: Record<CommentsAccessRightsSettingsFormField, string> = {
    [allowDeleting]: 'Delete comments',
    [allowEditing]: 'Edit comments',
    [allowReplying]: 'Reply to comments',
    [allowTopLevelCommentsCreating]: 'Create top level comments',
    [allowManagingCommentsStatuses]: 'Manage comments statuses',
};

const campaignSettings: CampaignSettingsLocales = {
    confirmTransitionTitle: '{title}',
    publishCampaignMessage: 'The status of the campaign «{title}» will be changed to «{toState}»',
    campaignSettings: 'Campaign settings',
    pfTemplates: 'PF templates',
    noTemplates: 'No templates configured',

    campaignDescription: 'Campaign description',
    campaignDescriptionEdit: 'Campaign editing',
    campaignDescriptionCreate: 'Campaign creating',

    comments: 'Commenting',
    setUpAccessToComments: 'Set up access to comments',

    campaignRequestForms: 'Request forms',
    addCampaignRequestFormButton: 'Add request form',
    addingCampaignRequestForm: 'Adding of request form',
    editCampaignRequestForm: 'Editing the request form',
    noRequestForms: 'There are no forms',
    requestFormInfo: 'Primary information',

    campaignManagement: 'Campaign management',

    addTemplate: 'Add template',
    editTemplate: 'Edit template',
    accessPfPermissions: 'Setting access to the PF templates',
    addingPfPermissions: 'Adding access to a PF templates',
    editingPfPermissions: 'Edit access to a PF templates',
    emptyAccessPermissionListTemplates: 'Access to the PF templates is not available',

    accessSectionSetting: 'Setting access to the section',
    addingSectionPermission: 'Adding access to a section',
    editingSectionPermission: 'Edit access to a section',
    setSectionAccess: 'Set section access',
    emptyAccessPermissionListSection: 'Access to the section is not available',

    commentsSetting: {
        accessSectionSetting: 'Setting access to the comments',
        addingSectionPermission: 'Adding access to the comments',
        editingSectionPermission: 'Edit access to the comments',
        setSectionAccess: 'Set comments access',
        emptyAccessPermissionListSection: 'Access to the comments is not available',
        ...commentsSettingFields,
    },

    requestAccessSetting: {
        accessSetting: 'Set up access for the application registry',
        emptyPermissionList: 'Access to the requests is not available',
    },

    deletion: {
        delete: {
            CampaignRequestForm: 'Delete request form',
            SectionSetting: 'Delete section',
            PF: 'Delete template',
        },
        confirmDelete: {
            CampaignRequestForm: 'Are you sure you want to delete the request form «{title}»?',
            SectionSetting: 'Are you sure you want to delete the section «{title}»?',
            PF: 'Are you sure you want to delete the template «{title}»?',
        },
    },

    sectionForm: {
        title: {
            label: 'Section title',
            placeholder: 'Enter section title',
        },
        identifier: {
            label: 'Section identifier',
            placeholder: 'Enter section identifier',
        },
        fileSize: {
            label: 'The size of the package of uploaded documents',
            placeholder: 'Enter value in MB',
        },
        form: {
            label: 'Form code',
            placeholder: 'Select value',
        },
        executor: {
            label: 'Responsible executor',
            placeholder: 'Select user',
        },
        parentSectionSetting: {
            label: 'Parent section',
            placeholder: 'Select the parent section',
        },
        openOnCreation: {
            label: 'Open when creating a request',
        },
        commentingAllowed: {
            label: 'Allow comments',
        },
        showComments: {
            label: 'Show comments',
        },
        maxCommentLength: {
            label: 'Maximum comment length',
            placeholder: 'Enter the maximum possible number of characters',
        },
        textEditorSettings: {
            label: 'Text formatting settings',
        },
        threadsAllowed: {
            label: 'Allow threads (answers)',
        },
        enableCommentStatuses: {
            label: 'Enable comment statuses',
        },
    },
    sectionFileSize: '{fileSize} MB',

    editPermissions: {
        list: {
            role: 'Role',
            status: 'Lifecycle status',
            allStatuses: 'All statuses',
        },
        group: {
            role: 'Role',
            condition: 'Condition',
        },
        access: 'Access',
        edit: 'Edit',
        generate: 'Generate',
        view: 'View',
        addAccess: 'Add access',
        accessSetting: 'Access setting',
        editPermission: 'Edit permission',
        lifecycleStage: 'Lifecycle stage',
        deletePermission: 'Delete permission',
        deletePermissionConfirmationMessage: 'Are you sure you want to remove access for a role «{role}»?',
        canComment: 'Using the commenting functions',
    },

    templateForm: {
        title: {
            label: 'Template title',
            placeholder: 'Enter title',
        },
        description: {
            label: 'Description',
            placeholder: 'Enter template description',
        },
        docCategory: {
            label: 'Document category',
            placeholder: 'Select value',
        },
        fileFormat: {
            label: 'Format',
            placeholder: '',
        },
        required: {
            label: 'Is required to generate',
            placeholder: '',
        },
        file: {
            label: 'Template file',
            placeholder: '',
        },
        ['signatureSettings.signatureEnabled']: {
            label: 'Enable use of electronic signature',
            placeholder: '',
        },
        ['signatureSettings.signatureRequired']: {
            label: 'Signature is required',
            placeholder: '',
        },
        ['signatureSettings.signatureBrowserPlugin']: {
            label: 'Browser plugin',
            placeholder: '',
        },
        ['signatureSettings.signatureUpload']: {
            label: 'Uploading a signature or a file with an attached signature',
            placeholder: '',
        },
    },
    signatureVariants: 'Signing variants',
    templateFormErrors: {
        notUniqueTemplateName: 'Template name must be unique',
        executorsNotAllowed:
            'This method of appointment is not available, since the method of assigning persons in charge has been selected in fact',
    },

    sectionsTitle: 'Request sections',
    noSections: 'No sections',
    addSectionButton: 'Create request section',
    createSection: 'Create section',
    creatingSection: 'Creating a section',
    editingSection: 'Editing the section',
    requestFormSettings: {
        title: 'Section title',
        formTitle: 'Form',
    },

    fileFormat: 'Format',

    templatesTitle: 'Print form templates in the section',
    creatingTemplate: 'Creating a template',
    editingTemplate: 'Editing the template',

    relatedObjects: {
        title: 'Relationships of the application form',
        addRelation: 'Add relation',
        emptyRelationsList: 'There are no relations with the object',
    },
};

export default campaignSettings;

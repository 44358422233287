import { Button, Grid, SxProps, Theme } from '@mui/material';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { sxHeight100 } from '../../../../../constants';
import { ICSDataTestIds } from '../../../../../constants/data-test-ids';
import { useStore } from '../../../../../hooks';
import { CampaignSettingsBodyHeader as CampaignSettingsBodyHeaderInj } from '../CampaignSettingsBodyHeader';
import { CampaignSettingsRequestFormsList as CampaignSettingsRequestFormsListInj } from './CampaignSettingsRequestFormsList';

const headerSx: SxProps<Theme> = {
    paddingTop: 1,
    paddingBottom: 3,
    paddingX: 2,
};

export const CampaignSettingsRequestForms = observer((): JSX.Element => {
    const [CampaignSettingsBodyHeader] = di([CampaignSettingsBodyHeaderInj], CampaignSettingsRequestForms);
    const [CampaignSettingsRequestFormsList] = di([CampaignSettingsRequestFormsListInj], CampaignSettingsRequestForms);

    const { campaignsStore } = useStore();

    const { id: campaignId } = useParams<RouteParamsDefault>();
    const history = useHistory();

    const createRequestForm = (): void => {
        campaignsStore.createRequestForm(campaignId).then((rfId) => {
            history.push(generatePath(clientRoute.campaignSettingsRequestFormCreate, { id: campaignId, rfId }));
        });
    };

    const renderButtons = (): JSX.Element => {
        const actionButtons = [
            <Grid key="create-request-form" item>
                <Button color="secondary" onClick={createRequestForm}>
                    <FormattedMessage id="campaignSettings.addCampaignRequestFormButton" />
                </Button>
            </Grid>,
        ];
        return <React.Fragment>{actionButtons}</React.Fragment>;
    };

    return (
        <Grid container direction="column" flexGrow={1} wrap="nowrap" sx={sxHeight100}>
            <Grid item>
                <CampaignSettingsBodyHeader
                    titleId="campaignSettings.campaignRequestForms"
                    sx={headerSx}
                    renderButtons={renderButtons}
                />
            </Grid>
            <Grid item container flexGrow={1} data-testid={ICSDataTestIds.campaignRegFormSettingsRegFormsListWrapper}>
                <CampaignSettingsRequestFormsList createRequestForm={createRequestForm} />
            </Grid>
        </Grid>
    );
});

import { useTheme } from '@mui/material';
import React from 'react';
import { flatpickrGlobalStyles, formioModalGlobalStyles, StyledForm } from '../styled';
export var FormWithTheme = function (props) {
    var children = props.children;
    var theme = useTheme();
    return (React.createElement(StyledForm, { theme: theme },
        flatpickrGlobalStyles(theme),
        formioModalGlobalStyles(theme),
        children));
};

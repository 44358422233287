import { AccessPermissionRoutes, SetupRelationsButton } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../../clientRoute';
import { CampaignRequestFormRouteParams, SetupRelationEntity } from '../../../../../../../types';

export const CampaignSettingsSetupRelationsButton = observer((): JSX.Element => {
    const params = useParams<CampaignRequestFormRouteParams>();
    const { rfId } = params;

    const routes: AccessPermissionRoutes = {
        closePath: generatePath(clientRoute.campaignSettingsRequestFormTab, params),
        listPath: generatePath(clientRoute.campaignSettingsSetupRelationsTabParam, params),
        addPath: generatePath(clientRoute.campaignSettingsSetupRelationsAddTabParam, params),
    };

    return <SetupRelationsButton entity={SetupRelationEntity.regForm} entityId={rfId} routes={routes} />;
});

import { useEffect } from 'react';
import { ICSRouteParams, ICSRouteTitles } from '../stores';
import { useStore } from './useStore';

export const useSetBreadcrumbsProps = (params: ICSRouteParams, titles: ICSRouteTitles): void => {
    const { breadcrumbsStore } = useStore().coreRootStore;

    const { id, rfId, sectionSettingId, campaignId } = params;
    useEffect(() => {
        breadcrumbsStore.setPageParams(params);
    }, [id, rfId, sectionSettingId, campaignId]);

    const { campaign, regForm, sectionSetting } = titles;
    useEffect(() => {
        breadcrumbsStore.setPageTitles(titles);
    }, [campaign, regForm, sectionSetting]);
};

import { CommentsAppConfig } from '@platform/comments';
import { createContext } from 'react';

export type CommentsAppContextType = {
    config: CommentsAppConfig | null;
};

export const CommentsAppContext = createContext<CommentsAppContextType>({
    config: null,
});

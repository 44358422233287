import { collaborationFieldBlurDelay, CollaborationFormModel } from '@platform/formiojs-react';
import { CollaborationFormRoutes, IFormApi } from '@platform/front-types';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RequestFormSectionModel } from '../../models';
import { CampaignRequestSectionRouteParams } from '../../types';
import { WebSocketApiConfigs } from '../../webSocketApiConfigs';
import { useStore } from '../useStore';

/**
 * Создаёт инстанс класса CollaborationFormModel.
 * Содержит useEffect(ы) с обновлениями полей, подключением/отключением соединения по websocket.
 * @prop sectionId - id раздела (вкладки)
 * @prop isFormView - режим просмотра формы.
 * @prop formModel - нужено для установки новой FormDTO (в режиме редактирования, получам через websocket).
 * @prop formApi - нужно для управления формой (блокировка, разблокировка, обновление полей и пр.).
 * @returns CollaborationFormModel - инстанс класса для совместной работы с формой.
 */
export const useCampaignRequestCollaborationForm = (
    isFormView: boolean,
    onSuccessSaved: VoidFunction,
    formModel?: RequestFormSectionModel,
    formApi?: IFormApi,
): CollaborationFormModel => {
    const { coreRootStore, requestStore } = useStore();
    const { api, currentUserStore } = coreRootStore;
    const { notificationSectionEditConnectDisconnect } = requestStore;
    const { id, sectionId } = useParams<CampaignRequestSectionRouteParams>();

    const socketUrl = useMemo<string>(
        () => (api.webSocketApiConfigs as WebSocketApiConfigs).campaignRequestCollaborationForm(sectionId),
        [sectionId],
    );

    const mainRoute = currentUserStore.getMainRoute();
    const collaborationFormRoutes: CollaborationFormRoutes = useMemo(
        () => ({
            deletedRoute: mainRoute,
        }),
        [mainRoute],
    );

    const collaborationFormModel = useMemo(
        () => new CollaborationFormModel(coreRootStore, socketUrl, collaborationFormRoutes, onSuccessSaved),
        [coreRootStore, socketUrl, collaborationFormRoutes, onSuccessSaved],
    );
    const {
        setFormModel,
        setFormApi: collaborationSetFormApi,
        openConnection,
        closeConnection,
    } = collaborationFormModel;

    useEffect(() => {
        setFormModel(formModel);
    }, [formModel]);

    useEffect(() => {
        collaborationSetFormApi(formApi);
    }, [formApi]);

    // Подключение сокета для формы
    useEffect(() => {
        if (!isFormView) {
            formModel?.dropFormDTO();
            openConnection();
            return () => {
                // Без timeout не успевает срабатывать blur на активном поле
                setTimeout(() => closeConnection(), collaborationFieldBlurDelay);
            };
        }
    }, [sectionId, isFormView]);

    // Подключение уведомлений
    useEffect(() => {
        if (!isFormView) {
            notificationSectionEditConnectDisconnect(id, sectionId, true);
            return () => {
                notificationSectionEditConnectDisconnect(id, sectionId);
            };
        }
    }, [id, sectionId, isFormView]);

    return collaborationFormModel;
};

import { IdTitle } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Route, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { useCampaignSettingsPageContext, useStore } from '../../../../../../hooks';
import { CampaignRequestFormRouteParams, CampaignSettingsEntities } from '../../../../../../types';
import { CampaignSettingsItemHeader as CampaignSettingsItemHeaderInj } from '../../components';
import { CampaignSettingsSetupRelationsButton } from './setup-relations';
import { CampaignSettingsAccessRequestPermissionButton as CampaignSettingsAccessRequestPermissionButtonInj } from './request-permissions';

export type CampaignSettingsRequestFormHeaderProps = {
    requestForm: IdTitle;
    isFormView: boolean;
};

export const CampaignSettingsRequestFormHeader = observer(
    (props: CampaignSettingsRequestFormHeaderProps): JSX.Element => {
        const [CampaignSettingsItemHeader] = di([CampaignSettingsItemHeaderInj], CampaignSettingsRequestFormHeader);
        const [CampaignSettingsAccessRequestPermissionButton] = di(
            [CampaignSettingsAccessRequestPermissionButtonInj],
            CampaignSettingsRequestFormHeader,
        );

        const { requestForm, isFormView } = props;
        const { title } = requestForm;
        const { isCampaignSettingsRequestFormCreatePage, isCampaignSettingsRequestFormEditingForm } =
            useCampaignSettingsPageContext();
        const { formatMessage } = useIntl();
        const formatTitle = isCampaignSettingsRequestFormEditingForm
            ? formatMessage({
                  id: isCampaignSettingsRequestFormCreatePage
                      ? 'campaignSettings.addingCampaignRequestForm'
                      : 'campaignSettings.editCampaignRequestForm',
              })
            : title;

        const { campaignsStore } = useStore();
        const params = useParams<CampaignRequestFormRouteParams>();
        const deleteItem = (): Promise<void> => campaignsStore.deleteCampaignRequestForm(params);
        const additionalHeaderButtons: JSX.Element[] = [
            <CampaignSettingsSetupRelationsButton key="setupRelationsButton" />,
            <CampaignSettingsAccessRequestPermissionButton key="requestPermissionButton" />,
        ];

        return (
            <Route
                path={[
                    clientRoute.campaignSettingsRequestFormInfo,
                    clientRoute.campaignSettingsRequestFormSectionSettings,
                    clientRoute.campaignSettingsRequestFormRelatedObjects,
                    clientRoute.campaignSettingsRequestFormAddRelatedObjects,
                ]}
            >
                <CampaignSettingsItemHeader
                    item={requestForm}
                    title={formatTitle}
                    itemType={CampaignSettingsEntities.CampaignRequestForm}
                    isFormView={isFormView}
                    viewPaths={[
                        clientRoute.campaignSettingsRequestFormAddRequestPermissionTabParam,
                        clientRoute.campaignSettingsRequestFormRequestPermissionsTabParam,
                        clientRoute.campaignSettingsRequestFormTab,
                    ]}
                    deleteItem={deleteItem}
                    additionalHeaderButtons={additionalHeaderButtons}
                />
            </Route>
        );
    },
);

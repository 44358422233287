import { rootElement } from '@platform/front-core';
import { useEffect } from 'react';

export const useHiddenScroll = (isHidden: boolean): void => {
    useEffect(() => {
        // фикс проблемы с доступным скроллом по хедеру
        if (rootElement) {
            rootElement.style.overflowY = isHidden ? 'hidden' : 'auto';
        }
        return () => {
            if (rootElement) {
                rootElement.style.overflowY = '';
            }
        };
    }, [rootElement, isHidden]);
};

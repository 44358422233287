import { TreeView } from '@mui/lab';
import { SidebarItem, SxStyle } from '@platform/front-types';
import { SkeletonTreeList } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { RefObject, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { permissionsConfig } from '../../../../../authSchemeConfig';
import { approvalAppDomId, commentsAppDomId } from '../../../../../constants';
import { useCampaignRequestPageContext, useCommentsAppContext, useStore } from '../../../../../hooks';
import { FormSidebarModel, RequestFormSectionModel } from '../../../../../models';
import { getCustomSidebarItem } from '../../../../../utils';
import { CampaignRequestFormSidebarItems as CampaignRequestFormSidebarItemsInj } from './CampaignRequestFormSidebarItems';

export type CampaignRequestFormSidebarListProps = {
    section: RequestFormSectionModel;
    startSidebarItems: SidebarItem[];
    formWrapperRef: RefObject<HTMLDivElement>;
    sidebarWrapperRef: RefObject<HTMLDivElement>;
};

const treeSx: SxStyle = {
    width: '100%',
    '> li[role="treeitem"]:not(:first-child)': {
        marginTop: (theme) => theme.spacing(0.5),
    },
};

export const CampaignRequestFormSidebarList = observer((props: CampaignRequestFormSidebarListProps): JSX.Element => {
    const [CampaignRequestFormSidebarItems] = di([CampaignRequestFormSidebarItemsInj], CampaignRequestFormSidebarList);

    const { formWrapperRef, sidebarWrapperRef, section, startSidebarItems } = props;
    const { isFormLoading } = section;
    const formSidebarModel = useMemo(
        () => new FormSidebarModel(formWrapperRef, sidebarWrapperRef),
        [formWrapperRef.current, sidebarWrapperRef.current],
    );
    const {
        sidebarItems,
        expandedItems,
        selectedItems,
        itemAnchorPrefix,
        isFirstSelectedItemActive,
        setSidebarItems,
        handleScroll,
        highlightFirstItemOnPageLoaded,
        expandItem,
        decreaseItem,
        onNodeSelect,
    } = formSidebarModel;
    const { model: requestModel, isFormView } = useCampaignRequestPageContext();
    const { currentSectionDTO, state } = requestModel;
    const { config } = useCommentsAppContext();
    const { formatMessage } = useIntl();
    const { authorizationStore } = useStore().coreRootStore;

    useEffect(() => {
        if (!isFormLoading) {
            setSidebarItems(startSidebarItems);
            if (!isFirstSelectedItemActive) {
                highlightFirstItemOnPageLoaded(startSidebarItems[0].id);
            }
            formWrapperRef.current?.addEventListener('scroll', handleScroll);
            return () => formWrapperRef.current?.removeEventListener('scroll', handleScroll);
        }
    }, [startSidebarItems, isFormLoading]);

    useEffect(() => {
        // Добавляет дополнительные SidebarItems (не поля формы)
        // p.s. Важен порядок добавления
        if (!isFormLoading) {
            const fullSidebarItems: SidebarItem[] = [...startSidebarItems];

            const setApprovalPromise = isFormView
                ? authorizationStore
                      .check(permissionsConfig.viewApprovalSheets(section.id))
                      .then((isWithApprovalLists) => {
                          if (isWithApprovalLists) {
                              // Добавляет ссылку на блок листов согласования
                              fullSidebarItems.push(
                                  getCustomSidebarItem(approvalAppDomId, formatMessage({ id: 'approval.title' })),
                              );
                          }
                      })
                : Promise.resolve();

            setApprovalPromise.finally(() => {
                if (config) {
                    const { contextInfo } = config;
                    const { title } = contextInfo;

                    // Добавляет ссылку на блок комментариев
                    fullSidebarItems.push(
                        getCustomSidebarItem(
                            commentsAppDomId,
                            title || formatMessage({ id: 'campaignSettings.comments' }),
                        ),
                    );
                }

                setSidebarItems(fullSidebarItems);
            });
        }
    }, [
        startSidebarItems,
        isFormLoading,
        isFormView,
        currentSectionDTO?.hasActiveComments,
        config?.contextInfo?.title,
        state.id,
    ]);

    return (
        <TreeView
            sx={treeSx}
            selected={selectedItems}
            expanded={expandedItems}
            onNodeSelect={onNodeSelect}
            multiSelect={true}
        >
            {isFormLoading ? (
                <SkeletonTreeList rowsCount={5} />
            ) : (
                <CampaignRequestFormSidebarItems
                    itemAnchorPrefix={itemAnchorPrefix}
                    sidebarItems={sidebarItems}
                    expandItem={expandItem}
                    decreaseItem={decreaseItem}
                    isFirstStep={true}
                />
            )}
        </TreeView>
    );
});

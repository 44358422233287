import { promiseVoidFunction, voidFunction, VoidFunction } from '@platform/front-utils';
import { createContext } from 'react';

export type CampaignCardPageContextType = {
    isCreateRequestDialogOpen: boolean;
    openCreateRequestDialog: (campaignId: string) => Promise<void>;
    closeCreateRequestDialog: VoidFunction;
};

export const CampaignCardPageContext = createContext<CampaignCardPageContextType>({
    isCreateRequestDialogOpen: false,
    openCreateRequestDialog: promiseVoidFunction,
    closeCreateRequestDialog: voidFunction,
});

import {
    ComponentWithValidationMessage,
    FormApi,
    FormioSidebarStore,
    validateEditPage,
    validateReadonlyPage,
} from '@platform/formiojs-react';
import { GeneralObjectContentErrorsModel, PersonModel } from '@platform/front-core';
import { FileDTO, IdTitle } from '@platform/front-types';
import { SimilarObject } from '@platform/front-ui';
import { DateUtils, LoadingModel } from '@platform/front-utils';
import { History } from 'history';
import { action, computed, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { RequestStore, RootStore } from '../stores';
import {
    CampaignRequestServiceInfoDTO,
    LabelUrlType,
    RequestDTO,
    RequestSectionDTO,
    RequestSectionListDTO,
    RequestSubSectionListDTO,
} from '../types';
import { RequestFormSectionModel } from './RequestFormSectionModel';

export const requestModelObservables = {
    formioSidebarStore: observable,
    id: observable,
    number: observable,
    regFormTitle: observable,
    campaign: observable,
    state: observable,
    regEnd: observable,
    sent: observable,
    created: observable,
    author: observable,
    lifeCycle: observable,
    formInfoDTO: observable,
    lastModified: observable,
    similarObjects: observable,

    sections: observable,
    currentSection: observable,
    errorsModel: observable,

    formName: observable,
    formApi: observable,
    formErrors: observable,
    showValidation: observable,

    currentSectionDTO: computed,

    load: action.bound,
    redirectToFirstOrCurrentSection: action.bound,
    redirectToSection: action.bound,
    loadRequest: action.bound,
    setCurrentUserAsExecutor: action.bound,
    editExecutor: action.bound,
    loadSection: action.bound,
    loadExecutor: action.bound,
    loadRequestServiceInfo: action.bound,
    validateEditPage: action.bound,
    validateAfterReadonly: action.bound,
    validateReadonlyPage: action.bound,
    loadSimilarObjects: action.bound,
    setFormApi: action.bound,
    setFormErrors: action.bound,
    setShowValidation: action.bound,
    resetFormValidation: action.bound,
    setInfoServiceFields: action.bound,
    setSimilarObjects: action.bound,
};

export class RequestModel extends LoadingModel {
    private readonly formioSidebarStore: FormioSidebarStore;
    private requestStore: RequestStore;
    private history: History;
    id: string;
    number = '';
    regFormTitle = '';
    regFormInstructions: FileDTO[] = [];
    campaign: IdTitle = {
        id: '',
        title: '',
    };
    state: IdTitle = {
        id: '',
        title: '',
    };
    regEnd?: Date;
    sent?: Date;
    created?: Date;
    author: PersonModel = new PersonModel();
    similarObjects = [] as SimilarObject[];

    sections: RequestSectionListDTO[] = [];
    currentSection?: RequestFormSectionModel;
    errorsModel: GeneralObjectContentErrorsModel;

    formName = 'request';
    formApi?: FormApi;
    formErrors: ComponentWithValidationMessage[] = [];
    showValidation = false;

    lifeCycle: LabelUrlType = {} as LabelUrlType;
    formInfoDTO: LabelUrlType[] = [];
    lastModified?: Date;

    // isFormReady = false;
    // deadlines: DeadlinesDTO = {};

    constructor(id: string, rootStore: RootStore) {
        super();
        makeObservable(this, requestModelObservables);
        this.formioSidebarStore = rootStore.formioSidebarStore;
        this.requestStore = rootStore.requestStore;
        this.history = rootStore.history;
        this.id = id;
        this.errorsModel = new GeneralObjectContentErrorsModel(rootStore.coreRootStore);
    }

    /** Возвращает ДТО текущего раздела. */
    get currentSectionDTO(): RequestSectionListDTO | undefined {
        return this.sections.find((sectionDTO) => sectionDTO.id === this.currentSection?.id);
    }

    load(dto: RequestDTO, isRequestEdit?: boolean, isSyslog?: boolean): void {
        const { regEnd, regFormTitle, regFormInstructions, state, number, campaign, sent, sections, author, created } =
            dto;

        this.regFormTitle = regFormTitle;
        this.regFormInstructions = regFormInstructions;
        this.campaign = campaign;
        this.number = number;
        this.state = state;

        const regEndDate = new Date(regEnd || '');
        const sentDate = new Date(sent || '');
        const createdDate = new Date(created);

        this.regEnd = DateUtils.isValidDate(regEndDate) ? regEndDate : undefined;
        this.sent = DateUtils.isValidDate(sentDate) ? sentDate : undefined;
        this.created = DateUtils.isValidDate(createdDate) ? createdDate : undefined;
        this.author.load(author.person);

        this.sections = sections || [];
        // на странице журнала нет смысла совершать действия с разделами (минус нашего апи - массив разделов приходит вместе с данными карточки)
        if (!isSyslog) {
            if (!this.currentSection) {
                this.redirectToFirstOrCurrentSection(isRequestEdit);
            } else {
                this.currentSection.checkIsSectionEditable();
            }
        }
        // this.loadDeadlines();
    }

    /*
     * этот метод используется в двух случаях:
     * 1. когда раздел не определен и нужно сделать редирект на первый из списка
     * 2. когда мы со страницы заявки ушли в журнал и вернулись обратно - раздел закэшировался в модели, логично возвращаться к нему
     * */
    redirectToFirstOrCurrentSection(isRequestEdit?: boolean): void {
        if (!this.currentSection) {
            // раздел с признаком "открывать при создании", его может и не быть (по договоренности работает также для кнопки "Редактировать" в реестре)
            let firstOpenSubSection: RequestSubSectionListDTO | undefined;
            const firstOpenSection = this.sections.find((sectionItem) => {
                const { openOnCreation, subSections } = sectionItem;
                if (openOnCreation) {
                    return true;
                }
                if (subSections) {
                    firstOpenSubSection = subSections.find((subSectionItem) => {
                        return subSectionItem.openOnCreation;
                    });
                }
                return !!firstOpenSubSection;
            });
            // первый раздел в списке, его тоже может не быть, если у пользователя нет доступа или нет настроенных разделов
            const firstSection = this.sections[0];

            // т.к firstOpenSection определен всегда, когда есть firstOpenSubSection, в первую очередь подставляем firstOpenSubSection
            const openOnCreationSection = firstOpenSubSection || firstOpenSection;

            const section = (isRequestEdit ? openOnCreationSection : firstSection) || firstSection;
            if (section) {
                const sectionId = section.id;
                this.redirectToSection(sectionId, isRequestEdit);
            }
        } else {
            this.redirectToSection(this.currentSection.id, isRequestEdit);
        }
    }

    redirectToSection(sectionId: string, isRequestEdit?: boolean): void {
        if (isRequestEdit) {
            this.history.replace(generatePath(clientRoute.requestSectionFormEdit, { id: this.id, sectionId }));
        } else {
            this.history.replace(generatePath(clientRoute.requestSection, { id: this.id, sectionId }));
        }
    }

    loadRequest(isRequestEdit?: boolean, isSyslog?: boolean): Promise<void> {
        this.startLoading();
        return this.requestStore
            .loadRequestDTO(this.id)
            .then((dto) => {
                this.load(dto, isRequestEdit, isSyslog);
            })
            .finally(this.stopLoading);
    }

    loadRequestServiceInfo(): void {
        this.startLoading();
        this.requestStore.getServiceInfo(this.id).then(this.setInfoServiceFields).finally(this.stopLoading);
    }

    editExecutor(userId: string): Promise<void> {
        return this.requestStore.editExecutorSettings(this.id, userId);
    }

    loadExecutor(): Promise<void> {
        return this.requestStore.getExecutorSettings(this.id).then((executor) => {
            this.currentSection?.setExecutor(executor);
        });
    }

    loadSection(sectionId: string, isFormView: boolean): void {
        this.setCurrentSection(sectionId);
        this.requestStore.loadRequestSection(sectionId).then((section: RequestSectionDTO): void => {
            this.currentSection?.load(section, isFormView);
        });
    }

    validateEditPage(onSuccess?: () => Promise<void>): Promise<void> {
        return validateEditPage(this.formApi, this.formioSidebarStore, this.formName).then(onSuccess);
    }

    validateAfterReadonly(): void {
        if (this.showValidation) {
            this.validateEditPage().then(this.resetFormValidation).catch(this.setFormErrors);
        }
    }

    validateReadonlyPage(onSuccess?: () => Promise<void>, withoutSignatureValidate?: boolean): Promise<void> {
        return validateReadonlyPage({
            setFormErrors: this.setFormErrors,
            setShowValidation: this.setShowValidation,
            onSuccess,
            formApi: this.formApi,
            formioSidebarStore: this.formioSidebarStore,
            formName: this.formName,
            withoutSignatureValidate,
        });
    }

    // loadDeadlines(): void {
    //     this.requestStore.getDeadlinesDTO(this.id).then((deadlinesDTO) => {
    //         const entityDeadlineDate = deadlinesDTO.entityDeadline?.date;
    //         const stateDeadlineDate = deadlinesDTO.stateDeadline?.date;
    //
    //         if (entityDeadlineDate) {
    //             (deadlinesDTO.entityDeadline as DeadlineDTO).date = new Date(entityDeadlineDate);
    //         }
    //
    //         if (stateDeadlineDate) {
    //             (deadlinesDTO.stateDeadline as DeadlineDTO).date = new Date(stateDeadlineDate);
    //         }
    //
    //         this.deadlines = deadlinesDTO;
    //     });
    // }

    loadSimilarObjects(): Promise<void> {
        this.startLoading();
        return this.requestStore.loadSimilarObjects(this.id).then(this.setSimilarObjects).finally(this.stopLoading);
    }

    setCurrentSection(sectionId: string): void {
        this.currentSection = new RequestFormSectionModel(sectionId, this.requestStore);
        this.resetFormValidation();
    }

    setCurrentUserAsExecutor(): Promise<void> {
        return this.requestStore.setCurrentUserAsExecutor(this.id);
    }

    resetFormValidation(): void {
        this.setShowValidation(false);
        this.setFormErrors([]);
    }

    setFormApi(formApi: FormApi): void {
        this.formApi = formApi;
    }

    setFormErrors(formErrors: ComponentWithValidationMessage[]): void {
        this.formErrors = formErrors;
    }

    setShowValidation(showValidation: boolean): void {
        this.showValidation = showValidation;
    }

    setInfoServiceFields(dto: CampaignRequestServiceInfoDTO) {
        const { formInfoDTO, lifecycleDTO, lastModified } = dto;
        this.formInfoDTO = formInfoDTO;
        this.lifeCycle = lifecycleDTO;
        if (lastModified) {
            this.lastModified = new Date(lastModified);
        }
    }

    setSimilarObjects(similarObjects: SimilarObject[]): void {
        this.similarObjects = similarObjects;
    }
}

import { FileDTO } from '@platform/front-types';
import { DropzoneFile } from '@platform/front-ui';

export function createDropzoneFileInstance(fileDTO: FileDTO): DropzoneFile {
    const { size, mimeType, filename, id } = fileDTO;
    const file: DropzoneFile = new File([new ArrayBuffer(size)], filename, {
        type: mimeType,
    });
    file.isUploaded = true;
    file.id = id;

    return file;
}

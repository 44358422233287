import { ApiStore, PaginationModel } from '@platform/front-core';
import { DateUtils } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { CampaignCardDTO, CampaignInfoForCardDTO } from '../types';

export const CampaignListModelProps = {
    apiUrl: observable,
    api: observable,
    isEmpty: observable,
    isLoading: observable,
    getPaginationData: action.bound,
    mapDTO: action.bound,
    setIsEmpty: action.bound,
    setIsLoading: action.bound,
};

const campaignsPageSize = 12;

export class CampaignListModel extends PaginationModel<CampaignCardDTO> {
    private api: ApiStore;
    private apiUrl?: string;

    isEmpty = false;
    isLoading = true;

    constructor(api: ApiStore, apiUrl?: string) {
        super();
        this.apiUrl = apiUrl;
        this.api = api;
        this.setPageSize(campaignsPageSize);
        // this.getPaginationData(); // при подгрузке данных здесь в dev-моде запрос отправляется дважды, перенесла в хук CampaignListPage
        makeObservable(this, CampaignListModelProps);
    }

    getPaginationData(): Promise<void> {
        this.setIsLoading(true);

        return this.api
            .mainInfoClient(apiConfigs.campaigns)
            .then((r) => r.data)
            .then((data) => {
                this.setIsEmpty(!data.length);
                return data.map(this.mapDTO);
            })
            .then(this.setPaginationData)
            .finally(() => {
                this.setIsLoading(false);
            });
    }

    mapDTO(dto: CampaignInfoForCardDTO): CampaignCardDTO {
        const to = new Date(dto.to || '');
        const from = new Date(dto.from || '');
        return {
            ...dto,
            avatarUrl: dto.avatarId && this.apiUrl && `${this.apiUrl}${apiConfigs.campaignFileUrl(dto.avatarId)}`,
            to: dto.to && DateUtils.isValidDate(to) ? to : undefined,
            from: dto.from && DateUtils.isValidDate(from) ? from : undefined,
        };
    }

    setIsEmpty(isEmpty: boolean): void {
        this.isEmpty = isEmpty;
    }

    setIsLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }
}

import { Grid, SxProps, Theme } from '@mui/material';
import { grey } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { RefObject, useRef } from 'react';
import { di } from 'react-magnetic-di';
import { ICSDataTestIds } from '../../../../../constants/data-test-ids';
import { useStore } from '../../../../../hooks';
import { RequestFormSectionModel } from '../../../../../models';
import { CampaignRequestFormSidebarList as CampaignRequestFormSidebarListInj } from './CampaignRequestFormSidebarList';

export type CampaignRequestFormSidebarProps = {
    section: RequestFormSectionModel;
    formWrapperRef: RefObject<HTMLDivElement>;
    formName: string;
};

const formSidebarSx: SxProps<Theme> = {
    overflowY: 'auto',
    position: 'relative',
    padding: 1,
    paddingLeft: 2,
    borderRight: `1px solid ${grey[300]}`,
};

export const CampaignRequestFormSidebar = observer((props: CampaignRequestFormSidebarProps): JSX.Element => {
    const [CampaignRequestFormSidebarList] = di([CampaignRequestFormSidebarListInj], CampaignRequestFormSidebar);

    const { section, formWrapperRef, formName } = props;
    const { isFormLoading } = section;
    const { formioSidebarStore } = useStore();
    const { items } = formioSidebarStore;
    const startSidebarItems = items[formName] || [];

    const sidebarWrapperRef = useRef<HTMLDivElement>(null);

    const isShowSidebar = isFormLoading || (!isFormLoading && !!startSidebarItems.length);

    return (
        <React.Fragment>
            {isShowSidebar && (
                <Grid
                    item
                    minWidth="240px"
                    maxWidth="240px"
                    sx={formSidebarSx}
                    ref={sidebarWrapperRef}
                    data-testid={ICSDataTestIds.campaignRequestCardNavigationSidebar}
                >
                    {section && (
                        <CampaignRequestFormSidebarList
                            section={section}
                            formWrapperRef={formWrapperRef}
                            sidebarWrapperRef={sidebarWrapperRef}
                            startSidebarItems={startSidebarItems}
                        />
                    )}
                </Grid>
            )}
        </React.Fragment>
    );
});

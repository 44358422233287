import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { CampaignListPage as CampaignListPageInj } from './campaign-list';
import { CampaignPage as CampaignPageInj } from './CampaignPage';

export const AdditionalCampaignPagesInj = (): JSX.Element[] => [];

export const CampaignPages = observer((): JSX.Element => {
    const [CampaignListPage] = di([CampaignListPageInj], CampaignPages);
    const [CampaignPage] = di([CampaignPageInj], CampaignPages);

    const [AdditionalCampaignPages] = di([AdditionalCampaignPagesInj], CampaignPages);

    return (
        <Switch>
            {AdditionalCampaignPages()}
            <Route exact path={clientRoute.campaigns}>
                <CampaignListPage />
            </Route>
            <Route path={clientRoute.campaign}>
                <CampaignPage />
            </Route>
        </Switch>
    );
});

import { Box, Grid } from '@mui/material';
import { AuthorizationCheck, EmptyListLayout, EmptyListProps, List } from '@platform/front-core';
import { lastIndex, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../../authSchemeConfig';
import { sxHeight100 } from '../../../../../../constants';
import { useLoadCampaignRequestForms } from '../../../../../../hooks';
import { CampaignRequestFormRouteParams } from '../../../../../../types';
import { CampaignCardDescriptionRequestForm as CampaignCardDescriptionRequestFormInj } from './CampaignCardDescriptionRequestForm';

/** Компонент, подгружающий и возвращающий список CampaignCardDescriptionRequestForm. */
export const CampaignCardDescriptionRequestForms = observer((): JSX.Element => {
    const [CampaignCardDescriptionRequestForm] = di(
        [CampaignCardDescriptionRequestFormInj],
        CampaignCardDescriptionRequestForms,
    );

    const { id } = useParams<CampaignRequestFormRouteParams>();

    const { formatMessage } = useIntl();

    const { loadCampaignRequestFormsModel, campaignRequestForms } = useLoadCampaignRequestForms();
    const [isLoading, startLoading, stopLoading] = useFlag(true);

    const emptyListProps: EmptyListProps = {
        noItemsTitle: formatMessage({ id: 'campaignSettings.noRequestForms' }),
    };

    const requestFormCards: JSX.Element[] = campaignRequestForms.map((requestForm, index) => {
        const isLastIndex = index === lastIndex(campaignRequestForms);
        const { id } = requestForm;
        return <CampaignCardDescriptionRequestForm key={id} requestForm={requestForm} isLast={isLastIndex} />;
    });

    const getForms = (): Promise<void> => {
        // Promise.resolve потому что List требует только Promise<void>
        startLoading();
        return loadCampaignRequestFormsModel(id)
            .then(() => {
                return Promise.resolve();
            })
            .finally(stopLoading);
    };

    useEffect(() => {
        getForms();
    }, []);

    const notAllowedTitle: JSX.Element = (
        <Box textAlign="center">
            <Box>
                <FormattedMessage id="campaign.collectionCompleted" />
            </Box>
            <Box>
                <FormattedMessage id="campaign.createRequestError" />
            </Box>
        </Box>
    );

    return (
        <Grid container direction="column" flexWrap="nowrap" sx={sxHeight100}>
            <AuthorizationCheck
                entityCode={entities.campaign}
                permCode={permissions.campaign.AddCampRequest}
                entityId={id}
            >
                {(allowed) => {
                    return allowed ? (
                        <List isLoading={isLoading} {...(!campaignRequestForms.length && { emptyListProps })}>
                            {requestFormCards}
                        </List>
                    ) : (
                        <EmptyListLayout title={notAllowedTitle} />
                    );
                }}
            </AuthorizationCheck>
        </Grid>
    );
});

import { Button, CircularProgress, Grid, TableCell } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useCampaignRequestPageContext, useCampaignRequestPrintFormContext } from '../../../../../../../../hooks';
import { CampaignRequestPrintFormsSignature as CampaignRequestPrintFormsSignatureInj } from './CampaignRequestPrintFormsSignature';

export const CampaignRequestPrintFormsRowActionsCell = observer((): JSX.Element => {
    const [CampaignRequestPrintFormsSignature] = di(
        [CampaignRequestPrintFormsSignatureInj],
        CampaignRequestPrintFormsRowActionsCell,
    );

    const { model } = useCampaignRequestPrintFormContext();
    const { pf, isGenerating, generatePrintForm } = model;

    const { model: listModel, isFormView } = useCampaignRequestPageContext();

    const { formatMessage } = useIntl();

    const pfButtonTextMessage = pf
        ? formatMessage({ id: 'printForms.table.updateAction' })
        : formatMessage({ id: 'printForms.table.generateAction' });

    const endIcon = isGenerating && <CircularProgress color="secondary" />;

    const onGenerate = (): Promise<void> => {
        const validateFunction = isFormView ? listModel.validateReadonlyPage : listModel.validateEditPage;
        return generatePrintForm(validateFunction);
    };

    return (
        <TableCell>
            <Grid container wrap="nowrap" alignItems="center" spacing={0.5}>
                <Grid item>
                    <Button
                        disabled={isGenerating}
                        onClick={onGenerate}
                        color="secondary"
                        variant="contained"
                        endIcon={endIcon}
                    >
                        {pfButtonTextMessage}
                    </Button>
                </Grid>
                {pf && (
                    <Grid item>
                        <CampaignRequestPrintFormsSignature printForm={model} />
                    </Grid>
                )}
            </Grid>
        </TableCell>
    );
});

import { CustomLogoConfig } from '@platform/front-core';
import { Features } from '../../types';

const customLogo: CustomLogoConfig = {
    src: require('./tszd-logo.svg'),
    alt: 'Растим детей',
    style: { minHeight: 70 },
    withText: false,
    mdLogo: false,
};

const features: Features = {
    loginWithoutRegister: false,
    headerLogo: false,
    footerLogo: false,
    allUsersRedirectPath: false,
    customLogo: {
        isEnabled: true,
        locales: {
            ru: customLogo,
            en: customLogo,
        },
    },
    helpIcon: false,
    notificationIcon: true,
    // changeLanguage: true,
    phoneNumber: false,
    esiaLogin: false,
    showCreateRequestButton: true,
    showRequestsCount: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    samlAuth: false,
    showCampaignPeriod: true,
    isWithRegistrationPage: true,
};

export default features;

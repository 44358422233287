import {
    ObjectRelationsPermissionsQueries,
    RelatedObjectsInfoInTable,
    RelatedObjectsInfoRoutes,
} from '@platform/front-core';
import { AuthorizationCheck } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useParams } from 'react-router-dom';
import { permissionsConfig } from '../../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../../clientRoute';
import { useCampaignRequestPageContext } from '../../../../../../hooks';
import {
    CampaignRequestFormRouteParams,
    CampaignRequestRouteParams,
    SetupRelationEntity,
} from '../../../../../../types';

export const CampaignSettingsInfoCommonObjectRelations = observer((): JSX.Element => {
    const params = useParams<CampaignRequestRouteParams>();

    const routes: RelatedObjectsInfoRoutes = {
        addPath: generatePath(clientRoute.campaignSettingsRequestFormAddRelatedObjects, params),
        closePath: generatePath(clientRoute.campaignSettingsRequestFormRelatedObjects, params),
        listPath: generatePath(clientRoute.campaignSettingsRequestFormRelatedObjects, params),
    };
    const { rfId } = useParams<CampaignRequestFormRouteParams>();

    const { formatMessage } = useIntl();

    const { model } = useCampaignRequestPageContext();

    const { id, number } = model;
    const permissionsQueries: ObjectRelationsPermissionsQueries = useMemo(
        () => ({
            deleteRelations: permissionsConfig.deleteRelations(id),
            saveRelation: permissionsConfig.saveRelation(id),
            deleteRelation: permissionsConfig.deleteRelation(id),
        }),
        [id],
    );

    const deleteEntityRelationTitle = formatMessage(
        { id: 'relations.deleteRelationMessage' },
        { entity: formatMessage({ id: 'campaignRequest.requests' }) },
    );

    return (
        <AuthorizationCheck {...permissionsConfig.viewRelations(id)}>
            <RelatedObjectsInfoInTable
                entity={SetupRelationEntity.regForm}
                entityId={rfId}
                addDialogTitle={formatMessage({ id: 'relatedObjects.addingRelation' })}
                listDialogTitle={formatMessage({ id: 'relatedObjects.relationsList' }, { number })}
                routes={routes}
                objectRelationsPermissionsQueries={permissionsQueries}
                deleteEntityRelationTitle={deleteEntityRelationTitle}
                loadAllRelatedObjects
            />
        </AuthorizationCheck>
    );
});

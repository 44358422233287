import { Grid } from '@mui/material';
import { FullScreenForm, Loader } from '@platform/front-ui';
import { VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { RequestFormSectionSettingModel } from '../../../../../../../../../models';
import { CampaignSettingsCommentsSettingDialogFormFields as CampaignSettingsCommentsSettingDialogFormFieldsInj } from './comments-setting-fields';
import { CampaignSettingsSectionSettingDialogFormFields as CampaignSettingsSectionSettingDialogFormFieldsInj } from './section-setting-fields';

export type CampaignSettingsSectionSettingDialogFormProps = {
    sectionSettingModel: RequestFormSectionSettingModel;
    onCancel: VoidFunction;
    dialogTitle?: React.ReactNode;
};

/**
 * Компонент, группирующий разделы формы для настройки "раздела" и настройки комментариев
 * @prop sectionSettingModel - Модель формы настройки создания раздела
 * @prop onCancel - Функция, срабатывающая на нажатие на кнопку "отмена"
 */
export const CampaignSettingsSectionSettingDialogForm = observer(
    (props: CampaignSettingsSectionSettingDialogFormProps): JSX.Element => {
        const [CampaignSettingsCommentsSettingDialogFormFields] = di(
            [CampaignSettingsCommentsSettingDialogFormFieldsInj],
            CampaignSettingsSectionSettingDialogForm,
        );
        const [CampaignSettingsSectionSettingDialogFormFields] = di(
            [CampaignSettingsSectionSettingDialogFormFieldsInj],
            CampaignSettingsSectionSettingDialogForm,
        );

        const { sectionSettingModel, onCancel, dialogTitle } = props;
        const { isLoaded } = sectionSettingModel;

        return (
            <FullScreenForm onCancel={onCancel} dialogTitle={dialogTitle}>
                {isLoaded ? (
                    <Grid container flexDirection="column" spacing={2} height="100%" flexWrap="nowrap">
                        <CampaignSettingsSectionSettingDialogFormFields sectionSettingModel={sectionSettingModel} />
                        <CampaignSettingsCommentsSettingDialogFormFields sectionSettingModel={sectionSettingModel} />
                    </Grid>
                ) : (
                    <Loader fullSize />
                )}
            </FullScreenForm>
        );
    },
);

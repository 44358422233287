import { SimilarObjects } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useCampaignRequestPageContext } from '../../../../../../hooks';

export const CampaignRequestInfoCommonSimilarRequests = observer((): JSX.Element => {
    const { model } = useCampaignRequestPageContext();
    const { id, loadSimilarObjects, similarObjects } = model;

    const { formatMessage } = useIntl();

    useEffect(() => {
        loadSimilarObjects();
    }, [id]);

    return (
        <SimilarObjects
            similarObjects={similarObjects}
            title={formatMessage({ id: 'campaignRequest.similarRequests' })}
        />
    );
});

import { Features } from '../../types';
import skolkovoLogo from './skolkovo-logo.svg';

const logoStyle = { width: 'auto', height: '32px' };

const features: Features = {
    loginWithoutRegister: false,
    customLogo: {
        isEnabled: true,
        locales: {
            en: {
                alt: '',
                src: '',
            },
            ru: {
                alt: '',
                src: '',
            },
        },
    },
    headerLogo: {
        isEnabled: true,
        locales: {
            ru: {
                image: skolkovoLogo,
                alt: 'Сколково',
                style: logoStyle,
            },
            en: {
                image: skolkovoLogo,
                alt: 'Skolkovo',
                style: logoStyle,
            },
        },
    },
    helpIcon: true,
    notificationIcon: true,
    phoneNumber: true,
    esiaLogin: true,
    showCreateRequestButton: true,
    showRequestsCount: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: {
        isEnabled: true,
        providers: [
            {
                code: 'onelogin',
                title: 'OneLogin',
            },
        ],
    },
    showCampaignPeriod: true,
    allUsersRedirectPath: false,
    isWithRegistrationPage: true,
};

export default features;

import { useFlag, useYup } from '@platform/front-utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { CustomSettingsEditPermissionFormProps } from '../../../../../../../../../../contexts';
import { useCampaignSettingsAccessPermissionContext } from '../../../../../../../../../../hooks';
import {
    AccessPermissionDTOFields,
    CommentsAccessRightsSettingsFormField,
    CustomCommentSettingPermissionsInitialValues,
} from '../../../../../../../../../../types';
import { CustomCommentsSettingEditPermissionFormContent as CustomCommentsSettingEditPermissionFormContentInj } from './components';

const { allowDeleting, allowEditing, allowReplying, allowTopLevelCommentsCreating, allowManagingCommentsStatuses } =
    CommentsAccessRightsSettingsFormField;

const { whenObjectStateIsOneOf, roleCondition } = AccessPermissionDTOFields;

const formikInitialValues: CustomCommentSettingPermissionsInitialValues = {
    [roleCondition]: null,
    [whenObjectStateIsOneOf]: [],
    [allowDeleting]: false,
    [allowEditing]: false,
    [allowReplying]: false,
    [allowTopLevelCommentsCreating]: false,
    [allowManagingCommentsStatuses]: false,
};

/**
 * Форма для настройки и редактирования доступа комментариев.
 */
export const CustomCommentSettingEditPermissionForm = observer(
    (props: CustomSettingsEditPermissionFormProps): JSX.Element => {
        const [CustomCommentsSettingEditPermissionFormContent] = di(
            [CustomCommentsSettingEditPermissionFormContentInj],
            CustomCommentSettingEditPermissionForm,
        );

        const { dialogTitle } = props;

        const { selectedPermissionIndex, goBack, model } = useCampaignSettingsAccessPermissionContext();
        const { Yup } = useYup();
        const optionsHandles = useFlag();
        const [isOptionsVisible, ,] = optionsHandles;

        const schema = Yup.object().shape({
            [roleCondition]: Yup.object()
                .shape({
                    title: Yup.string().required(),
                })
                .nullable()
                .required(),
            [whenObjectStateIsOneOf]: Yup.array(),
            [allowDeleting]: Yup.boolean(),
            [allowEditing]: Yup.boolean(),
            [allowReplying]: Yup.boolean(),
            [allowTopLevelCommentsCreating]: Yup.boolean(),
        });

        /**
         * Обрабатывает поля формы в зависимости от включенности верхнего чекбокса.
         * @param values Данные формы.
         */
        const handleSubmit = (values: CustomCommentSettingPermissionsInitialValues): Promise<void> => {
            let {
                allowDeleting,
                allowEditing,
                allowManagingCommentsStatuses,
                allowReplying,
                allowTopLevelCommentsCreating,
            } = values;
            const { whenObjectStateIsOneOf, roleCondition } = values;

            if (!isOptionsVisible) {
                allowDeleting = false;
                allowEditing = false;
                allowManagingCommentsStatuses = false;
                allowReplying = false;
                allowTopLevelCommentsCreating = false;
            }

            const dto: CustomCommentSettingPermissionsInitialValues = {
                allowDeleting,
                allowEditing,
                allowManagingCommentsStatuses,
                allowReplying,
                allowTopLevelCommentsCreating,
                whenObjectStateIsOneOf,
                roleCondition,
            };

            return model.submitPermissions(dto, selectedPermissionIndex, goBack);
        };

        const initialValues: CustomCommentSettingPermissionsInitialValues = useMemo(() => {
            if (selectedPermissionIndex !== null) {
                return model.customFormPermissionsList[selectedPermissionIndex];
            }

            return formikInitialValues;
        }, [selectedPermissionIndex, model.customFormPermissionsList]);

        return (
            <Formik
                initialValues={{ ...initialValues }}
                onSubmit={handleSubmit}
                validationSchema={schema}
                enableReinitialize
            >
                <CustomCommentsSettingEditPermissionFormContent
                    optionsHandles={optionsHandles}
                    dialogTitle={dialogTitle}
                />
            </Formik>
        );
    },
);

import { CoreRootStore, CurrentUserStore as CurrentUserStoreInj } from '@platform/front-core';
import { makeObservable, override } from 'mobx';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../clientRoute';
import { RedirectByRoleType, RolePriority } from '../types';

export const loginRedirectByRole: RedirectByRoleType = {
    User: clientRoute.campaigns,
    Employee: clientRoute.requests,
    SeniorEmployee: clientRoute.requests,
    Moderator: clientRoute.requests,
};

export const rolePriorities: RolePriority = {
    User: 0,
    Employee: 1,
    SeniorEmployee: 2,
    Moderator: 3,
};

export const currentUserStoreObservables = {
    getMainRoute: override,
    getRolePriorities: override,
    getLoginRedirectByRole: override,
};

export class CurrentUserStore extends CurrentUserStoreInj {
    constructor(rootStore: CoreRootStore) {
        super(rootStore);
        makeObservable(this, currentUserStoreObservables);
    }

    getMainRoute(): string {
        return clientRoute.campaigns;
    }

    getRolePriorities(): RolePriority {
        return rolePriorities;
    }

    getLoginRedirectByRole(): RedirectByRoleType {
        return loginRedirectByRole;
    }
}

export const getCurrentUserStore = (): any => {
    const [_CurrentUserStore] = di([CurrentUserStore], getCurrentUserStore);
    return _CurrentUserStore;
};

import { createContext } from 'react';
import { RequestFormSectionSettingModel } from '../models';

export type CampaignSettingsSectionSettingContextType = {
    rfId: string;
    sectionSettingId: string;
    isCreatePage: boolean;
    isEditPage: boolean;
    sectionSettingModel: RequestFormSectionSettingModel;
};

export const CampaignSettingsSectionSettingContext = createContext<CampaignSettingsSectionSettingContextType>({
    rfId: '',
    sectionSettingId: '',
    isCreatePage: false,
    isEditPage: false,
    sectionSettingModel: {} as RequestFormSectionSettingModel,
});

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton, Link, SxProps, Theme } from '@mui/material';
import { DraggableItem, MovableTableCell, RenderDraggableListBody } from '@platform/front-ui';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { useCampaignSettingsListContext, useStore } from '../../../../../hooks';
import {
    CampaignRequestFormSectionSettingsRouteParams,
    CampaignSettingsEntities,
    RequestSectionSettingsListDTO,
} from '../../../../../types';
import { CampaignSettingsItemDelete as CampaignSettingsItemDeleteInj } from '../components';
import { getSectionSettingEditRoute } from './getSectionSettingEditRoute';

const formTitleTdSx: SxProps<Theme> = {
    // todo: паддинг из темы
    paddingX: 1.25,
    paddingY: 1.5,
};

export const renderSectionSettingsBody: RenderDraggableListBody<RequestSectionSettingsListDTO> = (
    props,
): JSX.Element => {
    const [CampaignSettingsItemDelete] = di([CampaignSettingsItemDeleteInj], renderSectionSettingsBody);
    const { listItems, draggableId } = props;
    const { campaignsStore, env } = useStore();
    const { formServiceUrl } = env;
    const { setShouldLoadListItems } = useCampaignSettingsListContext();
    const params = useParams<CampaignRequestFormSectionSettingsRouteParams>();

    const listItemsComponents = listItems.map((item, index) => {
        const sectionSettingId = item.id;
        const paramsFull = { ...params, sectionSettingId };
        const itemEditPath = getSectionSettingEditRoute(params, sectionSettingId);
        const itemCardPath = generatePath(clientRoute.campaignSettingsRequestFormSectionSettingInfo, paramsFull);

        const deleteItem = (): Promise<void> => {
            return campaignsStore.deleteSectionSetting(paramsFull).then(setShouldLoadListItems);
        };

        const renderDeleteBtn = (): JSX.Element => (
            <CampaignSettingsItemDelete
                itemToDelete={item}
                itemType={CampaignSettingsEntities.SectionSetting}
                deleteItem={deleteItem}
            >
                <IconButton color="secondary">
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </CampaignSettingsItemDelete>
        );

        const { formInfo } = item;

        return (
            <DraggableItem
                key={sectionSettingId}
                index={index}
                item={item}
                draggableId={draggableId}
                editPath={itemEditPath}
                cardPath={itemCardPath}
                renderDeleteBtn={renderDeleteBtn}
            >
                <MovableTableCell size="small" sx={formTitleTdSx} maxWidth="39%">
                    {formServiceUrl && formInfo && (
                        <Link
                            component="a"
                            href={clientRoute.formVersionLink(formServiceUrl, formInfo.id)}
                            target="_blank"
                        >
                            {formInfo.title}
                        </Link>
                    )}
                </MovableTableCell>
            </DraggableItem>
        );
    });

    return <React.Fragment>{listItemsComponents}</React.Fragment>;
};

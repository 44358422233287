import { approval_en } from '@platform/approval';
import { commentsLocalesEn } from '@platform/comments';
import { crypto_en } from '@platform/crypto-ui';
import { formio_en } from '@platform/formiojs-react';
import { forms_en } from '@platform/forms';
import { recordToLang } from '@platform/front-utils';
import { multiLangFieldEn } from '@platform/multi-lang-field';
import { multilevelCatalog_en } from '@platform/multilevel-catalog';
import { ttable_en } from '@platform/ttable';
import breadcrumbs from './breadcrumbs';
import campaign from './campaign';
import campaignRequest from './campaignRequest';
import campaignSettings from './campaignSettings';
import common from './common';
import form from './form';
import homePage from './homePage';
import { kafkaEvents } from './kafkaEvents';
import printForms from './printForms';
import { relatedObjectsEn } from './relatedObjects';
import startPage from './startPage';
import { syslogEn } from './syslog';
import { userEn } from './user';

const lang: Record<string, string> = recordToLang(
    {
        productTitle: 'TOT X',
        crypto: crypto_en,
        formio: formio_en,
        forms: forms_en,
        ttable: ttable_en,
        approval: approval_en,
        multiLangField: multiLangFieldEn,
        startPage,
        homePage,
        campaign,
        campaignSettings,
        campaignRequest,
        common,
        form,
        breadcrumbs,
        printForms,
        multilevelCatalog: multilevelCatalog_en,
        comments: commentsLocalesEn,
        kafkaEvents,
        user: userEn,
        syslog: syslogEn,
        relatedObjects: relatedObjectsEn,
    },
    false,
);

export default lang;

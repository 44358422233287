import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { LinkButton } from '@platform/front-ui';
import { VoidFunction } from '@platform/front-utils';
import React from 'react';

export type RequestFormCardTitleWithButtonProps = {
    title: string;
    buttonTitle: string;
    onClickButton?: VoidFunction;
};

const requestFormCardTitleWithButtonSx: SxProps<Theme> = {
    whiteSpace: 'nowrap',
};

export const RequestFormCardTitleWithButton = (props: RequestFormCardTitleWithButtonProps): JSX.Element => {
    const { title, buttonTitle, onClickButton } = props;
    return (
        <Grid container justifyContent="space-between" alignItems="baseline" flexWrap="nowrap">
            <Grid item>
                <Typography variant="button">{title}</Typography>
            </Grid>
            <Grid item>
                <LinkButton color="secondary" onClick={onClickButton} sx={requestFormCardTitleWithButtonSx}>
                    {buttonTitle}
                </LinkButton>
            </Grid>
        </Grid>
    );
};

import { createContext } from 'react';

export type CampaignSettingsPageContextType = {
    isCampaignCreatePage: boolean;
    isCampaignSettingsRequestFormCreatePage: boolean;
    isCampaignSettingsRequestFormEditPage: boolean;
    isCampaignSettingsRequestFormEditingForm: boolean;
};

export const CampaignSettingsPageContext = createContext<CampaignSettingsPageContextType>({
    isCampaignCreatePage: false,
    isCampaignSettingsRequestFormCreatePage: false,
    isCampaignSettingsRequestFormEditPage: false,
    isCampaignSettingsRequestFormEditingForm: false,
});

import { FileDTO } from '@platform/front-types';
import { DateUtils } from '@platform/front-utils';
import { isAfter } from 'date-fns';
import { action, computed, makeObservable, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { CampaignInfoDTO } from '../types';

export const CampaignInfoModelProps = {
    id: observable,
    title: observable,
    from: observable,
    to: observable,
    annotation: observable,
    files: observable,
    load: action.bound,
    isCollectionCompleted: computed,
    avatarUrl: computed,
    campaignNotStarted: computed,
};

export class CampaignInfoModel {
    private readonly apiUrl?: string;
    private avatarId?: string;

    id = '';
    title = '';
    from?: Date;
    to?: Date;
    annotation = '';
    files: Array<FileDTO> = [];

    constructor(apiUrl?: string) {
        makeObservable(this, CampaignInfoModelProps);
        this.apiUrl = apiUrl;
    }

    load(dto: CampaignInfoDTO): void {
        this.id = dto.id;
        this.title = dto.title;
        this.avatarId = dto.avatarId;
        this.annotation = dto.annotation || '';
        this.files = dto.files;

        if (dto.from) {
            const from = new Date(dto.from);
            if (DateUtils.isValidDate(from)) {
                this.from = from;
            }
        }

        if (dto.to) {
            const to = new Date(dto.to);
            if (DateUtils.isValidDate(to)) {
                this.to = to;
            }
        }
    }

    get isCollectionCompleted() {
        return this.to && isAfter(new Date(), this.to);
    }

    get avatarUrl(): string | undefined {
        if (this.apiUrl && this.avatarId) {
            return `${this.apiUrl}${apiConfigs.campaignFileUrl(this.avatarId)}`;
        }
        return;
    }

    get campaignNotStarted(): boolean {
        if (this.from) {
            const now = new Date();
            return this.from > now;
        }
        return true;
    }
}

import { AuthorizationCheck, handleMainInfoError, useLocale } from '@platform/front-core';
import { TTable, TTableVisibilitySettings } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { entities, permissions } from '../../authSchemeConfig';
import { ttableDefaultPaths } from '../../constants';
import { useStore } from '../../hooks';
import { RegistryCode } from '../../types';

const visibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithDownload: false,
    },
};

export const KafkaEventsRegistry = observer((): JSX.Element => {
    const { coreRootStore, userStore } = useStore();
    const { api, catalogStore, history } = coreRootStore;
    const { userRoleList } = userStore;
    const [tableTitle] = useLocale('kafkaEvents.title');

    return (
        <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.Administration}>
            {(allowed: boolean): JSX.Element => (
                <TTable
                    registryCode={RegistryCode.kafkaEvent}
                    tableTitle={tableTitle}
                    client={api.client}
                    fetchUserRoleList={userRoleList}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    isAdmin={allowed}
                    visibleSettings={visibleSettings}
                    handleMainInfoError={handleMainInfoError(history)}
                    defaultPath={ttableDefaultPaths.kafkaEvents}
                />
            )}
        </AuthorizationCheck>
    );
});

import { LocalesTree } from '@platform/front-utils';

const startPage: LocalesTree = {
    title: 'MineTech Digital Platform',
    productDescription:
        'A solution for the search, selection and intensive development of the best production and digital technologies',
    logoAlt: 'Skolkovo logo',
};

export default startPage;

import { Grid, List, ListItem, SxProps, Theme, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useCampaignPageContext } from '../../../../../../hooks';
import { CampaignCardDescriptionCommonInfoFileCard as CampaignCardDescriptionCommonInfoFileCardInj } from './CampaignCardDescriptionCommonInfoFileCard';

const annotationSx: SxProps<Theme> = {
    '& p': {
        margin: 0,
    },
};

const listItemSx: SxProps<Theme> = {
    paddingTop: 0,
    paddingBottom: 0,
};

export const CampaignCardDescriptionCommonInfoTab = observer((): JSX.Element => {
    const [CampaignCardDescriptionCommonInfoFileCard] = di(
        [CampaignCardDescriptionCommonInfoFileCardInj],
        CampaignCardDescriptionCommonInfoTab,
    );

    const { model } = useCampaignPageContext();
    const { annotation, files } = model;

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="body1" sx={annotationSx}>
                    {parse(annotation)}
                </Typography>
            </Grid>
            {!!files.length && (
                <Grid item>
                    <Typography variant="button">
                        <FormattedMessage id="campaign.files" />
                    </Typography>
                    <List>
                        {files.map((file) => (
                            <ListItem key={file.id} disableGutters sx={listItemSx}>
                                <CampaignCardDescriptionCommonInfoFileCard file={file} />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            )}
        </Grid>
    );
});

import { CoreRootStore } from '@platform/front-core';
import { AuthorizationCheckQueryOrWithoutCheck } from '@platform/front-types';
import { ActionMenuItem, MenuButton } from '@platform/front-ui';
import { useCoreStore, useFlag, VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserPageRouteParams } from '../../../UserPage';
import { UserPageFullNameModal, UserPageLoginModal, UserPageRolesModal } from './components';

export const UserPageHeaderActions = observer((): JSX.Element => {
    const [resultOfQueries, setResultOfQueries] = useState<boolean[]>([]);
    const { authorizationStore, permissionsStore } = useCoreStore() as CoreRootStore;
    const { systemConfig, accountConfig } = permissionsStore;
    const { id } = useParams<UserPageRouteParams>();

    const queryToCheckIsAdmin = systemConfig.userSetting();
    const queryToCheckIsCanChangeRole = accountConfig.changeUserStatus(id);
    const queries: AuthorizationCheckQueryOrWithoutCheck[] = [queryToCheckIsAdmin, queryToCheckIsCanChangeRole];

    useEffect(() => {
        authorizationStore.checkAll(queries).then(setResultOfQueries);
    }, [id]);

    const [isAdmin, isCanChangeRole] = resultOfQueries;

    const [isFullNameDialogOpen, openFullNameDialog, closeFullNameDialog] = useFlag();
    const [isLoginDialogOpen, openLoginDialog, closeLoginDialog] = useFlag();
    const [isRolesDialogOpen, openRolesDialog, closeRolesDialog] = useFlag();

    const renderMenuItems = useCallback(
        (hideMenu?: VoidFunction): ReactNode[] => {
            return [
                <ActionMenuItem
                    key="editFullName"
                    messageId="user.settings.fullName"
                    hideMenu={hideMenu}
                    onClick={openFullNameDialog}
                />,
                isAdmin && (
                    <ActionMenuItem
                        key="editLogin"
                        messageId="user.settings.login"
                        hideMenu={hideMenu}
                        onClick={openLoginDialog}
                    />
                ),
                isCanChangeRole && (
                    <ActionMenuItem
                        key="editFullRoles"
                        messageId="user.settings.roles"
                        hideMenu={hideMenu}
                        onClick={openRolesDialog}
                    />
                ),
            ];
        },
        [isAdmin, openFullNameDialog, openLoginDialog, openRolesDialog],
    );

    return (
        <React.Fragment>
            <MenuButton renderMenuItems={renderMenuItems} />
            <UserPageFullNameModal open={isFullNameDialogOpen} onCancel={closeFullNameDialog} />
            <UserPageLoginModal open={isLoginDialogOpen} onCancel={closeLoginDialog} />
            <UserPageRolesModal open={isRolesDialogOpen} onCancel={closeRolesDialog} />
        </React.Fragment>
    );
});

import { TransitionsModel } from '@platform/front-ui';
import { createContext } from 'react';
import { RequestModel } from '../models';

export type CampaignRequestPageContextType = {
    model: RequestModel;
    isFormView: boolean;
    isRequestEdit: boolean;
    isSyslog: boolean;
    campaignRequestTransitionsModel: TransitionsModel;
};

export const CampaignRequestPageContext = createContext<CampaignRequestPageContextType>({
    model: {} as RequestModel,
    isFormView: true,
    isRequestEdit: false,
    isSyslog: false,
    campaignRequestTransitionsModel: {} as TransitionsModel,
});

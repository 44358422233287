import { Grid, Link } from '@mui/material';
import { CommonInfoLine } from '@platform/front-ui';
import { getOrElse } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useCampaignRequestPageContext } from '../../../../../../hooks';
import { formatDateAndTime } from '../../../../../../utils';

export const CampaignRequestInfoService = observer((): JSX.Element => {
    const { formatMessage } = useIntl();
    const { model } = useCampaignRequestPageContext();
    const { loadRequestServiceInfo, formInfoDTO, lastModified, lifeCycle } = model;

    useEffect(() => {
        loadRequestServiceInfo();
    }, [loadRequestServiceInfo]);

    const lifecycleLink = (
        <Link href={lifeCycle.url} target="_blank" component="a">
            {lifeCycle.label}
        </Link>
    );

    const lastIndexOfForms = formInfoDTO.length - 1;
    const forms = formInfoDTO.map((form, index) => {
        return (
            <React.Fragment>
                <Link href={form.url} target="_blank" component="a">
                    {form.label}
                </Link>
                {index !== lastIndexOfForms && ','}
            </React.Fragment>
        );
    });

    const lastModifiedValue = getOrElse(formatDateAndTime(lastModified));

    return (
        <Grid container flexDirection="column" justifyContent="start" alignItems="start" flexWrap="nowrap" spacing={1}>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.lifecycle' })}
                    value={lifecycleLink}
                />
            </Grid>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignSettings.requestFormSettings.formTitle' })}
                    value={forms}
                />
            </Grid>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.lastModified' })}
                    value={lastModifiedValue}
                />
            </Grid>
        </Grid>
    );
});

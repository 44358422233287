import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from '@mui/material';
import { DraggableItem, RenderDraggableListBody } from '@platform/front-ui';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { useStore } from '../../../../../hooks';
import {
    CampaignRequestFormDTO,
    CampaignRequestFormSectionSettingsRouteParams,
    CampaignSettingsEntities,
} from '../../../../../types';
import { CampaignSettingsItemDelete as CampaignSettingsItemDeleteInj } from '../components';

export const renderRequestFormsBody: RenderDraggableListBody<CampaignRequestFormDTO> = (props): JSX.Element => {
    const [CampaignSettingsItemDelete] = di([CampaignSettingsItemDeleteInj], renderRequestFormsBody);
    const { listItems, draggableId } = props;
    const { campaignsStore } = useStore();

    const params = useParams<CampaignRequestFormSectionSettingsRouteParams>();

    return (
        <React.Fragment>
            {listItems.map((requestForm, index) => {
                const rfId = requestForm.id;
                const paramsFull = { id: params.id, rfId };
                const requestFormEditPath = generatePath(clientRoute.campaignSettingsRequestFormEdit, paramsFull);
                const requestFormCardPath = generatePath(clientRoute.campaignSettingsRequestFormInfo, paramsFull);
                const deleteItem = (): Promise<void> => campaignsStore.deleteCampaignRequestForm(paramsFull);

                const renderDeleteBtn = (): JSX.Element => (
                    <CampaignSettingsItemDelete
                        itemToDelete={requestForm}
                        itemType={CampaignSettingsEntities.CampaignRequestForm}
                        deleteItem={deleteItem}
                    >
                        <IconButton color="secondary">
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </CampaignSettingsItemDelete>
                );

                return (
                    <DraggableItem
                        index={index}
                        item={requestForm}
                        draggableId={draggableId}
                        editPath={requestFormEditPath}
                        cardPath={requestFormCardPath}
                        renderDeleteBtn={renderDeleteBtn}
                    />
                );
            })}
        </React.Fragment>
    );
};

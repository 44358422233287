import { Link } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { VoidFunction } from '@platform/front-utils';
import { TTable, TTableRowActions, TTableVisibilitySettings } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { CellProps } from 'react-table';
import { CreateRequestDialog as CreateRequestDialogInj } from '../..';
import { entities, permissions } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { ttableDefaultPaths } from '../../../../constants';
import { useCreateRequestDialog, useStore } from '../../../../hooks';
import { CampaignTableRow, RegistryCode } from '../../../../types';
import { CampaignListRegistryActionsBtns as CampaignListRegistryActionsBtnsInj } from './CampaignListRegistryActionsBtns';

const campaignListRegistryVisibleSettings: TTableVisibilitySettings = {
    toolbar: {
        isWithPresets: false,
        isWithAddCustomColumns: false,
        isWithFilters: false,
        isWithDownload: false,
    },
    column: {
        isWithDraggableColumns: false,
    },
};

export const CampaignListRegistry = observer((): JSX.Element => {
    const [CampaignListRegistryActionsBtns] = di([CampaignListRegistryActionsBtnsInj], CampaignListRegistry);
    const [CreateRequestDialog] = di([CreateRequestDialogInj], CampaignListRegistry);

    const { coreRootStore, userStore } = useStore();
    const { formatMessage } = useIntl();
    const { catalogStore, api } = coreRootStore;
    const { isCreateRequestDialogOpen, openCreateRequestDialog, closeCreateRequestDialog } = useCreateRequestDialog();

    const setRowActions: TTableRowActions<CampaignTableRow> = useCallback(
        (
            row: CampaignTableRow,
            reloadData: VoidFunction,
            toggleIsRowBlocked: VoidFunction,
            rowErrorHandler: VoidFunction,
        ): JSX.Element => {
            return (
                <CampaignListRegistryActionsBtns
                    row={row}
                    reloadData={reloadData}
                    toggleIsRowBlocked={toggleIsRowBlocked}
                    rowErrorHandler={rowErrorHandler}
                    openCreateRequestDialog={openCreateRequestDialog}
                />
            );
        },
        [],
    );

    const cellFormatters = useRef({
        'customData.title': function title(p: CellProps<CampaignTableRow>): JSX.Element {
            const cellValue = p.row.original;
            return (
                <Link component={RouterLink} to={generatePath(clientRoute.campaign, { id: cellValue.id })}>
                    {cellValue.customData.title}
                </Link>
            );
        },
    });

    const tableTitle = formatMessage({ id: 'campaign.titleCampaignListPage' });

    return (
        <React.Fragment>
            <CreateRequestDialog onCancel={closeCreateRequestDialog} open={isCreateRequestDialogOpen} />
            <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.Administration}>
                {(allowed): JSX.Element => (
                    <TTable<CampaignTableRow>
                        tableTitle={tableTitle}
                        client={api.client}
                        registryCode={RegistryCode.campaign}
                        fetchCatalog={catalogStore.getCatalog}
                        fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                        fetchUserRoleList={userStore.userRoleList}
                        visibleSettings={campaignListRegistryVisibleSettings}
                        rowActions={setRowActions}
                        cellFormatters={cellFormatters.current}
                        isAdmin={allowed}
                        defaultPath={ttableDefaultPaths.campaigns}
                    />
                )}
            </AuthorizationCheck>
        </React.Fragment>
    );
});

import { Grid, SxProps, Theme } from '@mui/material';
import { RoutedTabs, RouteSetting } from '@platform/front-core';
import { grey } from '@platform/front-ui';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { commonTabsProps, sxHeight100 } from '../../../../../constants';
import { TabData } from '../../../../../types';
import { CampaignCardDescriptionCommonInfoTab as CampaignCardDescriptionCommonInfoTabInj } from './campaign-card-description-common-info';
import { CampaignCardDescriptionRequestForms as CampaignCardDescriptionRequestFormsInj } from './campaign-card-description-request-forms';

const commonInfoSx: SxProps<Theme> = {
    ...sxHeight100,
    padding: 2,
    overflowY: 'auto',
};

const tabsWrapperSx: SxProps<Theme> = {
    borderBottom: `1px solid  ${grey[300]}`,
    paddingTop: 1,
    paddingRight: 2,
    paddingLeft: 2,
};

export const getCampaignCardBodyDescriptionTabsInj = (): TabData[] => [
    {
        title: <FormattedMessage id="campaign.commonInfo" />,
        path: clientRoute.campaignDescriptionCommonInfo,
    },
    {
        title: <FormattedMessage id="campaignSettings.campaignRequestForms" />,
        path: clientRoute.campaignDescriptionRequestForms,
    },
];

export const CampaignCardBodyTabs = observer((): JSX.Element => {
    const [CampaignCardDescriptionCommonInfoTab] = di([CampaignCardDescriptionCommonInfoTabInj], CampaignCardBodyTabs);
    const [CampaignCardDescriptionRequestForms] = di([CampaignCardDescriptionRequestFormsInj], CampaignCardBodyTabs);
    const [getCampaignCardBodyDescriptionTabs] = di([getCampaignCardBodyDescriptionTabsInj], CampaignCardBodyTabs);

    const { id } = useParams<RouteParamsDefault>();

    const settings: RouteSetting[] = getCampaignCardBodyDescriptionTabs().map((tab) => {
        const { title, path } = tab;
        return {
            tab: path,
            title: title,
            path: generatePath(path, { id }),
        };
    });

    return (
        <Grid container direction="column" flexWrap="nowrap" sx={sxHeight100}>
            <Grid item sx={tabsWrapperSx}>
                <RoutedTabs tabsProps={commonTabsProps} settings={settings} />
            </Grid>
            <Grid item sx={commonInfoSx}>
                <Route path={clientRoute.campaignDescriptionCommonInfo}>
                    <CampaignCardDescriptionCommonInfoTab />
                </Route>
                <Route path={clientRoute.campaignDescriptionRequestForms}>
                    <CampaignCardDescriptionRequestForms />
                </Route>
            </Grid>
        </Grid>
    );
});

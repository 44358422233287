import { Warning } from '@mui/icons-material';
import { Box, Grid, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useCampaignRequestPageContext } from '../../../../../hooks';
import { RequestSubSectionListDTO } from '../../../../../types';
import { campaignRequestSectionSxStyles } from './sxStyles';

export type CampaignRequestSubSectionContentProps = {
    subSection: RequestSubSectionListDTO;
};

export const CampaignRequestSubSectionContent = observer((props: CampaignRequestSubSectionContentProps) => {
    const { subSection } = props;

    const { model } = useCampaignRequestPageContext();
    const { errorsByTabs } = model.errorsModel;

    const { id: sectionId, title } = subSection;
    const errors = errorsByTabs[sectionId];
    const sectionError = errors && errors.getGeneralMessage();
    const errorTitle = <Box whiteSpace="pre-line">{sectionError}</Box>;

    return (
        <Grid container direction="row" alignItems="center" spacing={0.5}>
            <Grid item>{title}</Grid>
            {sectionError && (
                <Grid item>
                    <Tooltip title={errorTitle}>
                        <Warning sx={campaignRequestSectionSxStyles.warningIcon} />
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    );
});

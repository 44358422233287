import { Theme } from '@mui/material';
import { withFunctionCallComponents } from '@platform/formio';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { Formio } from '../types';
import { RootStore } from './RootStore';

export const formioStoreObservables = {
    rootStore: observable,
    apiUrl: observable,
    windowWithFormio: observable,
    addComponents: action.bound,
    addMultilevelCatalog: action.bound,
    addComponentsWithMaterialTheme: action.bound,
    addStaticProperties: action.bound,
};

export class FormioStore {
    private rootStore: RootStore;
    private readonly apiUrl?: string;
    private windowWithFormio = window as unknown as { Formio: Formio };

    constructor(rootStore: RootStore) {
        makeObservable(this, formioStoreObservables);
        this.rootStore = rootStore;
        this.apiUrl = rootStore.env.apiUrl;
    }

    addComponents(): void {
        this.addStaticProperties();

        this.windowWithFormio.Formio.Components.addComponent(
            'select',
            withFunctionCallComponents.select({ baseUrl: this.apiUrl, catalogsUrl: this.apiUrl }),
        );

        this.addComponentsWithMaterialTheme();
        this.addMultilevelCatalog();
    }

    addMultilevelCatalog(): void {
        const multilevelCatalogProps = {
            client: this.rootStore.coreRootStore.api.client,
            intlStore: this.rootStore.coreRootStore.intlStore,
            theme: this.rootStore.theme,
        };
        this.windowWithFormio.Formio.Components.addComponent(
            'multilevelCatalog',
            withFunctionCallComponents.multilevelCatalog(multilevelCatalogProps),
        );
    }

    addComponentsWithMaterialTheme(): void {
        const fileProps = {
            baseUrl: this.apiUrl,
            catalogsUrl: this.apiUrl,
            materialUiTheme: this.rootStore.theme,
            intlStore: this.rootStore.coreRootStore.intlStore,
            signatureStore: this.rootStore.formioSignatureStore,
        };
        this.windowWithFormio.Formio.Components.addComponent('file', withFunctionCallComponents.styledFile(fileProps));
        this.windowWithFormio.Formio.Components.addComponent(
            'categoryFile',
            withFunctionCallComponents.categoryFile(fileProps),
        );
        this.windowWithFormio.Formio.Components.addComponent(
            'categoryFileWithImageCropper',
            withFunctionCallComponents.categoryFileWithImageCropper(fileProps),
        );

        const organizationProps = {
            rootStore: this.rootStore.coreRootStore,
        };
        this.windowWithFormio.Formio.Components.addComponent(
            'organization',
            withFunctionCallComponents.organization(organizationProps),
        );
    }

    addStaticProperties(): void {
        this.windowWithFormio.Formio.setTheme(this.rootStore.theme as Theme);
    }
}

export const getFormioStore = (): any => {
    const [_FormioStore] = di([FormioStore], getFormioStore);
    return _FormioStore;
};

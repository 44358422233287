import { Box, Container, Grid } from '@mui/material';
import { registryPageStyles, useLocale } from '@platform/front-core';
import { Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { UserTableRow } from '../../../types';
import {
    UserRegistryActionsBtns as UserRegistryActionsBtnsInj,
    UserRegistryTable as UserRegistryTableInj,
} from './components';

export type UserRegistryPageProps = {
    tableTitle?: JSX.Element | string;
    fileTitle?: string;
    createButton?: JSX.Element;
    registryCode?: string;
};

export type UserRegistrySetTableRowActionBtnsType = (
    row: UserTableRow,
    reloadData: VoidFunction,
    toggleIsRowBlocked: VoidFunction,
    rowErrorHandler: VoidFunction,
) => JSX.Element;

export const userRegistrySetTableRowActionBtnsInj: UserRegistrySetTableRowActionBtnsType = (
    row,
    reloadData,
    toggleIsRowBlocked,
    rowErrorHandler,
) => {
    const [UserRegistryActionsBtns] = di([UserRegistryActionsBtnsInj], UserRegistryPage);

    return (
        <UserRegistryActionsBtns
            userTableRow={row}
            reloadData={reloadData}
            toggleIsRowBlocked={toggleIsRowBlocked}
            rowErrorHandler={rowErrorHandler}
        />
    );
};

export const UserRegistryPage = observer((props: UserRegistryPageProps): JSX.Element => {
    const [UserRegistryTable] = di([UserRegistryTableInj], UserRegistryPage);
    const [userRegistrySetTableRowActionBtns] = di([userRegistrySetTableRowActionBtnsInj], UserRegistryPage);

    const defaultTitle = useLocale('user.plural.nominative')[0];
    const { tableTitle = defaultTitle, fileTitle = defaultTitle, createButton, registryCode } = props;

    const registryTitle = (
        <Typography variant="subtitle2" component="h1">
            {tableTitle}
        </Typography>
    );

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <Box pt={2}>
                <Grid container spacing={2} flexDirection="column">
                    <Grid item>
                        {createButton ? (
                            <Grid container justifyContent="space-between">
                                <Grid item>{registryTitle}</Grid>
                                <Grid item>{createButton}</Grid>
                            </Grid>
                        ) : (
                            registryTitle
                        )}
                    </Grid>
                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <UserRegistryTable
                            registryCode={registryCode}
                            setRowActions={userRegistrySetTableRowActionBtns}
                            tableTitle={fileTitle}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
});

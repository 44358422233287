import { CollaborationFormModel } from '@platform/formiojs-react';
import { voidFunction } from '@platform/front-utils';
import { createContext } from 'react';

export type CampaignRequestFormContextType = {
    collaborationFormModel: CollaborationFormModel;
    goToView: VoidFunction;
};

export const CampaignRequestFormContext = createContext<CampaignRequestFormContextType>({
    collaborationFormModel: {} as CollaborationFormModel,
    goToView: voidFunction,
});

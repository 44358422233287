import { LocalesTree } from '@platform/front-utils';

const homePage: LocalesTree = {
    headerLinks: {
        requests: 'Заявки',
        campaigns: 'Категории',
        users: 'Пользователи',
        console: 'Консоль',
    },
};

export default homePage;

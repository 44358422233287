import { yellow } from '@mui/material/colors';
import { blue, green, grey, red } from '@platform/front-ui';
export var collaborationFormUserColors = [
    yellow[500],
    yellow[800],
    blue[500],
    blue[800],
    green[500],
    green[800],
    red[400],
    red[700],
    grey[500],
    grey[800],
];
export var collaborationFormUserColorsCount = collaborationFormUserColors.length;
export var collaborationFieldBlurDelay = 200;

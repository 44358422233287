import { Dialog, DialogContent } from '@mui/material';
import { ActionItemModalProps, CodeTitle } from '@platform/front-types';
import {
    AutocompleteField,
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    DialogTitle,
    FieldLabel,
} from '@platform/front-ui';
import {
    disableSubmitOnEnterKeyPress,
    ServerErrorsModel,
    stopPropagation,
    useAntiDoubleClick,
    useYup,
} from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStore, useUserPageContext } from '../../../../../../../hooks';
import { RolesFields, RolesSettings } from '../../../../../../../types';

export const UserPageRolesModal = observer((props: ActionItemModalProps): JSX.Element => {
    const { open, onCancel, hideMenu } = props;
    const { userStore } = useStore();
    const { locale, formatMessage } = useIntl();
    const serverErrors = useMemo(() => new ServerErrorsModel<RolesFields>(), [ServerErrorsModel]);
    const { dropServerFormErrors, setServerFormErrors, serverFormErrors } = serverErrors;
    const { roles: rolesField } = RolesFields;
    const { userModel } = useUserPageContext();
    const { roles, setRoles } = userModel;
    const [allRoles, setAllRoles] = useState<CodeTitle[]>([]);

    useEffect(() => {
        userStore.userRoleList().then(setAllRoles);
    }, [locale]);

    const onClose = (): void => {
        onCancel();
        dropServerFormErrors();
    };

    const initialValues: RolesSettings = {
        roles,
    };

    const { Yup } = useYup();
    const validationSchema = Yup.object({
        roles: Yup.array().min(1).required(),
    });

    const onSubmit = (values: RolesSettings): Promise<void> => {
        dropServerFormErrors();
        return setRoles(values)
            .then(() => {
                onClose();
                hideMenu && hideMenu();
            })
            .catch(setServerFormErrors);
    };

    const [isSending, endIcon, createHandler] = useAntiDoubleClick(onSubmit);
    const labelMessage = formatMessage({ id: 'user.fields.roles' });
    const label = <FieldLabel required text={labelMessage} />;

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onClose,
    };

    const submitButtonMessage = formatMessage({ id: 'common.save' });
    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        disabled: isSending,
        endIcon,
        text: submitButtonMessage,
    };

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={open} onKeyDown={stopPropagation}>
            <DialogTitle onCloseClick={onClose}>
                <FormattedMessage id="user.settings.rolesTitle" />
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={createHandler}
                enableReinitialize={true}
            >
                <Form onKeyDown={disableSubmitOnEnterKeyPress}>
                    <DialogContent>
                        <AutocompleteField
                            fieldName={rolesField}
                            label={label}
                            options={allRoles}
                            multiple={true}
                            serverError={serverFormErrors[rolesField]}
                        />
                    </DialogContent>

                    <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                </Form>
            </Formik>
        </Dialog>
    );
});

import { RelatedObjectsMenuItemInfo } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { permissionsConfig } from '../../../../authSchemeConfig';
import { CampaignRequestRow, RelationEntity } from '../../../../types';

export type CampaignRequestListRelatedObjectsItemProps = {
    campaignRequestRow: CampaignRequestRow;
    isAllowSaveRelation: boolean;
    isAllowDeleteRelations: boolean;
};

export const CampaignRequestListRelatedObjectsItem = observer(
    (props: CampaignRequestListRelatedObjectsItemProps): JSX.Element => {
        const { campaignRequestRow, isAllowSaveRelation, isAllowDeleteRelations } = props;
        const { formatMessage } = useIntl();
        const { id, customData } = campaignRequestRow;
        const { number } = customData;

        const deleteEntityRelationTitle = formatMessage(
            { id: 'relations.deleteRelationMessage' },
            { entity: formatMessage({ id: 'campaignRequest.requests' }) },
        );

        const deleteRelation = useMemo(() => permissionsConfig.deleteRelation(id), [id]);

        return (
            <RelatedObjectsMenuItemInfo
                entity={RelationEntity.request}
                entityId={id}
                addDialogTitle={formatMessage({ id: 'relatedObjects.addingRelation' })}
                listDialogTitle={formatMessage({ id: 'relatedObjects.relationsList' }, { number: number.title })}
                deleteEntityRelationTitle={deleteEntityRelationTitle}
                deleteRelation={deleteRelation}
                isAllowDeleteRelations={isAllowDeleteRelations}
                isAllowSaveRelation={isAllowSaveRelation}
            />
        );
    },
);

import { AuthorizationCheckQuery } from '@platform/front-types';
import { ActionMenuItem, ConfirmationDialog, KebabButton, MenuButton } from '@platform/front-ui';
import { identity, useFlag, VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { permissionsConfig } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { ICSDataTestIds } from '../../../../constants/data-test-ids';
import { useCampaignRequestPageContext, useFetchAllPermissions, useStore } from '../../../../hooks';
import { CampaignRequestSectionRouteParams } from '../../../../types';

export const CampaignRequestMenuButton = observer((): JSX.Element => {
    const [isModalOpen, openModal, closeModal] = useFlag();
    const { requestStore } = useStore();
    const history = useHistory();
    const { id } = useParams<CampaignRequestSectionRouteParams>();

    const { formatMessage } = useIntl();

    const { model } = useCampaignRequestPageContext();
    const { number, campaign } = model;

    const allPermissionsQueries = useMemo<AuthorizationCheckQuery[]>(() => {
        return [permissionsConfig.deleteCampaignRequest(id)];
    }, [id]);
    const [permissionsData] = useFetchAllPermissions({ allPermissionsQueries });
    const [deleteCampaignRequest] = permissionsData;

    const onDeleteConfirm = async (): Promise<void> => {
        try {
            await requestStore.deleteRequest(id);
            history.replace(generatePath(clientRoute.campaign, { id: campaign.id }));
        } finally {
            closeModal();
        }
    };

    const renderActionItems = useCallback(
        (hideMenu: VoidFunction): ReactNode[] => {
            const onDeleteClick = (): void => {
                hideMenu && hideMenu();
                openModal();
            };

            return [
                <React.Fragment key="actionBtns">
                    {deleteCampaignRequest && <ActionMenuItem messageId="common.delete" onClick={onDeleteClick} />}
                </React.Fragment>,
            ];
        },
        [deleteCampaignRequest],
    );

    const renderMenuButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        const atLeastOneAllowed = permissionsData.some(identity);
        return atLeastOneAllowed ? (
            <KebabButton size="small" onClick={onClick} data-testid={ICSDataTestIds.campaignRequestCardKebabButton} />
        ) : (
            <React.Fragment />
        );
    };

    return (
        <React.Fragment>
            <MenuButton renderButton={renderMenuButton} renderMenuItems={renderActionItems} />
            <ConfirmationDialog
                id="delete-request"
                open={isModalOpen}
                title={formatMessage({
                    id: 'campaignRequest.confirmDeletionTitle',
                })}
                message={formatMessage(
                    {
                        id: 'campaignRequest.confirmDeletionInfoText',
                    },
                    { number },
                )}
                onConfirm={onDeleteConfirm}
                onCancel={closeModal}
                keepMounted
            />
        </React.Fragment>
    );
});

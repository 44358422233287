import { TabContext, TabList } from '@mui/lab';
import { Grid, Tab } from '@mui/material';
import { AuthorizationCheck, ObjectLayoutStyledTabPanel } from '@platform/front-core';
import { SxStyles } from '@platform/front-ui';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { CampaignRequestTabs } from '../../../../../types';
import { CampaignRequestInfoCommon as CampaignRequestInfoCommonInj } from './campaign-request-info-common';
import { CampaignRequestInfoService as CampaignRequestInfoServiceInj } from './campgain-request-info-service';

const sxStyles: SxStyles = {
    fullHeight: {
        height: '100%',
    },
    infoWrapper: {
        position: 'relative',
    },
    infoInner: {
        position: 'absolute',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        top: 0,
        right: 0,
        left: 0,
        padding: (theme) => `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} 0`,
    },
};

export const CampaignRequestInfo = (): JSX.Element => {
    const [CampaignRequestInfoCommon] = di([CampaignRequestInfoCommonInj], CampaignRequestInfo);
    const [CampaignRequestInfoService] = di([CampaignRequestInfoServiceInj], CampaignRequestInfo);

    const { formatMessage } = useIntl();
    const [selectedTab, setSelectedTab] = useState<CampaignRequestTabs>(CampaignRequestTabs.common);

    const handleTabChange = (event: React.SyntheticEvent, newTab: CampaignRequestTabs): void => {
        setSelectedTab(newTab);
    };

    const commonLabel = formatMessage({ id: 'campaignRequest.descriptionPanel.commonTabLabel' });
    const serviceLabel = formatMessage({ id: 'campaignRequest.descriptionPanel.serviceTabLabel' });

    return (
        <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.Administration}>
            {(allowed): JSX.Element =>
                allowed ? (
                    <TabContext value={selectedTab}>
                        <Grid container direction="column" sx={sxStyles.fullHeight}>
                            <Grid item>
                                <TabList onChange={handleTabChange}>
                                    <Tab label={commonLabel} value={CampaignRequestTabs.common} />
                                    <Tab label={serviceLabel} value={CampaignRequestTabs.service} />
                                </TabList>
                            </Grid>

                            <Grid container item direction="column" flexGrow={1} sx={sxStyles.infoWrapper}>
                                <Grid item sx={sxStyles.infoInner}>
                                    <ObjectLayoutStyledTabPanel value={CampaignRequestTabs.common}>
                                        <CampaignRequestInfoCommon />
                                    </ObjectLayoutStyledTabPanel>
                                    <ObjectLayoutStyledTabPanel value={CampaignRequestTabs.service}>
                                        <CampaignRequestInfoService />
                                    </ObjectLayoutStyledTabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabContext>
                ) : (
                    <Grid container direction="column" position="relative" sx={sxStyles.fullHeight}>
                        <Grid item sx={sxStyles.infoInner}>
                            <CampaignRequestInfoCommon />
                        </Grid>
                    </Grid>
                )
            }
        </AuthorizationCheck>
    );
};

import { Box, Grid, Typography } from '@mui/material';
import { FileDownloadLink } from '@platform/front-core';
import { FileDTO } from '@platform/front-types';
import { getOrElse } from '@platform/front-utils';
import React, { ReactNode } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { CampaignRequestFormListMember } from '../../../../../types';
import { requestFormCardSxStyles } from '../requestFormCardSxStyles';
import { RequestFormCardLabelColumn as RequestFormCardLabelColumnInj } from './RequestFormCardLabelColumn';

export type RequestFormCardInfoProps = {
    requestForm: CampaignRequestFormListMember;
    onDownloadFile: (file: FileDTO) => Promise<void>;
};

export const RequestFormCardInfo = (props: RequestFormCardInfoProps): JSX.Element => {
    const [RequestFormCardLabelColumn] = di([RequestFormCardLabelColumnInj], RequestFormCardInfo);

    const { requestForm, onDownloadFile } = props;
    const { regEnd, regStart, allowAfterDeadline, fileInstruction } = requestForm;

    const isInstructionExist = !!fileInstruction.length;
    const fileInstructionsComponents = isInstructionExist ? (
        fileInstruction.map((file) => {
            return (
                <Grid key={file.id} item xs={12}>
                    <FileDownloadLink
                        sx={requestFormCardSxStyles.requestFormInstructionLink}
                        file={file}
                        onDownload={onDownloadFile}
                    />
                </Grid>
            );
        })
    ) : (
        <Grid item xs={12}>
            <Typography variant="body1">
                <FormattedMessage id="campaignRequest.requestFormCard.instructionIsMissing" />
            </Typography>
        </Grid>
    );

    const yesOrNoText = (value: boolean): ReactNode => {
        return getOrElse(value, <FormattedMessage id="common.yes" />, <FormattedMessage id="common.no" />);
    };

    return (
        <Box sx={requestFormCardSxStyles.requestFormInfoContainer}>
            <Grid container>
                <Grid item sx={requestFormCardSxStyles.requestFormColumns} pr={5}>
                    <RequestFormCardLabelColumn id="campaignRequest.requestFormCard.registrationStartDate" />

                    <Box>
                        <Typography variant="body1" noWrap>
                            <FormattedDate
                                day="numeric"
                                month="numeric"
                                year="numeric"
                                hour="numeric"
                                minute="numeric"
                                value={regStart}
                            />
                        </Typography>
                    </Box>

                    <RequestFormCardLabelColumn id="campaignRequest.requestFormCard.registrationEndDate" />

                    <Box>
                        <Typography variant="body1" noWrap>
                            <FormattedDate
                                day="numeric"
                                month="numeric"
                                year="numeric"
                                hour="numeric"
                                minute="numeric"
                                value={regEnd}
                            />
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sx={requestFormCardSxStyles.requestFormColumns}>
                    <Box>
                        <RequestFormCardLabelColumn id="campaignRequest.requestFormCard.afterDeadline" />
                    </Box>
                    <Box>
                        <Typography variant="body1">{yesOrNoText(allowAfterDeadline)}</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item>
                    <RequestFormCardLabelColumn id="campaignRequest.requestFormCard.instruction" />
                </Grid>
                <Grid item>
                    <Grid item container flexDirection="column">
                        {fileInstructionsComponents}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

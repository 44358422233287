import { FullScreenDialog } from '@platform/front-ui';
import { titlesToTitlesDTO } from '@platform/multi-lang-field';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useHistory, useLocation } from 'react-router-dom';
import {
    useCampaignSettingsListContext,
    useCampaignSettingsSectionSettingContext,
    useStore,
} from '../../../../../../../hooks';
import { RequestFormSectionSettingFormValues, SectionSettingDTO } from '../../../../../../../types';
import { CampaignSettingsSectionSettingDialogForm as CampaignSettingsSectionDialogFormInj } from './components';

export const lastRouteRegexp = /(\/[^\/]*)$/g;

/** Попап с формой создания раздела */
export const CampaignSettingsSectionSettingDialog = observer((): JSX.Element => {
    const [CampaignSettingsSectionDialogForm] = di(
        [CampaignSettingsSectionDialogFormInj],
        CampaignSettingsSectionSettingDialog,
    );
    const { rfId, sectionSettingId, isCreatePage, sectionSettingModel } = useCampaignSettingsSectionSettingContext();
    const { setShouldLoadListItems } = useCampaignSettingsListContext();

    const rootStore = useStore();
    const { coreRootStore, campaignsStore } = rootStore;
    const { visibleHeaderHeight } = coreRootStore.headerHeightStore;

    const { sectionSettingFormInitialValues, getSectionSettingFormValidationSchema } = sectionSettingModel;

    const sectionFormValidationSchema = getSectionSettingFormValidationSchema();

    const history = useHistory();
    const location = useLocation();

    const onCancel = (): void => {
        const { pathname } = location;
        const newPath = pathname.replace(lastRouteRegexp, '');
        history.push(newPath);
    };

    const onSubmit = (values: RequestFormSectionSettingFormValues): void => {
        const {
            form,
            executor,
            fileSize,
            parentSectionSetting,
            showComments,
            textEditorSettings,
            titles,
            commentingAllowed,
            ...restValues
        } = values;

        const updatedTextEditorSettings: string[] | undefined =
            textEditorSettings?.length && showComments ? textEditorSettings : undefined;

        const dto: SectionSettingDTO = Object.assign(restValues, {
            showComments,
            formCode: form?.code || '',
            formTitle: form?.title || '',
            fileSize: Number(fileSize),
            executorId: executor?.id,
            parentSectionSettingId: parentSectionSetting?.id,
            commentAttributes: !showComments
                ? undefined
                : {
                      commentingAllowed,
                      textEditorSettings: updatedTextEditorSettings,
                      maxCommentLength: Number(values.maxCommentLength) || undefined,
                      titles: titles ? titlesToTitlesDTO(titles) : undefined,
                  },
        });
        const thenHandler = (): void => {
            setShouldLoadListItems();
            onCancel();
        };
        isCreatePage
            ? campaignsStore.createSectionSetting(rfId, dto).then(thenHandler)
            : campaignsStore.updateSectionSetting(sectionSettingId, dto).then(thenHandler);
    };

    const { formatMessage } = useIntl();
    const dialogTitle = formatMessage({
        id: isCreatePage ? 'campaignSettings.creatingSection' : 'campaignSettings.editingSection',
    });

    return (
        <FullScreenDialog open={true} headerHeight={visibleHeaderHeight} onCancel={onCancel}>
            <Formik
                initialValues={sectionSettingFormInitialValues}
                validationSchema={sectionFormValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                <CampaignSettingsSectionDialogForm
                    sectionSettingModel={sectionSettingModel}
                    onCancel={onCancel}
                    dialogTitle={dialogTitle}
                />
            </Formik>
        </FullScreenDialog>
    );
});

import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { CampaignRequestFormRouteParams } from '../../../../../types';

export const getSectionSettingEditRoute = (params: CampaignRequestFormRouteParams, sectionSettingId: string) => {
    return (
        generatePath(clientRoute.campaignSettingsRequestFormSectionSettingsEdit, params) +
        `?sectionSettingId=${sectionSettingId}`
    );
};

import { AuthorizationCheck } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../../../../../../clientRoute';
import { CustomSettingsEditPermissionFormProps } from '../../../../../../../../../../contexts';
import { useCampaignSettingsSectionSettingContext, useStore } from '../../../../../../../../../../hooks';
import {
    CampaignRequestFormRouteParams,
    NewPermissionDTO,
    PermissionSettingsDTO,
} from '../../../../../../../../../../types';
import { CampaignSettingsAccessPermissionButton as CampaignSettingsAccessPermissionButtonInj } from '../../../../../../components';
import { CampaignRequestFormSectionEditParams } from '../../../../CampaignSettingsSectionSettingRoutes';
import { CustomCommentSettingEditPermissionForm as CustomCommentSettingEditPermissionFormInj } from './CustomCommentsSettingEditPermissionForm';

/** Компонент, группирующий все настройки для кнопки настроек доступа к комментариям. */
export const CampaignSettingsAccessCommentsPermissionButton = observer(() => {
    const [CampaignSettingsAccessPermissionButton] = di(
        [CampaignSettingsAccessPermissionButtonInj],
        CampaignSettingsAccessCommentsPermissionButton,
    );
    const [CustomCommentSettingEditPermissionForm] = di(
        [CustomCommentSettingEditPermissionFormInj],
        CampaignSettingsAccessCommentsPermissionButton,
    );

    const params = useParams<CampaignRequestFormSectionEditParams & CampaignRequestFormRouteParams>();
    const { sectionSettingId } = params;
    const { formatMessage } = useIntl();
    const { campaignsStore } = useStore();
    const { getCommentPermissions, updateCommentPermissions } = campaignsStore;
    const { sectionSettingModel } = useCampaignSettingsSectionSettingContext();
    const { sectionSettingDTO } = sectionSettingModel;
    const { showComments } = sectionSettingDTO;

    const loadPermissions = useCallback((): Promise<PermissionSettingsDTO> => {
        return getCommentPermissions(sectionSettingId);
    }, [sectionSettingId, getCommentPermissions]);

    const savePermissions = useCallback(
        (dto: Partial<NewPermissionDTO>[]): Promise<void> => {
            return updateCommentPermissions(sectionSettingId, dto);
        },
        [sectionSettingId, updateCommentPermissions],
    );

    if (!showComments) {
        // Сделано для избежания бага с промаргиванием поп-апа.
        return <React.Fragment />;
    }

    const accessPermissionRoutePath = generatePath(
        clientRoute.campaignSettingsRequestFormSectionSettingCommentsAccessAddTabParam,
        params,
    );

    const accessPermissionRoutePathTemplate = generatePath(
        clientRoute.campaignSettingsRequestFormSectionSettingCommentsAccessAddTabParam,
        params,
    );

    const editPermissionRoutePath = generatePath(
        clientRoute.campaignSettingsRequestFormSectionSettingCommentsAccessEditTabParam,
        params,
    );

    const editPermissionRoutePathTemplate = generatePath(
        clientRoute.campaignSettingsRequestFormSectionSettingCommentsAccessEditTabParam,
        params,
    );

    const accessPermissionDialogTitle = formatMessage({
        id: 'campaignSettings.commentsSetting.accessSectionSetting',
    });

    const addPermissionDialogTitle = formatMessage({
        id: 'campaignSettings.commentsSetting.addingSectionPermission',
    });

    const editPermissionDialogTitle = formatMessage({
        id: 'campaignSettings.commentsSetting.editingSectionPermission',
    });

    const emptyAccessPermissionList = formatMessage({
        id: 'campaignSettings.commentsSetting.emptyAccessPermissionListSection',
    });

    const checkboxLabel = formatMessage({ id: 'campaignSettings.editPermissions.canComment' });
    const buttonTitle = formatMessage({ id: 'campaignSettings.commentsSetting.setSectionAccess' });

    const getCustomSettingsEditPermissionForm = (props: CustomSettingsEditPermissionFormProps) => (
        <CustomCommentSettingEditPermissionForm {...props} />
    );

    return (
        <AuthorizationCheck
            entityCode={entities.sectionSettings}
            permCode={permissions.sectionSettings.CommentManagement}
            entityId={sectionSettingId}
        >
            <CampaignSettingsAccessPermissionButton
                accessPermissionRoutePath={accessPermissionRoutePath}
                accessPermissionRoutePathTemplate={accessPermissionRoutePathTemplate}
                editPermissionRoutePath={editPermissionRoutePath}
                editPermissionRoutePathTemplate={editPermissionRoutePathTemplate}
                accessPermissionDialogTitle={accessPermissionDialogTitle}
                addPermissionDialogTitle={addPermissionDialogTitle}
                editPermissionDialogTitle={editPermissionDialogTitle}
                emptyAccessPermissionList={emptyAccessPermissionList}
                checkboxLabel={checkboxLabel}
                buttonTitle={buttonTitle}
                loadPermissions={loadPermissions}
                updatePermissions={savePermissions}
                buttonVariant="outlined"
                getCustomSettingsEditPermissionForm={getCustomSettingsEditPermissionForm}
            />
        </AuthorizationCheck>
    );
});

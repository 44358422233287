import { Skeleton } from '@mui/lab';
import { Chip, Grid, GridProps } from '@mui/material';
import { AuthorizationCheck, CoreRootStore, ObjectHeaderLayout, ObjectHeaderLayoutProps } from '@platform/front-core';
import { CommonInfoLine, grey, makeSxStyles, SkeletonGrid, Typography } from '@platform/front-ui';
import { useCoreStore } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserPageContext } from '../../../../../hooks';
import { UserPageHeaderActions } from './user-page-header-actions';
import { UserPageBreadcrumbs } from './UserPageBreadcrumbs';
import { UserPageLoginAsButton } from './UserPageLoginAsButton';
import { UserPageStatusButton } from './UserPageStatusButton';

export type UserPageHeaderProps = {
    breadcrumbs?: JSX.Element;
};

const sxStyles = makeSxStyles({
    firstLine: (theme) => ({
        height: '40px',
        marginBottom: theme.spacing(0.5),
    }),
    fullNameItem: (theme) => ({
        marginRight: theme.spacing(5),
    }),
    login: {
        color: grey[600],
    },
    skeletonList: {
        borderRadius: '16px',
        margin: '3px',
    },
});

const SkeletonListWrapperProps: GridProps = {
    item: true,
};

const commonInfoLineSkeletonProps = {
    width: 70,
    height: 20,
};

export const UserPageHeader = observer((props: UserPageHeaderProps): JSX.Element => {
    const { id, roles, login, status, fullName, isLoading } = useUserPageContext().userModel;
    const { breadcrumbs = <UserPageBreadcrumbs fullName={fullName} /> } = props;
    const { formatMessage } = useIntl();
    const { accountConfig } = (useCoreStore() as CoreRootStore).permissionsStore;
    const loginAsCheckQuery = accountConfig.loginAs(id);

    const commonInfoLineTitle = formatMessage({ id: 'user.fields.status' });

    const titleBlockAndMenu: ObjectHeaderLayoutProps['titleBlockAndMenu'] = {
        menu: {
            element: <UserPageHeaderActions />,
        },
        titleBlock: (
            <Grid container item wrap="nowrap" alignItems="center">
                <Grid item sx={sxStyles.fullNameItem}>
                    <Typography component="h1" variant="subtitle2" isWithoutAutoCapitalize>
                        {isLoading ? <Skeleton width={200} height={20} /> : fullName}
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="subtitle2" sx={sxStyles.login} isWithoutAutoCapitalize>
                        {isLoading ? <Skeleton width={150} height={20} /> : login}
                    </Typography>
                </Grid>
            </Grid>
        ),
    };

    const leftBottomBlock: ObjectHeaderLayoutProps['leftBottomBlock'] = {
        element: (
            <Grid container item spacing={2} wrap="nowrap" alignItems="center">
                <Grid item>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="user.fields.roles" />
                    </Typography>
                </Grid>

                <Grid container item spacing={1}>
                    {isLoading ? (
                        <SkeletonGrid
                            wrapperProps={SkeletonListWrapperProps}
                            itemsCount={2}
                            width={120}
                            height={32}
                            variant="rectangular"
                            sx={sxStyles.skeletonList}
                        />
                    ) : (
                        roles.map((role) => (
                            <Grid item key={role.code}>
                                <Chip label={role.title} variant="outlined" />
                            </Grid>
                        ))
                    )}
                </Grid>
            </Grid>
        ),
    };

    const rightBlocks: ObjectHeaderLayoutProps['rightBlocks'] = {
        topBlock: (
            <Grid container item sx={sxStyles.firstLine} alignItems="center">
                <CommonInfoLine
                    isLoading={isLoading}
                    skeletonProps={commonInfoLineSkeletonProps}
                    title={commonInfoLineTitle}
                    value={status && status.title}
                />
            </Grid>
        ),
        bottomBlock: (
            <Grid container item spacing={0.75} wrap="nowrap">
                <AuthorizationCheck {...loginAsCheckQuery}>
                    <UserPageLoginAsButton />
                </AuthorizationCheck>
                {isLoading ? (
                    <Grid item>
                        <Skeleton width={130} height={32} variant="rectangular" />
                    </Grid>
                ) : (
                    status && <UserPageStatusButton userStatusCode={status.code} />
                )}
            </Grid>
        ),
    };

    return (
        <ObjectHeaderLayout
            breadcrumbs={breadcrumbs}
            titleBlockAndMenu={titleBlockAndMenu}
            leftBottomBlock={leftBottomBlock}
            rightBlocks={rightBlocks}
        />
    );
});

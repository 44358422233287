import { LocalesTree } from '@platform/front-utils';

const campaignRequest: LocalesTree = {
    requests: 'request',
    registryTitle: 'Requests',
    withoutNumber: 'Without a number',
    requestN: 'Request № {number}',

    modalTitle: 'Status change',
    modalInfoText: 'Status will be changed to «{toState}»',
    invalidDateMessage: 'Invalid date format',
    maxDateMessage: 'Date should not be after than the current date',
    confirmDeletionTitle: 'Delete request',
    confirmDeletionInfoText: 'Are you sure you want to delete the request № {number}?',
    resultsOption: 'Results not found',
    readonlyRequestValidationMsg: 'The request form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
    readonlyRequestValidationBtnMsg: 'Go to fix',
    printFormsTable: {
        loading: 'Please, wait, preparation for the generation of printed forms of documents is in progress',
        title: 'Printed forms',
        fileColumnName: 'File',
        authorColumnName: 'Author',
        dateColumnName: 'Date',
        generateAction: 'Generate',
        updateAction: 'Update',
        cannotGenerateError: 'Printed form cannot be generated: request form is filled out incorrectly',
        cannotGenerateErrorWithLink:
            'Printed form cannot be generated: request form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
        generationError: 'An error occurred while generating the print form',
        downloadAllButton: 'Download all',
        taskStatus: 'Generation status',
    },
    taskStatus: {
        generating: 'Generation',
        completed: 'Generated',
        failed: 'Generation failed',
    },
    requestFormCard: {
        description: 'Description',
        registrationStartDate: 'Start',
        registrationEndDate: 'End',
        afterDeadline: 'Reception after deadline',
        resubmission: 'Resubmission', // todo delete https://totsys.atlassian.net/browse/ICS-1419
        instruction: 'Instruction',
        instructionIsMissing: 'Missing',
    },
    dialog: {
        executorsTitle: 'Take request to work',
        executorsMessage: 'You will be appointed as the responsible executor for the request №{ number }',
        editExecutorTitle: 'Appoint a responsible executor',
        changeExecutorTitle: 'Change a responsible executor',
    },
    allFiles: 'All documents for {title}',
    takeToWork: 'Take to Work',
    chooseExecutor: 'Appoint a responsible executor',
    actions: {
        appointExecutor: 'Appoint a responsible executor',
        changeExecutor: 'Change a responsible executor',
        requestToWork: 'Take to work',
    },
    expandWorkArea: 'Expand work area',
    reduceWorkArea: 'Reduce work area',
    deadlines: {
        entity: 'Total deadline',
        state: 'Deadline',
        error: 'Error. Failed to calculate deadline',
        notRelevant: 'Not relevant in this status',
        notRelevantTooltip:
            'The time spent in this status is not taken into account in the total duration of the work. Completion date will be recalculated upon transition to a different status',
    },
    requestCreation: 'Request creation',
    selectRequestFormFromList: 'Select the request form from the list down below',
    selectRequestForm: 'Select the request form in order to create request',

    descriptionPanel: {
        title: 'Request description',
        close: 'Collapse',
        open: 'Expand description',
        commonTabLabel: 'Common',
        serviceTabLabel: 'Service Data',
    },
    fields: {
        number: 'Request number',
        author: 'Request author',
        campaign: 'Campaign',
        requestForm: 'Request form',
        state: 'State',
        created: 'Created',
        sent: 'Sent',
        executor: 'Responsible executor',
        submissionDeadline: 'Submission deadline',
        lifecycle: 'Lifecycle',
        lastModified: 'Last modified',

        notAssigned: 'Not assigned',
        notSubmitted: 'Not yet submitted',
    },

    downloadInstruction: 'Download instruction',
    toDefault: 'Go to the registry without additional settings',

    similarRequests: 'Similar requests',
};

export default campaignRequest;

import { ObjectRelationsPermissionsQueries, RelatedObjectsInfo, RelatedObjectsInfoRoutes } from '@platform/front-core';
import { AuthorizationCheck } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useParams } from 'react-router-dom';
import { permissionsConfig } from '../../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../../clientRoute';
import { useCampaignRequestPageContext } from '../../../../../../hooks';
import { CampaignRequestRouteParams, RelationEntity } from '../../../../../../types';

export const CampaignRequestInfoCommonObjectRelations = observer((): JSX.Element => {
    const { model } = useCampaignRequestPageContext();
    const { id, number } = model;
    const params = useParams<CampaignRequestRouteParams>();
    const { sectionId } = params;
    const intl = useIntl();
    const { formatMessage } = intl;

    // Проверка добавлена, чтобы исключить моргания попапов создания связей
    if (!sectionId) {
        return <React.Fragment />;
    }

    const permissionsQueries: ObjectRelationsPermissionsQueries = useMemo(
        () => ({
            deleteRelations: permissionsConfig.deleteRelations(id),
            saveRelation: permissionsConfig.saveRelation(id),
            deleteRelation: permissionsConfig.deleteRelation(id),
        }),
        [id],
    );

    const routes: RelatedObjectsInfoRoutes = {
        addPath: generatePath(clientRoute.requestSectionAddRelatedObjects, params),
        closePath: generatePath(clientRoute.requestSection, params),
        listPath: generatePath(clientRoute.requestSectionRelatedObjects, params),
    };

    const deleteEntityRelationTitle = formatMessage(
        { id: 'relations.deleteRelationMessage' },
        { entity: formatMessage({ id: 'campaignRequest.requests' }) },
    );

    return (
        <AuthorizationCheck {...permissionsConfig.viewRelations(id)}>
            <RelatedObjectsInfo
                entity={RelationEntity.request}
                entityId={id}
                addDialogTitle={formatMessage({ id: 'relatedObjects.addingRelation' })}
                listDialogTitle={formatMessage({ id: 'relatedObjects.relationsList' }, { number })}
                routes={routes}
                deleteEntityRelationTitle={deleteEntityRelationTitle}
                objectRelationsPermissionsQueries={permissionsQueries}
            />
        </AuthorizationCheck>
    );
});

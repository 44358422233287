import { recordToLang } from '@platform/front-utils';
import common from './common';

const lang: Record<string, string> = recordToLang(
    {
        common,
    },
    false,
);

export default lang;

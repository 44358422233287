import {
    CommentContextInfoDTO,
    CommentsAppConfig,
    CommentsAuthCheckingFunctions,
    CommentsAuthStatuses,
    SpecificCommentConfig,
} from '@platform/comments';
import { useQuery } from '@platform/front-utils';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { permissionsConfig } from '../../authSchemeConfig';
import { clientRoute } from '../../clientRoute';
import { useCampaignRequestPageContext } from '../contexts';
import { useStore } from '../useStore';

export type UseCommentsAppConfigProps = {
    entityId: string;
    entityType: string;
    requestId: string;
};

/**
 * Хук, возвращающий необходимые данные (конфигурацию) для работы приложения комментариев.
 * @prop entityId - Id для распознавания какой-либо конкретной сущности модуля.
 * Например, если требуется авторизация на просмотр какой-либо конкретной заявки или категории,
 * соответсвенно вам потребуется передать её идентификатор.
 * @prop entityType - Код сущности из схемы авторизации на беке.
 * @prop requestId - Id заявки, в которой мы находимся сейчас.
 */
export const useCommentsAppConfig = (props: UseCommentsAppConfigProps): CommentsAppConfig | null => {
    const { entityId, entityType, requestId } = props;
    const { commentsStore, coreRootStore } = useStore();
    const { authorizationStore, currentUserStore } = coreRootStore;
    const { user } = currentUserStore;
    const { id: currentUserId } = user;
    const { locale } = useIntl();
    const { model: requestModel } = useCampaignRequestPageContext();
    const { id: stateId } = requestModel.state;
    const { loadRequest } = requestModel;
    const history = useHistory();
    const queries = useQuery();
    const commentId = queries.get('commentId');

    const [commentsAppConfig, setCommentsAppConfig] = useState<CommentsAppConfig | null>(null);

    /**
     * Проверка авторизации на удаление комментария.
     * @param commentId - Id комментария
     */
    const checkDeletingAuth = (commentId: string): Promise<boolean> => {
        return authorizationStore.check(permissionsConfig.deleteComment(commentId));
    };

    /**
     * Проверка авторизации на редактирование коментария.
     * @param commentId - Id комментария
     */
    const checkEditingAuth = (commentId: string): Promise<boolean> => {
        return authorizationStore.check(permissionsConfig.editComment(commentId));
    };

    /**
     * Проверка авторизации на смену статуса комментария (actual-resolved).
     * @param commentId - Id комментария
     */
    const checkChangeStateAuth = (commentId: string): Promise<boolean> => {
        return authorizationStore.check(permissionsConfig.changeCommentState(commentId));
    };

    /**
     * Проверка авторизации на возможность отвечать на комментарий.
     * @param commentId - Id комментария
     */
    const checkReplyingAuth = (commentId: string): Promise<boolean> => {
        return authorizationStore.check(permissionsConfig.createReplyComment(commentId));
    };

    /** Редиректит пользователя "назад" */
    const goToPreviousPath = (): void => {
        history.push(
            generatePath(clientRoute.requestSection, {
                id: requestId,
                sectionId: entityId,
            }),
        );
    };

    /**
     * Генерирует ссылку на целевой комментарий.
     * @param commentId - Id комментария
     * */
    const getCommentLink = (commentId: string): string => {
        const requestSectionPath = clientRoute.requestSectionWithCommentId(commentId, requestId, entityId);
        return `${window.location.protocol}//${window.location.host}${requestSectionPath}`;
    };

    /** Создает конфигурацию для приложения комментариев. */
    const createConfig = async (): Promise<void> => {
        const isCreatingTopLevelCommentsAuthorized: boolean = await authorizationStore.check(
            permissionsConfig.createTopLevelComments(entityId),
        );
        const contextInfo: CommentContextInfoDTO = await commentsStore.getCommentContextInfo({ entityId, entityType });
        setCommentsAppConfig(() => {
            const statuses: CommentsAuthStatuses = { isCreatingTopLevelCommentsAuthorized };
            const statusCheckers: CommentsAuthCheckingFunctions = {
                checkDeletingAuth,
                checkEditingAuth,
                checkChangeStateAuth,
                checkReplyingAuth,
            };
            const specificCommentConfig: SpecificCommentConfig | undefined = commentId
                ? { commentId, goToPreviousPath }
                : undefined;

            const config: CommentsAppConfig = {
                contextInfo,
                entityId,
                entityType,
                locale,
                commentsListSettings: contextInfo.attribute,
                commentsStore,
                userData: {
                    id: currentUserId,
                },
                auth: {
                    statuses,
                    statusCheckers,
                },
                getCommentLink,
                reloadSections: loadRequest,
                specificCommentConfig,
            };
            return config;
        });
    };

    /** Сбросить всю конфигурацию. */
    const dropConfig = (): void => {
        setCommentsAppConfig(null);
    };

    /**
     * При смене раздела или статуса заявки, или локали, генерируем конфиг.
     * */
    useEffect(() => {
        if (!entityId || !stateId) {
            return;
        }
        dropConfig();
        createConfig();
    }, [entityId, stateId, locale, commentId]);

    return commentsAppConfig;
};

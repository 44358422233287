var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { grey } from '@platform/front-ui';
import { css } from 'styled-components';
import { sassVars } from '../../constants';
export default function () {
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        margin-bottom: ", ";\n        color: ", ";\n        font-size: ", ";\n        font-weight: ", ";\n        line-height: ", ";\n    "], ["\n        margin-bottom: ", ";\n        color: ", ";\n        font-size: ", ";\n        font-weight: ", ";\n        line-height: ", ";\n    "])), sassVars.formElementLabelMarginBottom, grey[500], sassVars.formElementLabelFontSize, sassVars.formElementLabelFontWeight, sassVars.formElementLabelLineHeight).join('');
}
var templateObject_1;

import { Button, Grid } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { ConfirmationDialog } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { useStore } from '../../../hooks';

export type CampaignSettingsDeleteCampaignProps = {
    title: string;
    id: string;
};

export const CampaignSettingsDeleteCampaign = observer((props: CampaignSettingsDeleteCampaignProps): JSX.Element => {
    const { id, title } = props;
    const { campaignsStore } = useStore();
    const { formatMessage } = useIntl();

    const history = useHistory();
    const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useFlag();

    const deleteCampaign = (): Promise<void> => {
        closeDeleteModal();
        return campaignsStore.deleteCampaign(id).then(() => history.push(clientRoute.campaigns));
    };

    const deleteCampaignMessage = formatMessage({ id: 'campaign.deleteCampaign' });

    return (
        <AuthorizationCheck entityId={id} entityCode={entities.campaign} permCode={permissions.campaign.Delete}>
            <Grid item>
                <Button variant="outlined" color="secondary" size="large" onClick={openDeleteModal}>
                    {deleteCampaignMessage}
                </Button>
                <ConfirmationDialog
                    id="delete-campaign"
                    keepMounted
                    open={isDeleteModalOpen}
                    onConfirm={deleteCampaign}
                    onCancel={closeDeleteModal}
                    title={deleteCampaignMessage}
                    message={formatMessage(
                        { id: 'campaign.campaignManagementDelete' },
                        {
                            campaignName: title,
                        },
                    )}
                />
            </Grid>
        </AuthorizationCheck>
    );
});

import { UserStatus, UserStatusAction } from '@platform/front-types';
import { LocalesTree } from '@platform/front-utils';

const action: Record<UserStatusAction, string> = {
    block: 'Заблокировать',
    activate: 'Активировать',
    unblock: 'Разблокировать',
};

const status: Record<UserStatus, string> = {
    blocked: 'Заблокирован',
    active: 'Активный',
    inactive: 'Неактивный',
};

export const userRu: LocalesTree = {
    single: {
        nominative: 'пользователь',
        accusative: 'пользователя',
        instrumental: 'пользователем',
    },
    plural: {
        nominative: 'пользователи',
        accusative: 'пользователей',
    },
    fields: {
        login: 'Логин',
        status: 'Статус',
        name: 'ФИО',
        roles: 'Роли',
    },
    fullNameFields: {
        lastName: 'Фамилия',
        firstName: 'Имя',
        middleName: 'Отчество',
    },
    settings: {
        roles: 'Настроить роли',
        rolesTitle: 'Настройка ролей',
        fullName: 'Изменить Ф.И.О.',
        language: 'Изменить основной язык системы',
        login: 'Изменить логин',
    },
    action,
    status,
    notAllowed: 'Невозможно произвести данное действие над своей учетной записью',
    info: 'Сведения о персоне',
    createPerson: 'Создать персону',
    mainLanguage: 'Основной язык системы',

    phrases: {
        // confirmStatus
        confirmStatusBlock: 'Подтвердите блокировку {user}',
        confirmStatusActivate: 'Подтвердите активацию {user}',
        confirmStatusUnblock: 'Подтвердите разблокировку {user}',

        // confirmStatusText
        confirmStatusTextBlock: 'Вы действительно хотите заблокировать {user} {name} "{login}"?',
        confirmStatusTextActivate: 'Вы действительно хотите активировать {user} {name} "{login}"?',
        confirmStatusTextUnblock: 'Вы действительно хотите разблокировать {user} {name} "{login}"?',

        lang: 'Англоязычный {user}',
        loginAsUser: 'Авторизоваться под {user}',
        editUser: 'Редактировать {user}',
        deleteUser: 'Удаление {user}',
        deleteUserConfirmText: '{user} будет удален. Подтвердить действие?',
        profile: 'Профиль {user}',
        selectUser: 'Выберите {user}',
        selectUsers: 'Выберите {user}',
        emailNotExist: '{user} с таким email не найден',
        loginHelperText:
            'После смены логина учётную запись нужно будет активировать. На новый адрес электронной почты будет отправлено письмо со ссылкой активации.',
    },
};

import { Grid, MenuItem, SxProps, Theme, Typography } from '@mui/material';
import { FileDownload, FileDownloadProps, HelpButton, MenuButton } from '@platform/front-ui';
import { VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCampaignRequestPageContext, useStore } from '../../../../../hooks';

const menuItemSx: SxProps<Theme> = {
    maxWidth: '360px',
    p: 0,
};

const menuItemLinkSx: SxProps<Theme> = {
    width: '100%',
    p: [0.5, 2],
};

const titleWrapperSx: SxProps<Theme> = {
    minHeight: '40px',
};

const helpButtonWrapperSx: SxProps<Theme> = {
    ml: 1,
    minHeight: '40px',
    width: 'auto',
};

const fileNameEllipsisProps: FileDownloadProps['nameEllipsisProps'] = { isWithTooltip: true };

export const CampaignRequestInfoTitle = observer((): JSX.Element => {
    const { model } = useCampaignRequestPageContext();
    const { regFormInstructions } = model;

    const { campaignsStore } = useStore();

    const renderInstructionButton = (onClick: (event: React.MouseEvent<HTMLElement>) => void): JSX.Element => {
        return <HelpButton onClick={onClick} />;
    };

    const renderInstructionItems = (hideMenu: VoidFunction): ReactNode[] => {
        return [
            <MenuItem key="legend" disabled dense>
                <Typography variant="caption">
                    <FormattedMessage id="campaignRequest.downloadInstruction" />
                </Typography>
            </MenuItem>,
            regFormInstructions.map((fileDTO) => {
                const { id, filename } = fileDTO;
                const onItemClick = (): Promise<void> => {
                    return campaignsStore.downloadRequestFormFile(fileDTO).finally(hideMenu);
                };
                return (
                    <MenuItem key={id} dense sx={menuItemSx}>
                        <FileDownload
                            fileName={filename}
                            downloadFile={onItemClick}
                            linkSx={menuItemLinkSx}
                            nameEllipsisProps={fileNameEllipsisProps}
                        />
                    </MenuItem>
                );
            }),
        ];
    };

    return (
        <Grid container wrap="nowrap" alignItems="start">
            <Grid item container alignItems="center" sx={titleWrapperSx}>
                <Grid item>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="campaignRequest.descriptionPanel.title" />
                    </Typography>
                </Grid>
            </Grid>
            {regFormInstructions.length > 0 && (
                <Grid item container alignItems="center" sx={helpButtonWrapperSx}>
                    <Grid item>
                        <MenuButton renderButton={renderInstructionButton} renderMenuItems={renderInstructionItems} />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
});

export enum ICSDataTestIds {
    createCampaignButton = 'createCampaignButton',
    createCampaignModal = 'createCampaignModal',
    createCampaignModalAutocompleteOptionsList = 'createCampaignModalAutocompleteOptionsList',
    createCampaignModalAutocompleteShowOptionsButton = 'createCampaignModalAutocompleteShowOptionsButton',
    createCampaignModalSubmitButton = 'createCampaignModalSubmitButton',
    saveCampaignFormButton = 'saveCampaignFormButton',
    campaignCardHeaderTitle = 'campaignCardHeaderTitle',

    campaignList = 'campaignList',
    campaignListCardDate = 'campaignListCardDate',
    campaignListCardImage = 'campaignListCardImage',
    campaignListCardRequestCountContainer = 'campaignListCardRequestCountContainer',
    campaignListCardRequestCountValue = 'campaignListCardRequestCountValue',
    campaignListCardSettingsButton = 'campaignListCardSettingsButton',
    campaignListCardCreateRequestButton = 'campaignListCardCreateRequestButton',

    campaignCardCampaignPeriod = 'campaignCardCampaignPeriod',

    campaignCardUserRequestsWrapper = 'campaignCardUserRequestsWrapper',
    campaignCardUserRequestsList = 'campaignCardUserRequestsList',
    campaignCardUserRequestsListItem = 'campaignCardUserRequestsListItem',
    campaignCardUserRequestsListItemTitle = 'campaignCardUserRequestsListItemTitle',
    campaignCardUserRequestsListItemState = 'campaignCardUserRequestsListItemState',

    campaignRegFormSettingsRegFormsListWrapper = 'campaignRegFormSettingsSectionListWrapper',
    campaignRegFormSettingsSectionListWrapper = 'campaignRegFormSettingsSectionListWrapper',

    createRequestDialogContent = 'createRequestDialogContent',
    createRequestDialogCreateButton = 'createRequestDialogCreateButton',

    campaignRequestSavePanelWrapper = 'campaignRequestSavePanelWrapper',
    campaignRequestCardKebabButton = 'campaignRequestCardKebabButton',
    campaignRequestCardHeaderRequestInfo = 'campaignRequestCardHeaderRequestInfo',
    campaignRequestCardNavigationSidebar = 'campaignRequestCardNavigationSidebar',
    campaignRequestCardObjectContentLayoutContent = 'campaignRequestCardObjectContentLayoutContent',
}

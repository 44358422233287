import { Dialog, DialogContent, IconButtonProps, PaperProps, SxProps } from '@mui/material';
import {
    AutocompleteField,
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    DialogTitle,
} from '@platform/front-ui';
import { CodeTitle, CodeTitleNull, useYup } from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { ICSDataTestIds } from '../../../constants/data-test-ids';
import { useStore } from '../../../hooks';

export enum CreateCampaignFields {
    lifecycleCode = 'lifecycleCode',
}

export type CreateCampaignValues = {
    [CreateCampaignFields.lifecycleCode]: CodeTitleNull;
};

const initialValues: CreateCampaignValues = {
    [CreateCampaignFields.lifecycleCode]: null,
};

const label = <FormattedMessage id="campaign.lifecycle" />;
const submitText = <FormattedMessage id="common.create" />;

const dialogContentSx: SxProps = {
    paddingTop: 1,
    paddingBottom: 1,
};

export type CreateCampaignModalProps = {
    open: boolean;
    onCancel: VoidFunction;
};

export const CreateCampaignModal = observer((props: CreateCampaignModalProps): JSX.Element => {
    const { open, onCancel } = props;

    const { campaignsStore, selectStore } = useStore();
    const { createCampaign } = campaignsStore;
    const { getLifecycleList } = selectStore;
    const { Yup } = useYup();
    const history = useHistory();

    const [activeLifecycleList, setActiveLifecycleList] = useState<CodeTitle[]>([]);

    const handleCreateCampaign = (values: CreateCampaignValues): void => {
        if (values.lifecycleCode?.code) {
            createCampaign(values.lifecycleCode.code).then((id) => {
                history.push(generatePath(clientRoute.createCampaign, { id }));
            });
        }
    };

    const validationSchema = Yup.object().shape({
        [CreateCampaignFields.lifecycleCode]: Yup.object().nullable().required(),
    });

    const cancelButton: DialogCancelButtonConfig = {
        onClick: onCancel,
    };

    const submitButton: DialogSubmitButtonConfig & { 'data-testid'?: string } = {
        type: 'submit',
        text: submitText,
        'data-testid': ICSDataTestIds.createCampaignModalSubmitButton,
    };

    useEffect(() => {
        getLifecycleList().then(setActiveLifecycleList);
    }, [setActiveLifecycleList, getLifecycleList]);

    return (
        <Dialog
            open={open}
            maxWidth="xs"
            fullWidth
            componentsProps={{
                root: {
                    'data-testid': ICSDataTestIds.createCampaignModal,
                } as React.HTMLAttributes<HTMLDivElement>,
            }}
        >
            <DialogTitle onCloseClick={onCancel}>
                <FormattedMessage id="campaign.createCampaign" />
            </DialogTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleCreateCampaign}
                enableReinitialize={true}
            >
                <Form>
                    <DialogContent sx={dialogContentSx}>
                        <AutocompleteField
                            fieldName={CreateCampaignFields.lifecycleCode}
                            label={label}
                            options={activeLifecycleList}
                            size="medium"
                            required={true}
                            componentsProps={{
                                paper: {
                                    'data-testid': ICSDataTestIds.createCampaignModalAutocompleteOptionsList,
                                } as PaperProps,
                                popupIndicator: {
                                    'data-testid': ICSDataTestIds.createCampaignModalAutocompleteShowOptionsButton,
                                } as Partial<IconButtonProps>,
                            }}
                        />
                    </DialogContent>
                    <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                </Form>
            </Formik>
        </Dialog>
    );
});

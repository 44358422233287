var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { grey } from '@platform/front-ui';
import { css } from 'styled-components';
import { sassVars } from '../../constants';
export default function () {
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        // \u043B\u0438\u0431\u043E \u0442\u0435\u043A\u0441\u0442 \u043E\u0448\u0438\u0431\u043A\u0438, \u043B\u0438\u0431\u043E \u0434\u043E\u043F. \u0438\u043D\u0444\u0430 \u043A \u043F\u043E\u043B\u044E\n        .help-block {\n            margin: ", " ", " 0;\n            color: ", ";\n            font-weight: 400;\n            font-size: 0.75rem;\n            line-height: 1rem;\n        }\n\n        .formio-component-editgrid .formio-errors .help-block {\n            position: static;\n        }\n    "], ["\n        // \u043B\u0438\u0431\u043E \u0442\u0435\u043A\u0441\u0442 \u043E\u0448\u0438\u0431\u043A\u0438, \u043B\u0438\u0431\u043E \u0434\u043E\u043F. \u0438\u043D\u0444\u0430 \u043A \u043F\u043E\u043B\u044E\n        .help-block {\n            margin: ", " ", " 0;\n            color: ", ";\n            font-weight: 400;\n            font-size: 0.75rem;\n            line-height: 1rem;\n        }\n\n        .formio-component-editgrid .formio-errors .help-block {\n            position: static;\n        }\n    "])), sassVars.inputErrorHelpBlockOffsetTop, sassVars.inputErrorHelpBlockOffsetLeft, grey[500]).join('');
}
var templateObject_1;

import { Button, Grid, SxProps, Theme, Typography } from '@mui/material';
import { Form, FormApi } from '@platform/formiojs-react';
import { FormDTO } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Link, Route, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { emptyForm, maxWidth100 } from '../../../../../../constants';
import { useCampaignSettingsPageContext, useStore } from '../../../../../../hooks';
import { CampaignRequestFormRouteParams } from '../../../../../../types';
import { CampaignSettingsRequestFormActionsPanel as CampaignSettingsRequestFormActionsPanelInj } from './CampaignSettingsRequestFormActionsPanel';

const requestFormBodyWrapper: SxProps<Theme> = {
    paddingX: 2,
    paddingBottom: 1,
    height: '100%',
    overflowY: 'auto',
};

const formWrapper: SxProps<Theme> = {
    height: '100%',
    maxWidth: '100%',
};

const headerSx: SxProps<Theme> = {
    paddingTop: 2,
    paddingBottom: 3,
};

const titleSx: SxProps<Theme> = {
    fontSize: '16px',
    fontWeight: 500,
};

const getFormInnerSx = (isFormView: boolean): SxProps<Theme> => ({
    maxWidth: `calc(100% - ${isFormView ? '130px' : '56px'})`,
});

export const CampaignSettingsRequestForm = observer((): JSX.Element => {
    const [CampaignSettingsRequestFormActionsPanel] = di(
        [CampaignSettingsRequestFormActionsPanelInj],
        CampaignSettingsRequestForm,
    );

    const params = useParams<CampaignRequestFormRouteParams>();
    const { rfId } = params;

    const { coreRootStore, campaignsStore } = useStore();
    const { intlStore } = coreRootStore;
    const { loadRequestForm } = campaignsStore;

    const [formDTO, setFormDTO] = useState<FormDTO>(emptyForm);
    const [formApi, setFormApi] = useState<FormApi>();

    useEffect(() => {
        loadRequestForm(rfId).then(setFormDTO);
    }, [loadRequestForm, rfId, setFormDTO]);

    const { isCampaignSettingsRequestFormEditingForm } = useCampaignSettingsPageContext();

    const isFormView = !isCampaignSettingsRequestFormEditingForm;
    const formInnerSx = getFormInnerSx(isFormView);

    return (
        <React.Fragment>
            <Grid container direction="column" justifyContent="space-between" sx={requestFormBodyWrapper}>
                <Grid item flexGrow={1} sx={maxWidth100}>
                    <Grid item sx={headerSx}>
                        <Typography variant="subtitle2" sx={titleSx}>
                            <Route path={clientRoute.campaignSettingsRequestFormEdit}>
                                <FormattedMessage id="breadcrumbs.campaignRequestFormEdit" />
                            </Route>
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="space-between" flexWrap="wrap" sx={formWrapper}>
                        <Grid item flexGrow={1} sx={formInnerSx}>
                            <Form
                                formDTO={formDTO}
                                intlStore={intlStore}
                                ownerEntityId={rfId}
                                readOnly={isFormView}
                                onFormReady={setFormApi}
                            />
                        </Grid>
                        <Route path={clientRoute.campaignSettingsRequestFormInfo} exact>
                            <Grid item>
                                <Button
                                    component={Link}
                                    color="secondary"
                                    to={generatePath(clientRoute.campaignSettingsRequestFormEdit, params)}
                                >
                                    <FormattedMessage id="common.edit" />
                                </Button>
                            </Grid>
                        </Route>
                    </Grid>
                </Grid>
            </Grid>
            {!isFormView && <CampaignSettingsRequestFormActionsPanel formApi={formApi} />}
        </React.Fragment>
    );
});

import { action, makeObservable, observable } from 'mobx';
import { CampaignRequestFormDTO } from '../types';

export const CampaignSettingsRequestFormModelProps = {
    id: observable,
    campaignId: observable,
    title: observable,
    regStart: observable,
    regEnd: observable,
    procedureTitle: observable,
    reEnrollAllowed: observable,
    allowAfterDeadline: observable,
    load: action.bound,
};

export class CampaignSettingsRequestFormModel implements CampaignRequestFormDTO {
    id = '';
    campaignId = '';
    title = '';
    regStart = '';
    regEnd = '';
    procedureTitle = '';
    reEnrollAllowed = false;
    allowAfterDeadline = false;

    constructor() {
        makeObservable(this, CampaignSettingsRequestFormModelProps);
    }

    load(dto: CampaignRequestFormDTO): void {
        this.id = dto.id;
        this.campaignId = dto.campaignId;
        this.title = dto.title;
        this.regStart = dto.regStart;
        this.regEnd = dto.regEnd;
        this.procedureTitle = dto.procedureTitle;
        this.reEnrollAllowed = dto.reEnrollAllowed;
        this.allowAfterDeadline = dto.allowAfterDeadline;
    }
}

import { Theme } from '@mui/material';
import { FormioSidebarStore } from '@platform/formiojs-react';
import { CoreRootStore, Env, getBreadcrumbsStore, getCoreRootStore, RootStoreProps } from '@platform/front-core';
import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { getBreadcrumbsNames, getBreadcrumbsRoutes } from './BreadcrumbsStore';
import { CampaignsStore, getCampaignsStore } from './CampaignsStore';
import { CommentsStore, getCommentsStore } from './CommentsStore';
import { getCurrentUserStore } from './CurrentUserStore';
import { FormioSignatureStore, getFormioSignatureStore } from './FormioSignatureStore';
import { FormioStore, getFormioStore } from './FormioStore';
import { getGlobalSearchStore } from './GlobalSearchStore';
import { HeaderLinksStore } from './HeaderLinksStore';
import { getNotificationStore } from './NotificationStore';
import { getPrintFormStore, PrintFormStore } from './PrintFormStore';
import { getRequestStore, RequestStore } from './RequestStore';
import { getSelectStore, SelectStore } from './SelectStore';
import { getSignatureStore, SignatureStore } from './SignatureStore';
import { getUserStore, UserStore } from './UserStore';

export const RootStoreMainProps = {
    history: observable,
    coreRootStore: observable,
    campaignsStore: observable,
    formioStore: observable,
    selectStore: observable,
    requestStore: observable,
    printFormStore: observable,
    signatureStore: observable,
    formioSignatureStore: observable,
    commentsStore: observable,
    userStore: observable,
    env: observable,
    setObservables: action.bound,
    setAppTheme: action.bound,
};

export class RootStore {
    history: History;
    coreRootStore: CoreRootStore;
    campaignsStore: CampaignsStore;
    printFormStore: PrintFormStore;
    requestStore: RequestStore;
    formioStore: FormioStore;
    formioSidebarStore: FormioSidebarStore;
    selectStore: SelectStore;
    signatureStore: SignatureStore;
    formioSignatureStore: FormioSignatureStore;
    commentsStore: CommentsStore;
    userStore: UserStore;

    features: RootStoreProps['features'] = {};
    env: Env = {} as Env;
    theme?: Theme;

    constructor(props: RootStoreProps) {
        makeObservable(this, RootStoreMainProps);

        const CoreRootStore = getCoreRootStore();
        const CurrentUserStore = getCurrentUserStore();
        const NotificationStore = getNotificationStore();
        const CampaignsStore = getCampaignsStore();
        const RequestStore = getRequestStore();
        const BreadcrumbsStore = getBreadcrumbsStore();
        const FormioStore = getFormioStore();
        const SelectStore = getSelectStore();
        const PrintFormStore = getPrintFormStore();
        const SignatureStore = getSignatureStore();
        const FormioSignatureStore = getFormioSignatureStore();
        const CommentsStore = getCommentsStore();
        const GlobalSearchStore = getGlobalSearchStore();
        const UserStore = getUserStore();

        const { history, theme } = props;
        this.history = history;
        this.setObservables(props);

        this.coreRootStore = new CoreRootStore(props);
        this.coreRootStore.currentUserStore = new CurrentUserStore(this.coreRootStore);
        this.coreRootStore.notificationStore = new NotificationStore(this.coreRootStore);
        this.coreRootStore.headerLinksStore = new HeaderLinksStore(this.coreRootStore);
        this.coreRootStore.breadcrumbsStore = new BreadcrumbsStore(
            this.coreRootStore,
            getBreadcrumbsRoutes,
            getBreadcrumbsNames,
        );
        this.formioStore = new FormioStore(this);
        this.formioSidebarStore = new FormioSidebarStore();
        this.formioSignatureStore = new FormioSignatureStore(this);
        this.setAppTheme(theme);

        this.campaignsStore = new CampaignsStore(this);
        this.selectStore = new SelectStore(this);
        this.requestStore = new RequestStore(this);
        this.printFormStore = new PrintFormStore(this);
        this.signatureStore = new SignatureStore(this);
        this.commentsStore = new CommentsStore(this.coreRootStore.api, apiConfigs);
        this.userStore = new UserStore(this.coreRootStore);

        this.coreRootStore.globalSearchStore = new GlobalSearchStore(this.coreRootStore);
    }

    setObservables(props: RootStoreProps): void {
        const { env, features } = props;
        this.env = env;
        this.features = features;
    }

    setAppTheme(theme: Theme): void {
        this.theme = theme;
        this.formioStore.addComponents();
    }
}

import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../clientRoute';
import { CampaignRequestListPage as CampaignRequestListPageInj } from './campaign-request-list-page';
import {
    CampaignRequestPage as CampaignRequestPageInj,
    CampaignRequestPageContainer as CampaignRequestPageContainerInj,
} from './campaign-request-page';
import { CampaignRequestSyslogPage as CampaignRequestSyslogPageInj } from './campaign-request-syslog';

export const CampaignRequestPages = (): JSX.Element => {
    const [CampaignRequestListPage] = di([CampaignRequestListPageInj], CampaignRequestPages);
    const [CampaignRequestPage] = di([CampaignRequestPageInj], CampaignRequestPages);
    const [CampaignRequestPageContainer] = di([CampaignRequestPageContainerInj], CampaignRequestPages);
    const [CampaignRequestSyslogPage] = di([CampaignRequestSyslogPageInj], CampaignRequestPages);

    return (
        <Switch>
            {/*todo: редиректы */}
            <Route path={[clientRoute.requestSection, clientRoute.request, clientRoute.requestSyslog]}>
                <CampaignRequestPageContainer>
                    <Switch>
                        <Route path={clientRoute.requestSyslog} exact>
                            <CampaignRequestSyslogPage />
                        </Route>
                        <Route
                            path={[
                                clientRoute.requestSection,
                                clientRoute.request,
                                clientRoute.requestSectionRelatedObjects,
                                clientRoute.requestSectionAddRelatedObjects,
                            ]}
                        >
                            <CampaignRequestPage />
                        </Route>
                    </Switch>
                </CampaignRequestPageContainer>
            </Route>
            <Route exact path={clientRoute.requests}>
                <CampaignRequestListPage />
            </Route>
        </Switch>
    );
};

import { recordToLang } from '@platform/front-utils';
import startPage from './startPage';

const lang: Record<string, string> = recordToLang(
    {
        startPage,
    },
    false,
);

export default lang;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { blue, grey } from '@platform/front-ui';
import { css } from 'styled-components';
export var linkMixins = {
    default: {
        static: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            color: ", ";\n            text-decoration: none;\n        "], ["\n            color: ", ";\n            text-decoration: none;\n        "])), blue[600]).join(''),
        hover: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            color: ", ";\n            text-decoration: underline;\n        "], ["\n            color: ", ";\n            text-decoration: underline;\n        "])), blue[600]).join(''),
        active: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            color: ", ";\n            text-decoration: underline;\n        "], ["\n            color: ", ";\n            text-decoration: underline;\n        "])), blue[800]).join(''),
        disabled: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            color: ", ";\n            text-decoration: none;\n        "], ["\n            color: ", ";\n            text-decoration: none;\n        "])), blue[300]).join(''),
    },
    styled: {
        static: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n            color: ", ";\n            text-decoration: underline;\n        "], ["\n            color: ", ";\n            text-decoration: underline;\n        "])), grey[600]).join(''),
        hover: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n            color: ", ";\n            text-decoration: underline;\n        "], ["\n            color: ", ";\n            text-decoration: underline;\n        "])), grey[600]).join(''),
        active: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n            color: ", ";\n            text-decoration: underline;\n        "], ["\n            color: ", ";\n            text-decoration: underline;\n        "])), grey[800]).join(''),
        disabled: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n            color: ", ";\n            text-decoration: none;\n        "], ["\n            color: ", ";\n            text-decoration: none;\n        "])), grey[300]).join(''),
    },
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

import SearchIcon from '@mui/icons-material/Search';
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Grid,
    SxProps,
    TextField,
    TextFieldProps,
    Theme,
} from '@mui/material';
import { InputProps } from '@mui/material/Input/Input';
import { grey, TotDatePicker as TotDatePickerInj } from '@platform/front-ui';
import { voidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';

const fieldSx: SxProps<Theme> = {
    width: '100%',
    maxWidth: 236,

    '& [class*="MuiInputBase-root"]': {
        height: 40,
    },
};

const autocompleteFieldSx: SxProps<Theme> = {
    ...fieldSx,

    '& [class*="MuiAutocomplete-input"]': {
        width: '100% !important',
    },

    '& legend': {
        width: 0,
    },
};

const searchIconSx: SxProps<Theme> = { color: grey[600] };
const searchFieldSx: SxProps<Theme> = {
    ...fieldSx,

    '& [class*="MuiInputBase-root"]': {
        paddingRight: '11px !important',
    },
};

// Временная заглушка
export const CampaignListFilters = observer((): JSX.Element => {
    const [TotDatePicker] = di([TotDatePickerInj], CampaignListFilters);

    const { formatMessage } = useIntl();

    const renderInput = (params: TextFieldProps) => <TextField {...params} sx={fieldSx} />;
    const dateValue = null;
    const onChange = voidFunction;

    const selectRenderInput = (params: AutocompleteRenderInputParams): JSX.Element => (
        <TextField
            {...params}
            placeholder={formatMessage({ id: 'campaign.filters.allStates' })}
            sx={autocompleteFieldSx}
        />
    );

    const searchInputProps: Partial<InputProps> = {
        endAdornment: <SearchIcon sx={searchIconSx} />,
    };

    return (
        <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
                <TextField
                    label={formatMessage({ id: 'campaign.filters.title' })}
                    value={dateValue}
                    sx={searchFieldSx}
                    InputProps={searchInputProps}
                    onChange={onChange}
                />
            </Grid>
            <Grid item>
                <Autocomplete
                    options={[]}
                    noOptionsText={formatMessage({ id: 'ttable.resultsOption' })}
                    renderInput={selectRenderInput}
                />
            </Grid>
            <Grid item>
                <TotDatePicker
                    label={formatMessage({ id: 'campaign.filters.startDate' })}
                    value={dateValue}
                    onChange={onChange}
                    renderInput={renderInput}
                />
            </Grid>
            <Grid item>
                <TotDatePicker
                    label={formatMessage({ id: 'campaign.filters.endDate' })}
                    value={dateValue}
                    onChange={onChange}
                    renderInput={renderInput}
                />
            </Grid>
        </Grid>
    );
});

import { CodeTitle, CodeTitleGroup } from '@platform/front-utils';
import { CommentsAccessRightsSettingsFormField } from './campaignSettings';
import { SelectedIndex } from './selectedIndex';

export type CodeTitleGroupRC = CodeTitleGroup<PermissionTrigger>;
export type CodeTitleGroupRCNull = CodeTitleGroupRC | null;

export enum PermissionsType {
    templatePermissions = 'templatePermissions',
    permissions = 'permissions',
}

export enum PermissionTrigger {
    role = 'role',
    condition = 'condition',
}

export type PermissionSettingsDTO<T = any> = {
    processCode: string;
    permissions: T[];
};

export enum AccessPermissionDTOFields {
    roleCondition = 'roleCondition',
    whenObjectStateIsOneOf = 'whenObjectStateIsOneOf',
    allowEdit = 'allowEdit',
}

const { roleCondition, whenObjectStateIsOneOf, allowEdit } = AccessPermissionDTOFields;

export type CommentsPermission<T> = Record<CommentsAccessRightsSettingsFormField, boolean> & {
    [whenObjectStateIsOneOf]: CodeTitle[];
    [roleCondition]: T;
};
export type CommentsPermissionDTO = CommentsPermission<CodeTitleGroupRC>;
export type CustomCommentSettingPermissionsInitialValues = CommentsPermission<CodeTitleGroupRCNull>;

export type AccessPermission<T extends CodeTitleGroupRCNull> = {
    [roleCondition]: T;
    [whenObjectStateIsOneOf]: CodeTitle[];
    [allowEdit]: boolean;
};
export type AccessPermissionDTO = AccessPermission<CodeTitleGroupRC>;
export type AccessPermissionInitialValues = AccessPermission<CodeTitleGroupRCNull>;

export type NewPermissionDTO = Omit<CommentsPermissionDTO, typeof whenObjectStateIsOneOf> & {
    role?: string;
    when?: string;
    [allowEdit]?: boolean;
    [whenObjectStateIsOneOf]: string[];
};

export type LoadPermissions = () => Promise<PermissionSettingsDTO>;
export type UpdatePermissions = (dto: Partial<NewPermissionDTO>[]) => Promise<void>;

export type AccessPermissionMapToNewPermissionDTOOverloads = {
    (permission: AccessPermissionInitialValues): Partial<NewPermissionDTO>;
    (permission: CustomCommentSettingPermissionsInitialValues): Partial<NewPermissionDTO>;
};

export type SubmitPermissionsOverloads = {
    (
        formValues: AccessPermissionInitialValues,
        selectedPermissionIndex: SelectedIndex,
        onSuccess?: VoidFunction,
    ): Promise<void>;
    (
        formValues: Partial<CustomCommentSettingPermissionsInitialValues>,
        selectedPermissionIndex: SelectedIndex,
        onSuccess?: VoidFunction,
    ): Promise<void>;
};

export type EitherCommentOrDefaultAccessPermissions = Partial<AccessPermissionInitialValues> &
    Partial<CustomCommentSettingPermissionsInitialValues>;

export type CustomAndDefaultPermissionSettingsDTO<T> = PermissionSettingsDTO<T & AccessPermissionDTO>;

import { createContext } from 'react';
import { PrintFormListModel } from '../models';

export type CampaignRequestPrintFormsContextType = {
    model: PrintFormListModel;
};

export const CampaignRequestPrintFormsContext = createContext<CampaignRequestPrintFormsContextType>({
    model: {} as PrintFormListModel,
});

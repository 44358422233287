import { LocalesTree } from '@platform/front-utils';

const campaignRequest: LocalesTree = {
    requests: 'заявки',
    registryTitle: 'Заявки',
    withoutNumber: 'Без номера',
    requestN: 'Заявка № {number}',

    modalTitle: 'Изменение статуса',
    modalInfoText: 'Статус будет изменен на «{toState}»',
    invalidDateMessage: 'Недопустимый формат даты',
    maxDateMessage: 'Дата не может быть позже текущей даты',
    confirmDeletionTitle: 'Удалить заявку',
    confirmDeletionInfoText: 'Вы действительно хотите удалить заявку № {number}?',
    resultsOption: 'Результаты не найдены',
    readonlyRequestValidationMsg:
        'Форма заявки заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
    readonlyRequestValidationBtnMsg: 'Перейти к исправлению',
    printFormsTable: {
        loading: 'Пожалуйста подождите, идет подготовка к генерации печатных форм документов',
        title: 'Печатные формы документов',
        fileColumnName: 'Файл',
        authorColumnName: 'ФИО',
        dateColumnName: 'Дата',
        generateAction: 'Сгенерировать',
        updateAction: 'Обновить',
        cannotGenerateError: 'Невозможно сгенерировать печатную форму: форма заявки заполнена некорректно',
        cannotGenerateErrorWithLink:
            'Невозможно сгенерировать печатную форму: форма заявки заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
        generationError: 'При генерации печатной формы произошла ошибка',
        downloadAllButton: 'Скачать все',
        taskStatus: 'Статус генерации',
    },
    taskStatus: {
        generating: 'Идет генерация',
        completed: 'Сгенерирован',
        failed: 'Ошибка генерации',
    },
    requestFormCard: {
        description: 'Описание',
        registrationStartDate: 'Начало',
        registrationEndDate: 'Окончание',
        afterDeadline: 'Прием после дедлайна',
        resubmission: 'Повторная подача', // todo delete https://totsys.atlassian.net/browse/ICS-1419
        instruction: 'Инструкция',
        instructionIsMissing: 'Отсутствует',
    },
    dialog: {
        executorsTitle: 'Взять заявку в работу',
        executorsMessage: 'Вы будете назначены ответственным исполнителем по заявке №{ number }',
        editExecutorTitle: 'Назначить ответственного исполнителя',
        changeExecutorTitle: 'Изменить ответственного исполнителя',
    },
    allFiles: 'Все документы по {title}',
    takeToWork: 'Взять в работу',
    chooseExecutor: 'Назначить ответственного',
    actions: {
        appointExecutor: 'Назначить ответственного',
        changeExecutor: 'Изменить ответственного',
        requestToWork: 'Взять в работу',
    },
    expandWorkArea: 'Увеличить рабочую область',
    reduceWorkArea: 'Уменьшить рабочую область',
    deadlines: {
        entity: 'Общий срок завершения',
        state: 'Контрольный срок',
        error: 'Ошибка. Не удалось рассчитать срок',
        notRelevant: 'Не актуален в этом статусе',
        notRelevantTooltip:
            'Время нахождения в этом статусе не учитывается в общей длительности работ. Срок завершения будет пересчитан при переходе в другой статус',
    },
    requestCreation: 'Создание заявки',
    selectRequestFormFromList: 'Выберите форму заявки из перечня ниже',
    selectRequestForm: 'Выберите форму заявки для создания',

    // hideDescription: 'Скрыть',
    // visibleDescription: 'Развернуть описание',
    // requestDescription: 'Описание заявки',
    descriptionPanel: {
        title: 'Описание заявки',
        close: 'Скрыть',
        open: 'Развернуть описание',
        commonTabLabel: 'Общее',
        serviceTabLabel: 'Служебное',
    },

    fields: {
        number: 'Номер заявки',
        author: 'Автор заявки',
        campaign: 'Категория',
        requestForm: 'Форма заявки',
        state: 'Состояние',
        created: 'Дата создания',
        sent: 'Дата подачи',
        executor: 'Ответственный исполнитель',
        submissionDeadline: 'Срок подачи',
        lifecycle: 'Жизненный цикл',
        lastModified: 'Дата последнего изменения',

        notAssigned: 'Не назначен',
        notSubmitted: 'Еще не подана',
    },

    downloadInstruction: 'Скачайте инструкцию',
    toDefault: 'Перейти в реестр без дополнительных настроек',

    similarRequests: 'Схожие заявки',
};

export default campaignRequest;

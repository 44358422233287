import { Link } from '@mui/material';
import { IdTitle } from '@platform/front-types';
import React, { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { clientRoute } from '../clientRoute';

export type NotificationSectionsLinksProps = {
    id: string;
    sections: IdTitle[];
};

const linkStyles: CSSProperties = {
    color: 'inherit',
    textDecorationColor: 'inherit',
};

export const NotificationSectionsLinks = (props: NotificationSectionsLinksProps): JSX.Element => {
    const { sections, id } = props;

    const links: JSX.Element[] | JSX.Element = sections.map((link) => {
        const { id: sectionId, title } = link;

        return (
            <Link
                component={RouterLink}
                color="inherit"
                underline="always"
                style={linkStyles}
                to={generatePath(clientRoute.requestSectionFormEdit, { id, sectionId })}
            >
                {title}
            </Link>
        );
    });

    return (
        <React.Fragment>
            <FormattedMessage id="form.sections" />: {links}
        </React.Fragment>
    );
};

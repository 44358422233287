import { ApiStore } from '@platform/front-core';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { PrintFormDTO } from '../types';
import { RootStore } from './RootStore';

export const PrintFormStoreProps = {
    rootStore: observable,
    api: observable,
    generatePrintForm: action.bound,
    downloadPrintForm: action.bound,
    downloadAllPrintFormFiles: action.bound,
    getPrintForm: action.bound,
    loadPrintForms: action.bound,
};

export class PrintFormStore {
    protected rootStore: RootStore;
    protected api: ApiStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        makeObservable(this, PrintFormStoreProps);
    }

    generatePrintForm(sectionId: string, templateId: string): Promise<void> {
        return this.api.userActionClient(apiConfigs.generatePrintForm(sectionId, templateId)).then((r) => r.data);
    }

    downloadPrintForm(sectionId: string, pfSettingId: string): Promise<Blob> {
        return this.api.userActionClient(apiConfigs.downloadPrintForm(sectionId, pfSettingId)).then((r) => r.data);
    }

    downloadAllPrintFormFiles(sectionId: string): Promise<Blob> {
        return this.api.userActionClient(apiConfigs.downloadAllPrintFormFiles(sectionId)).then((r) => r.data);
    }

    getPrintForm(templateId: string, sectionId: string): Promise<PrintFormDTO> {
        return this.api.client(apiConfigs.getPrintForm(templateId, sectionId)).then((r) => r.data);
    }

    loadPrintForms(sectionId: string): Promise<PrintFormDTO[]> {
        return this.api.client(apiConfigs.loadPrintForms(sectionId)).then((r) => r.data);
    }
}

export const getPrintFormStore = (): any => {
    const [_RequestStore] = di([PrintFormStore], getPrintFormStore);
    return _RequestStore;
};

import { Dialog, DialogContent, Grid, SxProps, Theme } from '@mui/material';
import { PersonModel } from '@platform/front-core';
import { IdTitle, IdTitleNull, ModalProps } from '@platform/front-types';
import {
    AutocompleteField,
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    DialogTitle,
} from '@platform/front-ui';
import { useAntiDoubleClick, useYup } from '@platform/front-utils';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../hooks';
import { CampaignRequestRow } from '../../../types';
import { CampaignRequestEditExecutorDialogInfo as CampaignRequestEditExecutorDialogInfoInj } from './CampaignRequestEditExecutorDialogInfo';

export type CampaignRequestEditExecutorDialogProps = ModalProps & {
    onSubmit: (values: CampaignRequestEditExecutorFormType) => Promise<void>;
    executor?: IdTitle;
    campaignRequestRow?: CampaignRequestRow;
    requestId: string;
};

const dialogTitleSx: SxProps<Theme> = {
    paddingX: 2,
    paddingTop: 1,
    paddingBottom: 1,
};

export type CampaignRequestEditExecutorFormType = {
    executor: IdTitleNull;
};

export const CampaignRequestEditExecutorDialog = observer(
    (props: CampaignRequestEditExecutorDialogProps): JSX.Element => {
        const [CampaignRequestEditExecutorDialogInfo] = di(
            [CampaignRequestEditExecutorDialogInfoInj],
            CampaignRequestEditExecutorDialog,
        );

        const { open, onSubmit, onCancel, campaignRequestRow, requestId } = props;

        const { formatMessage } = useIntl();
        const { Yup } = useYup();
        const { selectStore, requestStore } = useStore();

        const [employees, setEmployees] = useState<IdTitle[]>([]);
        const [executor, setExecutor] = useState<PersonModel>();

        const initialValues = {
            executor: executor ? executor.asIdTitleWithInitials : null,
        };

        const validationSchema = Yup.object().shape({
            executor: Yup.object().nullable().required(),
        });

        const [isLoading, endIcon, handleSubmit] = useAntiDoubleClick(onSubmit);

        const cancelButton: DialogCancelButtonConfig = {
            onClick: onCancel,
        };

        const submitButton: DialogSubmitButtonConfig = {
            text: formatMessage({ id: 'common.save' }),
            type: 'submit',
            endIcon,
            disabled: isLoading,
        };

        useEffect((): void => {
            open && selectStore.fetchAllEmployee().then((employees) => setEmployees(employees));
        }, [open, selectStore]);

        useEffect((): void => {
            open &&
                requestStore.getExecutorSettings(requestId).then((executor) => {
                    if (executor) {
                        setExecutor(new PersonModel().load(executor.person));
                    }
                });
        }, [requestId, requestStore, open]);

        return (
            <Dialog fullWidth={true} maxWidth="md" open={open}>
                <DialogTitle sx={dialogTitleSx} onCloseClick={onCancel}>
                    {executor ? (
                        <FormattedMessage id="campaignRequest.dialog.changeExecutorTitle" />
                    ) : (
                        <FormattedMessage id="campaignRequest.dialog.editExecutorTitle" />
                    )}
                </DialogTitle>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    <Form>
                        <DialogContent>
                            <Grid container direction="column" spacing={1}>
                                {campaignRequestRow && (
                                    <Grid item>
                                        <CampaignRequestEditExecutorDialogInfo
                                            campaignRequestRow={campaignRequestRow}
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <AutocompleteField
                                        fieldName="executor"
                                        label={formatMessage({ id: 'campaignRequest.fields.executor' })}
                                        options={employees}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
                    </Form>
                </Formik>
            </Dialog>
        );
    },
);

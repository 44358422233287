import { Edit } from '@mui/icons-material';
import { Grid, IconButton, styled, TableCell, TableRow, TableRowProps } from '@mui/material';
import { Box } from '@mui/system';
import { VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useCampaignSettingsAccessPermissionContext } from '../../../../../../hooks';
import { AccessPermissionDTO, AccessPermissionDTOFields, CommentsPermissionDTO } from '../../../../../../types';
import { CampaignSettingsDeletePermissionButton as CampaignSettingsDeletePermissionButtonInj } from './CampaignSettingsDeletePermissionButton';

const showWithHover = 'show-with-hover';

const StyledRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
    [`.${showWithHover}`]: {
        opacity: 0,
        transition: `opacity ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.sharp}`,
    },
    '&:hover': {
        [`.${showWithHover}`]: {
            opacity: 1,
        },
    },
}));

export type CampaignSettingsPermissionListItemProps = {
    permission: AccessPermissionDTO | CommentsPermissionDTO;
    onEditClick: VoidFunction;
    onDeleteClick: VoidFunction;
};

export const CampaignSettingsPermissionListItem = observer(
    (props: CampaignSettingsPermissionListItemProps): JSX.Element => {
        const [CampaignSettingsDeletePermissionButton] = di(
            [CampaignSettingsDeletePermissionButtonInj],
            CampaignSettingsPermissionListItem,
        );

        const { permission, onEditClick, onDeleteClick } = props;
        const { whenObjectStateIsOneOf, roleCondition, allowEdit } = permission as AccessPermissionDTO;
        const { onDeleteConfirm, checkboxLabel } = useCampaignSettingsAccessPermissionContext();
        const { formatMessage } = useIntl();

        const statesCell = whenObjectStateIsOneOf.length ? (
            whenObjectStateIsOneOf.map((state) => <Box key={state.code}>{state.title}</Box>)
        ) : (
            <FormattedMessage id="campaignSettings.editPermissions.list.allStatuses" />
        );

        const customFields: JSX.Element[] = Object.keys(permission)
            .filter(
                (key: string) =>
                    key !== AccessPermissionDTOFields.roleCondition &&
                    key !== AccessPermissionDTOFields.whenObjectStateIsOneOf &&
                    key !== AccessPermissionDTOFields.allowEdit &&
                    permission[key as keyof typeof permission],
            )
            .map((key) => {
                return <Grid item>{formatMessage({ id: `campaignSettings.commentsSetting.${key}` })}</Grid>;
            });

        const defaultFields: JSX.Element = allowEdit ? (
            <React.Fragment>
                <Grid item>{checkboxLabel}</Grid>
                <Grid item>
                    <FormattedMessage id="campaignSettings.editPermissions.view" />
                </Grid>
            </React.Fragment>
        ) : (
            <FormattedMessage id="campaignSettings.editPermissions.view" />
        );

        return (
            <StyledRow>
                <TableCell align="left">{roleCondition.title}</TableCell>
                <TableCell align="left">{statesCell}</TableCell>
                <TableCell align="left">
                    <Grid container flexDirection="column">
                        {customFields.length ? customFields : defaultFields}
                    </Grid>
                </TableCell>
                <TableCell align="center" className={showWithHover}>
                    <Grid container spacing={1} justifyContent="flex-end" wrap="nowrap">
                        <Grid item>
                            <IconButton onClick={onEditClick}>
                                <Edit />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <CampaignSettingsDeletePermissionButton
                                role={roleCondition.title}
                                onConfirm={onDeleteConfirm}
                                onOpen={onDeleteClick}
                            />
                        </Grid>
                    </Grid>
                </TableCell>
            </StyledRow>
        );
    },
);

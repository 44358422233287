var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultFormsLocale } from '@platform/front-utils';
import { makeAutoObservable, observable } from 'mobx';
var FormModel = /** @class */ (function () {
    function FormModel(ownerEntityId, dto) {
        Object.defineProperty(this, "form", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "submission", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "startSubmission", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "i18n", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "loaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "ownerEntityId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeAutoObservable(this, { form: observable.ref, i18n: observable.ref }, { autoBind: true });
        this.ownerEntityId = ownerEntityId;
        this.load(dto);
    }
    Object.defineProperty(FormModel.prototype, "load", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (dto) {
            this.form = dto.form;
            this.i18n = dto.i18n;
            if (dto.submission) {
                this.setSubmission(dto.submission);
                this.startSubmission = this.clone(dto.submission);
            }
            this.loaded = true;
            return this;
        }
    });
    Object.defineProperty(FormModel.prototype, "getFullSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.submission;
        }
    });
    Object.defineProperty(FormModel.prototype, "setLangSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lang, submission) {
            var _a;
            this.setSubmission(__assign(__assign({}, this.submission), (_a = {}, _a[lang] = submission, _a)));
        }
    });
    Object.defineProperty(FormModel.prototype, "setStartSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lang) {
            if (lang && this.startSubmission && this.submission) {
                this.startSubmission[lang] = this.clone(this.submission[lang]);
            }
            else {
                this.startSubmission = this.clone(this.submission);
            }
        }
    });
    Object.defineProperty(FormModel.prototype, "setSubmissionFromStartSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setSubmission(this.clone(this.startSubmission));
        }
    });
    Object.defineProperty(FormModel.prototype, "getLangSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lang) {
            var langSubmission = this.submission[lang];
            if (langSubmission) {
                var newLangSubmission = this.clone(langSubmission);
                Object.assign(newLangSubmission.data, { ownerEntityId: this.ownerEntityId });
                return newLangSubmission;
            }
            return this.emptySubmission();
        }
    });
    Object.defineProperty(FormModel.prototype, "getSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lang) {
            var submission;
            if (lang) {
                submission = this.getLangSubmission(lang) || this.getLangSubmission(defaultFormsLocale);
            }
            else {
                submission = this.getLangSubmission(defaultFormsLocale);
            }
            return submission || this.emptySubmission();
        }
    });
    Object.defineProperty(FormModel.prototype, "setSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (submission) {
            this.submission = submission;
        }
    });
    Object.defineProperty(FormModel.prototype, "emptySubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return { data: { ownerEntityId: this.ownerEntityId }, meta: {} };
        }
    });
    Object.defineProperty(FormModel.prototype, "clone", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (obj) {
            return JSON.parse(JSON.stringify(obj));
        }
    });
    return FormModel;
}());
export { FormModel };

import { ModalProps, UserStatus } from '@platform/front-types';
import { ConfirmationDialog } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import {
    usersConfirmStatusMessages,
    usersConfirmStatusTextMessages,
} from '../../../customization/default/locales/messages';
import { useStore } from '../../../hooks';
import { actionCodeByUserStatus } from '../../../utils';

export type UserStatusModalProps = ModalProps & {
    id: string;
    login: string;
    name: string;
    userStatusCode: UserStatus;
    reloadData: VoidFunction;
    onFinally?: VoidFunction;
    toggleIsRowBlocked?: VoidFunction; //временно оставил потому что и для реестра, и для карточки используется один и тот же диалог
    rowErrorHandler?: VoidFunction;
};

export const UserStatusModal = observer((props: UserStatusModalProps): JSX.Element => {
    const {
        id,
        login,
        name,
        userStatusCode,
        open,
        reloadData,
        onCancel,
        onFinally,
        toggleIsRowBlocked,
        rowErrorHandler,
    } = props;
    const { coreRootStore, userStore } = useStore();
    const { intlStore } = coreRootStore;
    const userStatus = actionCodeByUserStatus[userStatusCode];

    const onChangeStatusConfirm = useCallback(async () => {
        onCancel();
        toggleIsRowBlocked && toggleIsRowBlocked();
        userStore
            .editUserStatus(
                id,
                {
                    action: userStatus,
                },
                true,
            )
            .then(reloadData)
            .catch(rowErrorHandler)
            .finally(() => {
                onFinally && onFinally();
            });
    }, [id, userStatusCode, userStore, reloadData]);

    const title = userStatus ? intlStore.formatMessageFromDefineMessage(usersConfirmStatusMessages[userStatus]) : '';
    const message = userStatus
        ? intlStore.formatMessageFromDefineMessage({
              ...usersConfirmStatusTextMessages[userStatus],
              //@ts-ignore
              values: {
                  ...(usersConfirmStatusTextMessages[userStatus] as any).values,
                  login,
                  name,
              },
          })
        : '';

    return (
        <ConfirmationDialog
            id="confirm-user-status"
            keepMounted
            open={open}
            onConfirm={onChangeStatusConfirm}
            onCancel={onCancel}
            title={title}
            message={message}
        />
    );
});

import { AuthorizationStore, IntlStore } from '@platform/front-core';
import { AuthorizationCheckQuery } from '@platform/front-types';
import downloadFile from 'js-file-download';
import { action, computed, makeObservable, observable } from 'mobx';
import { entities, permissions } from '../authSchemeConfig';
import { PrintFormStore, RootStore } from '../stores';
import { PrintFormModel } from './PrintFormModel';

export const PrintFormListModelProps = {
    rootStore: observable,
    authorizationStore: observable,
    printFormStore: observable,
    intlStore: observable,
    printForms: observable,
    sectionId: observable,
    isLoading: observable,
    downloadAllFilesTitle: observable,
    updatePfListIntervalId: observable,
    isWithEdit: observable,
    isSomePrintFormGenerating: computed,
    checkIsWithEdit: action.bound,
    load: action.bound,
    downloadAllPrintFormFiles: action.bound,
    clearUpdatePfListInterval: action.bound,
    updateIfSomePrintFormIsGenerating: action.bound,
    dropIsLoading: action.bound,
    setPrintForms: action.bound,
    setUpdatePfListIntervalId: action.bound,
    setDownloadAllFilesTitle: action.bound,
    setIsWithEdit: action.bound,
    setIsLoading: action.bound,
};

export class PrintFormListModel {
    protected rootStore: RootStore;
    protected authorizationStore: AuthorizationStore;
    protected printFormStore: PrintFormStore;
    protected intlStore: IntlStore;

    printForms: PrintFormModel[] = [];
    sectionId = '';
    isLoading = true;
    downloadAllFilesTitle = '';
    updatePfListIntervalId?: number;
    isWithEdit = false;

    constructor(rootStore: RootStore, sectionId: string) {
        makeObservable(this, PrintFormListModelProps);
        this.rootStore = rootStore;
        this.authorizationStore = rootStore.coreRootStore.authorizationStore;
        this.printFormStore = rootStore.printFormStore;
        this.intlStore = rootStore.coreRootStore.intlStore;
        this.sectionId = sectionId;
    }

    get isSomePrintFormGenerating(): boolean {
        return this.printForms.some((printFormModel: PrintFormModel) => {
            return printFormModel.isGenerating;
        });
    }

    async checkIsWithEdit(): Promise<void> {
        const query: AuthorizationCheckQuery = {
            entityCode: entities.section,
            permCode: permissions.section.EditPf,
            entityId: this.sectionId,
        };

        return this.authorizationStore
            .check(query)
            .then(this.setIsWithEdit)
            .catch(() => {
                this.setIsWithEdit(false);
            });
    }

    async load(): Promise<void> {
        this.setIsLoading(true);
        const printFormsDto = await this.printFormStore.loadPrintForms(this.sectionId).finally(this.dropIsLoading);
        const printForms = printFormsDto.map((dto) => new PrintFormModel(this.printFormStore, dto, this.sectionId));
        this.setPrintForms(printForms);
    }

    downloadAllPrintFormFiles(): Promise<void> {
        return this.printFormStore.downloadAllPrintFormFiles(this.sectionId).then((data) => {
            const fileName = `${this.intlStore.intl.formatMessage(
                { id: 'campaignRequest.allFiles' },
                { title: this.downloadAllFilesTitle },
            )}.zip`;
            downloadFile(data, fileName);
        });
    }

    clearUpdatePfListInterval(): void {
        if (this.updatePfListIntervalId) {
            clearInterval(this.updatePfListIntervalId);
            this.setUpdatePfListIntervalId(undefined);
        }
    }

    updateIfSomePrintFormIsGenerating(pfDelay: number): void {
        this.clearUpdatePfListInterval();
        if (this.isSomePrintFormGenerating) {
            const updatePfListInterval = setInterval(this.load, pfDelay);
            this.setUpdatePfListIntervalId(updatePfListInterval as unknown as number);
        }
    }

    dropIsLoading(): void {
        this.isLoading = false;
    }

    setPrintForms(printForms: PrintFormModel[]): void {
        this.printForms = printForms;
    }

    setUpdatePfListIntervalId(id?: number): void {
        this.updatePfListIntervalId = id;
    }

    setDownloadAllFilesTitle(downloadAllFilesTitle: string): void {
        this.downloadAllFilesTitle = downloadAllFilesTitle;
    }

    setIsWithEdit(isWithEdit: boolean): void {
        this.isWithEdit = isWithEdit;
    }

    setIsLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }
}

import { ObjectLayout, ObjectLayoutProps } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../hooks';
import {
    CampaignSettingsBody as CampaignSettingsBodyInj,
    CampaignSettingsBodyList as CampaignSettingsBodyListInj,
} from './campaign-settings-body';
import { CampaignSettingsPageHeader as CampaignSettingsPageHeaderInj } from './campaign-settings-page-header/CampaignSettingsPageHeader';

export const CampaignSettingsPage = observer((): JSX.Element => {
    const [CampaignSettingsPageHeader] = di([CampaignSettingsPageHeaderInj], CampaignSettingsPage);
    const [CampaignSettingsBody] = di([CampaignSettingsBodyInj], CampaignSettingsPage);
    const [CampaignSettingsBodyList] = di([CampaignSettingsBodyListInj], CampaignSettingsPage);

    const { campaignsStore } = useStore();

    useEffect(() => campaignsStore.dropCampaignRequestFormListModel, []);

    const objectLayoutProps: ObjectLayoutProps = {
        header: <CampaignSettingsPageHeader />,
        contentLayout: {
            content: <CampaignSettingsBody />,
            navigationSidebar: <CampaignSettingsBodyList />,
        },
    };

    return (
        <React.Fragment>
            <ObjectLayout {...objectLayoutProps} />
        </React.Fragment>
    );
});

import { IdTitle } from '@platform/front-types';
import { Transitions } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useCampaignPageContext, useStore } from '../../../hooks';

export type CampaignSettingsPublishCampaignProps = IdTitle;

export const CampaignSettingsPublishCampaign = observer((props: CampaignSettingsPublishCampaignProps): JSX.Element => {
    const { id, title } = props;
    const { campaignsStore } = useStore();
    const { reloadModel } = useCampaignPageContext();

    const confirmationDialogTitleLocale = {
        id: 'campaignSettings.confirmTransitionTitle',
    };

    const confirmationDialogMessageLocale = {
        id: 'campaignSettings.publishCampaignMessage',
        values: { title },
    };

    return (
        <Transitions
            objectId={id}
            transitionsStore={campaignsStore}
            onSuccessTransition={reloadModel}
            confirmationDialogTitleLocale={confirmationDialogTitleLocale}
            confirmationDialogMessageLocale={confirmationDialogMessageLocale}
            buttonSize="large"
        />
    );
});

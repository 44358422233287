import { Grid } from '@mui/material';
import { CommonInfoLine } from '@platform/front-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { CampaignRequestRow } from '../../../types';
import { renderCampaignRequestLink as renderCampaignRequestLinkInj } from './renderCampaignRequestLink';

export type CampaignRequestConfirmationDialogInfoProps = {
    campaignRequestRow: CampaignRequestRow;
};

export const CampaignRequestEditExecutorDialogInfo = (
    props: CampaignRequestConfirmationDialogInfoProps,
): JSX.Element => {
    const [renderCampaignRequestLink] = di([renderCampaignRequestLinkInj], CampaignRequestEditExecutorDialogInfo);

    const { campaignRequestRow } = props;
    const { customData } = campaignRequestRow;
    const { campaign, author, number } = customData;
    const { formatMessage } = useIntl();

    const authorId = author.id;
    const campaignId = campaign.id;
    const campaignTitle = campaign.title.title;
    const authorTitle = author.title.title;
    const requestNumber = number.title;

    const authorLink = renderCampaignRequestLink(generatePath(clientRoute.user, { id: authorId }), authorTitle);

    const campaignLink = renderCampaignRequestLink(
        generatePath(clientRoute.campaign, { id: campaignId }),
        campaignTitle,
    );

    return (
        <Grid item container direction="column" spacing={1}>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.number' })}
                    value={`№ ${requestNumber}`}
                />
            </Grid>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.author' })}
                    value={authorLink}
                />
            </Grid>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.campaign' })}
                    value={campaignLink}
                />
            </Grid>
        </Grid>
    );
};

import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { CampaignRequestPageContext, CampaignRequestPageContextType } from '../../../contexts';
import { useStore } from '../../../hooks';
import { RequestModel } from '../../../models';
import { CampaignRequestSectionRouteParams } from '../../../types';
import { TransitionsModel } from '@platform/front-ui';

export type CampaignRequestPageContainerProps = PropsWithChildren<{}>;

export const CampaignRequestPageContainer = observer((props: CampaignRequestPageContainerProps): JSX.Element => {
    const { children } = props;

    const rootStore = useStore();
    const { requestStore } = rootStore;
    const { notificationRequestConnectDisconnect } = rootStore.requestStore;
    const { id } = useParams<CampaignRequestSectionRouteParams>();
    const model = useMemo<RequestModel>((): RequestModel => new RequestModel(id, rootStore), [id, rootStore]);
    const { loadRequest } = model;

    const isRequestEdit = !!useRouteMatch(clientRoute.requestEdit);
    const isSyslog = !!useRouteMatch(clientRoute.requestSyslog);
    const isFormView = !useRouteMatch(clientRoute.requestSectionFormEdit);

    useEffect(() => {
        loadRequest(isRequestEdit, isSyslog);
        notificationRequestConnectDisconnect(id, true);
        return () => {
            notificationRequestConnectDisconnect(id);
        };
    }, [id]);

    const campaignRequestTransitionsModel = useMemo(() => {
        return new TransitionsModel(requestStore, id);
    }, [requestStore, id]);

    const contextValue: CampaignRequestPageContextType = {
        model,
        isFormView,
        isRequestEdit,
        isSyslog,
        campaignRequestTransitionsModel,
    };

    return <CampaignRequestPageContext.Provider value={contextValue}>{children}</CampaignRequestPageContext.Provider>;
});

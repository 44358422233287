var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
export var gridLabelMixin = function (theme) {
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        position: static;\n        transform: none;\n        overflow: visible;\n        white-space: normal;\n        max-width: none;\n        padding: 0;\n        margin-bottom: ", ";\n    "], ["\n        position: static;\n        transform: none;\n        overflow: visible;\n        white-space: normal;\n        max-width: none;\n        padding: 0;\n        margin-bottom: ", ";\n    "])), theme.spacing(1)).join('');
};
export var gridLabelRequiredMixin = function (theme) {
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        position: relative;\n        width: max-content;\n        overflow: hidden;\n        max-width: 100%;\n        margin-bottom: ", ";\n        padding-right: ", ";\n    "], ["\n        position: relative;\n        width: max-content;\n        overflow: hidden;\n        max-width: 100%;\n        margin-bottom: ", ";\n        padding-right: ", ";\n    "])), theme.spacing(1), theme.spacing(0.75)).join('');
};
var templateObject_1, templateObject_2;

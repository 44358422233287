import { Grid } from '@mui/material';
import { inputWithPlaceholderProps } from '@platform/front-core';
import { AutocompleteField, FieldWithServerError } from '@platform/front-ui';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { RequestFormSectionSettingModel } from '../../../../../../../../../../models';
import { RequestFormSectionSettingFormField } from '../../../../../../../../../../types';

export type CampaignSettingsSectionSettingDialogFormFieldsProps = {
    sectionSettingModel: RequestFormSectionSettingModel;
};

/**
 * Компонент, объединяющий поля настройки создания раздела
 * @prop sectionSettingModel - Модель формы настройки создания раздела
 */
export const CampaignSettingsSectionSettingDialogFormFields = observer(
    (props: CampaignSettingsSectionSettingDialogFormFieldsProps): JSX.Element => {
        const { sectionSettingModel } = props;
        const { getFieldLabel, getFieldPlaceholder, formCodeList, executorsList, parentSectionSettingList } =
            sectionSettingModel;

        const { title, form, parentSectionSetting, fileSize, identifier, openOnCreation, executor } =
            RequestFormSectionSettingFormField;

        const openOnCreationLabel = { label: getFieldLabel(openOnCreation) };

        return (
            <React.Fragment>
                <Grid item>
                    <FieldWithServerError
                        name={title}
                        label={getFieldLabel(title)}
                        placeholder={getFieldPlaceholder(title)}
                        {...inputWithPlaceholderProps}
                        required
                    />
                </Grid>
                <Grid item container justifyContent="space-between" spacing={2}>
                    <Grid item width="50%">
                        <FieldWithServerError
                            name={identifier}
                            label={getFieldLabel(identifier)}
                            placeholder={getFieldPlaceholder(identifier)}
                            {...inputWithPlaceholderProps}
                            required
                        />
                    </Grid>
                    <Grid item width="50%">
                        <FieldWithServerError
                            name={fileSize}
                            label={getFieldLabel(fileSize)}
                            placeholder={getFieldPlaceholder(fileSize)}
                            {...inputWithPlaceholderProps}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <AutocompleteField
                        fieldName={parentSectionSetting}
                        options={parentSectionSettingList}
                        label={getFieldLabel(parentSectionSetting)}
                        placeholder={getFieldPlaceholder(parentSectionSetting)}
                    />
                </Grid>
                <Grid item>
                    <AutocompleteField
                        fieldName={form}
                        options={formCodeList}
                        disableClearable={true}
                        required
                        label={getFieldLabel(form)}
                        placeholder={getFieldPlaceholder(form)}
                    />
                </Grid>
                <Grid item>
                    <AutocompleteField
                        fieldName={executor}
                        options={executorsList}
                        label={getFieldLabel(executor)}
                        placeholder={getFieldPlaceholder(executor)}
                    />
                </Grid>
                <Grid item>
                    <FieldWithServerError
                        name={openOnCreation}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        Label={openOnCreationLabel}
                        isWithoutHelperTextProp
                    />
                </Grid>
            </React.Fragment>
        );
    },
);

import { DeleteRelationPermissionGetter, getDeleteRelationPermissionGetter } from '@platform/front-core';
import { AuthorizationCheckQuery } from '@platform/front-types';

export const entities = {
    system: 'System',
    account: 'Account',
    campaign: 'Campaign',
    campaignRequestForm: 'CampaignRegForm',
    campaignPfTemplate: 'CampaignPfTemplate',
    campaignRequest: 'CampaignRequest',
    section: 'Section',
    sectionSettings: 'SectionSettings',
    comment: 'Comment',
};

export type Entities = keyof typeof entities;

export const permissions: Record<Entities, Record<string, string>> = {
    system: {
        Administration: 'Administration',
        AddCampaign: 'AddCampaign',
        ViewCampaignList: 'ViewCampaignList',
        ViewCampRequestList: 'ViewCampRequestList',
        ViewCampRequestRegistry: 'ViewCampRequestRegistry',
        ExportCampRequestList: 'ExportCampRequestList',
        ViewPersonList: 'ViewPersonList',
        ViewUserList: 'ViewUserList',
        AddCampaignRequest: 'AddCampaignRequest',
        ViewCampReqExecutorColumn: 'ViewCampReqExecutorColumn',
        ViewLogsRegistry: 'ViewLogsRegistry',
        Logout: 'Logout',
        ViewNotifications: 'ViewNotifications',
    },
    account: {
        LoginAs: 'LoginAs',
        Update: 'Update',
        UpdateForm: 'UpdateForm',
        View: 'View',
    },
    campaign: {
        View: 'View',
        Edit: 'Edit',
        Delete: 'Delete',
        AddCampRequest: 'AddCampRequest',
        ApproveProvisionalAppointment: 'ApproveProvisionalAppointment',
        ViewRequestsRegistryForCampaign: 'ViewRequestsRegistryForCampaign',
    },
    campaignRequestForm: {
        Edit: 'Edit',
        Delete: 'Delete',
        EditCampRequestFormSetting: 'EditCampRegFormSetting',
    },
    campaignPfTemplate: {
        Edit: 'Edit',
        Delete: 'Delete',
    },
    campaignRequest: {
        View: 'View',
        Edit: 'Edit',
        EditPf: 'EditPf',
        Delete: 'Delete',
        Send: 'Send',
        UpdateActualAppointment: 'UpdateActualAppointment',
        AddActualAppointment: 'AddActualAppointment',
        ViewRelations: 'ViewRelations',
        SaveRelation: 'SaveRelation',
        DeleteRelation: 'DeleteRelation',
        ViewSimilarityInfo: 'ViewSimilarityInfo',
    },
    section: {
        ViewPf: 'ViewPf',
        EditPf: 'EditPf',
        ViewComment: 'ViewComment',
        CreateComment: 'CreateComment',
        CreateCommentTopLvl: 'CreateCommentTopLvl',
        ViewApprovalFlows: 'ViewApprovalFlows',
    },
    comment: {
        Delete: 'Delete',
        Edit: 'Edit',
        ChangeState: 'ChangeState',
        CreateReplyComment: 'CreateReplyComment',
    },
    sectionSettings: {
        CommentManagement: 'CommentManagement',
    },
};

export const permissionsConfig = {
    administration: (): AuthorizationCheckQuery => ({
        entityCode: entities.system,
        permCode: permissions.system.Administration,
    }),
    updateActualAppointment: (campaignRequestId: string): AuthorizationCheckQuery => ({
        permCode: permissions.campaignRequest.UpdateActualAppointment,
        entityCode: entities.campaignRequest,
        entityId: campaignRequestId,
    }),
    addActualAppointment: (campaignRequestId: string): AuthorizationCheckQuery => ({
        permCode: permissions.campaignRequest.AddActualAppointment,
        entityCode: entities.campaignRequest,
        entityId: campaignRequestId,
    }),
    editCampaignRequest: (campaignRequestId: string): AuthorizationCheckQuery => ({
        permCode: permissions.campaignRequest.Edit,
        entityCode: entities.campaignRequest,
        entityId: campaignRequestId,
    }),
    deleteCampaignRequest: (campaignRequestId: string): AuthorizationCheckQuery => ({
        permCode: permissions.campaignRequest.Delete,
        entityCode: entities.campaignRequest,
        entityId: campaignRequestId,
    }),
    createCampaignRequest: (id: string): AuthorizationCheckQuery => ({
        permCode: permissions.campaign.AddCampRequest,
        entityCode: entities.campaign,
        entityId: id,
    }),
    viewCampaign: (id: string): AuthorizationCheckQuery => ({
        permCode: permissions.campaign.View,
        entityCode: entities.campaign,
        entityId: id,
    }),
    editCampaign: (id: string): AuthorizationCheckQuery => ({
        permCode: permissions.campaign.Edit,
        entityCode: entities.campaign,
        entityId: id,
    }),
    createTopLevelComments: (sectionId: string): AuthorizationCheckQuery => ({
        entityCode: entities.section,
        permCode: permissions.section.CreateCommentTopLvl,
        entityId: sectionId,
    }),
    createReplyComment: (commentId: string): AuthorizationCheckQuery => ({
        entityCode: entities.comment,
        permCode: permissions.comment.CreateReplyComment,
        entityId: commentId,
    }),
    editComment: (id: string): AuthorizationCheckQuery => ({
        entityCode: entities.comment,
        permCode: permissions.comment.Edit,
        entityId: id,
    }),
    deleteComment: (id: string): AuthorizationCheckQuery => ({
        entityCode: entities.comment,
        permCode: permissions.comment.Delete,
        entityId: id,
    }),
    changeCommentState: (id: string): AuthorizationCheckQuery => ({
        entityCode: entities.comment,
        permCode: permissions.comment.ChangeState,
        entityId: id,
    }),
    viewApprovalSheets: (id: string): AuthorizationCheckQuery => ({
        entityCode: entities.section,
        permCode: permissions.section.ViewApprovalFlows,
        entityId: id,
    }),
    viewRelations: (campaignRequestId: string): AuthorizationCheckQuery => ({
        entityCode: entities.campaignRequest,
        permCode: permissions.campaignRequest.ViewRelations,
        entityId: campaignRequestId,
    }),
    saveRelation: (campaignRequestId: string): AuthorizationCheckQuery => ({
        entityCode: entities.campaignRequest,
        permCode: permissions.campaignRequest.SaveRelation,
        entityId: campaignRequestId,
    }),
    deleteRelations: (campaignRequestId: string): AuthorizationCheckQuery => ({
        entityCode: entities.campaignRequest,
        permCode: permissions.campaignRequest.DeleteRelation,
        entityId: campaignRequestId,
    }),
    deleteRelation: (campaignRequestId: string): DeleteRelationPermissionGetter =>
        getDeleteRelationPermissionGetter(entities.campaignRequest, campaignRequestId),
    viewSimilarObjects: (campaignRequestId: string): AuthorizationCheckQuery => ({
        entityCode: entities.campaignRequest,
        permCode: permissions.campaignRequest.ViewSimilarityInfo,
        entityId: campaignRequestId,
    }),
};

export const authSchemeConfig = { entities, permissions, permissionsConfig };

import { makeSxStyles } from '@platform/front-ui';

export const requestFormCardSxStyles = makeSxStyles({
    radio: {
        marginRight: (theme) => theme.spacing(2),
        width: '20px',
        height: '20px',
    },
    requestFormInstructionLink: {
        wordBreak: 'break-word',
    },
    requestFormInfoContainer: {
        display: 'grid',
        gridTemplateRows: 'repeat(2, auto)',
        rowGap: (theme) => theme.spacing(0.5),
    },

    requestFormColumns: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, auto)',
        gridTemplateRows: 'repeat(2, auto)',
        rowGap: (theme) => theme.spacing(0.5),
        columnGap: (theme) => theme.spacing(2),
    },
});

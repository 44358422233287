import SettingsIcon from '@mui/icons-material/Settings';
import { CardMedia, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { DialogActionButton, DialogSubmitButtonConfig, DynamicEllipsisBox, Tag } from '@platform/front-ui';
import { PromiseVoidFunction, useAntiDoubleClick } from '@platform/front-utils';
import { isAfter } from 'date-fns';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { generatePath, Link, NavLink } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { ICSDataTestIds } from '../../../constants/data-test-ids';
import { ImagePlaceholder, NoteList } from '../../../resources';
import { CampaignCardDTO } from '../../../types';
import { campaignListCardSxStyles } from './campaignListCardSxStyles';

export type CampaignListCardProps = {
    campaign: CampaignCardDTO;
    onCreateRequestButtonClick: PromiseVoidFunction;
};

export const CampaignListCard = observer((props: CampaignListCardProps): JSX.Element => {
    const { campaign, onCreateRequestButtonClick } = props;
    const { avatarUrl, title, id, to, requestCount } = campaign;
    const [showRequestsCount] = useFeature('showRequestsCount');
    const { formatMessage } = useIntl();

    const isCollectionCompleted = to ? isAfter(new Date(), to) : false;

    const campaignPath = generatePath(clientRoute.campaignDescriptionCommonInfo, { id });

    const theme = useTheme();
    const sx = campaignListCardSxStyles(theme);

    const handlePreventDefault = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();
    };

    const handleCreateRequestButtonClick = (event: React.MouseEvent<HTMLElement>): Promise<void> => {
        handlePreventDefault(event);
        return onCreateRequestButtonClick();
    };

    const [isButtonDisabled, buttonEndIcon, createHandler] = useAntiDoubleClick(handleCreateRequestButtonClick);

    const dialogActionButtonConfig = (allowed: boolean): DialogSubmitButtonConfig & { 'data-testid': string } => ({
        text: formatMessage({ id: 'campaign.createRequest' }),
        onClick: createHandler,
        endIcon: buttonEndIcon,
        disabled: isButtonDisabled || !allowed,
        tooltip: !allowed ? { title: formatMessage({ id: 'campaign.createRequestError' }) } : undefined,
        'data-testid': ICSDataTestIds.campaignListCardCreateRequestButton,
    });

    return (
        <Grid container item sx={sx.wrapper}>
            <Grid container item component={NavLink} to={campaignPath} sx={sx.inner} wrap="nowrap">
                <Grid item sx={sx.imageWrapper}>
                    {avatarUrl ? (
                        <CardMedia
                            sx={sx.image}
                            component="img"
                            image={avatarUrl}
                            alt={title}
                            data-testid={ICSDataTestIds.campaignListCardImage}
                        />
                    ) : (
                        <Grid
                            container
                            item
                            sx={sx.image}
                            alignItems="center"
                            justifyContent="center"
                            data-testid={ICSDataTestIds.campaignListCardImage}
                        >
                            <ImagePlaceholder />
                        </Grid>
                    )}
                </Grid>

                <Grid
                    container
                    item
                    direction="column"
                    justifyContent="space-between"
                    sx={sx.contentWrapper}
                    wrap="nowrap"
                >
                    <Grid item>
                        <Typography variant="body1" sx={sx.title}>
                            <DynamicEllipsisBox text={title} startTimeout={500} />
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="space-between"
                        wrap="nowrap"
                        minHeight={32}
                    >
                        <Grid container item alignItems="center" wrap="nowrap" sx={sx.leftButtonsGroup}>
                            <Grid item pr={2}>
                                <Tag
                                    color={isCollectionCompleted ? 'default' : 'success'}
                                    sx={sx.date}
                                    data-testid={ICSDataTestIds.campaignListCardDate}
                                >
                                    <FormattedDate
                                        day="numeric"
                                        month="numeric"
                                        year="numeric"
                                        hour="numeric"
                                        minute="numeric"
                                        value={to}
                                    />
                                </Tag>
                            </Grid>

                            {showRequestsCount && (
                                <Grid
                                    container
                                    item
                                    alignItems="center"
                                    wrap="nowrap"
                                    width="auto"
                                    data-testid={ICSDataTestIds.campaignListCardRequestCountContainer}
                                >
                                    <Grid item container mr={0.5} width="24px" px={0.375}>
                                        <NoteList />
                                    </Grid>
                                    <Grid item data-testid={ICSDataTestIds.campaignListCardRequestCountValue}>
                                        {requestCount}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>

                        <Grid container item justifyContent="space-between" wrap="nowrap" sx={sx.rightButtonsGroup}>
                            <Grid item pr={2}>
                                <AuthorizationCheck
                                    entityId={id}
                                    entityCode={entities.campaign}
                                    permCode={permissions.campaign.Edit}
                                >
                                    <IconButton
                                        sx={sx.settings}
                                        className="settings-button"
                                        component={Link}
                                        to={generatePath(clientRoute.campaignSettingsDescription, { id })}
                                        data-testid={ICSDataTestIds.campaignListCardSettingsButton}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                </AuthorizationCheck>
                            </Grid>

                            <Grid item onClick={handlePreventDefault}>
                                <AuthorizationCheck
                                    entityCode={entities.campaign}
                                    permCode={permissions.campaign.AddCampRequest}
                                    entityId={id}
                                >
                                    {(allowed) => <DialogActionButton {...dialogActionButtonConfig(allowed)} />}
                                </AuthorizationCheck>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

import { MenuButton, MenuButtonProps } from '@platform/front-ui';
import { VoidFunction } from '@platform/front-utils';
import { TTableKebabButton } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { UserTableRow } from '../../../../types';
import { UserStatusButton as UserStatusButtonInj } from './UserStatusButton';

export type UserRegistryActionsBtnsProps = {
    userTableRow: UserTableRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

const renderButton: MenuButtonProps['renderButton'] = (onClick) => {
    return <TTableKebabButton onClick={onClick} />;
};

export const UserRegistryActionsBtns = observer((props: UserRegistryActionsBtnsProps): JSX.Element => {
    const [UserStatusButton] = di([UserStatusButtonInj], UserRegistryActionsBtns);

    const { userTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;

    const renderActionItems = useCallback(
        (hideMenu?: VoidFunction): ReactNode[] => {
            return [
                <UserStatusButton
                    key="actionBtns"
                    userTableRow={userTableRow}
                    reloadData={reloadData}
                    hideMenu={hideMenu}
                    toggleIsRowBlocked={toggleIsRowBlocked}
                    rowErrorHandler={rowErrorHandler}
                />,
            ];
        },
        [userTableRow, reloadData, toggleIsRowBlocked, rowErrorHandler],
    );

    return <MenuButton renderButton={renderButton} renderMenuItems={renderActionItems} />;
});

import { TableCell, TableRow, Typography } from '@mui/material';
import { SxStyle } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sxWidth15, sxWidth20, sxWidth25 } from '../../../../../../../../constants';
import { useCampaignRequestPrintFormsContext } from '../../../../../../../../hooks';

export const campaignRequestPrintFormsTableHeadActionCellSx: SxStyle = {
    width: '20%',
    minWidth: '174px',
};

export const CampaignRequestPrintFormsTableHeadRow = observer((): JSX.Element => {
    const { model } = useCampaignRequestPrintFormsContext();
    const { isWithEdit } = model;

    return (
        <TableRow>
            <TableCell sx={sxWidth20}>
                <Typography>
                    <FormattedMessage id="printForms.table.fileColumnName" />
                </Typography>
            </TableCell>
            <TableCell sx={sxWidth25}>
                <Typography>
                    <FormattedMessage id="printForms.table.documentCategory" />
                </Typography>
            </TableCell>
            <TableCell sx={sxWidth20}>
                <Typography>
                    <FormattedMessage id="printForms.table.taskStatus" />
                </Typography>
            </TableCell>
            <TableCell sx={sxWidth15}>
                <Typography>
                    <FormattedMessage id="printForms.table.dateColumnName" />
                </Typography>
            </TableCell>
            {isWithEdit && (
                <TableCell sx={campaignRequestPrintFormsTableHeadActionCellSx}>
                    <Typography>
                        <FormattedMessage id="common.actions" />
                    </Typography>
                </TableCell>
            )}
        </TableRow>
    );
});

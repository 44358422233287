import { SignatureSettings, SignatureStatus } from '@platform/crypto-ui';
import { FileDTO } from '@platform/front-types';
import downloadFile from 'js-file-download';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { PrintFormStore } from '../stores';
import { PrintFormDTO, PrintFormStatus, PrintFormStatusCode } from '../types';

export const PrintFormModelProps = {
    printFormStore: observable,
    sectionId: observable,
    templateId: observable,
    filename: observable,
    pf: observable,
    docCategory: observable,
    status: observable,
    created: observable,
    signatureStatus: observable,
    signatureSettings: observable,
    isGenerating: observable,
    required: observable,
    load: action.bound,
    generatePrintForm: action.bound,
    downloadPrintForm: action.bound,
    updatePrintForm: action.bound,
    dropIsGenerating: action.bound,
    setIsGenerating: action.bound,
};

export class PrintFormModel {
    private printFormStore: PrintFormStore;

    sectionId = '';
    templateId = '';
    filename = '';
    required = '';
    signatureStatus: SignatureStatus = SignatureStatus.WITHOUT;
    signatureSettings: SignatureSettings = {
        signatureEnabled: false,
        signatureBrowserPlugin: false,
        signatureRequired: false,
        signatureUpload: false,
    };
    pf?: FileDTO;
    docCategory?: string;
    status: PrintFormStatus = {
        code: PrintFormStatusCode.new,
        title: '',
    };
    created?: string;

    isGenerating = false;

    constructor(printFormStore: PrintFormStore, dto: PrintFormDTO, sectionId: string) {
        makeObservable(this, PrintFormModelProps);
        this.sectionId = sectionId;
        this.printFormStore = printFormStore;
        this.load(dto);
    }

    load(dto: PrintFormDTO): void {
        const { templateId, filename, pf, docCategory, status, created, required, signatureStatus, signatureSettings } =
            dto;
        this.templateId = templateId;
        this.filename = filename;
        this.pf = pf;
        this.docCategory = docCategory;
        this.status = status;
        this.created = created;
        this.required = required;
        this.signatureStatus = signatureStatus;
        this.signatureSettings = signatureSettings;

        const isGenerating = status.code === PrintFormStatusCode.processing;
        this.setIsGenerating(isGenerating);
    }

    generatePrintForm(
        validateFunction: (onSuccess?: () => Promise<void>, withoutSignatureValidate?: boolean) => Promise<void>,
    ): Promise<void> {
        this.setIsGenerating(true);
        return validateFunction((): Promise<void> => {
            return this.printFormStore.generatePrintForm(this.sectionId, this.templateId);
        }, true).catch(this.dropIsGenerating);
    }

    downloadPrintForm(): Promise<void> {
        const { filename, mimeType } = this.pf!;

        return this.printFormStore
            .downloadPrintForm(this.sectionId, this.templateId)
            .then((file) => downloadFile(file, filename, mimeType));
    }

    updatePrintForm(): void {
        this.printFormStore.getPrintForm(this.templateId, this.sectionId).then(this.load);
    }

    dropIsGenerating(): void {
        this.isGenerating = false;
    }

    setIsGenerating(isGenerating: boolean): void {
        this.isGenerating = isGenerating;
    }
}

export const getPrintFormModel = (): any => {
    const [_PrintFormModel] = di([PrintFormModel], getPrintFormModel);
    return _PrintFormModel;
};

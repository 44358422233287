import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { IconButton } from '@mui/material';
import { MenuButton, MenuButtonProps } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { RequestSubSectionListDTO } from '../../../../../types';
import { CampaignRequestSubSection as CampaignRequestSubSectionInj } from './CampaignRequestSubSection';

export type CampaignRequestSectionContentMenuProps = {
    subSections: RequestSubSectionListDTO[];
};

export const CampaignRequestSectionContentMenu = observer(
    (props: CampaignRequestSectionContentMenuProps): JSX.Element => {
        const [CampaignRequestSubSection] = di([CampaignRequestSubSectionInj], CampaignRequestSectionContentMenu);

        const { subSections } = props;

        const renderSubSectionsButton: MenuButtonProps['renderButton'] = (openMenu, isMenuOpen): JSX.Element => {
            return (
                <IconButton size="small" onClick={openMenu}>
                    {isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            );
        };

        const renderSubSections: MenuButtonProps['renderMenuItems'] = (hideMenu): JSX.Element[] => {
            return subSections.map((section): JSX.Element => {
                const { id: subSectionId } = section;
                return <CampaignRequestSubSection key={subSectionId} hideMenu={hideMenu} subSection={section} />;
            });
        };

        return <MenuButton renderButton={renderSubSectionsButton} renderMenuItems={renderSubSections} />;
    },
);

import { makeAutoObservable } from 'mobx';
var FormioFormApi = /** @class */ (function () {
    function FormioFormApi(form, readOnly) {
        if (readOnly === void 0) { readOnly = false; }
        Object.defineProperty(this, "form", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "readOnly", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeAutoObservable(this);
        this.form = form;
        this.readOnly = readOnly;
    }
    Object.defineProperty(FormioFormApi.prototype, "setSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (s) {
            return this.form.setSubmission(s);
        }
    });
    Object.defineProperty(FormioFormApi.prototype, "getSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return Object.assign({}, this.form.submission);
        }
    });
    Object.defineProperty(FormioFormApi.prototype, "getExpertiseInfo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var expertiseForm = this.form.components.find(function (_) { return _.component.type === 'expertiseForm'; });
            var getExpertiseInfo = expertiseForm === null || expertiseForm === void 0 ? void 0 : expertiseForm.getExpertiseInfo;
            getExpertiseInfo = getExpertiseInfo === null || getExpertiseInfo === void 0 ? void 0 : getExpertiseInfo.bind(expertiseForm);
            if (typeof getExpertiseInfo === 'function') {
                return getExpertiseInfo();
            }
            else {
                return undefined;
            }
        }
    });
    Object.defineProperty(FormioFormApi.prototype, "validate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.form.setPristine(false);
            return this.form.checkData(this.getSubmission().data, {}, null);
        }
    });
    Object.defineProperty(FormioFormApi.prototype, "submit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (validation) {
            var options = validation ? { state: 'submitted' } : { state: 'draft' };
            return this.form.submit(false, options);
        }
    });
    Object.defineProperty(FormioFormApi.prototype, "destroy", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.form.destroy();
        }
    });
    return FormioFormApi;
}());
export { FormioFormApi };

import { RoutedTabs, RouteSetting } from '@platform/front-core';
import { AuthorizationCheckQuery } from '@platform/front-types';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { commonTabsProps } from '../../../../constants';
import { useStore } from '../../../../hooks';
import { TabData } from '../../../../types';

export enum CampaignCardBodyTabCode {
    description = 'description',
    requests = 'requests',
}

export type CampaignCardBodyTabData = TabData & {
    tabCode: CampaignCardBodyTabCode;
    query?: AuthorizationCheckQuery;
};

export const getCampaignCardBodyTabsDataInj = (): CampaignCardBodyTabData[] => [
    {
        tabCode: CampaignCardBodyTabCode.description,
        title: <FormattedMessage id="campaign.description" />,
        path: clientRoute.campaignDescription,
    },
    {
        tabCode: CampaignCardBodyTabCode.requests,
        title: <FormattedMessage id="campaign.requests" />,
        path: clientRoute.campaignRequests,
    },
];

export const CampaignCardTabs = observer((): JSX.Element => {
    const [getCampaignCardBodyTabsData] = di([getCampaignCardBodyTabsDataInj], CampaignCardTabs);

    const { coreRootStore } = useStore();
    const { authorizationStore } = coreRootStore;

    const { id } = useParams<RouteParamsDefault>();
    const tabsData = getCampaignCardBodyTabsData();

    const [isRequestsTabAllowed, setIsRequestsTabAllowed] = useState<boolean>(false);

    const requestsTabQuery: AuthorizationCheckQuery = {
        entityCode: entities.campaign,
        permCode: permissions.campaign.ViewRequestsRegistryForCampaign,
        entityId: id,
    };

    useEffect(() => {
        authorizationStore.check(requestsTabQuery).then(setIsRequestsTabAllowed);
    }, []);

    const settings: RouteSetting[] = tabsData
        .map((tabData) => ({
            title: tabData.title,
            tab: tabData.tabCode,
            path: generatePath(tabData.path, { id }),
        }))
        .filter((setting) => {
            if (setting.tab === CampaignCardBodyTabCode.requests) {
                return isRequestsTabAllowed;
            }
            return true;
        });

    return <RoutedTabs tabsProps={commonTabsProps} settings={settings} />;
});

import { SvgIcon } from '@mui/material';
import { brand, grey, SxStyle } from '@platform/front-ui';
import React, { CSSProperties } from 'react';

const getAnimationProperty = (delay: number): CSSProperties['animation'] =>
    `fillChange 1s cubic-bezier(0.47, 0, 0.745, 0.715) ${delay}s infinite`;

const svgIconSx: SxStyle = {
    width: '100px',
    height: '150px',
    '@keyframes fillChange': {
        '0%': {
            fill: grey[0],
        },
        '100%': {
            fill: brand[600],
        },
    },
    '[class*="lazysvg__item1"]': {
        animation: getAnimationProperty(3.2),
    },
    '[class*="lazysvg__item2"]': {
        animation: getAnimationProperty(2.4),
    },
    '[class*="lazysvg__item3"]': {
        animation: getAnimationProperty(1.6),
    },
    '[class*="lazysvg__item4"]': {
        animation: getAnimationProperty(0.8),
    },
};

// LazyLoader - компонент, необходимый для реализации Lazy страниц

export const LazyLoader = (): JSX.Element => {
    return (
        <SvgIcon className="lazysvg" sx={svgIconSx} viewBox="34.3 0 433.8 500">
            <path
                d="M267.5,143.7v0.7v211.7v0.9c0.1,2.7,1.7,5.2,4.1,6.7c2.5,1.4,5.4,1.4,7.8,0.2l0.8-0.5l183.3-105.9l0.6-0.4
            c2.4-1.4,3.9-4.1,3.9-7.1c0-3-1.5-5.6-3.9-7.1c-0.1-0.1-0.4-0.2-0.5-0.4c-27.5-16-155.4-89.7-183.2-105.8c-0.4-0.2-0.7-0.4-1.1-0.6
            c-2.4-1.2-5.3-1.2-7.8,0.2C269,138.4,267.6,141,267.5,143.7z"
                fill={brand[600]}
                className="lazysvg__item1"
            />
            <path
                d="M34.3,278.1v0.7v211.7v0.9c0.1,2.7,1.7,5.2,4.1,6.7c2.5,1.4,5.4,1.4,7.8,0.2L47,498l183.3-105.9l0.6-0.4
            c2.4-1.4,3.9-4.1,3.9-7.1c0-3-1.5-5.6-3.9-7.1c-0.1-0.1-0.4-0.2-0.5-0.4c-27.5-16-155.4-89.7-183.2-105.8c-0.4-0.2-0.7-0.4-1.1-0.6
            c-2.4-1.2-5.3-1.2-7.8,0.2C35.8,272.7,34.4,275.4,34.3,278.1z"
                fill={brand[600]}
                className="lazysvg__item2"
            />
            <path
                d="M54.7,257l0.6,0.4l183.3,105.9l0.8,0.5c2.4,1.2,5.3,1.2,7.9-0.2c2.5-1.4,4-4,4.1-6.7v-0.9V144.1v-0.6
            c-0.1-2.8-1.5-5.4-4.1-7c-2.6-1.5-5.7-1.4-8.2-0.1c-0.1,0.1-0.4,0.2-0.5,0.4c-27.5,15.8-155.4,89.7-183.2,105.8
            c-0.4,0.2-0.7,0.4-1.1,0.6c-2.2,1.5-3.7,4-3.7,6.9C50.8,253,52.4,255.6,54.7,257z"
                fill={brand[600]}
                className="lazysvg__item3"
            />
            <path
                d="M34.3,8.6v0.7V221v0.9c0.1,2.7,1.7,5.2,4.1,6.7c2.5,1.4,5.4,1.4,7.8,0.2l0.8-0.5l183.3-105.9l0.6-0.4
            c2.4-1.4,3.9-4.1,3.9-7.1c0-3-1.5-5.6-3.9-7.1c-0.1-0.1-0.4-0.2-0.5-0.4C203,92,75.1,18.1,47.3,2c-0.4-0.2-0.7-0.4-1.1-0.6
            c-2.4-1.2-5.3-1.2-7.8,0.2C35.8,3.3,34.4,5.9,34.3,8.6z"
                fill={brand[600]}
                className="lazysvg__item4"
            />
        </SvgIcon>
    );
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormioComponentType, } from '@platform/front-types';
import { defaultFormsLocale } from '@platform/front-utils';
import { makeAutoObservable } from 'mobx';
import { getComponentsKeysByType, getFilesDataFromSubmission } from '../utils';
var FormApi = /** @class */ (function () {
    function FormApi(formApiProps, formModel) {
        Object.defineProperty(this, "formioFormApi", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "formModel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "lang", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: defaultFormsLocale
        });
        Object.defineProperty(this, "readOnly", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "multiLang", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        makeAutoObservable(this, {}, { autoBind: true });
        this.formModel = formModel;
        this.lang = formApiProps.lang;
        this.readOnly = formApiProps.readOnly;
        this.multiLang = formApiProps.multiLang;
    }
    Object.defineProperty(FormApi.prototype, "defaultSubmission", {
        get: function () {
            return {
                ru: {
                    data: {},
                    meta: {},
                },
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormApi.prototype, "setFormioFormApi", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (formioFormApi) {
            this.formioFormApi = formioFormApi;
        }
    });
    Object.defineProperty(FormApi.prototype, "setLang", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lang) {
            this.lang = lang;
        }
    });
    Object.defineProperty(FormApi.prototype, "onChangeLang", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lang) {
            this.saveLangSubmission(); // save prev lang submission
            this.setLang(lang);
        }
    });
    Object.defineProperty(FormApi.prototype, "saveLangSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.readOnly && this.formioFormApi) {
                var lang = this.multiLang ? this.lang : defaultFormsLocale;
                this.formModel.setLangSubmission(lang, this.formioFormApi.getSubmission());
            }
        }
    });
    Object.defineProperty(FormApi.prototype, "form", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            return (_a = this.formioFormApi) === null || _a === void 0 ? void 0 : _a.form;
        }
    });
    Object.defineProperty(FormApi.prototype, "getSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.saveLangSubmission();
            var submission = this.formModel.getFullSubmission();
            return Object.keys(submission).length ? submission : this.defaultSubmission;
        }
    });
    Object.defineProperty(FormApi.prototype, "getSubmissionWithAdditionalInfo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var submission = this.getSubmission();
            var filesKeys = getComponentsKeysByType(this.formModel.form.components, [FormioComponentType.categoryFile]);
            var filesData = getFilesDataFromSubmission(submission, filesKeys);
            var submissionWithAdditionalInfo = {
                submission: submission,
                additionalInfo: {
                    files: filesData,
                },
            };
            return submissionWithAdditionalInfo;
        }
    });
    Object.defineProperty(FormApi.prototype, "setStartSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var lang = this.multiLang ? this.lang : undefined;
            this.formModel.setStartSubmission(lang);
        }
    });
    Object.defineProperty(FormApi.prototype, "setSubmissionFromStartSubmission", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.formModel.setSubmissionFromStartSubmission();
        }
    });
    Object.defineProperty(FormApi.prototype, "getExpertiseInfo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            return (_a = this.formioFormApi) === null || _a === void 0 ? void 0 : _a.getExpertiseInfo();
        }
    });
    Object.defineProperty(FormApi.prototype, "validate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.formioFormApi ? this.formioFormApi.validate() : true;
        }
    });
    Object.defineProperty(FormApi.prototype, "submit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (validation) {
            return this.formioFormApi ? this.formioFormApi.submit(validation) : Promise.resolve();
        }
    });
    Object.defineProperty(FormApi.prototype, "destroy", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            (_a = this.formioFormApi) === null || _a === void 0 ? void 0 : _a.destroy();
        }
    });
    /*
     * Не сохраняет новый сабмишен в formModel из-за чего не происходит ререндер формы.
     * Использовать только для форм без кнопки 'отмена' (без режима просмотра) т.к. не обновляет `submission`.
     */
    Object.defineProperty(FormApi.prototype, "getSubmissionWithoutRerender", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            if (this.formioFormApi) {
                var newLangSubmission = this.formioFormApi.getSubmission();
                var oldSubmission = this.formModel.submission;
                return __assign(__assign({}, oldSubmission), (_a = {}, _a[this.multiLang ? this.lang : defaultFormsLocale] = newLangSubmission, _a));
            }
            return this.defaultSubmission;
        }
    });
    return FormApi;
}());
export { FormApi };

import { Box, useTheme } from '@mui/material';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../hooks';
import { CampaignRequestFormListMember } from '../../../types';
import {
    RequestFormCardInfo as RequestFormCardInfoInj,
    RequestFormCardTitleWithRadio as RequestFormCardTitleWithRadioInj,
} from '../components';

export type CreateRequestDialogRequestFormCardProps = {
    requestForm: CampaignRequestFormListMember;
};

export const CreateRequestDialogRequestFormCard = (props: CreateRequestDialogRequestFormCardProps): JSX.Element => {
    const [RequestFormCardInfo] = di([RequestFormCardInfoInj], CreateRequestDialogRequestFormCard);
    const [RequestFormCardTitleWithRadio] = di([RequestFormCardTitleWithRadioInj], CreateRequestDialogRequestFormCard);
    const { requestForm } = props;
    const { id, title } = requestForm;

    const { campaignsStore } = useStore();
    const theme = useTheme();
    const borderCard = `1px solid ${theme.palette.grey[200]}`;

    return (
        <Box pb={2}>
            <Box padding={2.25} borderRadius="9px" border={borderCard}>
                <RequestFormCardTitleWithRadio name="requestFormId" id={id} title={title} />
                <Box pl={3.75} pt={1.25}>
                    <RequestFormCardInfo
                        requestForm={requestForm}
                        onDownloadFile={campaignsStore.downloadRequestFormFile}
                    />
                </Box>
            </Box>
        </Box>
    );
};

import { Box, Container, Grid, Typography } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { makeSxStyles } from '@platform/front-ui';
import { VoidFunction } from '@platform/front-utils';
import { TTableRowActions } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import { ttableDefaultPaths } from '../../../constants';
import { CampaignRequestRow } from '../../../types';
import { CampaignRequestListActionsBtns as CampaignRequestListActionsBtnsInj } from './campaign-request-list-actions-buttons';
import { CampaignRequestList as CampaignRequestListInj } from './CampaignRequestList';

export const campaignRequestListTableSxStyles = makeSxStyles({
    container: {
        paddingLeft: (theme) => `${theme.spacing(2)} !important`,
        paddingRight: (theme) => `${theme.spacing(2)} !important`,
    },
    tableWrapper: {
        width: '100%',
    },
});

export const campaignRequestSetRowActionsInj: TTableRowActions<CampaignRequestRow> = (
    row: CampaignRequestRow,
    reloadData: VoidFunction,
    toggleIsRowBlocked: VoidFunction,
    rowErrorHandler: VoidFunction,
): JSX.Element => {
    const [CampaignRequestListActionsBtns] = di([CampaignRequestListActionsBtnsInj], CampaignRequestListPage);

    return (
        <CampaignRequestListActionsBtns
            campaignRequestRow={row}
            reloadData={reloadData}
            toggleIsRowBlocked={toggleIsRowBlocked}
            rowErrorHandler={rowErrorHandler}
        />
    );
};

export const CampaignRequestListPage = observer((): JSX.Element => {
    const [CampaignRequestList] = di([CampaignRequestListInj], CampaignRequestListPage);
    const [campaignRequestSetRowActions] = di([campaignRequestSetRowActionsInj], CampaignRequestListPage);

    const { formatMessage } = useIntl();
    const tableTitle = formatMessage({ id: 'campaignRequest.registryTitle' });

    return (
        <AuthorizationCheck
            entityCode={entities.system}
            permCode={permissions.system.ViewCampRequestRegistry}
            isWithRedirect
        >
            <Container maxWidth={false} sx={campaignRequestListTableSxStyles.container}>
                <Box pt={2}>
                    <Grid container spacing={2} flexDirection="column">
                        <Grid item>
                            <Typography variant="subtitle2" component="h1">
                                {tableTitle}
                            </Typography>
                        </Grid>
                        <Grid item sx={campaignRequestListTableSxStyles.tableWrapper}>
                            <CampaignRequestList
                                setRowActions={campaignRequestSetRowActions}
                                title={tableTitle}
                                defaultPath={ttableDefaultPaths.requests}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </AuthorizationCheck>
    );
});

import { LocalesTree } from '@platform/front-utils';

const printForms: LocalesTree = {
    table: {
        loading: 'Пожалуйста подождите, идет подготовка к генерации печатных форм документов',
        title: 'Печатные формы документов',
        fileColumnName: 'Файл',
        authorColumnName: 'ФИО',
        dateColumnName: 'Дата',
        documentCategory: 'Категория документа',
        generateAction: 'Сгенерировать',
        updateAction: 'Обновить',
        cannotGenerateError: 'Невозможно сгенерировать печатную форму: форма заявки заполнена некорректно',
        cannotGenerateErrorWithLink:
            'Невозможно сгенерировать печатную форму: форма заявки заполнена некорректно. Для исправления ошибок перейдите к <a>редактированию</a>.',
        generationError: 'При генерации печатной формы произошла ошибка',
        downloadAllButton: 'Скачать все',
        taskStatus: 'Статус генерации',
    },
};

export default printForms;

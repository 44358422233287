import { ButtonProps, Grid } from '@mui/material';
import { Form, FormApi } from '@platform/formiojs-react';
import { coreClientRoute, CoreRootStore, FormEditButton, FormSavePanel, scrollContainerSx } from '@platform/front-core';
import { FormDTO } from '@platform/front-types';
import { SxStyle } from '@platform/front-ui';
import { useCoreStore, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { useUserPageContext } from '../../../../hooks';

export type UserPageMainContentProps = {
    formDTO: FormDTO;
};

export const userPageMainContentSx: SxStyle = {
    ...scrollContainerSx,
    pl: 2,
    pr: 1,
};

export const userPageSubmitBtnProps: ButtonProps = { color: 'secondary' };

export const UserPageMainContent = observer((props: UserPageMainContentProps): JSX.Element => {
    const { formDTO } = props;
    const { intlStore } = useCoreStore() as CoreRootStore;
    const { userModel } = useUserPageContext();
    const { id, saveForm } = userModel;

    const [formApi, setFormApi] = useState<FormApi>();
    const [formIsChanged, , setFormIsChangedFalse, setFormIsChanged] = useFlag(false);

    const isReadOnlyForm = !useRouteMatch(coreClientRoute.userEdit);

    const onSubmit = async (): Promise<boolean> => {
        if (formApi) {
            return saveForm(formApi.getSubmission()).then(() => true);
        }
        return Promise.reject();
    };

    return (
        <Grid container item direction="column" wrap="nowrap">
            <Grid container item direction="column" sx={userPageMainContentSx}>
                {isReadOnlyForm && <FormEditButton formEditPath={generatePath(coreClientRoute.userEdit, { id })} />}
                <Form
                    ownerEntityId={id}
                    multiLang={true}
                    readOnly={isReadOnlyForm}
                    onFormReady={setFormApi}
                    formDTO={formDTO}
                    intlStore={intlStore}
                    setFormIsChanged={setFormIsChanged}
                />
            </Grid>
            {!isReadOnlyForm && (
                <FormSavePanel
                    formApi={formApi}
                    isWithValidation={true}
                    returnPath={generatePath(coreClientRoute.user, { id })}
                    afterSubmit={onSubmit}
                    submitBtnProps={userPageSubmitBtnProps}
                    formIsChanged={formIsChanged}
                    setFormIsChangedFalse={setFormIsChangedFalse}
                />
            )}
        </Grid>
    );
});

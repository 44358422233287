import { AuthorizationCheck, handleMainInfoError } from '@platform/front-core';
import { TTable } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { ttableDefaultPaths } from '../../../../constants';
import { useStore } from '../../../../hooks';
import { RegistryCode, UserTableRow } from '../../../../types';
import { UserRegistrySetTableRowActionBtnsType } from '../UserRegistryPage';

export type UserRegistryTableProps = {
    setRowActions: UserRegistrySetTableRowActionBtnsType;
    tableTitle: string;
    registryCode?: string;
};

export const UserRegistryTable = observer((props: UserRegistryTableProps): JSX.Element => {
    const { registryCode = RegistryCode.account, tableTitle, setRowActions } = props;

    const { coreRootStore, userStore } = useStore();
    const { catalogStore, permissionsStore, api, history } = coreRootStore;
    const { systemConfig } = permissionsStore;
    const registryCheckQuery = systemConfig.registry();

    const createHandleMainInfoError = useMemo(() => handleMainInfoError(history), [history]);

    return (
        <AuthorizationCheck {...registryCheckQuery}>
            {(allowed): JSX.Element => (
                <TTable<UserTableRow>
                    tableTitle={tableTitle}
                    client={api.client}
                    registryCode={registryCode}
                    fetchCatalog={catalogStore.getCatalog}
                    fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                    fetchUserRoleList={userStore.userRoleList}
                    handleMainInfoError={createHandleMainInfoError}
                    rowActions={setRowActions}
                    isAdmin={allowed}
                    defaultPath={ttableDefaultPaths.users}
                />
            )}
        </AuthorizationCheck>
    );
});

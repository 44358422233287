import { approval_ru } from '@platform/approval';
import { commentsLocalesRu } from '@platform/comments';
import { crypto_ru } from '@platform/crypto-ui';
import { formio_ru } from '@platform/formiojs-react';
import { forms_ru } from '@platform/forms';
import { recordToLang } from '@platform/front-utils';
import { multiLangFieldRu } from '@platform/multi-lang-field';
import { multilevelCatalog_ru } from '@platform/multilevel-catalog';
import { ttable_ru } from '@platform/ttable';
import breadcrumbs from './breadcrumbs';
import campaign from './campaign';
import campaignRequest from './campaignRequest';
import campaignSettings from './campaignSettings';
import common from './common';
import form from './form';
import homePage from './homePage';
import { kafkaEvents } from './kafkaEvents';
import printForms from './printForms';
import { relatedObjectsRu } from './relatedObjects';
import startPage from './startPage';
import { syslogRu } from './syslog';
import { userRu } from './user';

const lang: Record<string, string> = recordToLang(
    {
        productTitle: 'TOT X',
        crypto: crypto_ru,
        formio: formio_ru,
        forms: forms_ru,
        ttable: ttable_ru,
        approval: approval_ru,
        multiLangField: multiLangFieldRu,
        startPage,
        homePage,
        campaign,
        campaignSettings,
        campaignRequest,
        common,
        form,
        breadcrumbs,
        printForms,
        console,
        multilevelCatalog: multilevelCatalog_ru,
        comments: commentsLocalesRu,
        kafkaEvents,
        user: userRu,
        syslog: syslogRu,
        relatedObjects: relatedObjectsRu,
    },
    false,
);

export default lang;

import { Table, TableBody, TableHead } from '@mui/material';
import { CoreRootStore } from '@platform/front-core';
import { useCoreStore } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { CampaignRequestPrintFormContext } from '../../../../../../../contexts';
import { useCampaignRequestPrintFormsContext } from '../../../../../../../hooks';
import {
    CampaignRequestPrintFormsRow as CampaignRequestPrintFormsRowInj,
    CampaignRequestPrintFormsTableHeadRow as CampaignRequestPrintFormsTableHeadRowInj,
} from './components';

export const CampaignRequestPrintFormsTable = observer((): JSX.Element => {
    const [CampaignRequestPrintFormsTableHeadRow] = di(
        [CampaignRequestPrintFormsTableHeadRowInj],
        CampaignRequestPrintFormsTable,
    );
    const [CampaignRequestPrintFormsRow] = di([CampaignRequestPrintFormsRowInj], CampaignRequestPrintFormsTable);

    const { model } = useCampaignRequestPrintFormsContext();
    const {
        printForms,
        isSomePrintFormGenerating,
        isWithEdit,
        updateIfSomePrintFormIsGenerating,
        clearUpdatePfListInterval,
    } = model;

    const { env } = useCoreStore() as CoreRootStore;
    const { pfDelay } = env;

    useEffect(() => {
        updateIfSomePrintFormIsGenerating(pfDelay as number);
        return clearUpdatePfListInterval;
    }, [isSomePrintFormGenerating, pfDelay]);

    const tableBodyContent = printForms.map((printFormModel): JSX.Element => {
        const { templateId } = printFormModel;
        const contextValue = { model: printFormModel, isWithEdit };
        return (
            <CampaignRequestPrintFormContext.Provider key={templateId} value={contextValue}>
                <CampaignRequestPrintFormsRow />
            </CampaignRequestPrintFormContext.Provider>
        );
    });

    return (
        <Table>
            <TableHead>
                <CampaignRequestPrintFormsTableHeadRow />
            </TableHead>
            <TableBody>{tableBodyContent}</TableBody>
        </Table>
    );
});

import { Button, Grid } from '@mui/material';
import { FormApi } from '@platform/formiojs-react';
import { useFormioHandlers } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { useCampaignPageContext, useCampaignSettingsPageContext, useStore } from '../../../../../../hooks';
import { CampaignRequestFormRouteParams } from '../../../../../../types';
import { campaignSettingActionPanelSxStyles } from '../../../campaignSettingActionPanelStyles';

export type CampaignSettingsRequestFormActionsPanelProps = {
    formApi?: FormApi;
};

export const CampaignSettingsRequestFormActionsPanel = observer(
    (props: CampaignSettingsRequestFormActionsPanelProps): JSX.Element => {
        const { formApi } = props;
        const { campaignsStore } = useStore();
        const { updateRequestForm, loadRequestFormInfo } = campaignsStore;

        const history = useHistory();
        const { id, rfId } = useParams<CampaignRequestFormRouteParams>();

        const { isCampaignSettingsRequestFormCreatePage } = useCampaignSettingsPageContext();
        const { requestFormModel } = useCampaignPageContext();

        const formInfoPath: string = generatePath(clientRoute.campaignSettingsRequestFormInfo, {
            id,
            rfId,
        });

        const afterSubmit = (): Promise<boolean> => {
            const isWithGoBack = !isCampaignSettingsRequestFormCreatePage;

            return formApi
                ? updateRequestForm(rfId, formApi.getSubmission()).then((): Promise<boolean> => {
                      return loadRequestFormInfo(rfId).then((dto): boolean => {
                          requestFormModel.load(dto);

                          isCampaignSettingsRequestFormCreatePage && history.push(formInfoPath);
                          return isWithGoBack;
                      });
                  })
                : Promise.reject();
        };

        // todo: возможно будет полезной доработка useFormioHandlers, чтобы в goBack прокидывался boolean:
        // это возврат после сохранения или возврат при отмене, тогда при успешном создании тоже будет возможен редирект на карточку
        const goBack = (): void => {
            const onCancelLink: string = isCampaignSettingsRequestFormCreatePage
                ? generatePath(clientRoute.campaignSettingsRequestForms, { id })
                : formInfoPath;
            history.push(onCancelLink);
        };

        const { handleClose, handleSubmit, isSubmitDisabled, submitEndIcon } = useFormioHandlers({
            afterSubmit,
            goBack,
            formApi,
            isWithValidation: true,
        });

        return (
            <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                sx={campaignSettingActionPanelSxStyles.actionPanel}
            >
                <Grid item sx={campaignSettingActionPanelSxStyles.actionPanelCancelButton}>
                    <Button color="secondary" variant="text" onClick={handleClose}>
                        <FormattedMessage id="common.cancel" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="secondary"
                        disabled={isSubmitDisabled}
                        endIcon={submitEndIcon}
                        onClick={handleSubmit}
                    >
                        <FormattedMessage id="common.saveChanges" />
                    </Button>
                </Grid>
            </Grid>
        );
    },
);

import { LocalesTree } from '@platform/front-utils';

const form: LocalesTree = {
    publishAll: 'Publish all changes',
    publishAllConfirmText: 'Are you sure you want to publish all the changes by all users?',
    deleteAllChanges: 'Delete all changes',
    deleteAllChangesConfirmText: 'Are you sure you want to delete all the changes by all users?',
    unsavedChanges: 'Unsaved changes',
    sections: 'Sections',
};

export default form;

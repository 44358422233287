import { EmptyList } from '@platform/front-core';
import { DraggableList } from '@platform/front-ui';
import { RouteParamsDefault, VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useCampaignSettingsListContext, useStore } from '../../../../../hooks';
import { CampaignRequestFormDTO } from '../../../../../types';
import { renderRequestFormsBody as renderRequestFormsBodyInj } from './renderRequestFormsBody';

export type CampaignSettingsRequestFormsListProps = {
    createRequestForm: VoidFunction;
};

export const CampaignSettingsRequestFormsList = observer(
    (props: CampaignSettingsRequestFormsListProps): JSX.Element => {
        const [renderRequestFormsBody] = di([renderRequestFormsBodyInj], CampaignSettingsRequestFormsList);

        const { createRequestForm } = props;

        const { campaignsStore } = useStore();
        const { loadRequestForms, changeRequestFormPosition } = campaignsStore;
        const { formatMessage } = useIntl();

        const { shouldLoadListItems, setShouldntLoadListItems } = useCampaignSettingsListContext();

        const { id } = useParams<RouteParamsDefault>();

        const loadListItems = (): Promise<CampaignRequestFormDTO[]> => loadRequestForms(id, true, true);

        const createButtonProps = {
            title: formatMessage({ id: 'campaignSettings.addCampaignRequestFormButton' }),
            createItem: createRequestForm,
        };

        const renderDraggableListEmpty = (): JSX.Element => (
            <EmptyList
                noItemsTitle={formatMessage({ id: 'campaignSettings.noRequestForms' })}
                createButtonProps={createButtonProps}
            />
        );

        return (
            <DraggableList
                loadListItems={loadListItems}
                changeItemPosition={changeRequestFormPosition}
                renderDraggableListBody={renderRequestFormsBody}
                renderDraggableListEmpty={renderDraggableListEmpty}
                setShouldntLoadListItems={setShouldntLoadListItems}
                shouldLoadListItems={shouldLoadListItems}
            />
        );
    },
);

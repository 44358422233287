import { LocalesTree } from '@platform/front-utils';

const printForms: LocalesTree = {
    table: {
        loading: 'Please, wait, preparation for the generation of printed forms of documents is in progress',
        title: 'Printed forms',
        fileColumnName: 'File',
        authorColumnName: 'Author',
        dateColumnName: 'Date',
        documentCategory: 'Document category',
        generateAction: 'Generate',
        updateAction: 'Update',
        cannotGenerateError: 'Printed form cannot be generated: request form is filled out incorrectly',
        cannotGenerateErrorWithLink:
            'Printed form cannot be generated: request form is filled out incorrectly. Go to <a>edit</a> to fix errors.',
        generationError: 'An error occurred while generating the print form',
        downloadAllButton: 'Download all',
        taskStatus: 'Generation status',
    },
};

export default printForms;

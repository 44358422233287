import { grey, makeSxStyles } from '@platform/front-ui';

export const campaignSxStyles = makeSxStyles({
    headerWrapper: {
        padding: (theme) => `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
        borderBottom: `1px solid ${grey[300]}`,
    },
    headerBottomWrapper: {
        '$headerWrapper &': {
            paddingTop: (theme) => theme.spacing(1.5),
        },
    },
    titleWrapper: {
        width: '100%',
    },
    title: {
        fontWeight: 500,
        fontSize: '18px',
    },
    campaignPageWrapper: {
        height: '100%',
    },
});

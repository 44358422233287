import { action, makeObservable, observable } from 'mobx';
export var collaborationFormFieldsModelObservables = {
    history: observable,
    intlStore: observable,
    notificationStore: observable,
    socketUrl: observable,
    routes: observable,
    formModel: observable,
    formApi: observable,
    isConnected: observable,
    connectionId: observable,
    activeUsers: observable,
    socket: observable,
    setIsConnected: action.bound,
    setConnectionId: action.bound,
    setActiveUsers: action.bound,
    setFormApi: action.bound,
    setFormModel: action.bound,
};
var CollaborationFormFieldsModel = /** @class */ (function () {
    function CollaborationFormFieldsModel(rootStore, socketUrl, routes) {
        Object.defineProperty(this, "history", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "intlStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "notificationStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "socketUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "routes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "formModel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "formApi", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isConnected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "connectionId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        Object.defineProperty(this, "activeUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "socket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(this, collaborationFormFieldsModelObservables);
        this.history = rootStore.history;
        this.intlStore = rootStore.intlStore;
        this.notificationStore = rootStore.notificationStore;
        this.socketUrl = socketUrl;
        this.routes = routes;
    }
    Object.defineProperty(CollaborationFormFieldsModel.prototype, "setIsConnected", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isConnected) {
            this.isConnected = isConnected;
        }
    });
    Object.defineProperty(CollaborationFormFieldsModel.prototype, "setConnectionId", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (connectionId) {
            this.connectionId = connectionId;
        }
    });
    Object.defineProperty(CollaborationFormFieldsModel.prototype, "setActiveUsers", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (users) {
            this.activeUsers = users;
        }
    });
    Object.defineProperty(CollaborationFormFieldsModel.prototype, "setFormModel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (formModel) {
            this.formModel = formModel;
        }
    });
    Object.defineProperty(CollaborationFormFieldsModel.prototype, "setFormApi", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (formApi) {
            this.formApi = formApi;
        }
    });
    return CollaborationFormFieldsModel;
}());
export { CollaborationFormFieldsModel };

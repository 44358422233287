import { Box, Typography } from '@mui/material';
import { grey } from '@platform/front-ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type RequestFormCardLabelColumnProps = {
    id: string;
};

const minWidthLabel = '83px';

export const RequestFormCardLabelColumn = (props: RequestFormCardLabelColumnProps): JSX.Element => {
    const { id } = props;

    return (
        <Box minWidth={minWidthLabel}>
            <Typography variant="body1" color={grey[400]}>
                <FormattedMessage id={id} />
            </Typography>
        </Box>
    );
};

import { SignatureInfoDTO, SignatureStatus } from '@platform/crypto-ui';
import { ApiStore } from '@platform/front-core';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { RootStore } from './RootStore';

export const SignatureStoreProps = {
    rootStore: observable,
    api: observable,
    getFileByteCreator: action.bound,
    uploadSignatureCreator: action.bound,
    uploadSignatureFileCreator: action.bound,
    getSignatureInfoCreator: action.bound,
    downloadSignatureCreator: action.bound,
    deleteSignatureCreator: action.bound,
};

export class SignatureStore {
    private rootStore: RootStore;
    private api: ApiStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        makeObservable(this, SignatureStoreProps);
    }

    getFileByteCreator(printFormId: string): () => Promise<ArrayBuffer> {
        return (): Promise<ArrayBuffer> => {
            return this.api.client(apiConfigs.getFileByte(printFormId)).then((r) => r.data);
        };
    }

    uploadSignatureCreator(printFormId: string): (signature: string) => Promise<SignatureStatus> {
        return (signature: string): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadSignature(printFormId, signature)).then((r) => r.data);
        };
    }

    uploadSignatureFileCreator(printFormId: string): (signatureFile: FormData) => Promise<SignatureStatus> {
        return (signatureFile: FormData): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadSignatureFile(printFormId, signatureFile)).then((r) => r.data);
        };
    }

    getSignatureInfoCreator(printFormId: string): () => Promise<SignatureInfoDTO[]> {
        return (): Promise<SignatureInfoDTO[]> => {
            return this.api.client(apiConfigs.getSignatureInfo(printFormId)).then((r) => r.data);
        };
    }

    downloadSignatureCreator(printFormId: string): (isWithFile: boolean) => Promise<Blob> {
        return (isWithFile: boolean): Promise<Blob> => {
            return this.api.client(apiConfigs.downloadSignature(printFormId, isWithFile)).then((r) => r.data);
        };
    }

    deleteSignatureCreator(printFormId: string): () => Promise<SignatureStatus> {
        return (): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.deleteSignature(printFormId)).then((r) => r.data);
        };
    }
}

export const getSignatureStore = (): any => {
    const [_SignatureStore] = di([SignatureStore], getSignatureStore);
    return _SignatureStore;
};

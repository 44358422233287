import { SxProps, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { grey } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useCampaignSettingsAccessPermissionContext } from '../../../../../../hooks';
import { AccessPermissionDTO, CommentsPermissionDTO } from '../../../../../../types';
import { CampaignSettingsPermissionListItem as CampaignSettingsPermissionListItemInj } from './CampaignSettingsPermissionListItem';

const PermissionListTableRowSx: SxProps = {
    textAlign: 'left',
    color: grey[500],
};

/** Список доступов к разделу */
export const CampaignSettingsPermissionList = observer((): JSX.Element => {
    const [CampaignSettingsPermissionListItem] = di(
        [CampaignSettingsPermissionListItemInj],
        CampaignSettingsPermissionList,
    );

    const { model, editPermission, onDelete } = useCampaignSettingsAccessPermissionContext();

    const mapPermissionListItem = (item: AccessPermissionDTO | CommentsPermissionDTO, index: number): JSX.Element => {
        const handleClickEditPermissionButton = editPermission(index);
        const handleClickDeletePermissionButton = onDelete(index);
        return (
            <CampaignSettingsPermissionListItem
                key={index + item.roleCondition.code}
                permission={item}
                onEditClick={handleClickEditPermissionButton}
                onDeleteClick={handleClickDeletePermissionButton}
            />
        );
    };

    const bodyTable = model.customFormPermissionsList.length
        ? model.customFormPermissionsList.map(mapPermissionListItem)
        : model.permissionList.map(mapPermissionListItem);

    return (
        <Table size="medium" width="100%">
            <TableHead>
                <TableRow>
                    <TableCell sx={PermissionListTableRowSx}>
                        <FormattedMessage id="campaignSettings.editPermissions.list.role" />
                    </TableCell>
                    <TableCell sx={PermissionListTableRowSx}>
                        <FormattedMessage id="campaignSettings.editPermissions.list.status" />
                    </TableCell>
                    <TableCell sx={PermissionListTableRowSx}>
                        <FormattedMessage id="campaignSettings.editPermissions.access" />
                    </TableCell>
                    <TableCell sx={PermissionListTableRowSx} />
                </TableRow>
            </TableHead>
            <TableBody>{bodyTable}</TableBody>
        </Table>
    );
});

import { Grid } from '@mui/material';
import { Form, FormApi } from '@platform/formiojs-react';
import { FormEditButton } from '@platform/front-core';
import { FormioOnFormChange } from '@platform/front-types';
import { SxStyle, Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { useCampaignRequestFormContext, useStore } from '../../../../../../hooks';
import { RequestFormSectionModel, RequestModel } from '../../../../../../models';
import { CampaignRequestSectionRouteParams } from '../../../../../../types';

export type CampaignRequestFormProps = {
    requestModel: RequestModel;
    section: RequestFormSectionModel;
    setFormApi: (formApi: FormApi) => void;
    isFormView: boolean;
    formName: string;
};

const titleWrapperSx: SxStyle = {
    marginTop: '-40px',
    width: 'calc(100% - 140px)',
};

export const CampaignRequestForm = observer((props: CampaignRequestFormProps): JSX.Element => {
    const { requestModel, section, setFormApi, isFormView, formName } = props;
    const { formDTO, isEditable, dropIsFormLoading, title } = section;
    const { onFieldFocus, onFieldBlur, onFieldUpdate } = useCampaignRequestFormContext().collaborationFormModel;
    const { coreRootStore, formioSidebarStore } = useStore();
    const { intlStore } = coreRootStore;

    const params = useParams<CampaignRequestSectionRouteParams>();
    const { sectionId } = params;

    useEffect(() => {
        formioSidebarStore.setItems({
            [formName]: [],
        });
    }, [sectionId, formName]);

    const onFormReady = (formApi: FormApi): void => {
        setFormApi(formApi);
        dropIsFormLoading();

        // тут был setTimeout на 80ms
        requestModel.validateAfterReadonly();
        formioSidebarStore.initSidebarItems(formName, formApi.form());
    };

    const onFormChange: FormioOnFormChange = (form): void => {
        // тут был setTimeout на 100ms
        requestModel.validateAfterReadonly();
        formioSidebarStore.updateItemsVisibility(formName, form);
    };

    const isEditButtonVisible = isFormView && isEditable;
    const isViewOnly = isFormView && !isEditable;
    const titleWrapperPt = isViewOnly ? 1.75 : 0.75;

    return (
        <Grid container direction="column">
            {formDTO && (
                <React.Fragment>
                    {isEditButtonVisible && (
                        <FormEditButton formEditPath={generatePath(clientRoute.requestSectionFormEdit, params)} />
                    )}
                    <Grid item pt={titleWrapperPt} pb={1.75} {...(isEditButtonVisible && { sx: titleWrapperSx })}>
                        <Typography variant="subtitle2">{title}</Typography>
                    </Grid>
                    <Form
                        formDTO={formDTO}
                        intlStore={intlStore}
                        ownerEntityId={sectionId}
                        readOnly={isFormView}
                        onFormReady={onFormReady}
                        onFormChange={onFormChange}
                        onFieldFocus={onFieldFocus}
                        onFieldBlur={onFieldBlur}
                        onFieldUpdate={onFieldUpdate}
                    />
                </React.Fragment>
            )}
        </Grid>
    );
});

import { PromiseVoidFunction, ServerErrorsModel } from '@platform/front-utils';
import {
    FieldValues,
    getRuTitleInitialValue,
    titlesGroupDTOToTitlesGroup,
    titlesGroupToTitlesGroupDTO,
} from '@platform/multi-lang-field';
import { action, makeObservable, observable } from 'mobx';
import { UserStore } from '../../stores';
import { FullNameFields, FullNameGroupFields, FullNameSettings, FullNameSettingsDTO } from '../../types';

export const fullNameEditModelObservables = {
    userStore: observable,
    id: observable,

    serverErrors: observable,
    lastName: observable,
    firstName: observable,
    middleName: observable,

    loadFullName: action.bound,
    setFullName: action.bound,
    setMainFields: action.bound,
};

export class FullNameEditModel {
    userStore: UserStore;
    id: string;

    serverErrors = new ServerErrorsModel<FullNameFields>();
    lastName: FieldValues[];
    firstName: FieldValues[];
    middleName: FieldValues[];

    constructor(userStore: UserStore, id: string) {
        makeObservable(this, fullNameEditModelObservables);
        this.userStore = userStore;
        this.id = id;

        this.lastName = getRuTitleInitialValue();
        this.firstName = getRuTitleInitialValue();
        this.middleName = getRuTitleInitialValue();
    }

    loadFullName(): Promise<void> {
        return this.userStore.getUserFullName(this.id).then((dto) => {
            const fieldKeys: string[] = Object.keys(FullNameGroupFields);
            const fullName: FullNameSettings = titlesGroupDTOToTitlesGroup(dto, fieldKeys);
            this.setMainFields(fullName);
        });
    }

    setFullName(formValues: FullNameSettings, onSuccess: PromiseVoidFunction): Promise<void> {
        this.serverErrors.dropServerFormErrors();
        const dto: FullNameSettingsDTO = titlesGroupToTitlesGroupDTO(formValues);
        return this.userStore.setFullName(this.id, dto).then(onSuccess).catch(this.serverErrors.setServerFormErrors);
    }

    setMainFields(fullName: FullNameSettings): void {
        this.lastName = fullName.lastName;
        this.firstName = fullName.firstName;
        this.middleName = fullName.middleName;
    }
}

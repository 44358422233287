import { IdTitle, PromiseVoidFunction } from '@platform/front-types';
import { ConfirmationDialog } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { cloneElement } from 'react';
import { useIntl } from 'react-intl';
import { CampaignSettingsEntities } from '../../../../../../types';

export type CampaignSettingsItemDeleteProps = {
    itemToDelete: IdTitle;
    itemType: CampaignSettingsEntities;
    deleteItem: PromiseVoidFunction;
    children: JSX.Element;
};

export const CampaignSettingsItemDelete = observer((props: CampaignSettingsItemDeleteProps): JSX.Element => {
    const { itemToDelete, itemType, deleteItem, children } = props;
    const { title } = itemToDelete;
    const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useFlag();

    const clone = cloneElement(children, {
        onClick: openDeleteModal,
    });

    const onConfirmDelete = (): Promise<void> => {
        return deleteItem().finally(closeDeleteModal);
    };

    const { formatMessage } = useIntl();

    const confirmDeletionTitle = formatMessage({ id: `campaignSettings.deletion.delete.${itemType}` });
    const confirmDeletionMessage = formatMessage(
        { id: `campaignSettings.deletion.confirmDelete.${itemType}` },
        { title },
    );

    return (
        <React.Fragment>
            {clone}
            <ConfirmationDialog
                id="confirm-delete"
                keepMounted
                open={isDeleteModalOpen}
                onConfirm={onConfirmDelete}
                onCancel={closeDeleteModal}
                title={confirmDeletionTitle}
                message={confirmDeletionMessage}
            />
        </React.Fragment>
    );
});

import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Redirect, Route, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { CampaignRequestFormSectionSettingsRouteParams } from '../../../../../../types';
import { getSectionSettingEditRoute as getSectionEditRouteInj } from '../getSectionSettingEditRoute';
import {
    CampaignSettingsSectionSetting as CampaignSettingsSectionInj,
    CampaignSettingsSectionSettingProvider as CampaignSettingsSectionProviderInj,
} from './';
import { CampaignSettingsSectionSettingDialog as CampaignSettingsSectionDialogInj } from './section-setting-dialog';

export type CampaignRequestFormSectionEditParams = CampaignRequestFormSectionSettingsRouteParams & {
    tabSettingId?: string; // legacy route
};

export const CampaignSettingsSectionSettingRoutes = observer((): JSX.Element => {
    const [getSectionEditRoute] = di([getSectionEditRouteInj], CampaignSettingsSectionSettingRoutes);
    const [CampaignSettingsSectionDialog] = di(
        [CampaignSettingsSectionDialogInj],
        CampaignSettingsSectionSettingRoutes,
    );
    const [CampaignSettingsSectionProvider] = di(
        [CampaignSettingsSectionProviderInj],
        CampaignSettingsSectionSettingRoutes,
    );
    const [CampaignSettingsSection] = di([CampaignSettingsSectionInj], CampaignSettingsSectionSettingRoutes);

    const params = useParams<CampaignRequestFormSectionEditParams>();
    const { sectionSettingId } = params;

    return (
        <CampaignSettingsSectionProvider>
            <React.Fragment>
                <Route path={clientRoute.campaignSettingsRequestFormSectionEditOld} exact>
                    <Redirect to={getSectionEditRoute(params, sectionSettingId)} />
                </Route>
                <Route
                    path={[
                        clientRoute.campaignSettingsRequestFormSectionSettingsCreate,
                        clientRoute.campaignSettingsRequestFormSectionSettingsEdit,
                        clientRoute.campaignSettingsRequestFormSectionSettingEdit,
                    ]}
                    exact
                >
                    <CampaignSettingsSectionDialog />
                </Route>
                <Route
                    path={[
                        clientRoute.campaignSettingsRequestFormSectionSettingEdit,
                        clientRoute.campaignSettingsRequestFormSectionSettingInfo,
                        clientRoute.campaignSettingsRequestFormSectionSettingPF,
                    ]}
                >
                    <CampaignSettingsSection />
                </Route>
            </React.Fragment>
        </CampaignSettingsSectionProvider>
    );
});

import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useRouteMatch } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { CampaignSettingsPageContext, CampaignSettingsPageContextType } from '../../../contexts';
import { CampaignSettingsPage as CampaignSettingsPageInj } from './CampaignSettingsPage';

export const CampaignSettingsPageRoutes = observer((): JSX.Element => {
    const [CampaignSettingsPage] = di([CampaignSettingsPageInj], CampaignSettingsPageRoutes);

    const isCampaignCreatePage = !!useRouteMatch(clientRoute.createCampaign);
    const isCampaignSettingsRequestFormCreatePage = !!useRouteMatch(clientRoute.campaignSettingsRequestFormCreate);
    const isCampaignSettingsRequestFormEditPage = !!useRouteMatch(clientRoute.campaignSettingsRequestFormEdit);
    const isCampaignSettingsRequestFormEditingForm =
        isCampaignSettingsRequestFormCreatePage || isCampaignSettingsRequestFormEditPage;

    const contextValue: CampaignSettingsPageContextType = {
        isCampaignCreatePage,
        isCampaignSettingsRequestFormCreatePage,
        isCampaignSettingsRequestFormEditPage,
        isCampaignSettingsRequestFormEditingForm,
    };

    return (
        <CampaignSettingsPageContext.Provider value={contextValue}>
            <CampaignSettingsPage />
        </CampaignSettingsPageContext.Provider>
    );
});

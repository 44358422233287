import { SignatureSettings, SignatureStatus } from '@platform/crypto-ui';
import { FileDTO, PersonDTO } from '@platform/front-types';

export enum PrintFormStatusCode {
    generated = 'GENERATED',
    failed = 'FAILED',
    new = 'NEW',
    processing = 'PROCESSING',
}

export type PrintFormStatus = {
    code: PrintFormStatusCode;
    title: string;
};

export type PrintFormDTO = {
    templateId: string;
    filename: string;
    docCategory?: string;
    signatureSettings: SignatureSettings;
    signatureStatus: SignatureStatus;
    author?: PersonDTO;
    created?: string; //Date
    pf?: FileDTO;
    status: PrintFormStatus;
    required: string;
};

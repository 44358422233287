import { LocalesTree } from '@platform/front-utils';
import instructionSrc from '../../instructions/en_instr.docx';

const common: LocalesTree = {
    tabExecutor: 'Responsible executor: {name}',
    responsibleExecutor: 'Responsible executor:',
    instructionSrc: instructionSrc,
    instructionFileName: 'Instruction.docx',
    // todo: move to console
    result: 'Result',
    copy: 'Copy',
    clear: 'Clear',
};

export default common;

import { Box } from '@mui/material';
import { makeSxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import {
    CampaignCardUserRequestCardColumnItem as CampaignCardUserRequestCardColumnItemInj,
    CampaignCardUserRequestCardColumnItemProps,
} from './CampaignCardUserRequestCardColumnItem';

export type CampaignCardUserRequestCardColumnProps = {
    items: CampaignCardUserRequestCardColumnItemProps[];
};

export const campaignCardUserRequestCardColumnSxStyles = makeSxStyles({
    root: {
        padding: (theme) => `${theme.spacing(0.25)} 0`,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, auto)',
        gridTemplateRows: 'repeat(2, auto)',
        columnGap: (theme) => theme.spacing(2),
        rowGap: (theme) => theme.spacing(0.5),
    },
});

export const CampaignCardUserRequestCardColumn = observer(
    (props: CampaignCardUserRequestCardColumnProps): JSX.Element => {
        const [CampaignCardUserRequestCardColumnItem] = di(
            [CampaignCardUserRequestCardColumnItemInj],
            CampaignCardUserRequestCardColumn,
        );
        const { items } = props;
        const itemComponents: JSX.Element[] = items.map(
            (item): JSX.Element => <CampaignCardUserRequestCardColumnItem key={item.label} {...item} />,
        );
        return <Box sx={campaignCardUserRequestCardColumnSxStyles.root}>{itemComponents}</Box>;
    },
);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CollaborationFormRequestCode, } from '@platform/front-types';
import { voidFunction } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { getFormComponentKeyFromObservable, getFormComponentValue } from '../../utils';
import { CollaborationFormFieldsModel } from './CollaborationFormFieldsModel';
import { withCollaborationFormReceive } from './withCollaborationFormReceive';
import { withCollaborationFormSend } from './withCollaborationFormSend';
export var collaborationFormModelObservables = {
    handleSaved: observable,
    onFieldBlur: observable,
    onFieldUpdate: observable,
    oldConnections: observable,
    isReconnecting: observable,
    isFailedConnection: observable,
    disconnectNotificationId: observable,
    reconnectingInterval: observable,
    openConnection: action.bound,
    closeConnection: action.bound,
    onConnectionFailed: action.bound,
    onConnectionLost: action.bound,
    onSuccessReconnect: action.bound,
    tryReconnect: action.bound,
    onFieldFocus: action.bound,
    createBlurUpdateHandler: action.bound,
    deleteDisconnectNotification: action.bound,
    dropSocket: action.bound,
    dropIsReconnecting: action.bound,
    dropIsFailedConnection: action.bound,
    setOldConnections: action.bound,
    setIsReconnecting: action.bound,
    setIsFailedConnection: action.bound,
    setDisconnectNotificationId: action.bound,
};
var CollaborationFormModel = /** @class */ (function (_super) {
    __extends(CollaborationFormModel, _super);
    function CollaborationFormModel(rootStore, socketUrl, routes, handleSaved) {
        var _this = _super.call(this, rootStore, socketUrl, routes) || this;
        Object.defineProperty(_this, "handleSaved", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onFieldBlur", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: _this.createBlurUpdateHandler(true)
        });
        Object.defineProperty(_this, "onFieldUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: _this.createBlurUpdateHandler(false)
        });
        Object.defineProperty(_this, "oldConnections", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "isReconnecting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "isFailedConnection", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "disconnectNotificationId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "reconnectingInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        makeObservable(_this, collaborationFormModelObservables);
        _this.handleSaved = handleSaved;
        return _this;
    }
    Object.defineProperty(CollaborationFormModel.prototype, "openConnection", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.socket = new WebSocket(this.socketUrl);
            /**
             * Первый случай:
             * Соединение открывается, и сразу же получает ошибку.
             * Даже onopen не срабатывает.
             * Такая ошибка обрабатывается здесь.
             */
            this.socket.onerror = function () {
                console.error('Произошла ошибка до открытия ws!');
            };
            this.socket.onopen = function () {
                _this.setIsConnected(true);
                _this.isReconnecting && _this.onSuccessReconnect();
                if (!_this.socket) {
                    return;
                }
                /**
                 * Второй случай:
                 * ВС-соединение открылось. В процессе произошла ошибка.
                 * Она обрабатывается здесь.
                 */
                _this.socket.onerror = function () {
                    var _a;
                    if (_this.isReconnecting) {
                        (_a = _this.socket) === null || _a === void 0 ? void 0 : _a.close();
                        setTimeout(function () {
                            _this.openConnection();
                        }, 3000);
                    }
                    else if (!_this.isConnected) {
                        _this.onConnectionFailed();
                    }
                };
                /**
                 * Метод в котором доступны коды.
                 * Здесь можно отследить результат закрытия вебсокета.
                 * С разными кодами - разная обработка.
                 */
                _this.socket.onclose = function (event) {
                    if (event.code === 1000) {
                        // 'Websocket has shut down gracefully.'
                        return;
                    }
                    if (!_this.oldConnections.length) {
                        _this.isConnected && _this.onConnectionLost();
                    }
                    else {
                        var newOldConnections = __spreadArray([], __read(_this.oldConnections), false);
                        newOldConnections.shift();
                        _this.setOldConnections(newOldConnections);
                    }
                };
            };
            this.socket.onmessage = function (event) {
                var eventData = event.data;
                if (eventData instanceof Blob) {
                    var reader_1 = new FileReader();
                    reader_1.onload = function () {
                        try {
                            var data = JSON.parse(reader_1.result);
                            switch (data.code) {
                                case CollaborationFormRequestCode.yourConnection:
                                    var yourConnectionDTO = data.body;
                                    _this.handleYourConnection(yourConnectionDTO);
                                    break;
                                case CollaborationFormRequestCode.allUsers:
                                    var allUsersDTO = data
                                        .body;
                                    _this.handleAllUsers(allUsersDTO);
                                    break;
                                case CollaborationFormRequestCode.connect:
                                    var connectDTO = data
                                        .body;
                                    _this.handleConnect(connectDTO);
                                    break;
                                case CollaborationFormRequestCode.disconnect:
                                    var disconnectDTO = data.body;
                                    _this.handleDisconnect(disconnectDTO);
                                    break;
                                case CollaborationFormRequestCode.formState:
                                    var formStateDTO = data
                                        .body;
                                    _this.handleFormState(formStateDTO);
                                    break;
                                case CollaborationFormRequestCode.blocked:
                                    var blockedDTO = data.body;
                                    _this.handleBlocked(blockedDTO);
                                    break;
                                case CollaborationFormRequestCode.unblock:
                                    var unblockedDTO = data
                                        .body;
                                    _this.handleUnblock(unblockedDTO);
                                    break;
                                case CollaborationFormRequestCode.update:
                                    var updateDTO = data.body;
                                    _this.handleUpdate(updateDTO);
                                    break;
                                case CollaborationFormRequestCode.saved:
                                    _this.handleSaved();
                                    break;
                                case CollaborationFormRequestCode.deleted:
                                    _this.handleDeleted();
                                    break;
                                case CollaborationFormRequestCode.notification:
                                    var notificationDTO = data.body;
                                    _this.handleNotification(notificationDTO.params);
                                    break;
                            }
                        }
                        catch (error) {
                            console.error(error);
                        }
                    };
                    reader_1.readAsText(eventData);
                }
            };
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "closeConnection", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            (_a = this.socket) === null || _a === void 0 ? void 0 : _a.close();
            this.dropSocket();
            this.setIsConnected(false);
            if (this.isReconnecting) {
                this.dropIsReconnecting();
                this.deleteDisconnectNotification();
            }
            this.isFailedConnection && this.dropIsFailedConnection();
            this.setOldConnections(__spreadArray(__spreadArray([], __read(this.oldConnections), false), [this.connectionId], false));
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "onConnectionFailed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setIsFailedConnection(true);
            var formatMessage = this.intlStore.intl.formatMessage;
            var text = formatMessage({ id: 'formio.impossibleReceiveData' });
            var title = formatMessage({ id: 'formio.noConnection' });
            this.notificationStore.onError(text, true, title, 'standard', this.setDisconnectNotificationId);
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "onConnectionLost", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var formatMessage = this.intlStore.intl.formatMessage;
            var text = formatMessage({ id: 'formio.waitConnection' });
            var title = formatMessage({ id: 'formio.connectionFailed' });
            this.notificationStore.onWarning(text, false, title, 'standard', this.setDisconnectNotificationId);
            this.setIsConnected(false);
            this.tryReconnect();
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "onSuccessReconnect", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.dropIsReconnecting();
            var formatMessage = this.intlStore.intl.formatMessage;
            var text = formatMessage({ id: 'formio.connectionRestored' });
            var title = formatMessage({ id: 'formio.canContinue' });
            this.deleteDisconnectNotification().then(function () {
                _this.notificationStore.onSuccess(text, true, title, 'standard');
            });
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "tryReconnect", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.setIsReconnecting(true);
            this.openConnection();
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "onFieldFocus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (component) {
            var key = getFormComponentKeyFromObservable(component);
            this.sendBlocked(key);
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "createBlurUpdateHandler", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isWithUnblock) {
            var _this = this;
            return function (component, isWithBlocking) {
                var _a;
                if (isWithBlocking === void 0) { isWithBlocking = false; }
                if (_this.formApi) {
                    var key = getFormComponentKeyFromObservable(component);
                    var value = (_a = getFormComponentValue(_this.formApi, key)) !== null && _a !== void 0 ? _a : '';
                    _this.sendUpdate(key, value, isWithBlocking);
                    isWithUnblock && _this.sendUnblocked(key);
                }
            };
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "deleteDisconnectNotification", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var model = this.notificationStore.model;
            return this.disconnectNotificationId
                ? model.deleteNotification(this.disconnectNotificationId)
                : Promise.resolve();
        }
    });
    // В ff `socket.close` вызывает `onerror` также у закрытия сокета есть задержка
    Object.defineProperty(CollaborationFormModel.prototype, "dropSocket", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.socket) {
                this.socket.onerror = voidFunction;
                this.socket.onclose = voidFunction;
                this.socket.onopen = voidFunction;
                this.socket.onmessage = voidFunction;
                this.socket = undefined;
            }
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "dropIsReconnecting", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isReconnecting = false;
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "dropIsFailedConnection", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.isFailedConnection = false;
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "setOldConnections", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (oldConnections) {
            this.oldConnections = oldConnections;
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "setIsReconnecting", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isReconnecting) {
            this.isReconnecting = isReconnecting;
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "setIsFailedConnection", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isFailedConnection) {
            this.isFailedConnection = isFailedConnection;
        }
    });
    Object.defineProperty(CollaborationFormModel.prototype, "setDisconnectNotificationId", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (disconnectNotificationId) {
            this.disconnectNotificationId = disconnectNotificationId;
        }
    });
    return CollaborationFormModel;
}(withCollaborationFormReceive(withCollaborationFormSend(CollaborationFormFieldsModel))));
export { CollaborationFormModel };

import { Typography } from '@mui/material';
import React from 'react';

export type ViewFieldLabelProps = {
    label: string;
};

export const ViewFieldLabel = (props: ViewFieldLabelProps): JSX.Element => {
    const { label } = props;
    return <Typography variant="body2">{label}</Typography>;
};

import { IdTitle } from '@platform/front-types';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { useStore } from '../../../../../../hooks';
import {
    CampaignRequestFormSectionSettingsRouteParams,
    CampaignSettingsEntities,
    NewPermissionDTO,
    PermissionSettingsDTO,
    PermissionsType,
} from '../../../../../../types';
import {
    CampaignSettingsAccessPermissionButton as CampaignSettingsAccessPermissionButtonInj,
    CampaignSettingsItemHeader as CampaignSettingsItemHeaderInj,
} from '../../components';
import { CampaignSettingsAccessCommentsPermissionButton as CampaignSettingsAccessCommentsPermissionButtonInj } from './section-setting-dialog';

export type CampaignSettingsSectionSettingHeaderProps = {
    item: IdTitle;
};

export const CampaignSettingsSectionSettingHeader = observer(
    (props: CampaignSettingsSectionSettingHeaderProps): JSX.Element => {
        const [CampaignSettingsAccessCommentsPermissionButton] = di(
            [CampaignSettingsAccessCommentsPermissionButtonInj],
            CampaignSettingsSectionSettingHeader,
        );
        const [CampaignSettingsItemHeader] = di([CampaignSettingsItemHeaderInj], CampaignSettingsSectionSettingHeader);
        const [CampaignSettingsAccessPermissionButton] = di(
            [CampaignSettingsAccessPermissionButtonInj],
            CampaignSettingsSectionSettingHeader,
        );

        const { item } = props;
        const { title } = item;

        const { campaignsStore } = useStore();
        const { formatMessage } = useIntl();

        const params = useParams<CampaignRequestFormSectionSettingsRouteParams>();
        const { sectionSettingId } = params;

        const deleteItem = (): Promise<void> => campaignsStore.deleteSectionSetting(params);
        const loadTemplatesPermissions = (): Promise<PermissionSettingsDTO> =>
            campaignsStore.loadAccessPermissions(sectionSettingId, PermissionsType.permissions);
        const updateTemplatePermissions = (permissions: Partial<NewPermissionDTO>[]): Promise<void> =>
            campaignsStore.saveAccessPermissions(sectionSettingId, permissions, PermissionsType.permissions);

        const accessPermissionButton = (
            <CampaignSettingsAccessPermissionButton
                accessPermissionDialogTitle={formatMessage({ id: 'campaignSettings.accessSectionSetting' })}
                addPermissionDialogTitle={formatMessage({ id: 'campaignSettings.addingSectionPermission' })}
                editPermissionDialogTitle={formatMessage({ id: 'campaignSettings.editingSectionPermission' })}
                emptyAccessPermissionList={formatMessage({
                    id: 'campaignSettings.emptyAccessPermissionListSection',
                })}
                checkboxLabel={formatMessage({ id: 'campaignSettings.editPermissions.edit' })}
                buttonTitle={formatMessage({ id: 'campaignSettings.setSectionAccess' })}
                updatePermissions={updateTemplatePermissions}
                loadPermissions={loadTemplatesPermissions}
                accessPermissionRoutePathTemplate={
                    clientRoute.campaignSettingsRequestFormSectionSettingAccessPermissionsTabParam
                }
                editPermissionRoutePathTemplate={
                    clientRoute.campaignSettingsRequestFormSectionSettingEditPermissionTabParam
                }
                accessPermissionRoutePath={generatePath(
                    clientRoute.campaignSettingsRequestFormSectionSettingAccessPermissionsTabParam,
                    params,
                )}
                editPermissionRoutePath={generatePath(
                    clientRoute.campaignSettingsRequestFormSectionSettingEditPermissionTabParam,
                    params,
                )}
            />
        );

        const commentsPermissionButton = <CampaignSettingsAccessCommentsPermissionButton />;

        const headerButtons: JSX.Element[] = [commentsPermissionButton, accessPermissionButton];

        return (
            <CampaignSettingsItemHeader
                item={item}
                title={title}
                itemType={CampaignSettingsEntities.SectionSetting}
                isFormView={true}
                viewPaths={[clientRoute.campaignSettingsRequestFormSectionSettingTab]}
                deleteItem={deleteItem}
                additionalHeaderButtons={headerButtons}
            />
        );
    },
);

import { Grid, SxProps, Theme } from '@mui/material';
import { RoutedTabs, RouteSetting } from '@platform/front-core';
import { grey } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { commonTabsProps } from '../../../../../../constants';
import { TabData } from '../../../../../../types';

const campaignSettingsTabsSx: SxProps<Theme> = {
    borderBottom: `1px solid  ${grey[300]}`,
    px: 2,
    mb: 1,
};

export type CampaignSettingsTabsProps = {
    tabsData: TabData[];
};

export const CampaignSettingsItemTabs = observer((props: CampaignSettingsTabsProps): JSX.Element => {
    const { tabsData } = props;

    const settings: RouteSetting[] = tabsData.map((tab) => {
        const { path, title } = tab;
        return {
            tab: path,
            title: title,
            path: path,
        };
    });

    return (
        <Grid item sx={campaignSettingsTabsSx}>
            <RoutedTabs settings={settings} tabsProps={commonTabsProps} />
        </Grid>
    );
});

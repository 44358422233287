import { styled } from '@mui/material';
import { StyledComponent } from '@mui/styles';
import React from 'react';
import InfiniteScroll, { Props } from 'react-infinite-scroll-component';

export const CampaignCardUserRequestInfiniteScrollStyled: StyledComponent<Props> = styled((props: Props) => (
    <InfiniteScroll {...props} />
))`
    overflow: unset !important;
`;

import { Grid, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const rootSx: SxProps<Theme> = {
    py: 2,
};

export const CampaignCardUserRequestsEndMessage = (): JSX.Element => {
    return (
        <Grid container direction="column" alignItems="center" sx={rootSx}>
            <Typography variant="body1">
                <FormattedMessage id="campaign.userRequests.endMessage" />
            </Typography>
        </Grid>
    );
};

import { Button, Grid, SxProps, Theme, Typography } from '@mui/material';
import {
    AuthorizationCheck,
    ClientBreadcrumbsWithCustomLinks,
    ObjectHeaderLayout,
    ObjectHeaderLayoutProps,
} from '@platform/front-core';
import { DialogActionButton, DialogSubmitButtonConfig, RenderFunction, Tag } from '@platform/front-ui';
import { RouteParamsDefault, useAntiDoubleClick } from '@platform/front-utils';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../authSchemeConfig';
import { clientRoute } from '../../../clientRoute';
import { ICSDataTestIds } from '../../../constants/data-test-ids';
import { useCampaignCardPageContext, useCampaignPageContext, useSetBreadcrumbsProps } from '../../../hooks';
import { getCampaignCardBreadcrumbsLinks as getCampaignCardBreadcrumbsLinksInj } from '../../../stores';
import { campaignSxStyles } from '../campaignSxStyles';

const campaignSettingsBtnSx: SxProps<Theme> = {
    whiteSpace: 'nowrap',
};

const campaignPeriodSx: SxProps<Theme> = {
    mt: 1.5,
};

/** Заголовок страницы категории. */
export const CampaignCardHeader: RenderFunction = observer(() => {
    const [getCampaignCardBreadcrumbsLinks] = di([getCampaignCardBreadcrumbsLinksInj], CampaignCardHeader);
    const { model } = useCampaignPageContext();
    const { from, title, to, isCollectionCompleted, campaignNotStarted } = model;

    const params = useParams<RouteParamsDefault>();
    const { id } = params;
    const breadcrumbsLinksData = getCampaignCardBreadcrumbsLinks({ id, title });
    useSetBreadcrumbsProps(params, { campaign: title });

    const history = useHistory();

    const { formatMessage } = useIntl();

    const [showCampaignPeriod] = useFeature('showCampaignPeriod');
    const { openCreateRequestDialog } = useCampaignCardPageContext();

    const openDialog = (): Promise<void> => {
        return openCreateRequestDialog(id);
    };

    const [isButtonDisabled, buttonEndIcon, createHandler] = useAntiDoubleClick(openDialog);

    const goToSettingsPage = (): void => {
        history.push(generatePath(clientRoute.campaignSettingsDescription, { id }));
    };

    const renderCampaignPeriod = (): ReactNode => {
        if (!from && !to) {
            return null;
        }

        if (!isCollectionCompleted && !campaignNotStarted) {
            return (
                <Tag color="success" data-testid={ICSDataTestIds.campaignCardCampaignPeriod}>
                    <FormattedMessage id="campaign.campaignPeriod" />
                    &nbsp;
                    <FormattedMessage id="common.to" />: &nbsp;
                    <FormattedDate value={to} />, <FormattedTime value={to} />
                </Tag>
            );
        } else if (!isCollectionCompleted && campaignNotStarted) {
            return (
                <Tag data-testid={ICSDataTestIds.campaignCardCampaignPeriod}>
                    <FormattedMessage id="campaign.campaignPeriodIfNotStarted" />
                    &nbsp;
                    <FormattedMessage id="common.from" />: &nbsp;
                    <FormattedDate value={from} />, <FormattedTime value={from} />
                </Tag>
            );
        } else if (isCollectionCompleted) {
            return (
                <Tag data-testid={ICSDataTestIds.campaignCardCampaignPeriod}>
                    <FormattedMessage id="campaign.collectionCompleted" />
                </Tag>
            );
        }
    };

    const dialogActionButtonConfig = (allowed: boolean): DialogSubmitButtonConfig => ({
        text: formatMessage({ id: 'campaign.createRequest' }),
        onClick: createHandler,
        endIcon: buttonEndIcon,
        disabled: isButtonDisabled || !allowed,
        tooltip: !allowed ? { title: formatMessage({ id: 'campaign.createRequestError' }) } : undefined,
    });

    const objectHeaderLayoutProps: ObjectHeaderLayoutProps = {
        breadcrumbs: <ClientBreadcrumbsWithCustomLinks customLinks={breadcrumbsLinksData} />,
        titleBlockAndMenu: {
            titleBlock: (
                <Typography
                    variant="subtitle2"
                    sx={campaignSxStyles.title}
                    data-testid={ICSDataTestIds.campaignCardHeaderTitle}
                >
                    {title}
                </Typography>
            ),
        },
        rightBlocks: {
            topBlock: (
                <Grid container spacing={1} wrap="nowrap" justifyContent="flex-end">
                    <AuthorizationCheck
                        entityId={id}
                        entityCode={entities.campaign}
                        permCode={permissions.campaign.Edit}
                    >
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={campaignSettingsBtnSx}
                                onClick={goToSettingsPage}
                            >
                                <FormattedMessage id="campaignSettings.campaignSettings" />
                            </Button>
                        </Grid>
                    </AuthorizationCheck>
                    <Grid item>
                        <AuthorizationCheck
                            entityCode={entities.campaign}
                            permCode={permissions.campaign.AddCampRequest}
                            entityId={id}
                        >
                            {(allowed): JSX.Element => {
                                return <DialogActionButton {...dialogActionButtonConfig(allowed)} />;
                            }}
                        </AuthorizationCheck>
                    </Grid>
                </Grid>
            ),
            minBlockWidth: '340px',
        },
        leftBottomBlock: {
            element: showCampaignPeriod ? <React.Fragment>{renderCampaignPeriod()}</React.Fragment> : undefined,
            containerProps: {
                sx: campaignPeriodSx,
            },
        },
    };

    return <ObjectHeaderLayout {...objectHeaderLayoutProps} />;
});

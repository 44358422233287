var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CollaborationFormRequestCode, } from '@platform/front-types';
import { action, makeObservable } from 'mobx';
export var withCollaborationFormSendObservables = {
    send: action.bound,
    sendBlocked: action.bound,
    sendUnblocked: action.bound,
    sendUpdate: action.bound,
    sendCancel: action.bound,
    sendCancelAll: action.bound,
    sendSave: action.bound,
};
export var withCollaborationFormSend = function (MainModel) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var _this = _super.apply(this, __spreadArray([], __read(args), false)) || this;
            makeObservable(_this, withCollaborationFormSendObservables);
            return _this;
        }
        Object.defineProperty(class_1.prototype, "send", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (dto) {
                var _a;
                this.isConnected && ((_a = this.socket) === null || _a === void 0 ? void 0 : _a.send(JSON.stringify(dto)));
            }
        });
        Object.defineProperty(class_1.prototype, "sendBlocked", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (key) {
                var dto = {
                    code: CollaborationFormRequestCode.blocked,
                    body: {
                        key: key,
                    },
                };
                this.send(dto);
            }
        });
        Object.defineProperty(class_1.prototype, "sendUnblocked", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (key) {
                var dto = {
                    code: CollaborationFormRequestCode.unblock,
                    body: {
                        key: key,
                    },
                };
                this.send(dto);
            }
        });
        Object.defineProperty(class_1.prototype, "sendUpdate", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (key, value, isWithBlocking) {
                var dto = {
                    code: CollaborationFormRequestCode.update,
                    body: {
                        key: key,
                        value: value,
                        isWithBlocking: isWithBlocking,
                    },
                };
                this.send(dto);
            }
        });
        Object.defineProperty(class_1.prototype, "sendCancel", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function () {
                var dto = {
                    code: CollaborationFormRequestCode.cancel,
                };
                this.send(dto);
            }
        });
        Object.defineProperty(class_1.prototype, "sendCancelAll", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function () {
                var dto = {
                    code: CollaborationFormRequestCode.cancelAll,
                };
                this.send(dto);
            }
        });
        Object.defineProperty(class_1.prototype, "sendSave", {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function () {
                var dto = {
                    code: CollaborationFormRequestCode.save,
                };
                this.send(dto);
            }
        });
        return class_1;
    }(MainModel));
};

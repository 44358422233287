import { CoreRootStore, IntlStore, NotificationStore as NotificationStoreInj } from '@platform/front-core';
import { NotificationCommonParams, SeverityType } from '@platform/front-types';
import { action, makeObservable, observable } from 'mobx';
import React from 'react';
import { di } from 'react-magnetic-di';
import { NotificationSectionsLinks } from '../components';
import {
    ICSNotificationDTO,
    ICSNotificationType,
    NotificationLocationDTO,
    NotificationRequestCode,
    NotificationRequestData,
    NotificationSectionsHasChangesParams,
    NotificationShortRequestData,
} from '../types';
import { WebSocketApiConfigs } from '../webSocketApiConfigs';

export const notificationStoreProps = {
    intlStore: observable,

    isConnected: observable,
    socket: observable,
    currentEntityId: observable,
    hasChangesNotificationId: observable,

    openConnection: action.bound,
    closeConnection: action.bound,

    handleNotification: action.bound,
    handleCommonNotification: action.bound,
    handleSectionsHasChangesNotification: action.bound,

    sendConnect: action.bound,
    sendDisconnect: action.bound,
    send: action.bound,

    setIsConnected: action.bound,
    setCurrentEntityId: action.bound,
    setHasChangesNotificationId: action.bound,
};

export class NotificationStore extends NotificationStoreInj {
    protected intlStore: IntlStore;

    isConnected = false;
    socket: WebSocket = {} as WebSocket;
    currentEntityId = '';
    hasChangesNotificationId = '';

    constructor(rootStore: CoreRootStore) {
        super(rootStore);
        makeObservable(this, notificationStoreProps);
        this.intlStore = rootStore.intlStore;
    }

    openConnection(): void {
        const socketUrl = (this.rootStore.api.webSocketApiConfigs as WebSocketApiConfigs).notification();
        this.socket = new WebSocket(socketUrl);

        this.socket.onopen = () => {
            this.setIsConnected(true);
        };

        this.socket.onmessage = (event) => {
            const eventData = event.data;

            if (eventData instanceof Blob) {
                const reader = new FileReader();

                reader.onload = () => {
                    try {
                        const data: NotificationShortRequestData = JSON.parse(reader.result as string);

                        switch (data.code) {
                            case NotificationRequestCode.notification:
                                const notificationDTO = (data as NotificationRequestData<ICSNotificationDTO>).body;
                                this.handleNotification(notificationDTO);
                                break;
                        }
                    } catch (error) {
                        console.error(error);
                    }
                };

                reader.readAsText(eventData);
            }
        };
    }

    handleNotification(dto: ICSNotificationDTO): void {
        const { type, params } = dto;
        switch (type) {
            case ICSNotificationType.common:
                this.handleCommonNotification(params as NotificationCommonParams);
                break;
            case ICSNotificationType.sectionsHasChanges:
                this.handleSectionsHasChangesNotification(params as NotificationSectionsHasChangesParams);
                break;
        }
    }

    handleCommonNotification(dto: NotificationCommonParams): void {
        const { text, title, withAutoClose, variant, severityType } = dto;
        this.model.pushNotification(text, withAutoClose, severityType, title, variant);
    }

    async handleSectionsHasChangesNotification(dto: NotificationSectionsHasChangesParams): Promise<void> {
        const { sections } = dto;
        const { formatMessage } = this.intlStore.intl;
        const { pushNotification, deleteNotification } = this.model;
        const title = formatMessage({ id: 'form.unsavedChanges' });
        const text = <NotificationSectionsLinks id={this.currentEntityId} sections={sections} />;

        this.hasChangesNotificationId && (await deleteNotification(this.hasChangesNotificationId));
        pushNotification(text, false, SeverityType.warning, title, 'standard', this.setHasChangesNotificationId);
    }

    sendConnect(bodyDTO: NotificationLocationDTO): void {
        const dto: NotificationRequestData<NotificationLocationDTO> = {
            code: NotificationRequestCode.connect,
            body: bodyDTO,
        };
        this.send(dto);
    }

    sendDisconnect(bodyDTO: NotificationLocationDTO): void {
        const dto: NotificationRequestData<NotificationLocationDTO> = {
            code: NotificationRequestCode.disconnect,
            body: bodyDTO,
        };
        this.send(dto);
    }

    private send<T>(dto: T): void {
        this.isConnected && this.socket.send(JSON.stringify(dto));
    }

    closeConnection(): void {
        this.socket.close();
    }

    setIsConnected(isConnected: boolean): void {
        this.isConnected = isConnected;
    }

    setCurrentEntityId(id: string): void {
        this.currentEntityId = id;
    }

    setHasChangesNotificationId(id: string): void {
        this.hasChangesNotificationId = id;
    }
}

export const getNotificationStore = (): any => {
    const [_NotificationStore] = di([NotificationStore], getNotificationStore);
    return _NotificationStore;
};

import { ApiStore, CoreRootStore } from '@platform/front-core';
import { AsyncCheckActionType, FullSubmission, UserDTO } from '@platform/front-types';
import { AsyncCheckStore, CodeTitle, TotLocale } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { ApiConfigs } from '../apiConfigs';
import {
    EditStatusDTO,
    FullNameFields,
    FullNameSettingsDTO,
    LoginFields,
    LoginSettingsDTO,
    RolesFields,
    RolesSettingsDTO,
} from '../types';

export const userStoreObservables = {
    rootStore: observable,
    api: observable,
    apiConfigs: observable,
    asyncCheckStore: observable,

    loginAsUser: action.bound,
    loadUser: action.bound,
    saveUser: action.bound,
    userRoleList: action.bound,
    deleteUser: action.bound,
    getUserFullName: action.bound,
    editUserStatus: action.bound,
    setRoles: action.bound,
    setUserLang: action.bound,
    setFullName: action.bound,
};

/**
 * Стор с апи для работы с данными пользователя.
 * */
export class UserStore {
    protected rootStore: CoreRootStore;
    protected api: ApiStore;
    protected apiConfigs: ApiConfigs;
    protected asyncCheckStore: AsyncCheckStore;

    constructor(coreRootStore: CoreRootStore) {
        makeObservable(this, userStoreObservables);
        const { api, asyncCheckStore } = coreRootStore;
        this.rootStore = coreRootStore;
        this.api = api;
        this.asyncCheckStore = asyncCheckStore;
        this.apiConfigs = api.apiConfigs as ApiConfigs;
    }

    /**
     * Выполнить вход как пользователь
     * @param id - Идентификатор пользователя
     * */
    loginAsUser(id: string): Promise<void> {
        const { currentUserStore, history } = this.rootStore;
        return this.api
            .client(this.apiConfigs.loginAsUser(id))
            .then(currentUserStore.getInfo)
            .then(() => {
                history.push(generatePath(currentUserStore.redirectPath));
            });
    }

    /**
     * Загрузить данные пользователя
     * @param id - Идентификатор пользователя
     * @returns Промис с данными пользователя
     */
    loadUser(id: string): Promise<UserDTO> {
        return this.api.mainInfoClient(this.apiConfigs.loadUser(id)).then((r) => {
            return r.data;
        });
    }

    /**
     * Сохранить данные пользователя
     * @param id - Идентификатор пользователя
     * @param submission - Данные формы
     */
    saveUser(id: string, submission: FullSubmission): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.saveUser(id, submission)).then((r) => r.data);
    }

    /**
     * Запросить роли пользователя
     * @returns Промис с массивом ролей пользователя
     */
    userRoleList(): Promise<CodeTitle[]> {
        return this.api.client(this.apiConfigs.userRoleList).then((r) => r.data);
    }

    /**
     * Удалить пользователя
     * @param id - Идентификатор пользователя
     */
    deleteUser(id: string): Promise<void> {
        return this.api.client(this.apiConfigs.deleteUser(id)).then((r) => r.data);
    }

    /**
     * Запросить полное имя пользователя
     * @param id - Идентификатор пользователя
     * @returns Данные пользователя
     */
    getUserFullName(id: string): Promise<FullNameSettingsDTO> {
        return this.api.client(this.apiConfigs.userFullName(id)).then((r) => r.data);
    }

    /**
     * Изменить статус пользователя
     * @param id - Идентификатор пользователя
     * @param data - Новый статус пользователя
     * @param isAsyncCheckRequired - Требуется ли асинхронная проверка
     */
    editUserStatus(id: string, data: EditStatusDTO, isAsyncCheckRequired = false): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.editUserStatus(id, data))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, isAsyncCheckRequired))
            .then((r) => r.data);
    }

    /**
     * Установить логин пользователя
     * @param id - Идентификатор пользователя
     * @param dto - Новый логин
     */
    setLogin(id: string, dto: LoginSettingsDTO): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.setLogin(id, dto), Object.values(LoginFields))
            .then((r) => r.data);
    }

    /**
     * Установить роли пользователя
     * @param id - Идентификатор пользователя
     * @param dto - Роли
     */
    setRoles(id: string, dto: RolesSettingsDTO): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.setRoles(id, dto), Object.values(RolesFields))
            .then((r) => r.data);
    }

    /**
     * Установить локаль пользователя
     * @param id - Идентификатор пользователя
     * @param lang - Локаль
     */
    setUserLang(id: string, lang: TotLocale): Promise<void> {
        return this.api.userActionClient(this.apiConfigs.setUserLang(id, lang)).then((r) => r.data);
    }

    /**
     * Установить полное имя пользователя
     * @param id - Идентификатор пользователя
     * @param dto - Новое полное имя
     */
    setFullName(id: string, dto: FullNameSettingsDTO): Promise<void> {
        return this.api
            .userActionClient(this.apiConfigs.setFullName(id, dto), Object.values(FullNameFields))
            .then((r) => r.data);
    }
}

export const getUserStore = (): any => {
    const [_UserStore] = di([UserStore], getUserStore);
    return _UserStore;
};

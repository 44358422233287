import { ClientBreadcrumbs } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useCampaignPageContext, useSetBreadcrumbsProps, useStore } from '../../../../hooks';
import { CampaignRequestFormRouteParams } from '../../../../types';

export const CampaignSettingsPageHeaderRequestFormBreadcrumbs = observer((): JSX.Element => {
    const { breadcrumbsStore } = useStore().coreRootStore;
    const { breadCrumbsSettings } = breadcrumbsStore;

    const { requestFormModel } = useCampaignPageContext();
    const { title: requestFormTitle } = requestFormModel;

    const params = useParams<CampaignRequestFormRouteParams>();
    const { rfId } = params;
    useSetBreadcrumbsProps({ rfId }, { regForm: requestFormTitle });

    return <ClientBreadcrumbs breadcrumbsSettings={breadCrumbsSettings} />;
});

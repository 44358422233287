import { PromiseVoidFunction, promiseVoidFunction } from '@platform/front-utils';
import { createContext } from 'react';
import { CampaignInfoModel, CampaignSettingsRequestFormModel } from '../models';

export type CampaignPageContextType = {
    model: CampaignInfoModel;
    reloadModel: PromiseVoidFunction;
    requestFormModel: CampaignSettingsRequestFormModel;
};

export const CampaignPageContext = createContext<CampaignPageContextType>({
    requestFormModel: new CampaignSettingsRequestFormModel(),
    reloadModel: promiseVoidFunction,
    model: new CampaignInfoModel(),
});

import { Grid } from '@mui/material';
import { AuthorizationCheck, SyslogLink } from '@platform/front-core';
import { CommonInfoLine } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { entities, permissions, permissionsConfig } from '../../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../../clientRoute';
import { useCampaignRequestPageContext } from '../../../../../../hooks';
import { formatDateAndTime } from '../../../../../../utils';
import { renderCampaignRequestLink as renderCampaignRequestLinkInj } from '../../../../components';
import { CampaignRequestInfoCommonObjectRelations as CampaignRequestInfoCommonObjectRelationsInj } from './CampaignRequestInfoCommonObjectRelations';
import { CampaignRequestInfoCommonSimilarRequests as CampaignRequestInfoCommonSimilarRequestsInj } from './CampaignRequestInfoCommonSimilarRequests';

export const CampaignRequestInfoCommon = observer((): JSX.Element => {
    const [renderCampaignRequestLink] = di([renderCampaignRequestLinkInj], CampaignRequestInfoCommon);
    const [CampaignRequestInfoCommonObjectRelations] = di(
        [CampaignRequestInfoCommonObjectRelationsInj],
        CampaignRequestInfoCommon,
    );
    const [CampaignRequestInfoCommonSimilarRequests] = di(
        [CampaignRequestInfoCommonSimilarRequestsInj],
        CampaignRequestInfoCommon,
    );

    const { model } = useCampaignRequestPageContext();
    const { id, number, author, campaign, regEnd, sent, regFormTitle, created, currentSection, state } = model;
    const { formatMessage } = useIntl();
    const campaignId = campaign.id;
    const authorId = author.userId;
    const sectionExecutor = currentSection?.executor;

    const authorLink = renderCampaignRequestLink(
        authorId ? generatePath(clientRoute.user, { id: authorId }) : '',
        author.lastNameWithInitials,
    );
    const campaignLink = renderCampaignRequestLink(
        campaignId ? generatePath(clientRoute.campaign, { id: campaignId }) : '',
        campaign.title,
    );

    const executorLink = sectionExecutor
        ? renderCampaignRequestLink(
              generatePath(clientRoute.user, { id: sectionExecutor.userId }),
              sectionExecutor.lastNameWithInitials,
          )
        : null;

    return (
        <Grid container flexDirection="column" justifyContent="start" alignItems="start" flexWrap="nowrap" spacing={1}>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.number' })}
                    value={`№ ${number}`}
                />
            </Grid>
            {executorLink && (
                <Grid item>
                    <CommonInfoLine
                        isColumnDirection
                        title={formatMessage({ id: 'campaignRequest.fields.executor' })}
                        value={executorLink}
                    />
                </Grid>
            )}
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.author' })}
                    value={authorLink}
                />
            </Grid>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.campaign' })}
                    value={campaignLink}
                />
            </Grid>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.requestForm' })}
                    value={regFormTitle}
                />
            </Grid>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.submissionDeadline' })}
                    value={formatDateAndTime(regEnd)}
                />
            </Grid>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.created' })}
                    value={formatDateAndTime(created)}
                />
            </Grid>
            <Grid item>
                <CommonInfoLine
                    isColumnDirection
                    title={formatMessage({ id: 'campaignRequest.fields.sent' })}
                    value={formatDateAndTime(sent, formatMessage({ id: 'campaignRequest.fields.notSubmitted' }))}
                />
            </Grid>
            <CampaignRequestInfoCommonObjectRelations key={state.id} />
            <AuthorizationCheck {...permissionsConfig.viewSimilarObjects(id)}>
                <CampaignRequestInfoCommonSimilarRequests />
            </AuthorizationCheck>
            <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.ViewLogsRegistry}>
                <SyslogLink to={generatePath(clientRoute.requestSyslog, { id })} />
            </AuthorizationCheck>
        </Grid>
    );
});

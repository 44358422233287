import { PersonModel } from '@platform/front-core';
import { FormDTO, UserPersonDTO } from '@platform/front-types';
import { action, makeObservable, observable } from 'mobx';
import { RequestStore } from '../stores';
import { RequestSectionDTO } from '../types';
import { PrintFormListModel } from './PrintFormListModel';

export const RequestFormSectionModelProps = {
    requestStore: observable,
    id: observable,
    title: observable,
    formDTO: observable,
    executor: observable,
    printForms: observable,
    isEditable: observable,
    isFormLoading: observable,

    load: action.bound,
    dropIsFormLoading: action.bound,
    dropFormDTO: action.bound,
    checkIsSectionEditable: action.bound,

    setIsEditable: action.bound,
    setIsFormLoading: action.bound,
    setFormDTO: action.bound,
    setExecutor: action.bound,
    setPrintForms: action.bound,
};

export class RequestFormSectionModel {
    protected requestStore: RequestStore;

    id: string;
    title = '';
    formDTO?: FormDTO;
    executor?: PersonModel;
    printForms?: PrintFormListModel;
    isEditable = false;
    isFormLoading = true;

    constructor(id: string, requestStore: RequestStore) {
        makeObservable(this, RequestFormSectionModelProps);
        this.id = id;
        this.requestStore = requestStore;
    }

    load(dto: RequestSectionDTO, isFormView: boolean): void {
        const { id, title, executor, formInfo } = dto;
        this.id = id;
        this.title = title;
        isFormView && this.setFormDTO(formInfo);
        this.setExecutor(executor);
        this.checkIsSectionEditable();
    }

    dropIsFormLoading(): void {
        this.isFormLoading = false;
    }

    dropFormDTO(): void {
        this.formDTO = undefined;
    }

    checkIsSectionEditable(): void {
        this.requestStore
            .checkIsSectionEditable(this.id)
            .then(() => this.setIsEditable(true))
            .catch(() => this.setIsEditable(false));
    }

    setIsEditable(isEditable: boolean): void {
        this.isEditable = isEditable;
    }

    setIsFormLoading(isFormLoading: boolean): void {
        this.isFormLoading = isFormLoading;
    }

    setFormDTO(formDTO: FormDTO): void {
        this.formDTO = formDTO;
    }

    setExecutor(executor?: UserPersonDTO): void {
        this.executor = executor ? new PersonModel().load(executor.person) : undefined;
    }

    setPrintForms(model: PrintFormListModel): void {
        this.printForms = model;
    }
}

import { Link } from '@platform/front-ui';
import React from 'react';

export const renderCampaignRequestLink = (to: string, title: string): JSX.Element => {
    return (
        <Link key={to} href={to}>
            {title}
        </Link>
    );
};

import { IdLinkType, IdTitle, ModalProps, RouteParamsDefault } from '@platform/front-types';
import { ConfirmationDialogProps } from '@platform/front-ui';
import { LinkType, TTableRow } from '@platform/ttable';

export type CampaignRequestRow = TTableRow & {
    customData: {
        campaign: IdLinkType;
        regForm: IdTitle;
        number: LinkType;
        state: IdTitle;
        created: string; //date
        executors: Array<IdTitle>;
        author: IdLinkType;
        sent: string; //date
    };
};

export interface CampaignRequestListFilterData {
    filter: { campaigns: string } | {};
}

export type RequestRow = TTableRow & {
    number: string;
    campaign: IdTitle;
};

export enum CampaignRequestListConfirmationButtons {
    delete = 'delete',
    addExecutor = 'addExecutor',
}

export type CampaignRequestListSettingsConfirmDialogType = Pick<ConfirmationDialogProps, 'onConfirm'> & {
    id: CampaignRequestListConfirmationButtons;
    titleId: string;
    messageId: string;
};

export type CampaignRequestListSettingsPropsType = Record<
    CampaignRequestListConfirmationButtons,
    ConfirmationDialogProps['onConfirm']
>;
export type CampaignRequestListSettings = Record<
    CampaignRequestListConfirmationButtons,
    CampaignRequestListSettingsConfirmDialogType
>;

export type CampaignRequestSectionRouteParams = RouteParamsDefault & {
    sectionId: string;
};

export type CampaignRequestRouteParams = RouteParamsDefault & {
    sectionId?: string;
};

export type CampaignRequestRoutes = {
    root: string;
    card: string;
    section: string;
    formEdit: string;
    syslog: string;
};

export enum CampaignRequestObjectFieldName {
    author = 'author',
    syslog = 'syslog',
    category = 'category',
    responsible = 'responsible',
}

export enum CampaignRequestCommonFieldName {
    title = 'title',
    number = 'number',
    created = 'created',
    lifecycle = 'lifecycle',
    form = 'form',
    modified = 'modified',
}

export const CampaignRequestFieldName = { ...CampaignRequestObjectFieldName, ...CampaignRequestCommonFieldName };
export type CampaignRequestFieldName = keyof typeof CampaignRequestFieldName;

export type CampaignRequestEditFieldModalProps = ModalProps & {
    objectId: string;
};

export type CampaignRequestEditModalComponent<T extends CampaignRequestEditFieldModalProps> = (props: T) => JSX.Element;

export type LabelUrlType = {
    label: string;
    url: string;
};

export type CampaignRequestServiceInfoDTO = {
    lifecycleDTO: LabelUrlType;
    formInfoDTO: LabelUrlType[];
    lastModified?: string;
};

export enum CampaignRequestTabs {
    service = 'service',
    common = 'common',
}

import { AppHeaderLinkData, coreClientRoute, HeaderLinksStore as HeaderLinksStoreInj } from '@platform/front-core';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../authSchemeConfig';
import { clientRoute } from '../clientRoute';

export class HeaderLinksStore extends HeaderLinksStoreInj {
    get mainLinks(): AppHeaderLinkData[] {
        return [
            {
                key: 'campaigns',
                to: clientRoute.campaigns,
                messageDescriptor: { id: 'homePage.headerLinks.campaigns' },
                query: {
                    entityCode: entities.system,
                    permCode: permissions.system.ViewCampaignList,
                },
            },
            {
                key: 'requests',
                to: clientRoute.requests,
                messageDescriptor: { id: 'homePage.headerLinks.requests' },
                query: {
                    entityCode: entities.system,
                    permCode: permissions.system.ViewCampRequestRegistry,
                },
            },
            ...this.generalLinks,
            {
                key: 'console',
                to: coreClientRoute.console,
                messageDescriptor: { id: 'homePage.headerLinks.console' },
                query: {
                    entityCode: entities.system,
                    permCode: permissions.system.Administration,
                },
            },
            {
                key: clientRoute.kafkaEvents,
                to: clientRoute.kafkaEvents,
                messageDescriptor: { id: 'kafkaEvents.title' },
                query: {
                    entityCode: entities.system,
                    permCode: permissions.system.Administration,
                },
            },
        ];
    }
}

export const getHeaderLinksStore = (): any => {
    const [_HeaderLinksStore] = di([HeaderLinksStore], getHeaderLinksStore);
    return _HeaderLinksStore;
};

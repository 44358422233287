import React from 'react';
import ReactDOM from 'react-dom';
import 'setimmediate'; // setimmediate нужно для работы с react-scripts@5.0.0
import { apiConfigs } from './apiConfigs';
import { env, features, history, locales, palette } from './initRootProps';
import { Root } from './Root';
import { WebSocketApiConfigs } from './webSocketApiConfigs';
// не могу импортировать rootElement из @platform/front-core,
// т.к. он должен идти после setimmediate, а сортировка импортов поднимает его выше

ReactDOM.render(
    <React.StrictMode>
        <Root
            env={env}
            features={features}
            locales={locales}
            history={history}
            palette={palette}
            apiConfigs={apiConfigs}
            WebSocketApiConfigsClass={WebSocketApiConfigs}
        />
    </React.StrictMode>,
    document.getElementById('root'),
);

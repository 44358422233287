import { Theme } from '@mui/material';
import { grey, makeSxStylesWithProps } from '@platform/front-ui';

const middleScreenWidth = 1825;
const minScreenWidth = 1232;

export const campaignListCardSxStyles = makeSxStylesWithProps((theme: Theme) => {
    return {
        wrapper: {
            flexBasis: '33.3%',

            [theme.breakpoints.down(middleScreenWidth)]: {
                flexBasis: '50%',
            },
            [theme.breakpoints.down(minScreenWidth)]: {
                flexBasis: '100%',
            },
        },
        inner: {
            position: 'relative',
            width: '100%',
            height: '140px',
            border: `1px solid ${grey[200]}`,
            borderRadius: '8px',
            color: 'inherit',
            textDecoration: 'none',
            overflow: 'hidden',

            '&:hover, &:focus': {
                outline: 'none',
                borderColor: grey[400],
            },

            '&:hover [class*="settings-button"]': {
                display: 'inline-flex',
            },

            '&:active': {
                borderColor: grey[600],
            },
        },
        settings: {
            display: 'none',
        },
        imageWrapper: {
            width: '160px',
            height: '139px',
            overflow: 'hidden',
        },
        image: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            background: theme.palette.secondary.light,
        },
        contentWrapper: {
            padding: theme.spacing(2),
            width: 'calc(100% - 160px)',
        },
        title: {
            maxWidth: '440px',
        },
        leftButtonsGroup: {
            flexBasis: '50%',
            justifyContent: 'start',
        },
        rightButtonsGroup: {
            flexBasis: '50%',
            justifyContent: 'end',
        },
        date: {
            width: 'auto',
            padding: '2px 8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
});

import { Autocomplete, AutocompleteRenderInputParams, Grid, TextField, Typography } from '@mui/material';
import { CKEditorToolbarConfigDefault } from '@platform/comments';
import { inputWithPlaceholderProps } from '@platform/front-core';
import { FieldWithServerError, FieldWithServerErrorProps, makeSxStyles } from '@platform/front-ui';
import { MultiLangField } from '@platform/multi-lang-field';
import { useFormikContext } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RequestFormSectionSettingModel } from '../../../../../../../../../../models';
import {
    CommentsSectionSettingsFormField,
    RequestFormSectionSettingFormValues,
} from '../../../../../../../../../../types';

const checkboxProps: FieldWithServerErrorProps = {
    type: 'checkbox',
    component: CheckboxWithLabel,
    isWithoutHelperTextProp: true,
};

const sx = makeSxStyles({
    flexColumn: {
        flexDirection: 'column',
    },
    topBar: {
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: (theme) => theme.spacing(1),
        minHeight: (theme) => theme.spacing(4),
    },
});

export type CampaignSettingsCommentsSettingDialogFormFieldsProps = {
    sectionSettingModel: RequestFormSectionSettingModel;
};

/**
 * Компонент, объединяющий поля настройки комментариев
 * @prop sectionSettingModel - Модель формы настройки создания раздела
 */
export const CampaignSettingsCommentsSettingDialogFormFields = observer(
    (props: CampaignSettingsCommentsSettingDialogFormFieldsProps): JSX.Element => {
        const { sectionSettingModel } = props;
        const { getFieldLabel, getFieldPlaceholder } = sectionSettingModel;
        const { values, setFieldValue } = useFormikContext<RequestFormSectionSettingFormValues>();
        const { showComments: isCommentsEnabled, textEditorSettings } = values;

        const showCommentsLabel = { label: getFieldLabel(CommentsSectionSettingsFormField.showComments) };
        const enableCommentStatusesLabel = {
            label: getFieldLabel(CommentsSectionSettingsFormField.enableCommentStatuses),
        };

        const renderTextFieldInput = (params: AutocompleteRenderInputParams): JSX.Element => {
            return (
                <TextField
                    {...params}
                    variant="outlined"
                    label={getFieldLabel(CommentsSectionSettingsFormField.textEditorSettings)}
                />
            );
        };

        const getTextEditorSettingsLabel = (setting: string): string => {
            return setting;
        };

        const setTextEditorSettings = (e: any, value?: string | string[] | null): void => {
            setFieldValue(CommentsSectionSettingsFormField.textEditorSettings, value);
        };

        return (
            <Grid item container sx={sx.flexColumn}>
                <Grid item container sx={sx.topBar}>
                    <Typography variant="button">
                        <FormattedMessage id="campaignSettings.comments" />
                    </Typography>
                </Grid>
                <Grid item container sx={sx.flexColumn} spacing={0.06}>
                    <Grid item>
                        <FieldWithServerError
                            {...checkboxProps}
                            name={CommentsSectionSettingsFormField.showComments}
                            Label={showCommentsLabel}
                        />
                    </Grid>
                    {isCommentsEnabled && (
                        <React.Fragment>
                            <Grid item>
                                <FieldWithServerError
                                    {...checkboxProps}
                                    name={CommentsSectionSettingsFormField.enableCommentStatuses}
                                    Label={enableCommentStatusesLabel}
                                />
                            </Grid>
                            <Grid item>
                                <MultiLangField fieldName={CommentsSectionSettingsFormField.titles} ruEngLangs={true} />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
                {isCommentsEnabled && (
                    <React.Fragment>
                        <Grid item mt={1}>
                            <FieldWithServerError
                                {...inputWithPlaceholderProps}
                                name={CommentsSectionSettingsFormField.maxCommentLength}
                                label={getFieldLabel(CommentsSectionSettingsFormField.maxCommentLength)}
                                placeholder={getFieldPlaceholder(CommentsSectionSettingsFormField.maxCommentLength)}
                            />
                        </Grid>
                        <Grid item mt={2}>
                            <Autocomplete
                                renderInput={renderTextFieldInput}
                                options={CKEditorToolbarConfigDefault}
                                getOptionLabel={getTextEditorSettingsLabel}
                                onChange={setTextEditorSettings}
                                value={textEditorSettings}
                                multiple
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    },
);

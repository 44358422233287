var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
import { sassVars } from '../../constants';
import formLabel from '../mixins/formLabel';
export default function () {
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        .panel .formio-component-fieldset,\n        .formio-component-fieldset .formio-component-fieldset {\n            legend {\n                ", ";\n                border-bottom: none;\n            }\n\n            .card-body {\n                border: ", ";\n                padding: ", ";\n\n                > .form-group {\n                    margin-bottom: ", ";\n                }\n            }\n        }\n    "], ["\n        .panel .formio-component-fieldset,\n        .formio-component-fieldset .formio-component-fieldset {\n            legend {\n                ", ";\n                border-bottom: none;\n            }\n\n            .card-body {\n                border: ", ";\n                padding: ", ";\n\n                > .form-group {\n                    margin-bottom: ", ";\n                }\n            }\n        }\n    "])), formLabel(), sassVars.formioFieldsetBorder, sassVars.formioFieldsetBodyPadding, sassVars.formioFieldsetFormGroupMarginBottom).join('');
}
var templateObject_1;

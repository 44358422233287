import { Button, Grid, SxProps, Theme, Typography } from '@mui/material';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Route, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';

export type CampaignSettingsDescriptionHeaderProps = {
    isFormView: boolean;
};

const headerSx: (isFormView: boolean) => SxProps<Theme> = (isFormView) => {
    return {
        paddingX: 2,
        paddingTop: isFormView ? 1 : 2,
        paddingBottom: 3,
    };
};

const titleSx: SxProps<Theme> = {
    fontSize: '16px',
    fontWeight: 500,
};

export const CampaignSettingsDescriptionHeader = observer(
    (props: CampaignSettingsDescriptionHeaderProps): JSX.Element => {
        const history = useHistory();
        const { id } = useParams<RouteParamsDefault>();
        const { isFormView } = props;

        const goToEditFormPage = (): void => {
            history.push(generatePath(clientRoute.campaignSettingsEdit, { id }));
        };

        return (
            <Grid container justifyContent="space-between" alignItems="center" sx={headerSx(isFormView)}>
                <Grid item>
                    <Typography variant="subtitle2" sx={titleSx}>
                        <Route exact path={clientRoute.campaignSettingsDescription}>
                            <FormattedMessage id="campaignSettings.campaignDescription" />
                        </Route>
                        <Route path={clientRoute.campaignSettingsEdit}>
                            <FormattedMessage id="campaignSettings.campaignDescriptionEdit" />
                        </Route>
                        <Route path={clientRoute.createCampaign}>
                            <FormattedMessage id="campaignSettings.campaignDescriptionCreate" />
                        </Route>
                        <Route exact path={clientRoute.campaignSettingsRequestForms}>
                            <FormattedMessage id="campaignSettings.campaignRequestForms" />
                        </Route>
                    </Typography>
                </Grid>

                <Route exact path={clientRoute.campaignSettingsDescription}>
                    <Grid item>
                        <Button color="secondary" onClick={goToEditFormPage}>
                            <FormattedMessage id="common.edit" />
                        </Button>
                    </Grid>
                </Route>
            </Grid>
        );
    },
);

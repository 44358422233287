import { FormLabel, Grid, SxProps, Theme } from '@mui/material';
import { FieldWithServerError } from '@platform/front-ui';
import { useFormikContext } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { CampaignPfTemplateModel } from '../../../../../../../../models';
import { TemplateFormSignatureFields, TemplateFormValues } from '../../../../../../../../types';
import { campaignSettingsSectionSxStyles } from './campaignSettingsSectionSxStyles';

export type DigitalSignaturePfFormFieldsProps = {
    model: CampaignPfTemplateModel;
};

const lastGroupCheckbox: SxProps<Theme> = {
    marginBottom: 2,
};

const { signatureEnabled, signatureRequired, signatureBrowserPlugin, signatureUpload } = TemplateFormSignatureFields;

export const DigitalSignaturePfFormFields = observer((props: DigitalSignaturePfFormFieldsProps): JSX.Element => {
    const { model } = props;
    const { getFieldLabel } = model;
    const { values, setFieldValue } = useFormikContext<TemplateFormValues>();
    const isEnableSignature = values.signatureSettings.signatureEnabled;

    const enabledLabel = { label: getFieldLabel(signatureEnabled) };
    const requiredLabel = { label: getFieldLabel(signatureRequired) };
    const browserPluginLabel = { label: getFieldLabel(signatureBrowserPlugin) };
    const uploadLabel = { label: getFieldLabel(signatureUpload) };

    useEffect(() => {
        if (!isEnableSignature) {
            Object.values(TemplateFormSignatureFields).map((signatureField) => {
                setFieldValue(signatureField, false);
            });
        }
    }, [isEnableSignature]);

    return (
        <React.Fragment>
            <Grid item>
                <FieldWithServerError
                    name={signatureEnabled}
                    component={CheckboxWithLabel}
                    type="checkbox"
                    Label={enabledLabel}
                    isWithoutHelperTextProp
                />
            </Grid>
            {isEnableSignature && (
                <React.Fragment>
                    <Grid item sx={lastGroupCheckbox}>
                        <FieldWithServerError
                            name={signatureRequired}
                            component={CheckboxWithLabel}
                            type="checkbox"
                            Label={requiredLabel}
                            isWithoutHelperTextProp
                        />
                    </Grid>

                    <Grid item>
                        <FormLabel sx={campaignSettingsSectionSxStyles.formLabel}>
                            <FormattedMessage id="campaignSettings.signatureVariants" />
                        </FormLabel>
                    </Grid>
                    <Grid item>
                        <FieldWithServerError
                            name={signatureBrowserPlugin}
                            component={CheckboxWithLabel}
                            type="checkbox"
                            Label={browserPluginLabel}
                            isWithoutHelperTextProp
                        />
                    </Grid>
                    <Grid item>
                        <FieldWithServerError
                            name={signatureUpload}
                            component={CheckboxWithLabel}
                            type="checkbox"
                            Label={uploadLabel}
                            isWithoutHelperTextProp
                        />
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
});

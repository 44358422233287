import { Box, Container, Grid, Typography } from '@mui/material';
import { registryPageStyles } from '@platform/front-core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { KafkaEventsRegistry as KafkaEventsRegistryInj } from './KafkaEventsRegistry';

export const KafkaEventsRegistryPage = (): JSX.Element => {
    const [KafkaEventsRegistry] = di([KafkaEventsRegistryInj], KafkaEventsRegistryPage);

    return (
        <Container maxWidth={false} sx={registryPageStyles.container}>
            <Box pt={2}>
                <Grid container spacing={2} flexDirection="column">
                    <Grid container item wrap="nowrap" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle2">
                                <FormattedMessage id="kafkaEvents.title" />
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item sx={registryPageStyles.tableWrapper}>
                        <KafkaEventsRegistry />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

import { DateUtils } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { CampaignsStore } from '../stores';
import { CampaignRequestFormListMember, CampaignRequestFormListMemberDTO } from '../types';

export const CampaignRequestFormListModelProps = {
    campaignRequestForms: observable,
    campaignId: observable,
    isLoading: observable,

    getCampaignRequestFormsData: action.bound,
    mapDTO: action.bound,
    setCampaignRequestFormsData: action.bound,
    setLoadingStart: action.bound,
    setLoadingFinish: action.bound,
};

/**
 * Модель, отвечающая за список форм категории.
 * @param campaignsStore CampaignsStore
 * @param campaignId Айди категории.
 */
export class CampaignRequestFormListModel {
    private campaignsStore: CampaignsStore;
    campaignId: string;
    campaignRequestForms: CampaignRequestFormListMember[] = [];
    isLoading = false;

    constructor(campaignsStore: CampaignsStore, campaignId: string) {
        makeObservable(this, CampaignRequestFormListModelProps);
        this.campaignsStore = campaignsStore;
        this.campaignId = campaignId;
        this.getCampaignRequestFormsData();
    }

    /** Загружает, мапит и сеттит формы. */
    getCampaignRequestFormsData(): Promise<void> {
        this.setLoadingStart();
        return this.campaignsStore
            .getCampaignRequestForms(this.campaignId)
            .then((data) => data.map(this.mapDTO))
            .then((data) => this.setCampaignRequestFormsData(data))
            .finally(() => {
                this.setLoadingFinish();
            });
    }

    /** Преобразоывывает формы в CampaignRequestFormListMember. */
    mapDTO(dto: CampaignRequestFormListMemberDTO): CampaignRequestFormListMember {
        const regStart = new Date(dto.regStart);
        const regEnd = new Date(dto.regEnd);
        return {
            id: dto.id,
            title: dto.title,
            procedureTitle: dto.procedureTitle,
            regStart: DateUtils.isValidDate(regStart) ? regStart : undefined,
            regEnd: DateUtils.isValidDate(regEnd) ? regEnd : undefined,
            allowAfterDeadline: dto.allowAfterDeadline,
            reEnrollAllowed: dto.reEnrollAllowed,
            fileInstruction: dto.fileInstruction,
        };
    }

    /** Сеттит формы. */
    setCampaignRequestFormsData(requestForms: CampaignRequestFormListMember[]): void {
        this.campaignRequestForms = requestForms;
    }

    setLoadingStart() {
        this.isLoading = true;
    }

    setLoadingFinish() {
        this.isLoading = false;
    }
}

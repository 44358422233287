import { Box, Container, Grid } from '@mui/material';
import { AuthorizationCheck } from '@platform/front-core';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../authSchemeConfig';
import {
    CampaignRequestList as CampaignRequestListInj,
    campaignRequestListTableSxStyles,
    campaignRequestSetRowActionsInj,
} from '../../../../campaign-request';
import { ttableDefaultPaths } from '../../../../../constants';

const customTableHeight = '55vh';

export const CampaignCardBodyRequests = observer((): JSX.Element => {
    const [CampaignRequestList] = di([CampaignRequestListInj], CampaignCardBodyRequests);
    const [campaignRequestSetRowActions] = di([campaignRequestSetRowActionsInj], CampaignCardBodyRequests);

    const { formatMessage } = useIntl();
    const tableTitle = formatMessage({ id: 'campaignRequest.registryTitle' });

    const { id } = useParams<RouteParamsDefault>();
    const campaignRequestPath = ttableDefaultPaths.getCampaignRequest(id);
    const customRegistryCode = `campaign/${id}`;

    return (
        <AuthorizationCheck
            entityCode={entities.campaign}
            permCode={permissions.campaign.ViewRequestsRegistryForCampaign}
            entityId={id}
            isWithRedirect
        >
            <Container maxWidth={false} sx={campaignRequestListTableSxStyles.container}>
                <Box pt={2}>
                    <Grid container spacing={2} flexDirection="column">
                        <Grid item sx={campaignRequestListTableSxStyles.tableWrapper}>
                            <CampaignRequestList
                                setRowActions={campaignRequestSetRowActions}
                                title={tableTitle}
                                customRegistryCode={customRegistryCode}
                                customTableHeight={customTableHeight}
                                defaultPath={campaignRequestPath}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </AuthorizationCheck>
    );
});

import { SkeletonProps } from '@mui/lab';
import { Grid } from '@mui/material';
import { CommonInfoLine, makeSxStyles, Typography } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserPageContext } from '../../../../../hooks';
import { UserPageSidebarActions } from './user-page-sidebar-actions';

const sxStyles = makeSxStyles({
    firstItem: (theme) => ({
        marginBottom: theme.spacing(1),
    }),
});

const langSkeletonProps: SkeletonProps = {
    width: 60,
};

export const UserPageSidebar = observer((): JSX.Element => {
    const { userModel } = useUserPageContext();
    const { formatMessage } = useIntl();
    const { lang, isLoading } = userModel;

    return (
        <Grid container item direction="column">
            <Grid container item justifyContent="space-between" alignItems="center" sx={sxStyles.firstItem}>
                <Grid item>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="user.info" />
                    </Typography>
                </Grid>
                <Grid item>
                    <UserPageSidebarActions />
                </Grid>
            </Grid>
            <Grid container item>
                <CommonInfoLine
                    title={formatMessage({ id: 'user.mainLanguage' })}
                    value={lang && formatMessage({ id: `locale.${lang}` })}
                    isColumnDirection={true}
                    isLoading={isLoading}
                    skeletonProps={langSkeletonProps}
                />
            </Grid>
        </Grid>
    );
});

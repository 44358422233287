import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { RenderFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const wrapperSx: SxProps<Theme> = {
    paddingTop: 0,
    paddingBottom: 0.5,
    paddingX: 2,
};

export type CampaignSettingsBodyHeaderProps = {
    renderButtons: RenderFunction;
    sx?: SxProps<Theme>;
    titleId?: string;
    titleSx?: SxProps<Theme>;
};

export const CampaignSettingsBodyHeader = observer((props: CampaignSettingsBodyHeaderProps): JSX.Element => {
    const { titleId, sx = wrapperSx, titleSx, renderButtons } = props;
    const isWithTitle = !!titleId;

    const actionButtons = renderButtons();

    return (
        <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap" sx={sx}>
            <Grid item>
                {isWithTitle && (
                    <Typography variant="subtitle2" sx={titleSx}>
                        <FormattedMessage id={titleId} />
                    </Typography>
                )}
            </Grid>
            <Grid item>
                <Grid container spacing={1}>
                    {actionButtons}
                </Grid>
            </Grid>
        </Grid>
    );
});

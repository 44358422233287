import { Grid, Tab, TabsActions, TabsProps } from '@mui/material';
import { AuthorizationCheck, CommentsTabIndicator, RoutedTabs, RouteSetting } from '@platform/front-core';
import { makeSxStyles, mergeSxStyles, SxStyle, SxStyles } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, Link, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { commonTabsProps } from '../../../../constants';
import { useCampaignRequestPageContext } from '../../../../hooks';
import { CampaignRequestSectionRouteParams, RequestSectionListDTO } from '../../../../types';
import { CampaignRequestSectionContent as CampaignRequestSectionContentInj } from './components';
import { getCampaignRequestSectionPath as getCampaignRequestSectionPathInj } from './getCampaignRequestSectionPath';

export type CampaignRequestSectionsProps = {
    tabsActions: React.Ref<TabsActions>;
};

export const campaignRequestSectionsSxStyles = makeSxStyles({
    scroller: {
        display: 'flex',
        alignItems: 'center',
    },
    tab: {
        minWidth: '59px',
        maxWidth: 'none',
        '& > div': {
            alignItems: 'center',
            flexWrap: 'nowrap !important',
        },
    },
    tabWithIndicator: {
        paddingY: 0.65,
    },
    tabContainer: {
        alignItems: 'center',
        flexWrap: 'nowrap',
        '& > *': {
            '&:not(:first-child)': {
                paddingLeft: (theme) => theme.spacing(1) + '!important',
            },
        },
    },
});

const sx = campaignRequestSectionsSxStyles;

// Код декомпозирован в угоду читаемости и отсутствия циклических зависимостей (и во славу di)
// Иерархия следующая: CampaignRequestSections -> CampaignRequestSectionContent -> CampaignRequestSectionContentMenu -> CampaignRequestSubSection -> CampaignRequestSubSectionContent
// Пробовала выносить контент (CampaignRequestSectionContent + CampaignRequestSubSectionContent) в общий компонент, но это читается значительно хуже, и тяжко кастомизируется
export const CampaignRequestSections = observer((props: CampaignRequestSectionsProps): JSX.Element => {
    const [CampaignRequestSectionContent] = di([CampaignRequestSectionContentInj], CampaignRequestSections);
    const [getCampaignRequestSectionPath] = di([getCampaignRequestSectionPathInj], CampaignRequestSections);

    const { tabsActions } = props;
    const { model } = useCampaignRequestPageContext();
    const { sections, errorsModel } = model;
    const { errorsByTabs } = errorsModel;
    const { id } = useParams<CampaignRequestSectionRouteParams>();

    const settings: RouteSetting[] = [];
    sections.forEach((section) => {
        const { id: sectionId, title, subSections, hasActiveComments, hasUnreadComments } = section;

        const currentSetting = {
            title,
            tab: sectionId,
            path: generatePath(clientRoute.requestSection, { id, sectionId }),
            hasActiveComments,
            hasUnreadComments,
        };

        settings.push(currentSetting);

        // добавляем в список роутов дочерние, чтобы родитель подчеркивался выделенным, когда выбран дочерний
        if (subSections) {
            subSections.forEach((subSection) => {
                settings.push({
                    title: subSection.title,
                    tab: sectionId,
                    path: generatePath(clientRoute.requestSection, { id, sectionId: subSection.id }),
                });
            });
        }
    });

    // Внутрянку пробовала выносить в CampaignRequestSection, но у RoutedTabs бугурт, если передать внутрь Tab, обернутый в другой компонент
    const renderSection = (section: RequestSectionListDTO): JSX.Element => {
        const { id: sectionId, hasActiveComments, hasUnreadComments } = section;

        const pathParams = { id, sectionId };
        const errors = errorsByTabs[sectionId];
        const sectionPath = getCampaignRequestSectionPath(pathParams, errors);

        const label = (
            <Grid item container sx={sx.tabContainer}>
                {hasActiveComments && (
                    <AuthorizationCheck
                        entityCode={entities.section}
                        permCode={permissions.section.ViewComment}
                        entityId={sectionId}
                    >
                        <CommentsTabIndicator
                            statuses={{
                                hasActiveComments,
                                hasUnreadComments,
                            }}
                        />
                    </AuthorizationCheck>
                )}
                <CampaignRequestSectionContent section={section} />
            </Grid>
        );

        const tabSxStyles: SxStyle[] = [campaignRequestSectionsSxStyles.tab];
        hasActiveComments && tabSxStyles.push(campaignRequestSectionsSxStyles.tabWithIndicator);

        const tabSx = mergeSxStyles(...tabSxStyles);

        return (
            <Tab
                key={sectionId}
                // @ts-ignore
                component={Link}
                label={label}
                value={sectionId}
                to={sectionPath}
                sx={tabSx}
            />
        );
    };

    const tabsProps: TabsProps = {
        ...commonTabsProps,
        sx: campaignRequestSectionsSxStyles.scroller as SxStyles,
    };

    return (
        <RoutedTabs customTabsActions={tabsActions} tabsProps={tabsProps} settings={settings}>
            {sections.map(renderSection)}
        </RoutedTabs>
    );
});

import { clientRoute } from '../clientRoute';
import { generatePath } from 'react-router-dom';

export const ttableDefaultPaths = {
    users: generatePath(clientRoute.users),
    campaigns: generatePath(clientRoute.campaigns),
    requests: generatePath(clientRoute.requests),
    kafkaEvents: generatePath(clientRoute.kafkaEvents),
    getCampaignRequest: (id: string) => generatePath(clientRoute.campaignRequests, { id }),
};

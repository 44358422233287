import { voidFunction } from '@platform/front-utils';
import { createContext } from 'react';

export type CampaignSettingsTemplateListContextType = {
    reloadList: VoidFunction;
};

export const CampaignSettingsTemplateListContext = createContext<CampaignSettingsTemplateListContextType>({
    reloadList: voidFunction,
});

import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { UseFlag } from '@platform/front-utils';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CustomCommentSettingPermissionsInitialValues } from '../../../../../../../../../../../types';

export type CustomCommentsSettingEditPermissionFormContentMainCheckboxProps = {
    optionsHandles: UseFlag;
};

const truePredicate = (value: any): boolean => !!value === true;
const falsePredicate = (value: any): boolean => !!value === false;

/**
 * Чекбокс, контролирующий видимость и состояние формы добавления и редактирования доступа к комментариям.
 * @prop optionsHandles - Инструменты для взаимодействия с состоянием формы добавления и редактирования доступа к комментариям.
 */
export const CustomCommentsSettingEditPermissionFormContentMainCheckbox = observer(
    (props: CustomCommentsSettingEditPermissionFormContentMainCheckboxProps): JSX.Element => {
        const { optionsHandles } = props;
        const { values, setFieldValue } = useFormikContext<CustomCommentSettingPermissionsInitialValues>();
        const [isOptionsVisible, showCheckboxes, hideCheckboxes] = optionsHandles;
        const { formatMessage } = useIntl();

        const [mainCheckboxState, setMainCheckboxState] = useState<boolean | undefined>(false);

        const {
            allowDeleting,
            allowEditing,
            allowManagingCommentsStatuses,
            allowReplying,
            allowTopLevelCommentsCreating,
        } = values;

        const valuesToCheck = {
            allowDeleting,
            allowEditing,
            allowManagingCommentsStatuses,
            allowReplying,
            allowTopLevelCommentsCreating,
        };

        const valuesOfValuesToCheck = Object.values(valuesToCheck);
        const allowCommentingLabel = formatMessage({ id: 'campaignSettings.editPermissions.canComment' });

        useEffect(() => {
            if (!isOptionsVisible) {
                return;
            }
            const isMainCheckboxShouldBeDetermined = valuesOfValuesToCheck.every(truePredicate);
            const isMainCheckboxShouldBeIndetermined =
                (valuesOfValuesToCheck.some(truePredicate) && valuesOfValuesToCheck.some(falsePredicate)) ||
                valuesOfValuesToCheck.some(falsePredicate);

            isMainCheckboxShouldBeDetermined && setMainCheckboxState(true);
            isMainCheckboxShouldBeIndetermined && setMainCheckboxState(undefined);
        }, [valuesOfValuesToCheck, isOptionsVisible]);

        useEffect(() => {
            if (valuesOfValuesToCheck.every(falsePredicate)) {
                setMainCheckboxState(false);
            }

            if (valuesOfValuesToCheck.every(truePredicate)) {
                showCheckboxes();
                setMainCheckboxState(true);
            }

            if (valuesOfValuesToCheck.some(truePredicate) && valuesOfValuesToCheck.some(falsePredicate)) {
                showCheckboxes();
                setMainCheckboxState(undefined);
            }
        }, []);

        const handleCheckboxChange = useCallback((): void => {
            setMainCheckboxState(!isOptionsVisible);
            if (isOptionsVisible === true) {
                setFieldValue('allowDeleting', false);
                setFieldValue('allowEditing', false);
                setFieldValue('allowReplying', false);
                setFieldValue('allowManagingCommentsStatuses', false);
                setFieldValue('allowTopLevelCommentsCreating', false);
                hideCheckboxes();
            }
            if (isOptionsVisible === false) {
                setFieldValue('allowDeleting', true);
                setFieldValue('allowEditing', true);
                setFieldValue('allowReplying', true);
                setFieldValue('allowManagingCommentsStatuses', true);
                setFieldValue('allowTopLevelCommentsCreating', true);
                showCheckboxes();
            }
        }, [setFieldValue, isOptionsVisible, values]);

        const usingCommentingFunctionsCheckbox: JSX.Element = (
            <Checkbox
                onChange={handleCheckboxChange}
                value={mainCheckboxState}
                checked={mainCheckboxState === true}
                indeterminate={mainCheckboxState === undefined}
            />
        );

        return (
            <Grid item>
                <FormControlLabel label={allowCommentingLabel} control={usingCommentingFunctionsCheckbox} />
            </Grid>
        );
    },
);

import { useTheme } from '@mui/material';
import { TotTheme } from '@platform/front-ui';
import React from 'react';

export const ImagePlaceholder = (): JSX.Element => {
    const theme = useTheme<TotTheme>();
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30 30V0H0V30H30ZM9.16667 17.5L13.3333 22.5167L19.1667 15L26.6667 25H3.33333L9.16667 17.5Z"
                fill={theme.palette.secondary.disabledBackground}
            />
        </svg>
    );
};

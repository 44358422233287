import { UserStatus, UserStatusAction } from '@platform/front-types';
import { createLocalesWithoutCases, LocalesTree } from '@platform/front-utils';

const action: Record<UserStatusAction, string> = {
    block: 'Block',
    activate: 'Activate',
    unblock: 'Unblock',
};

const status: Record<UserStatus, string> = {
    blocked: 'Blocked',
    active: 'Active',
    inactive: 'Inactive',
};

export const userEn: LocalesTree = {
    ...createLocalesWithoutCases('user', 'users'),
    fields: {
        login: 'Login',
        status: 'Status',
        name: 'Full name',
        roles: 'Roles',
    },
    fullNameFields: {
        lastName: 'Last name',
        firstName: 'First name',
        middleName: 'Middle name',
    },
    settings: {
        roles: 'Assign roles',
        rolesTitle: 'Assign roles',
        fullName: 'Edit name',
        language: 'Change main system language',
        login: 'Change login',
    },
    status,
    action,
    notAllowed: 'It is impossible to perform this action on your account',
    info: 'Person info',
    createPerson: 'Create person',
    mainLanguage: 'Default language',

    phrases: {
        // confirmStatus
        confirmStatusBlock: 'Confirm blocking the {user}',
        confirmStatusActivate: 'Confirm {user} activation',
        confirmStatusUnblock: 'Confirm unblocking the {user}',

        // confirmStatusText
        confirmStatusTextBlock: 'Are you sure you want to block the {user} {name} "{login}"?',
        confirmStatusTextActivate: 'Are you sure you want to activate the {user} {name} "{login}"?',
        confirmStatusTextUnblock: 'Are you sure you want to unblock the {user} {name} "{login}"?',

        lang: 'English speaking {user}',
        loginAsUser: 'Login as {user}',
        editUser: 'Edit {user}',
        deleteUser: 'Delete {user}',
        deleteUserConfirmText: 'The {user} will be deleted. Confirm action?',
        profile: '{user} profile',
        selectUser: 'Select an {user}',
        selectUsers: 'Select {user}',
        emailNotExist: '{user} with this email was not found',
        loginHelperText:
            'After changing the login, the account will need to be activated. An email with an activation link will be sent to the new email address.',
    },
};

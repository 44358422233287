import { LocalesTree } from '@platform/front-utils';

const startPage: LocalesTree = {
    headerLinks: {
        about: 'About product',
        news: 'News',
        documents: 'Documents',
        resources: 'Other resources',
        contacts: 'Contacts',
        radar: 'Radar',
        login: 'Sign in',
        loginAccount: 'Login to your account',
    },
    loginAnonymously: 'Login without registration',
    title: 'System for collecting and verifying ideas and suggestions',
    productDescription:
        'A customizable information environment for managing the intellectual capital of an organization',
    logoAlt: 'TOT X logo',
    footerLogoAlt: 'TOT X logo',
};

export default startPage;

import { BoxProps, ButtonProps, GridProps } from '@mui/material';
import { collaborationFieldBlurDelay } from '@platform/formiojs-react';
import { FormSavePanelLayout } from '@platform/front-core';
import { useAntiDoubleClick } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useCampaignRequestFormContext } from '../../../../../../hooks';
import { CampaignRequestSavePanelLeftElement as CampaignRequestSavePanelLeftElementInj } from './campaign-request-save-panel-left-element';

const savePanelWrapperProps: BoxProps = {
    pb: 1,
};

const savePanelInnerProps: GridProps = {
    alignItems: 'center',
};

// TODO Возможно нужно будет вынести в front-core
export const CampaignRequestSavePanel = observer((): JSX.Element => {
    const [CampaignRequestSavePanelLeftElement] = di(
        [CampaignRequestSavePanelLeftElementInj],
        CampaignRequestSavePanel,
    );

    const { collaborationFormModel, goToView } = useCampaignRequestFormContext();
    const { isFailedConnection, sendCancel, sendSave } = collaborationFormModel;

    const collaborationFormActiveUsers = <CampaignRequestSavePanelLeftElement />;

    const onCloseFormFetch = (): void => {
        // Без timeout сначала срабатывает 'cancel', а потом blur,
        setTimeout(() => {
            sendCancel();
            goToView();
        }, collaborationFieldBlurDelay);
    };

    const onSaveFormFetch = (): void => {
        // Без timeout сначала срабатывает 'save', а потом blur,
        setTimeout(() => {
            sendSave();
        }, collaborationFieldBlurDelay);
    };

    const [isSendingSubmit, endIconSubmit, onSaveForm] = useAntiDoubleClick(onSaveFormFetch);
    const [isSendingCancel, endIconCancel, onCloseForm] = useAntiDoubleClick(onCloseFormFetch);

    const cancelBtnProps: ButtonProps = {
        onClick: onCloseForm,
        endIcon: endIconCancel,
        disabled: isSendingCancel,
    };

    const submitBtnProps: ButtonProps = {
        onClick: onSaveForm,
        endIcon: endIconSubmit,
        disabled: isSendingSubmit,
    };

    return (
        <FormSavePanelLayout
            leftElement={collaborationFormActiveUsers}
            wrapperProps={savePanelWrapperProps}
            innerProps={savePanelInnerProps}
            cancelBtnProps={cancelBtnProps}
            submitBtnProps={submitBtnProps}
            isWithSubmitButton={!isFailedConnection}
            isWithCancelButton={!isFailedConnection}
        />
    );
});

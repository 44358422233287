import { Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import {
    defaultInfoSidebarWidth,
    Loader,
    ObjectContentLayoutProps,
    ObjectLayout,
    ObjectLayoutProps,
} from '@platform/front-core';
import { SxStyle } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { UserPageContext } from '../../../contexts';
import { useStore } from '../../../hooks';
import { UserCardModel } from '../../../models';
import { UserPageContextType } from '../../../types';
import { UserPageHeader, UserPageMainContent, UserPageSidebar } from './components';

export type UserPageRouteParams = {
    id: string;
};

export type UserPageProps = {
    breadcrumbs?: JSX.Element;
};

const infoSidebarWrapperSx: SxStyle = {
    padding: 2,
    maxWidth: defaultInfoSidebarWidth,
    width: '100%',
};

const infoSidebar: ObjectLayoutProps['infoSidebar'] = {
    elements: {
        contentElement: <UserPageSidebar />,
        wrapperSx: infoSidebarWrapperSx,
    },
};

const loaderSx: SxProps = {
    margin: 'auto',
};

export const UserPage = observer((props: UserPageProps): JSX.Element => {
    const { breadcrumbs } = props;
    const { id } = useParams<UserPageRouteParams>();
    const { userStore } = useStore();
    const { locale } = useIntl();
    const model = useMemo(() => new UserCardModel(userStore, id), [userStore, id]);
    const { formInfo, isLoading, load } = model;

    useEffect(() => {
        load();
    }, [id, locale]);

    const contextValue: UserPageContextType = {
        userModel: model,
    };

    const header = <UserPageHeader breadcrumbs={breadcrumbs} />;

    const contentLayout: ObjectContentLayoutProps = {
        content: (
            <React.Fragment>
                {isLoading ? (
                    <Grid sx={loaderSx}>
                        <Loader />
                    </Grid>
                ) : (
                    <React.Fragment>{formInfo && <UserPageMainContent formDTO={formInfo} />}</React.Fragment>
                )}
            </React.Fragment>
        ),
    };

    return (
        <UserPageContext.Provider value={contextValue}>
            <ObjectLayout header={header} contentLayout={contentLayout} infoSidebar={infoSidebar} />
        </UserPageContext.Provider>
    );
});

import { FormControlLabel, Radio } from '@mui/material';
import { IdTitle } from '@platform/front-types';
import React from 'react';
import { requestFormCardSxStyles } from './requestFormCardSxStyles';

export type RequestFormCardTitleWithRadioProps = IdTitle & {
    name: string;
};

export const RequestFormCardTitleWithRadio = (props: RequestFormCardTitleWithRadioProps): JSX.Element => {
    const { id, title, name } = props;

    const radioButton = <Radio sx={requestFormCardSxStyles.radio} />;
    return <FormControlLabel name={name} value={id} id={id} control={radioButton} label={title} />;
};

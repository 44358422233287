import { useFlag } from '@platform/front-utils';
import { useLoadCampaignRequestForms } from './create-model';
import { useStore } from './useStore';

export type useCreateRequestDialogType = {
    isCreateRequestDialogOpen: boolean;
    openCreateRequestDialog: (campaignId: string) => Promise<void>;
    closeCreateRequestDialog: VoidFunction;
};

/**
 * Хук для открывания/закрывания CreateRequestDialog.
 * @return {useCreateRequestDialogType} Утилиты для управления CreateRequestDialog.
 */
export const useCreateRequestDialog = (): useCreateRequestDialogType => {
    const [isModalOpen, openModal, closeModal] = useFlag();
    const { campaignsStore } = useStore();

    const { loadCampaignRequestFormsModel } = useLoadCampaignRequestForms();

    const openCreateRequestDialog = (campaignId: string): Promise<void> => {
        return loadCampaignRequestFormsModel(campaignId).then((requestFormsModel) => {
            const { campaignRequestForms } = requestFormsModel;

            if (campaignRequestForms.length > 1) {
                openModal();
            } else {
                return campaignsStore.createRequest(campaignRequestForms[0].id);
            }
        });
    };

    const requestDialogUtils = {
        isCreateRequestDialogOpen: isModalOpen,
        openCreateRequestDialog,
        closeCreateRequestDialog: closeModal,
    };

    return requestDialogUtils;
};
